@use "../../../../styles/variables.scss" as color;
.container {
  display: flex;
  align-items: center;
  // width: 700px;
  position: relative;
  padding-bottom: 5px;
  .time {
    position: absolute;
    font-weight: 500;
    font-size: 23px;
    line-height: 36px;
    color: #000000;
    left:-30% ;
    transform: translateY(-30%);
    &.timeDark{
      color:white;
    }
  }
  .line {
    position: absolute;
    left: -10%;
    margin-left: 14px;
    margin-right: 6px;
    height: 114px;
    border-left: 16px solid #e76859;
  }
  .image {
    width: 100px;
    height: 100px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 10px;
    object-fit: cover;
  }
  .imageSkel{
    width: 80px;
    height: 80px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 10px;
    background-color: rgba(128, 128, 128, 0.088);

  }
  
  .details {
    margin-left: 24px;
    max-width: 350px;
    width:350px;
    .title {
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: #000000;
      max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      &.titleDark{
        color:color.$white-text-color;
      }
    }
    .text {
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      color: #666666;
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &.textDark{
        color:rgb(224, 224, 224);
      }
    }
  }
  @media screen and (max-width: 3000px) {
    .time {
      font-size: 18px;
      line-height: 28.8px;
      left: -26%;
    }
    .line {
      margin-left: 11.2px;
      margin-right: 4.8px;
      height: 91.2px;
      left: -8%;
      border-left: 12.8px solid #e76859;
    }
    .image {
      width: 80px;
      height: 80px;
    }
    .details {
      margin-left: 19.2px;
      max-width: 424px;
      .title {
        font-size: 19.2px;
        line-height: 28.8px;
      }
      .text {
        font-size: 19.2px;
        line-height: 28.8px;
      }
    }
  }
  @media screen and (max-width: 1150px) {
    margin-left: 33%;
    .time {
      font-size: 19.2px;
      line-height: 28.8px;
      left: -44%;
    }
    .line {
      margin-left: 11.2px;
      margin-right: 4.8px;
      height: 91.2px;
      left: -12%;
      border-left: 12.8px solid #e76859;
    }
    .image {
      width: 80px;
      height: 80px;
    }
    .details {
      margin-left: 19.2px;
      max-width: 425px;
      
      .title {
        font-size: 19.2px;
        line-height: 28.8px;
        max-width: 300;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .text {
        font-size: 19.2px;
        line-height: 28.8px;
        max-width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  @media screen and (max-width: 700px) {
    margin-left: 35%;
    .time{
     font-size: 14px;
    //  width: 100px;
    left: -50%;
    }
    .line {
      left: -16%;
    }
    .details {
        margin-left: 24px;
        max-width: 200px;
        width:200px;
      .title {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .text {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  @media screen and (max-width: 500px) {
    margin-left: 43%;
    .time{
      font-size: 14px;
     //  width: 100px;
     left: -68%;
     }
     .line {
       left: -23%;
     }
    .details {
      margin-left: 24px;
      max-width: 120px;
      width:120px;
      .title {
        max-width: 120px;
        font-size: 14px;
        line-height: 23.04px;
        overflow: hidden;
      }
      .text {
        max-width: 120px;
        font-size: 14px;
        line-height: 23.04px;
      }
    }
  }
}
.container:hover {
  cursor: pointer;
}
