@use "../../../../styles/variables.scss" as color;
.images_container {
  display: flex;
  gap: 10px;
  .big_image_container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    .image {
      width: 563px;
      height: 410.83px;
      object-fit: cover;
      border-radius: 19px 19px 0px 0px;
    }
    .image_details {
      width: 563px;
      height: 40px;
      background: #f8f8f9;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding-top: 15px;
      &.image_detailsDark{
        background-color: color.$dark-text-color;
      }
      .image_details_text {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #0298a6;
        display: flex;
        align-items: center;
        &.image_details_textDark{
          color:#015866
        }
        .icon {
          display: inline-block;
          margin-right: 5px;
          font-size: 25px;
        }
      }
    }
    .wishlist {
      position: absolute;
      top: 8px;
      right: 19px;
      width: 28px;
      height: 28px;
      color: #0298a6;
      cursor: pointer;
    }
    .featured {
      position: absolute;
      top: 0;
      left: 0px;
      background: #ffcb3c;
      border-radius: 19px 0px;
      padding: 4px 27px;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: #000000;
    }
    .social_media_container {
      margin-top: 20px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #353844;
      display: flex;
      align-items: center;
      gap: 15px;
      &.social_media_containerDark{
        color:color.$white-text-color;
      }
      .social {
        display: inline-block;
        width: 32px;
        height: 32px;
        cursor: pointer;
        &.socialDark{
          color: #015866;
        }
        &:hover{
          transform: scale(1.03);
    
        }
      }
    }
    .copied{
      
      width: 70px;
      height: 30px;
     
      .copiedp{
        width: 70px;
        height: 30px;
        font-weight: 500;
        color: #0298a6;
        border-radius: 50px;
        background-color: #cecece30;
        margin-top: 10px;
        align-items: center;
        display: flex;
        justify-content: center;
      }
    }
  }
  .small_images_container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 80px;
    max-height: 450px;
    overflow: auto;
    // overflow-y: scroll;
    .small_image {
      width: 81px;
      height: 73.97px;
      object-fit: cover;
      border-radius: 19px;
      cursor: pointer;
    }
  }
  @media screen and (max-width: 1380px) {
    .big_image_container {
      .image {
        width: 394.1px;
        height: 287.581px;
        border-radius: 13.3px 13.3px 0px 0px;
      }
      .image_details {
        width: 394.1px;
        height: 28px;
        .image_details_text {
          font-size: 11.2x;
          line-height: 16.8px;
          .icon {
            font-size: 17.5px;
          }
        }
      }
      .wishlist {
        top: 5.6px;
        right: 13.3px;
        width: 19.6px;
        height: 19.6px;
        cursor: pointer;
      }
      .featured {
        top: 0;
        left: 0x;
        border-radius: 13.3px 0px;
        padding: 2.8px 18.9px;
        font-size: 14px;
        line-height: 21px;
      }
      .social_media_container {
        margin-top: 14px;
        font-size: 11.2px;
        line-height: 13.3px;
        gap: 10.5px;
        .social {
          width: 22.4px;
          height: 22.4px;
        }
      }
    }
    .small_images_container {
      gap: 16.8px;
      max-height: 315px;
      .small_image {
        width: 56.7px;
        height: 51.779px;
        border-radius: 13.3px;
      }
    }
  }
  @media screen and (max-width: 520px) {
    .big_image_container {
      .image {
        width: 315.28px;
        height: 230.0648px;
        border-radius: 10.64px 10.64px 0px 0px;
      }
      .image_details {
        width: 315.28px;
        height: 22.4px;
        .image_details_text {
          font-size: 9px;
          line-height: 13.44px;
          .icon {
            font-size: 14px;
          }
        }
      }
      .wishlist {
        top: 4.48px;
        right: 10.64px;
        width: 15.68px;
        height: 15.68px;
      }
      .featured {
        top: 0;
        left: 0px;
        border-radius: 10.64px 0px;
        padding: 2.24px 15.12x;
        font-size: 11.2px;
        line-height: 16.8px;
      }
      .social_media_container {
        margin-top: 11.2px;
        font-size: 8.96px;
        line-height: 10.64px;
        gap: 8.4px;
        .social {
          width: 17.92px;
          height: 17.92px;
        }
      }
    }
    .small_images_container {
      gap: 13.44px;
      max-height: 252px;
      .small_image {
        width: 45.36px;
        height: 41.4232px;
      }
    }
  }
  @media screen and (max-width: 500px) {
    gap: 3px;
    flex-direction: column;
    .small_images_container {
      gap: 13.44px;
      max-width: 300px;
      overflow-x: auto;
      margin-top: 15px;
      display: flex;
      width: 300px;
      flex-direction: row;
      .small_image {
        width: 45.36px;
        height: 41.4232px;
      }
    }
  }
 
}
.confirm_title {
  font-weight: 600;
  font-size: 25.6px;
  line-height: 38.4px;
  color: color.$dark-text-color;
  margin-bottom: 15px;
}
.confirm_message {
  font-weight: 500;
  font-size: 19.2px;
  line-height: 28.8px;
  color: color.$dark-text-color;
  margin-bottom: 15px;
}
.buttons_container {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  button {
    border-radius: 10px;
    padding: 10.4px 35.2px;
    font-weight: 600;
    font-size: 19.2px;
    line-height: 28.8px;
    color: #ffffff;
  }
  .confirm {
    background-color:  color.$primary-color;
  }
  .cancel {
    background-color: color.$red-color;
  }
}