.carousel {
  height: 515.04px;
  position: relative;
  .myswiper {
    width: 100%;
    .swiperslide {
      img {
        width: 100%;
        height: 515.04px;
        object-fit: cover;
        background-repeat: no-repeat;
      }
    }
  }
}
.bg {
  height: 515.04px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  .countdown {
    font-size: 40px;
    font-weight: 600;
    color: white;
  }
}
.bg2 {
  height: 515.04px;
  width: auto;
  background-color: gray;
  background-size: cover;
}
.flex {
  display: flex;
  .btn {
    font-size: 30px;
    color: rgb(104, 93, 93);
    z-index: 2;
    cursor: pointer;
    font-weight: bolder;
    
  }
  .left {
    position: absolute;
    top: 50%;
    right: 1px;
    width:33px;
    height: 70px;
    border-radius: 50px  0 0 50px ;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.379);
    display: flex; 
    align-items: center; 
    justify-content: center; 
    padding-left: 5px;
    &:hover{
      background-color: white;
    }
  }
  .right {
    position: absolute;
    top: 50%;
    left: 1px;
    transform: translateY(-50%);
    width:33px;
    height: 70px;
    border-radius:0 50px 50px 0;
    background-color: rgba(255, 255, 255, 0.379);
    display: flex; 
    align-items: center; 
    justify-content: center; 
    padding-right: 5px;
    &:hover{
      background-color: white;
    }  
  }
}
  @media screen and (max-width: 850px) {
    .flex {    
      .btn {
        font-size: 78px;
      }
  }
}
  @media screen and (max-width: 650px) {
    .flex {    
      .btn {
        font-size: 60px;
      }
  }
}
  @media screen and (max-width: 480px) {
    .flex {    
      .btn {
        font-size:35px;
      }
  }
}
