.edit--phone {
  .PhoneInput {
    width: 376px;
    height: 58px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
  }
  .PhoneInputInput {
    background: #ffffff;
    padding-left: 50px;
  }
  .PhoneInputInput:focus {
    outline: none;
  }
  .PhoneInputCountryIcon {
    width: 40px;
    height: auto;
    margin: 0 auto;
  }
  .PhoneInputCountry {
    width: 70px;
    border-right: 2px solid #d9d9d9;
  }
  .PhoneInputCountrySelectArrow {
    display: none;
  }
  @media screen and (max-width: 800px) {
    .PhoneInput {
      width: 300.8px;
      height: 46.4px;
    }
    .PhoneInputInput {
      padding-left: 35px;
    }
    .PhoneInputCountryIcon {
      width: 28px;
    }
    .PhoneInputCountry {
      width: 79px;
    }
  }
}
