.circle {
    display: inline-block;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    text-align: center;
    vertical-align: middle;
    line-height: 40px;
    font-size: 20px;
    font-weight: bold;
    margin-right: 10px;  
    &.yellow {
      background-color: #ffcb3c;
    }
  
    &.blue {
      background-color: #59aef7;
    }
  
    &.red {
      background-color: #e76859;
    }
  
    &.gray {
      background-color: gray;
    }
  
    .letter {
      color: white;
      text-transform: uppercase;
    }
  }
  