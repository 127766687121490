.image_section {
  margin-top: 95px;
  margin-bottom: 95px;
  .label {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: #353844;
  }
  .wrapper {
    display: flex;
    margin-top: 45px;
    column-gap: 41px;
    row-gap: 52px;
    flex-wrap: wrap;
    .image_container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 124px;
      height: 124px;
      background: #f8feff;
      border: 2.6px solid #ace0e0;
      border-radius: 5.3px;
      img {
        cursor: pointer;
      }
      .image {
        width: 70%;
      }
      .full_image {
        width: 100%;
        object-fit: cover;
      }
    }
  }
  @media screen and (max-width: 730px) {
    margin-top: 35px;
    margin-bottom: 35px;
    .label {
      font-size: 25.6px;
      line-height: 38.4px;
    }
    .wrapper {
      margin-top: 10px;
      column-gap: 15px;
      row-gap: 10px;
      .image_container {
        width: 99.2px;
        height: 99.2px;
      }
    }
  }
}
