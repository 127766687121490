.owner{
    text-align: center;
    padding-top: 13%;
    .ownerText{
        font-size:13px ;
        font-weight: 500;
        font-family:poppins;
    }
    @media (max-width: 1050px){
        text-align: start;
        padding-top: 0%;
    }
    }
