@use "../../../../styles/variables.scss" as color;
.search__section {
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  &.search__sectionDark{
    background-color: color.$dark-text-color;
  }
  .title {
    font-weight: 600;
    font-size: 40px;
    line-height: 60px;
    text-align: center;
    color: color.$dark-text-color;
    &.titleDark{
      color: color.$white-text-color;
    }
  }
  .search__type {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: color.$dark-text-color;
    border: 2px solid color.$primary-color;
    border-radius: 15px;
    &.search__typeDark{
      border: 2px solid #015866;
    }
    button {
      flex: 1;
      padding: 15px 31px;
      background-color: white;
      cursor: pointer;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      color: color.$dark-text-color;
      //   border: hidden;
      border-radius: 16px;
      width: 150px;
      &.buttonDark{
        background-color: color.$dark-text-color;
        color:color.$white-text-color
      }
      &.active {
        border: 2px solid color.$primary-color;
        border-radius: 12px;
        background-color: color.$primary-color;
        color:color.$white-text-color;
        width: 150px;
      }
      &.activeDark{
        background-color:#015866 ;
        border: 2px solid #015866;
        border-radius: 12px;
        color:color.$white-text-color;
        width: 150px;
      }
    }
  }
  @media screen and (max-width: 410px) {
    padding: 0;
  }
  @media screen and (max-width: 520px) {
    padding: 15px;
  .search__type {
    width: 350px;
    button {
      padding: 15px 15.8px;
      width: 100px;
      font-size: 15px;
      &.active {
        border: 2px solid color.$primary-color;
        border-radius: 12px;
        background-color: color.$primary-color;
      }
      
    }
  }
}
  @media screen and (max-width: 396px) {
    padding: 15px;
  .search__type {
    width: 290px;
    button {
      padding: 10px 15px;
      width: 90px;
      &.active {
        border: 2px solid color.$primary-color;
        border-radius: 12px;
        background-color: color.$primary-color;
        width: 90px;
      }
    }
  }
}
  .search__component {
    margin-top: 50px;
  }
  .search__filter {
    margin-top: 17px;
    display: flex;
    .button {
      background: color.$light-background-color;
      border: 3px solid color.$primary-color;
      border-radius: 15px;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      color: color.$primary-color;
      width: 256px;
      height: 66px;
      padding: 15px 20px 15px 36px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      &.buttonDark{
        background: color.$dark-text-color;
      }
    }
    .price__button {
      margin-left: 95px;
      margin-right: 95px;
    }
    .select_container {
      position: relative;
      .select_icon {
        position: absolute;
        top: 50%;
        right: 10px;
        color: color.$primary-color;
        transform: translateY(-50%);
      }
      .select {
        padding-left: 5px ;
        width: 256px;
        height: 66px;
        background: color.$light-background-color;
        border: 3px solid color.$primary-color;
        border-radius: 15px;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: color.$primary-color;
        cursor: pointer;
        margin-right: 10px;
        // padding: 15px 20px 15px 36px;
        // replace dafault styling (arrow)
        // appearance: none;
        // -webkit-appearance: none;
        // -moz-appearance: none;
        &.selectDark{
          background-color:color.$dark-text-color ;
        }
        &:focus,
        &:hover {
          outline: none;
          // border: 1px solid #bbb;
        }
        // option {
        //   //we can only styles
        //   // background: ;
        //   // color: ;
        // }
      }
    }
  }
  .search__filterS {
    margin-top: 17px;
    display: flex;
    .button {
      background: color.$light-background-color;
      border: 3px solid color.$primary-color;
      border-radius: 15px;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      color: color.$primary-color;
      width: 256px;
      height: 66px;
      padding: 15px 20px 15px 36px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      &.buttonDark{
        background: color.$dark-text-color;
      }
    }
    
    .select_containerS {
      position: relative;
      .select_icon {
        position: absolute;
        top: 50%;
        right: 10px;
        color: color.$primary-color;
        transform: translateY(-50%);
      }
      .selectS {
        padding-left: 5px ;
        width: 256px;
        height: 66px;
        background: color.$light-background-color;
        border: 3px solid color.$primary-color;
        border-radius: 15px;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: color.$primary-color;
        cursor: pointer;
        margin-right: 10px;       
        &.selectSDark{
          background-color:color.$dark-text-color ;
        }
        &:focus,
        &:hover {
          outline: none;
        }
        
      }
    }
  }
  @media screen and (max-width: 995px) {
    .search__filter {
      width: 350px;
      .button{
      width: 150px;
      font-size: 20px;
      padding: 12px 12px 12px 10px;
      }
      .price__button {
        margin-left: 35px;
        margin-right: 35px;
      }
  
    
    .select_container {
    .select {
      width: 160px;
      font-size: 18px;
      // padding: 12px 12px 12px 10px;
    }
  }
  }
}
  @media screen and (max-width: 610px) {
    .search__filterS {
      width: 200px;
      .button{
      width: 150px;
      font-size: 20px;
      padding: 12px 12px 12px 10px;
      }
    .select_containerS {
    .selectS {
      width: 160px;
      font-size: 18px;
      // padding: 12px 12px 12px 10px;
    }
  }
  }
}
  @media screen and (max-width: 510px) {
    .search__filter {
      margin-left: 100px;
      .button{
      width: 100px;
      font-size: 12px;
      height: 50px;
      padding: 12px 12px 12px 10px;
      }
      .price__button {
        margin-left: 20px;
        margin-right: 20px;
        padding-left: 30px;
      }
  
    
    .select_container {
      .select_icon {
        position: absolute;
        top: 50%;
        right: 10px;
        width: 12px;
      }
    .select {
      width: 110px;
      height: 50px;
      font-size: 12px;
      // padding: 4px 6px 12px 12px;
    }
  }
  }
  .search__filterS {
    width: 200px;
    .button{
    width: 120px;
    font-size: 20px;
    padding: 12px 12px 12px 10px;
    }
  .select_containerS {
  .selectS {
    width: 160px;
    font-size: 18px;
    height: 50px;
    // padding: 12px 12px 12px 10px;
  }
}
}
}
  @media screen and (max-width: 360px) {
    .search__filter {
      margin-left: 130px;
      .button{
      width: 80px;
      font-size: 12px;
      height: 50px;
      padding: 12px 12px 12px 10px;
      }
      .price__button {
        margin-left: 20px;
        margin-right: 20px;
        // padding-left: 30px;
      }
  
    
    .select_container {
      .select_icon {
        position: absolute;
        top: 50%;
        right: 10px;
        width: 12px;
      }
    .select {
      width: 93px;
      height: 42px;
      font-size: 9px;
      // padding: 4px 6px 12px 12px;
    }
  }
  }
}
}
