.cardSkelContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content:space-between;
    align-items: flex-start;
   
  }
.cardSkel {
    
    width: 27%;
    // height:400px;
    border-radius: 10px;
    background-color: #E8E8E8;
    margin-bottom: 3%;
    position: relative;
    overflow: hidden;    
    .cardHeaderSkel {
      width: 100%;
      height: 200px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background-color: #D8D8D8;
    }
    .cardBodySkel {
      padding: 16px;
  
      .cardTitleSkel {
        width: 80%;
        height: 24px;
        margin-bottom: 12px;
        border-radius: 4px;
        background-color: #D8D8D8;
      }
  
      .cardDescriptionSkel {
        width: 100%;
        height: 100px;
        border-radius: 4px;
        background-color: #D8D8D8;
        margin-top: 16px;
      }
    }
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, transparent, #E8E8E8, transparent);
        animation: slide-in 1s ease-in-out infinite;
      }
   
  @keyframes slide-in {
    0% {
      left: -100%;
    }
    100% {
      left: 100%;
    }
  }
  }
  @media screen and (max-width: 720px) {
    .cardSkel {
      width: 47%;
      
    }
  }
  
  @media screen and (max-width: 485px) {
    .cardSkel{
        width: 70%;
        margin-left: 15%;
    }
  }
 
  