$primary-color: #0298a6;
$secondary-color: #ace0e0;
$dark-text-color: #353844;
$white-text-color: #f8feff;
$light-text-color: #a1a1a1;
$light-gray-color: #c2c2c2;
$light-background-color: #f8f8f9;
$background-category-dropdown: #f3f2f2;
$border-category-dropdown: #9d9d9d;
$header-color: #f3f2f2;
$footer-color: #ececec;
$error_color: #ba2c2d;
$red-color: #fe3f3f;
$green-color: #43ffd3;
$yellow-color: #ffcb3c;
$box-shadow-card: 0px 6px 17px rgba(0, 0, 0, 0.25);
$box-shadow-input: 2px 3px 8px rgba(0, 0, 0, 0.25);
$box-shadow-subcategory: 3px 3px 4px rgba(0, 0, 0, 0.25);
$box-shadow-button: inset -5px 5px 0px rgba(0, 0, 0, 0.25);
