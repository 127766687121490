@use "../../../../styles/variables.scss" as color;
.section {
  &.sectionDark{
    background-color: color.$dark-text-color;
  }
  .header {
    padding: 0px 15px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 13px;
    padding-right: 45px;
    @media screen and (max-width: 1440px) {
      padding-right: 30px;
    }
    @media screen and (max-width: 570px) {
      padding-right: 10px;
    }
    .header__title {
      font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    padding-left: 40px;
    color: color.$dark-text-color;
    @media screen and (max-width: 950px) {
      padding-left: 5px;
    }
    &.header__titleDark{
      color: color.$white-text-color;
    }
    }
    .see_all {
      font-weight: 600;
      font-size: 32px;
      line-height: 48px;
      color: color.$primary-color;
      cursor: pointer;
      .S_all{
        text-decoration: none;
        color:color.$primary-color;
        &:hover{
          color:#01b1c0 ;
        }
        .arrow {
          transform: translateY(-5%);
          display: inline-block;
        }
        &.S_allDark{
          color:#015866;
        }
      }
      
    }
   
  
  }
  .swiper__section {
    padding-left: 60px;
    @media screen and (max-width: 950px) {
      padding-left: 20px;
    }
    .myswiper {
      height: 1017px;
      .swiperslide {
        height: calc((100% - 30px) / 2) !important;
      }
      @media screen and (max-width: 1400px) {
        height: 508px;
      }
    }
    @media (max-width: 1290px) {
      .swiperslide {
        flex-basis: 25%;
      }
    }
    @media (max-width: 930px) {
      .swiperslide {
        flex-basis: 33%;
      }
    }
    @media (max-width: 580px) {
      
      .swiperslide {
        flex-basis: 40%;
      }
    }
    @media (max-width: 475px) {
      .swiperslide {
        flex-basis: 50%;
      }
    }
    @media (max-width: 385px) {
      .swiperslide {
        flex-basis: 60%;
      }
    }
    @media (max-width: 335px) {
      .swiperslide {
        flex-basis: 70%;
      }
    }
  }
}
@media screen and (max-width: 570px) {
  
  .section {
    .header {
  .header__title {
    font-size: 19px;
  }
  .see_all {
    font-size: 16px;
    margin-top: 3px;
    margin-left: 4px;
  }
    }
  }
}
