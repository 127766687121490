@use "../../../styles/variables.scss" as color;
.card {
  width: 300px;
  height: 472px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: color.$box-shadow-card;
  margin-bottom: 50px;
  cursor: pointer;
  &.cardDark{
    background: #015866;
  }
  .image__container {
    position: relative;
    cursor: pointer;
    .card__image {
      width: 312px;
      height: 311.31px;
      border-radius: 10px;
      object-fit: cover;
      z-index: 5;
    }
    .condition_container {
      position: absolute;
      bottom: -28.5px;
      right: 18px;
      z-index: 3;
      .condition_image {
        width: 52px;
        height: 30px;
      }
      .condition {
        font-weight: 500;
        font-size: 14px;
        line-height: 19.2px;
        text-align: center;
        color: #ffffff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .heart {
      position: absolute;
      top: 11.86px;
      right: 12px;
      color: color.$primary-color;
      width: 35px;
      height: 29.65px;
      cursor: pointer;
    }
    .timer {
      position: absolute;
      left: 7px;
      bottom: 14px;
      background-color: color.$primary-color;
      border-radius: 10px;
      padding: 3px 6px;
      display: flex;
      align-items: center;
      .timer__icon {
        color: white;
        width: 18px;
        height: 19.77px;
        margin-right: 1.9px;
      }
      .timer__text {
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        text-align: center;
        color: #ffffff;
      }
    }
    .featured {
      position: absolute;
      top: -1px;
      left: -1px;
      width: 128px;
      height: 44.47px;
      background-color: color.$yellow-color;
      padding: 7.91px 9.13px 6.92px 8.91px;
      border-radius: 10px 0px;
      p {
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: #000000;
      }
    }
  }
  .details {
    padding: 4px 21px;
    .price__container {
      display: flex;
      align-items: center;
      &.price__containerdisc{
        display: flex;
      flex-direction: column;
      align-items: normal;
      }
      .price {
        font-style: normal;
        font-weight: 600;
        font-size: 27px;
        line-height: 40px;
        color: color.$dark-text-color;
        max-width: 250px; 
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &.priceDark{
          color: color.$white-text-color;
        }
      }
      .oldpd{
        display: flex;
        align-items: center;
        margin-top: -20px;
      .oldprice {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 40px;
        margin-left: 10px;
        color: #e76859;
        text-decoration: line-through;
        max-width: 150px; 
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        // &.oldpriceDark{
        //   color: color.$red-color;
        // }
      }
      .discount{
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 40px;
        margin-left: 10px;
        color:#e76859;
      
      // &.discountDark{
      //   color: color.$red-color;
      // }
    }
    }
      .bid {
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        text-align: center;
        color: color.$dark-text-color;
        margin-left: 8px;
        &.bidDark{
          color: color.$white-text-color;
        }
      }
    }
    .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 17px;
      color: color.$dark-text-color;
      margin-top: 14px;
      text-align: start;
      &.titledisc{
        margin-top: -8px;
      }
      &.titleDark{
        color: color.$white-text-color;
      }
    }
    .location {
      margin-top: 8px;
      text-align: start;
    }
    .time {
      margin-top:-12px;
      text-align: start;
    }
    .location__icon,
    .time__icon {
      color: color.$primary-color;
      display: inline-block;
    }
    .location__text,
    .time__text {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 107%;
      color: color.$light-text-color;
    }
  }
  @media screen and (max-width: 1200px) {
    width: 218.4px;
    height: 335px;
    .image__container {
      .card__image {
        width: 218.4px;
        height: 217.9px;
      }
      .heart {
        position: absolute;
        top: 8.3px;
        right: 8.4px;
        color: color.$primary-color;
        width: 24.5px;
        height: 20.755px;
        cursor: pointer;
      }
      .timer {
        left: 4.9px;
        bottom: 9.8px;
        padding: 2.1px 4.2px;
        .timer__icon {
          color: white;
          width: 12.6px;
          height: 13.8px;
          margin-right: 1.33px;
        }
        .timer__text {
          font-size: 12px;
          line-height: 14px;
        }
      }
      .featured {
        width: 89.6px;
        height: 31.1px;
        padding: 5.53px 6.39px 4.84px 6.23px;
        p {
          font-size: 12.6px;
          line-height: 18.9px;
          text-align: center;
          color: #000000;
        }
      }
    }
    .details {
      padding: 2.8px 14.7px;
      .price__container {
        .price {
          font-size: 18.9px;
          line-height: 28px;
        }
        .bid {
          font-size: 9.1px;
          line-height: 14px;
          margin-left: 5.6px;
        }
        .oldpd{
          display: flex;
          align-items: center;
        .oldprice {
          font-size: 14px;
          line-height: 30px;
          // &.oldpriceDark{
          //   color: color.$red-color;
  
          // }
        }
        .discount{
          font-size: 12px;
          line-height: 30px;
        }
        // &.discountDark{
        //   color: color.$red-color;
        // }
      }
      }
      .title {
        font-size: 11.2px;
        line-height: 11.9px;
        margin-top: 9.8px;
      }
      .location {
        margin-top: 9.68px;
      }
      .time {
        margin-top:-22px;
      }
      .location__text,
      .time__text {
        font-size: 9px;
      }
    }
  }
  @media screen and (max-width: 870px) {
    width: 184.23px;
    height: 276.6px;
    .image__container {
      .card__image {
        width: 184.23px;
        height: 183.82px;
      }
      .timer {
        .timer__text {
          font-size: 10.5px;
          line-height: 10px;
        }
      }
      .featured {
        width: 71.68px;
        height: 24.88px;
        padding: 4.424px 5.112px 3.872px 4.98px;
        p {
          font-size: 10px;
          line-height: 15.12px;
        }
      }
    }
    .details {
      padding: 2.24px 11.76px;
      .price__container {
        .price {
          font-size: 15.12px;
          line-height: 22.4px;
        }
        .oldpd{
          display: flex;
          align-items: center;
          margin-top: -8px;
        .oldprice {
          font-size: 11px;
          line-height: 20px;
          &.oldpriceDark{
            color: color.$red-color;
  
          }
        }
        .discount{
          font-size: 8px;
          line-height: 20px;
          margin-left: 4px;
        }
        &.discountDark{
          color: color.$red-color;
        }
      }
        .bid {
          font-size: 7.28px;
          line-height: 11.2px;
          margin-left: 4.48px;
        }
      }
      .title {
        font-size: 8.96px;
        line-height: 9.52px;
        margin-top: 7.84px;
      }
      .location {
        margin-top: -7px;
      }
        .time {
          margin-top:-25px;
        }      
      .location__text,
      .time__text {
        font-size: 7px;
      }
      .location__icon,
      .time__icon {
        font-size: 13px;
      }
    }
  }
  @media screen and (max-width: 800px) {
    margin-bottom: 30px;
  }
  @media screen and (max-width: 384px) {
    margin-bottom: 30px;
  }
}
.confirm_title {
  font-weight: 600;
  font-size: 25.6px;
  line-height: 38.4px;
  color: color.$dark-text-color;
  margin-bottom: 15px;
  &.confirm_titleDark{
    color:white
}
}
.confirm_message {
  font-weight: 500;
  font-size: 19.2px;
  line-height: 28.8px;
  color: color.$dark-text-color;
  margin-bottom: 15px;
  &.confirm_messageDark{
    color:white
}
}
.buttons_container {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  button {
    border-radius: 10px;
    padding: 10.4px 35.2px;
    font-weight: 600;
    font-size: 19.2px;
    line-height: 28.8px;
    color: #ffffff;
  }
  .confirm {
    background-color:  color.$primary-color;
  }
  .cancel {
    background-color: color.$red-color;
  }
  @media screen and (max-width: 500px) {
    justify-content: flex-start;
  }
}
////mobile card
.cardmb {
  height: 166px;
  // width: 450px;
  background: #ffffff;
  box-shadow: 0px 2.93px 8.32px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-bottom: 15px;
  display: flex;
  cursor: pointer;
  &.cardmbDark{
    background-color: #015866;
  }
  .image__containermb {
    position: relative;
    width: 229px;
    height: 166px;
    .card__imagemb {
      width: 160px;
      height: 166px;
      object-fit: cover;
      border-radius:10px ;
    }
    .featured_containermb {
      position: absolute;
      top: -1px;
      left: 0px;
      width: 65px;
      height: 25px;
      background-color: color.$yellow-color;
      padding: 7.91px 9.13px 6.92px 8.91px;
      border-radius:10px 0  10px ;
      border-radius:10px 0  10px 0;
      .featured_textmb {
        font-weight: 600;
        font-size: 12px;
        line-height:8px;
        text-align: center;
        color: #000000;
      }
    }
    .timermb {
      position: absolute;
      bottom: 14px;
      background-color: color.$primary-color;
      border-radius: 10px;
      padding: 3px 6px;
      display: flex;
      align-items: center;
      .timermb__icon {
        color: white;
        width: 18px;
        height: 19.77px;
        margin-right: 1.9px;
      }
      .timermb__text {
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        text-align: center;
        color: #ffffff;
      }
    }
      
  }
  .detailsmb {
    width: 176px;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 41px;
    .price__containermb {
      display: flex;
      align-items: center;
      &.price__containermbdisc{
        display: flex;
      flex-direction: column;
      align-items: normal;
      }
    .pricemb {
      font-weight:700;
      font-size: 18px;
      line-height: 20px;
      color: color.$dark-text-color;
      margin-left: -50px;
      max-width: 250px; 
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &.pricembDark{
        color: #ffffff;
      }
    }
    .oldmbpd{
      display: flex;
      // align-items: center;
      margin-top: -20px;
    .oldmbprice {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 40px;
      margin-left: -40px;
      color:#e76859;
      text-decoration: line-through;
      max-width: 150px; 
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      // &.oldmbpriceDark{
      //   color: color.$red-color;
      // }
    }
    .discountmb{
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 40px;
      margin-left: 10px;
      color:#e76859;
    
    // &.discountmbDark{
    //   color: color.$red-color;
    // }
  }
}
  }
  
    .titlemb {
      font-weight: 600;
      font-size: 13px;
      line-height: 107%;
      color: color.$dark-text-color;
      margin-left: -50px;
      &.titlembDark{
        color: #ffffff;
      }
      &.titlembdisc{
        margin-top: -8px;
      }
      &.titlembDark{
        color: color.$white-text-color;
      }
    }
    .locationmb,
    .timemb {
      font-weight: 600;
      font-size: 10px;
      line-height: 107%;
      color: color.$light-text-color;
      margin-left: -50px;
      &.locationmbDark{
        color: #ffffff;
      }
      &.timembDark{
        color: #ffffff;
      }
    }
    // .location {
    //   margin-top: 20px;
    // }
    // .time {
    //   margin-top: 9px;
    // }
    .location__iconmb,
    .time__iconmb
     {
      display: inline-block;
      width: 15px;
      height: 15px;
      color: color.$primary-color;
      margin-right: 5px;
    }
    .heartmb {
      position: absolute;
      top: 5px;
      right: 6px;
      color: color.$primary-color;
      font-size: 22px;
      cursor: pointer;
    }
    .condition_containermb {
      position: absolute;
      top: -1px;
      right: 35px;
      // z-index: 3;
    
      .condition_imagemb {
        width: 52px;
        height: 30.4px;
        
      }
      .conditionmb {
        font-weight: 500;
        font-size: 12.8px;
        line-height: 19.2px;
        text-align: center;
        color: #ffffff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  @media screen and (min-width:1150px) {
    
      height: 166px;
      width: 390px;
      
      .detailsmb{
        margin-left: 30px;
        
      }
  }
  @media screen and (min-width:768px) and (max-width: 1000px) {
    
      height: 166px;
      width: 310px;
      .image__containermb{
        .featured_containermb{
          right: 0px;
        }
      }
      .detailsmb{
        margin-left: 20%;
      }
  }
  @media screen and (min-width:700px) and (max-width:767px){
    
      height: 166px;
      width: 100%;
      .image__containermb{
        width:50%;
        .card__imagemb {
          width: 100%;
          height: 166px;
          object-fit: cover;
          border-radius:10px ;
        }
        .featured_containermb{
          right: 0px;
        }
      }
      .detailsmb{
        margin-left: 15%;
        .heartmb {
         left: 100%;
        }
        .condition_containermb {
          right: 10px;
          }
  }
  
  }
  @media screen and  (min-width:626px) and (max-width:699px){
    
      height: 166px;
      width: 100%;
      .image__containermb{
        width:45%;
        .card__imagemb {
          width: 100%;
          height: 166px;
          object-fit: cover;
          border-radius:10px ;
        }
        .featured_containermb{
          right: 0px;
        }
      }
      .detailsmb{
        margin-left: 15%;
        .heartmb {
         left: 100%;
        }
        .condition_containermb {
          right: 10px;
          }
  }
  
  }
  @media screen and (min-width:576px) and (max-width:625px){
    
      height: 166px;
      width: 100%;
      .image__containermb{
        width:40%;
        .card__imagemb {
          width: 100%;
          height: 166px;
          object-fit: cover;
          border-radius:10px ;
        }
        .featured_containermb{
          right: 0px;
        }
      }
      .detailsmb{
        margin-left: 15%;
        .heartmb {
         left: 100%;
        }
        .condition_containermb {
          right: 10px;
          }
  }
  
  }
  @media screen and (min-width:501px) and (max-width:575px){
    
      height: 166px;
      width: 100%;
      .image__containermb{
        width:35%;
        .card__imagemb {
          width: 100%;
          height: 166px;
          object-fit: cover;
          border-radius:10px ;
        }
        .featured_containermb{
          right: 0px;
        }
      }
      .detailsmb{
        margin-left: 15%;
        .heartmb {
         left: 100%;
        }
        .condition_containermb {
          right: 10px;
          }
  }
  
  }
  @media screen and (max-width:500px){
      .image__containermb{
        .featured_containermb{
          right:30%;
        }
       .timermb{
        .timermb__text{
          font-size: 11px;
        }
        .timermb__icon{
          width: 14px;
          height: 15px;   
         }
       }
      }
  }
  @media screen and (max-width:455px){
      .image__containermb{
        .featured_containermb{
          right:28%;
        }
       
      }
  }
  @media screen and (max-width:445px){
    height: 166px;
    width: 100%;
      .image__containermb{
        width:35%;
        .card__imagemb {
          width: 100%;
          height: 166px;
          object-fit: cover;
          border-radius:10px ;
        }
        .featured_containermb{
          right:0px;
        }
      }
      .detailsmb{
        margin-left: 20%;
       .timermb{
        .timermb__text{
          font-size: 9px;
        }
        .timermb__icon{
          width: 14px;
          height: 15px;   
         }
       }
      }
  }
}


@media screen and (max-width:768px){
  .buttons_container {
    display: flex;
    gap: 20px;
    justify-content: flex-start;
    button {
      border-radius: 10px;
      padding: 10.4px 35.2px;
      font-weight: 600;
      font-size: 19.2px;
      line-height: 28.8px;
      color: #ffffff;
    }
    .confirm {
      background-color:  color.$primary-color;
    }
    .cancel {
      background-color: color.$red-color;
    }
  }
}