@use "../../../../styles/variables.scss" as color;
.overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.35);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2001;
}
.modal_styles {
  position: fixed;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: white;
  width: 316px;
  height: 700px;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  animation-name: animate;
  animation-duration: 2s;
  &.modal_stylesDark{
    background-color: color.$dark-text-color;
  }
  .popup {
    display: flex;
    flex-direction: column;
    text-align: center;
    position: relative;
    .icon {
      position: absolute;
      top: 6px;
      right: 4px;
      width: 25px;
      height: 25px;
      color: #000000;
      cursor: pointer;
      z-index: 2002;
      &.iconDark{
        color:white
      }
    }
    .wrapper {
      padding: 25px 30px;
    }
    .popup__img {
      width: auto;
      height: 166px;
      margin-bottom: 40px;
      object-fit: cover;
    }
    .title {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      max-width: 250px;
      overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      &.titleDark{
        color:color.$white-text-color;
      }
    }
    .price {
      margin-top: 34px;
    }
    .old_price {
      color: color.$red-color;
      text-decoration: line-through;
      font-weight: 700;
      font-size: 28px;
      margin-bottom: 0;
    }
    .new_price {
      margin-top: 0;
      color: black;
      font-weight: 700;
      font-size: 37px;
      display: block;
      &.new_priceDark{
        color: color.$white-text-color;
      }
    }
    .view {
      margin-top: 23px;
      background-color: color.$primary-color;
      border-radius: 10px;
      padding: 15px 45px;
      color: white;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
    .available {
      margin-top: 21px;
      margin-bottom: 21px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      &.availableDark{
        color:color.$white-text-color
      }
    }
    .timer {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 32px;
      line-height: 48px;
      color: color.$primary-color;
      .timer_square {
        width: 61.09px;
        height: 64px;
        border-radius: 10px;
        border: 1px solid #9d9d9d;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .margin {
        margin-left: 15px;
        margin-right: 15px;
      }
    }
  }
  @media screen and (max-width: 1000px) {
    width: 221.2px;
    height: 500px;
    left: 50%;
    animation-name: animatem;
    .popup {
      .popup__img {
        height: 116.2px;
        margin-bottom: 28px;
      }
      .title {
        font-size: 14px;
        line-height: 20px;
      }
      .price {
        margin-top: 23.8px;
      }
      .old_price {
        font-size: 19.6px;
      }
      .new_price {
        font-size: 25.9px;
      }
      .view {
        margin-top: 16.1px;
        padding: 10.5px 31.5px;
        font-size: 11.2px;
        line-height: 16.8px;
      }
      .available {
        margin-top: 14.7px;
        margin-bottom: 14.7px;
        font-size: 11.2px;
        line-height: 16.8px;
      }
      .timer {
        font-size: 22.4px;
        line-height: 33.6px;
        .timer_square {
          width: 42.7px;
          height: 44.8px;
        }
        .margin {
          margin-left: 10.5px;
          margin-right: 10.5px;
        }
      }
    }
  }
  @media screen and (max-height: 710px) {
    width: 221.2px;
    height: 350px;
    left: 50%;
    animation-name: animatem;
    .popup {
      .popup__img {
        height: 80px;
        margin-bottom: 10px;
        margin-left: 7px;
        
      }
      .title {
        font-size: 10px;
        line-height: 20px;
      }
      .price {
        margin-top:10px;
      }
      .old_price {
        font-size: 15px;
      }
      .new_price {
        font-size: 20px;
      }
      .view {
        margin-top: -20px;
        padding: 5px 28px;
        height: 25px;
        font-size: 12px;
        line-height: 15px;
      }
      .available {
        margin-top: -1px;
        font-size: 10px;
        line-height: 16.8px;
      }
      .timer {
        font-size: 17px;
        line-height: 33.6px;
        margin-left: 18px;
        margin-top: -5px;
        .timer_square {
          width: 25px;
          height: 27px;
        }
        .margin {
          margin-left: 13px;
          margin-right: 10.5px;
        }
      }
    }
  }
}
  
@keyframes animate {
  from {
    top: 100%;
    left: 30%;
  }
  to {
    top: 50%;
    left: 30%;
  }
}
@keyframes animatem {
  from {
    top: 100%;
    left: 50%;
  }
  to {
    top: 50%;
    left: 50%;
  }
}
