@use "../../styles/variables.scss" as color;
@import "~bootstrap/scss/bootstrap";
$lightgray: #f8f9fa;
$gray: #dee2e6;
.header{
  margin-bottom: 50px;
  
}
.table {
  // width:850px;
  margin-bottom: 20px;
  border-radius: 15px;
  overflow: hidden;
  max-height: 700px;
  overflow-y: auto;
  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    text-align: left;
    font-size: 14px;
    border: 2px solid $gray;
  
  
      th,
      td {
        width: 100px;
        padding: 12px;
        vertical-align: middle;
        border-top: none;
        border-left: none;
        border-right: none;
        
        &:first-child {
          border-left: none;
          border-radius: 0 0 0 0;
        }
  
        &:last-child {
          border-right: none;
          border-radius: 0 0 0 0;
        }
      }
  
      thead {
        tr {
          background-color: darkgray;
          color: black;
        }
        
      }
  
      tbody {
        tr:nth-child(odd) {
          background-color: white;
        }
  
        tr:nth-child(even) {
          background-color: rgb(235, 231, 231);
        }
  
        tr:first-child {
          background-color: white;
          color: #fff;
        }
      }
    
  }  
}
.customername{
    font-weight: 600;
    
    .uInfo{
      display: flex;
      align-items: center;
      width:50px;
      margin-right: 10px;
      .customerImage{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
.email{
    font-weight: 600;
    width:100px;
  }
   
  .pagination {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  
    .page-item {
      &:not(.active) {
        a {
          background-color: $lightgray;
          border-color: $lightgray;
          color: #000;
        }
  
        &:hover a {
          background-color: $gray;
          border-color: $gray;
          color: #fff;
        }
      }
  
      &.active {
        a {
          background-color: $gray;
          border-color: $gray;
          color: #fff;
        }
      }
    }
  
    .page-link {
      border-radius: 10px;
      margin: 0 2px;
    }
  }
  @media (max-width: 1200px) {
    .table{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
      
    }
  @media (max-width: 490px) {
    .table{
        margin-left: 0;
        
    }
  }