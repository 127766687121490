@use "../../../styles/variables.scss" as color;
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 45px;
  margin-bottom: 50px;
  .title {
    margin-top: 26px;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: color.$dark-text-color;
  }
  .text {
    margin-top: 9px;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: color.$light-gray-color;
    max-width: 450px;
  }
  .btn {
    .icon {
      display: inline-block;
      margin-right: 13px;
    }
    margin-top: 45px;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #ffffff;
    padding: 9px 60px;
    background: color.$primary-color;
    border-radius: 10px;
  }
  @media screen and (max-width: 620px) {
    .title {
      font-size: 19.2px;
      line-height: 28.8px;
    }
    .text {
      font-size: 16px;
      line-height: 24px;
      margin-top: 7.2px;
    }
    .btn {
      .icon {
        margin-right: 10.4px;
      }
      font-size: 19.2px;
      line-height: 28.8px;
      padding: 7.2px 48px;
      margin-top: 36px;
    }
  }
}
