.chat_page {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
  background-color: white;
  width: 100%;
  cursor: pointer;
  .profile_pic {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #ddd;
    margin-right: 10px;
  }
  .chat_details {
    display: flex;
    flex-direction: column;
    flex-grow: 1; 
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .time {
    font-size: 12px;
    color: #0298a6;
    font-weight: 600;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    margin-left: 10px; 
  }
  .name {
    margin: 0;
    font-weight: 700;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    flex-grow: 1; 
  }
  .bothbod {
    display: flex;
    justify-content: space-between;
    align-items: center; 
  }
  .unread_messages {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #0298a6;
    color: white;
    font-size: 12px;
  }
  .description {
    margin: 5px 0;
    font-size: 11px;
  }
  .last_message {
    margin: 0;
    font-size: 12px;
    color: #a09e9e;
  }
}
@media screen and (max-width: 830px) {
  .chat_page {
    width: 100%;
  }
}
