@use "../../../../styles/variables.scss" as color;
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  // &.containerDark{
  //   background-color: color.$dark-text-color;
  //   height: 100%;
  // }
  .skip {
    position: absolute;
    top: 23px;
    left: 15px;
    font-weight: 400;
    font-size: 24px;
    line-height: 25.2px;
    color: color.$dark-text-color;
    cursor: pointer;
    &.skipDark{
      color: color.$white-text-color;
    }
  }
  .back {
    position: absolute;
    top: 18px;
    right: 30px;
    width: 32px;
    height: 32px;
    font-weight: 600;
    cursor: pointer;
    &.backDark{
      color:white
    }
  }
  .logoCont{
    justify-content: center;
    align-items: center;
    display: flex;
  .logo {
    margin-top: 45.5px;
    font-weight: 500;
    font-size: 28px;
    line-height: 42px;
    text-align: center;
    color: color.$primary-color;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
    transform: scale(2.5);
  }
}
  .welcome {
    font-weight: 600;
    font-size: 22.4px;
    line-height: 33.6px;
    color: color.$dark-text-color;
    text-align: center;
    &.welcomeDark{
      color: color.$white-text-color;
    }
  }
  .slogan {
    font-weight: 500;
    font-size: 16.8px;
    line-height: 25.2px;
    color: color.$dark-text-color;
    text-align: center;
    margin-bottom: 25.9px;
    &.sloganDark{
      color: color.$white-text-color;
    }
  }
  .desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: color.$light-text-color;
    max-width: 399px;
    text-align: center;
    margin-top: 4px;
    margin-bottom: 26.6px;
    &.descDark{
      color: color.$white-text-color;
    }
  }
  .btn_container {
    position: relative;
    cursor: pointer;
    width: 300px;
    height: 46.2px;
    background: color.$light-background-color;
    border: 1px solid #111111;
    border-radius: 10px;
    margin-bottom: 22.4px;
    font-weight: 400;
    font-size: 14x;
    line-height: 21px;
    color: #000000;
    padding: 13.3px 16.8px;
    display: flex;
    align-items: center;
    &.btn_containerDark{
      color: color.$white-text-color;
      background-color:#015866;
    }
    .input_icon {
      margin-right: 14px;
      width: 21px;
      height: 21px;
    }
    &.active {
      border: 3px solid color.$primary-color;
      color: color.$primary-color ;
    }
    .active_icon {
      position: absolute;
      top: 50%;
      right: 5px;
      transform: translateY(-50%);
      width: 16.8px;
      height: 16.8px;
    }
  }
  .next {
    margin-top: 13.3px;
    width: 217.7px;
    height: 46.2px;
    background: rgba(2, 152, 166, 0.6);
    border-radius: 10px;
    font-weight: 600;
    font-size: 19.6px;
    line-height: 29.4px;
    color: #ffffff;
    &.nextDark{
      background-color: rgba(140, 139, 139, 0.313);
    }
    .arrow {
      display: inline-block;
      width: 19.6px;
      height: 15.4px;
      margin-left: 16.1px;
    }
    &.active_next {
      background: color.$primary-color;
      &.nextDark{
        background-color: #015866;
      }
    }
  }
  @media screen and (max-width: 750px) {
    .next {
      margin-bottom: 25px;
    }
  }
  @media screen and (max-width: 620px) {
    .logo {
      margin-top: 36.4px;
      font-size: 22.4px;
      line-height: 33.6px;
    }
    .welcome {
      font-size: 17.92px;
      line-height: 26.88px;
    }
    .slogan {
      font-size: 13.44px;
      line-height: 20.16px;
      margin-bottom: 20.72px;
    }
    .desc {
      font-size: 11.2px;
      line-height: 16.8px;
      max-width: 319.2px;
      margin-top: 3px;
      margin-bottom: 21.28px;
    }
    .btn_container {
      width: 270px;
      height: 41.58px;
      font-size: 12.6x;
      line-height: 18.9px;
      .icon {
        margin-right: 10px;
        width: 18.9px;
        height: 18.9px;
      }
      .active_icon {
        width: 15.12px;
        height: 15.12px;
      }
    }
  }
  @media screen and (max-width: 475px) {
    .skip {
      font-size: 14px;
      right: 20px;
    }
  }
  @media screen and (max-width: 370px) {
    .skip {
      font-size: 14px;
      right: 20px;
    }
    .btn_container {
      width: 220px;
      height: 41.58px;
      font-size: 12px;
      line-height: 18.9px;
      .icon {
        margin-right: 10px;
        width: 18.9px;
        height: 18.9px;
      }
      .active_icon {
        width: 15.12px;
        height: 15.12px;
      }
    }
  }
  @media screen and (max-width: 420px) {
    .desc {
      max-width: 250px;
    }
  }
}
