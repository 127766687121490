@use "../../../styles/variables.scss" as color;
.footer_container {
  width: 100vw;
  background: color.$footer-color;
  &.footer_containerDark{
    background:#3b3c3f;
  }
  .wrapper {
    padding: 39.4px 66px 23px;
    display: flex;
    justify-content: space-between;
  }
  .title {
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 19.68px;
    &.titleDark{
      color: color.$white-text-color;
    }
  }
  .down_icon {
    display: none;
  }
  .link {
    text-decoration: none;
    color: inherit;
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
    margin-bottom: 9px;
    margin-left: -28px;
    &:hover{
      color:color.$primary-color
    }
    
    cursor: pointer;
    &.linkDark{
      color:color.$white-text-color;
    }
  }
  .copyright {
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    margin-top: 28px;
    left: 60px;
    position: absolute;
    &.copyrightDark{
      color: color.$white-text-color;
    }
  }
  .social {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .icons {
    display: flex;
    gap: 17px;
    margin-left: -50px;
    .icon {
      font-size: 20px;
    
    }
    li:hover .icon {
      color: #3b5998; 
    }
  
    li:nth-child(2):hover .icon {
      color:#C13584;
    }
  
    li:nth-child(3):hover .icon {
      color: #00acee; 
    }
  
    li:nth-child(4):hover .icon {
      color: red; 
    }
  }
  .app {
    display: flex;
    flex-direction: column;
    align-items: center;
    .mobile_copyright {
      display: none;
    }
  }
  .stores {
    display: flex;
    gap: 16px;
    cursor: pointer;
    .store {
      width: 154px;
      height: 48px;
      &:hover{
        transform: scale(1.03);
      }
    }
  }
  @media screen and (max-width: 1000px) {
    .stores {
      flex-direction: column;
    }
    .icons {
      flex-direction: column;
    }
  }
  @media screen and (max-width: 775px) {
    .wrapper {
      padding: 30px 30px;
    }
  }
  @media screen and (max-width: 750px) {
    .wrapper {
      flex-direction: column;
      padding: 40px 51px;
    }
    .copyright {
      display: none;
    }
    .social {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .icons {
      flex-direction: row;
      transform: translateY(-10%);
    }
    .stores {
      flex-direction: row;
    }
    .footer_part {
      margin-top: 1rem;
      border-bottom: 1px solid color.$light-text-color;
      &:last-child {
        border-bottom: none;
      }
    }
    .title_container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .down_icon {
        display: inline-block;
        cursor: pointer;
        transform: translateY(-50%);
        &.show_first {
          transform: rotate(180deg);
        }
        &.show_second {
          transform: rotate(180deg);
        }
        &.show_third {
          transform: rotate(180deg);
        }
      }
    }
    .list {
      &.hide_first {
        display: none;
      }
      &.hide_second {
        display: none;
      }
      &.hide_third {
        display: none;
      }
    }
    .app {
      margin-top: 28px;
      .mobile_copyright {
        display: block;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        color: #666666;
        margin-top: 18px;
      }
    }
  }
  @media screen and (max-width: 420px) {
    .stores {
      flex-direction: column;
    }
  }
}
