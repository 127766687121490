@use "../../../../styles/variables.scss" as color;
.overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3000;
}
.wrapper {
  position: fixed;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  z-index: 3001;
  background-color: white;
  width: 532px;
  height: 556.8px;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .close {
    cursor: pointer;
    position: absolute;
    top: 13.3px;
    right: 13.3px;
    width: 32px;
    height: 32px;
  }
  .title {
    margin-top: 129.6px;
    max-width: 358.4px;
    font-weight: 700;
    font-size: 28px;
    line-height: 41.6px;
    text-align: center;
    color: #353844;
  }
  .subtitle {
    margin-top: 52.8px;
    font-weight: 600;
    font-size: 38.4px;
    line-height: 57.6px;
    text-align: center;
    color: #0298a6;
    border: 3px solid #0298a6;
    border-radius: 10px;
    padding: 10px 20px;
    cursor: pointer;
  }
}
