@use "../../../styles/variables.scss" as color;
.card_container {
  margin-bottom: 38px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 30%;
  margin-top: 50px;
  .card {
    width: 272.7px;
    height: 333px;
    background: color.$light-background-color;
    border: 5px solid color.$secondary-color;
    border-radius: 10px;
    transition: width 0.5s ease-in-out;
    position: relative;
    .card_image {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.5s ease;
      max-height: 100px;
      width: 200px;
    }
    &.cardDark{
      background-color: #015866;
    }
    &.cardBar{
      &:hover {
        width: 400px;
      }
    }
    .subcategories_container {
      //added this width
      // width: 110px;
      display: none;
      transition: all 0.5s ease;
      position: absolute;
      top: 50%;
      left: 55%;
      transform: translate(0, -50%);
      .subcategories_item {
        color: color.$primary-color;
        border-bottom: 2px solid color.$secondary-color;
        padding-top: 5px;
        padding-bottom: 4px;
        z-index: 3;
        max-width: 150px;
        word-wrap: break-word;
        .subcategories_title {
          font-weight: 600;
          line-height: 21.6px;
          font-size: 16px;
          text-indent: 10px;
          text-decoration: none;
          color: color.$primary-color;
          max-width: 210px;
          word-wrap: break-word;
         
          &.subcategories_titleDark{
            color:color.$white-text-color;
          }
        }
        &:last-child {
          border-bottom: 0px;
        }
      }
    }
    &:hover {
      width: 400px;
      .subcategories_container {
        display: block;
        // right: 5%;
      }
      .card_image {
        left: 30%;
        z-index: 2;
      }
    }
  }
  .title_container {
    width: 272.7px;
    .card_title {
      margin-top: 38px;
      font-weight: 600;
      font-size: 32px;
      line-height: 48px;
      color: #666666;
      text-align: center;
      &.card_titleDark{
        color:color.$white-text-color
      }
    }
  }
  @media screen and (min-width: 1700px) {
    .card_container {
      margin-bottom: 38px;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-basis: 40%;
      margin-top: 50px;
      .card {
        width: 500px;
        height: 333px;
        background: color.$light-background-color;
        border: 5px solid color.$secondary-color;
        border-radius: 10px;
        transition: width 0.5s ease-in-out;
        position: relative;
    
        .card_image {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transition: all 0.5s ease;
          max-height: 225px;
        }
        &.cardDark{
          background-color: #015866;
        }
        &.cardBar{
          &:hover {
            width: 400px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1300px) {
    .card {
      width: 212px;
      height: 259px;
      &.cardBar{
        &:hover {
          width: 350px;
        }
      }
      &:hover {
        width: 400px;
        // .subcategories_container {
        //   // right: 10%;
        // }
      }
      .subcategories_container {
        .subcategories_item {
          padding-top: 2px;
          padding-bottom: 2px;
          .subcategories_title {
            font-size: 12px;
            max-width: 150px;
          word-wrap: break-word;
          }
        }
      }
      .card_image {
        max-width: 150px;
      }
      .title_container {
        width: 212px;
        .card_title {
          margin-top: 38px;
          font-weight: 600;
          font-size: 25px;
          line-height: 20px;
        }
      }
    }
  }
  @media only screen and (max-width: 830px) {
    .card {
      width: 212px;
      height: 259px;
      &.cardBar{
        &:hover {
          width: 320px;
        }
      }
      &:hover {
        width: 350px;
        // .subcategories_container {
        //   right: 10%;
        // }
      }
      .subcategories_container {
        .subcategories_item {
          padding-top: 2px;
          padding-bottom: 2px;
          .subcategories_title {
            font-size: 12px;
            max-width: 130px;
          word-wrap: break-word;
          }
        }
      }
      .card_image {
        max-width: 150px;
      }
      .title_container {
        width: 212px;
        .card_title {
          margin-top: 38px;
          font-weight: 600;
          font-size: 25px;
          line-height: 20px;
        }
      }
    }
  }
  @media only screen and (max-width: 630px) {
    .card {
      width: 212px;
      height: 259px;
      &:hover {
        width: 400px;
        // .subcategories_container {
        //   right: 10%;
        // }
      }
      .card_image {
        max-width: 150px;
      }
      .subcategories_container {
        .subcategories_item {
          padding-top: 1px;
          padding-bottom: 1px;
          .subcategories_title {
            font-size: 12px;
          }
        }
      }
      .title_container {
        .card_title {
          margin-top: 38px;
          font-weight: 600;
          font-size: 25px;
          line-height: 20px;
        }
      }
    }
  }
  @media only screen and (max-width: 434px) {
    .card {
      &.cardBar{
        &:hover {
          width: 300px;
        }
      }
      &:hover {
        width: 350px;
        // .subcategories_container {
        //   right: 10%;
        // }
      }
    }
    .card_image {
      max-width: 130px;
    }
    .subcategories_item {
      padding-top: 1px;
      padding-bottom: 1px;
      .subcategories_title {
        font-size: 11px;
      }
    }
    .title_container {
      .card_title {
        margin-top: 30px;
      }
    }
  }
  
}
