@use "../../styles/variables.scss" as color;
.search {
  margin-top: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper {
  .container {
    margin-top: 0px;
    display: flex;
    justify-content: space-between;
    // margin-bottom: 50px;
    .title {
      font-weight: 600;
      font-size: 36px;
      line-height: 54px;
      color: color.$dark-text-color;
      //   margin-bottom: 40px;
      &.titleDark {
        color: color.$white-text-color;
      }
    }
    .calender_section {
      display: flex;
      flex-direction: column;
      // align-items: center;
      gap: 40px;
    }
    .ctitle {
      margin-top: -20px;
      margin-bottom: 0;
    }
    .cproduct__title {
      font-size: 24px;
      font-weight: bold;
      text-align: center;
      color: white;
    }
    .ctime_container {
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }
    .ctext {
      font-size: 20px;
      margin: 0 5px;
      color: white;
    }
    .ccenter {
      color: white;
    }
    .ctime_square {
      width: 40px;
      height: 40px;
      border-radius: 15px;
      background-color: transparent;
      backdrop-filter: blur(6px);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
    }
    .cprice_container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
    }
    .cprice {
      font-size: 24px;
      margin: 0 5px;
    }
    .cold {
      text-decoration: line-through;
      color: red;
    }
    .carrow {
      margin: 0 10px;
      color: white;
    }
    .cnew {
      color: #43FFD3;
      ;
      font-weight: bold;
    }
  }
  .searchType__container {
    display: flex;
    justify-content: center;
    align-items: center;
    .search__typecd {
      justify-content: space-between;
      display: flex;
      margin-bottom: 30px;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      color: color.$dark-text-color;
      border: 2px solid color.$primary-color;
      border-radius: 15px;
      width: 300px;
      &.search__typeDark {
        border: 2px solid #015866;
      }
      .buttoncd {
        padding: 15px 31px;
        background-color: white;
        cursor: pointer;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: color.$dark-text-color;
        //   border: hidden;
        border-radius: 16px;
        &.buttonDark {
          background-color: color.$dark-text-color;
          color: color.$white-text-color
        }
        &.activecd {
          border: 2px solid color.$primary-color;
          border-radius: 12px;
          background-color: color.$primary-color;
        }
        &.activeDark {
          background-color: #015866;
          border: 2px solid #015866;
          border-radius: 12px;
        }
      }
    }
  }
  .productDetail {
    height: 700px;
    width: 620px;
    background: white;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    justify-content: space-between;
  }
  h4 {
    margin-bottom: 10px;
  }
  .inputLine {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .inputPair {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .labelin {
    font-weight: 600;
    margin-right: 5px;
  }
  .toggle-button-container {
    display: flex;
    align-items: center;
  }
  
  .toggle-button {
    background-color: transparent;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
    font-weight: 600;
  }
  
  .toggle-button.active {
    color:  #0298A6;
    text-decoration: underline;
  }
  .inputPairsrch {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    
    .inpofs {
      box-sizing: border-box;
      width: 550px;
      height: 45px;
      background: #FFFFFF;
      border: 1px solid #D0D3D6;
      border-radius: 8px;
      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 1;
      padding-left: 10px;
    }
    .codrop {
      width: 550px;
      color: #0298A6;
     cursor: pointer;

      .srchimg{
        width: 100px;
        height: 50px;
        margin: 10px;
        object-fit: cover;
        display: flex;
        align-items: center;
        border-radius: 10px;
        float: right;
      }
      .codres {
        cursor: pointer;
      }
    }
    .labelinsrch {
      font-weight: 600;
      margin-right: 5px;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
  .inpof {
    box-sizing: border-box;
    width: 216.73px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #D0D3D6;
    border-radius: 8px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 1;
    padding-left: 10px;
  }
  .timeinput {
    box-sizing: border-box;
    width: 65.52px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #D0D3D6;
    border-radius: 8px;
    padding-left: 22px;
  }
  .clone {
    padding: 10px;
  }
  .timeinput:last-child {
    margin-right: 0;
  }
  .timeselect {
    margin-top: 30px;
  }
  .datein {
    margin-top: 5px;
    padding-left: 5px;
  }
  .butdiv {
    margin-left: auto;
    width: 207px;
    height: 50px;
    background: #0298A6;
    border-radius: 10px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 600;
    margin-bottom: 30px;
  }
  .butdiv.loading {
    margin-left: auto;
    width: 207px;
    height: 50px;
    background: grey;
    border-radius: 10px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 600;
    margin-bottom: 30px;
    cursor: not-allowed;
  }
  .srchcod {
    width: 20px;
  }
  @media screen and (max-width: 1500px) {
    .container {
      justify-content: space-around;
    }
  }
  @media screen and (max-width: 1200px) {
    .container {
      justify-content: space-between;
    }
  }
  @media screen and (max-width: 1150px) {
    .container {
      flex-direction: column;
      align-items: center;
      .title {
        font-size: 28.8px;
        line-height: 43.2px;
        margin-bottom: 32px;
        padding-left: 10px;
      }
      .calender_section {
        margin-bottom: 20px;
      }
    }
  }
  @media screen and (max-width: 700px) {
    .searchType__container {
      .search__typecd {
        height: 50px;
        width: 216px;
        .buttoncd {
          padding: 15px 23px;
          background-color: white;
          font-size: 15px;
          line-height: 15px;
        }
      }
    }
    .inputPairsrch {
      .inpofs {
        width: 216px;
      }
      .codrop {
        width: 216px;
      }
    }
    .productDetail {
      width: 80%;
      .inputLine {
        display: flex;
        flex-direction: column;
        align-items: center;
        .inputPair {
          display: flex;
          flex-direction: column;
        }
      }
      .butdiv {
        margin-left: 10%;
      }
    }
  }
  @media screen and (max-width: 500px) {
    .productDetail {
      width: 100%;
      margin-left: -60px;
      .inputLine {
        display: flex;
        flex-direction: column;
        align-items: center;
        .inputPair {
          display: flex;
          flex-direction: column;
        }
      }
      .butdiv {
        margin-left: 10%;
      }
    }
  }
  @media screen and (max-width: 570px) {
    .timeinput {
      box-sizing: border-box;
      width: 40px;
      height: 30px;
      background: #FFFFFF;
      border: 1px solid #D0D3D6;
      border-radius: 8px;
      padding-left: 10px;
    }
  }
  @media screen and (max-width:410px) {
    .timeinput {
      box-sizing: border-box;
      width: 30px;
      height: 20px;
      background: #FFFFFF;
      border: 1px solid #D0D3D6;
      border-radius: 8px;
      padding-left: 6px;
    }
  }
  @media screen and (max-width: 500px) {
    .productDetail {
      width: 100%;
      margin-left: -60px;
      .inputLine {
        display: flex;
        flex-direction: column;
        align-items: center;
        .inputPair {
          display: flex;
          flex-direction: column;
        }
      }
      .butdiv {
        margin-left: 10%;
      }
    }
  }
  @media screen and (max-width: 400px) {
    .wrapper {
      width: 200px;
      .container {
        width: 200px;
        .calender_section {
          width: 200px;
        }
      }
    }
    .productDetail {
      height: 750px;
      .butdiv {
        margin-left: 10%;
        margin-top: 15px;
      }
    }
  }
}