@use "../../../styles/variables.scss" as color;
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 9rem auto;
  .img {
    max-width: 400px;
  }
  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: color.$dark-text-color;
    margin-top: 23px;
  }
  .text {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: color.$light-gray-color;
    margin-top: 9px;
    text-align: center;
  }
  .btn {
    margin-top: 50px;
    background: color.$primary-color;
    border-radius: 10px;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #ffffff;
    padding: 9px 121px;
  }
  @media screen and (max-width: 620px) {
    .title {
      font-size: 19.2px;
      line-height: 28.8px;
      margin-top: 18.4px;
    }
    .text {
      font-size: 16px;
      line-height: 24px;
      margin-top: 7.2px;
      max-width: 350px;
    }
    .btn {
      margin-top: 40px;
      font-size: 19.2px;
      line-height: 28.8px;
      padding: 9px 119px;
    }
  }
  @media screen and (max-width: 440px) {
    .img {
      width: 300px;
    }
    .text {
      max-width: 280px;
    }
    .btn {
      padding: 9px 90px;
    }
  }
}
