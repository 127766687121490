@use "../../../styles/variables.scss" as color;
.overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
}
.modal_styles {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2001;
  background-color: white;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 716.8px;
  height: 640px;
  &.modal_stylesDark{
    background-color: color.$dark-text-color;
  }
  @media screen and (max-width: 750px) {
    width: 80vw;
    height: auto;
  }
}
