@use "../../../../styles/variables.scss" as color;
.overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
}
.modal_styles {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2001;
  background-color: white;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 716.8px;
  height: 540px;
  &.modal_stylesDark{
    background-color: color.$dark-text-color;
  }
  @media screen and (max-width: 750px) {
    width: 80vw;
    height: auto;
  }
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  .close {
    position: absolute;
    top: 13.3px;
    right: 13.3px;
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
  .skip {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 16.8px;
    line-height: 25.2px;
    color: color.$dark-text-color;
    cursor: pointer;
    &.skipDark{
      color:color.$white-text-color;
    }
  }
  .back {
    position: absolute;
    top: 13.3px;
    left: 13.3px;
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
  .logoCont{
    justify-content: center;
    align-items: center;
    display: flex;
  .logo {
    margin-top: 45.5px;
    font-weight: 500;
    font-size: 28px;
    line-height: 42px;
    text-align: center;
    color: color.$primary-color;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
    transform: scale(2.5);
  }
}
  .form_container {
    margin: 40px auto;
    display: flex;
    flex-direction: column;
    .label {
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: color.$dark-text-color;
      margin-bottom: 15px;
      &.labelDark{
        color:white
      }
    }
    .phone_input_container {
      position: relative;
      .phonei{
      &.phoneiDark{
        background-color: #015866;
      }
    }
      .phone {
        position: absolute;
        width: 21px;
        height: 21px;
        top: 50%;
        left: 85px;
        transform: translateY(-50%);
        
      }
      
    }
    .code_container {
      width: 400px;
      height: 56px;
      border: 1px solid color.$border-category-dropdown;
      border-radius: 10px;
      margin-top: 23px;
      position: relative;
      .code_input {
        background: #f8f8f9;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        padding-left: 20px;
        &.code_inputDark{
          background-color: #015866;
          color:rgb(207,203,203)
        }
        &:focus {
          border: 1px solid color.$primary-color;
          outline: none;
        }
      }
      .send_btn {
        position: absolute;
        right: -1px;
        top: -1px;
        height: 56px;
        width: 170px;
        background: #ffffff;
        border: 1px solid color.$border-category-dropdown;
        border-radius: 0px 8px 8px 0px;
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        color: color.$dark-text-color;
        &.send_btnDark{
          background-color: #015866;
          color: white;
          &:hover{
            background: #007e91;
            color: white;
  
          }
        }
        &:hover{
          background: color.$primary-color;
          color: white;
        }
      }
      .send_btnRsnd {
        position: absolute;
        right: -1px;
        top: -1px;
        height: 56px;
        padding: 0 30px;
        background: gray;
        border: 1px solid color.$border-category-dropdown;
        border-radius: 0px 8px 8px 0px;
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        color: white;
        cursor: not-allowed;
      }
    }
    .confirm_btn {
      margin: 30px auto;
      width: 170px;
      height: 56px;
      left: 0px;
      top: 0px;
      background: color.$primary-color;
      border-radius: 10px;
      font-weight: 600;
      font-size: 20px;
      line-height: 36px;
      color: #ffffff;
      &.confirm_btnDark{
        background-color: #015866;
      }
      &:hover{
        background: #01b1c0;
  
      }
    }
    .confirm_ld {
      margin: 30px auto;
      width: 170px;
      height: 56px;
      left: 0px;
      top: 0px;
      background: gray;
      border-radius: 10px;
      font-weight: 600;
      font-size: 20px;
      line-height: 36px;
      color: #ffffff;
    }
  }
  .register_text {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #8d8d8d;
    margin-top: -20px;
    &.register_textDark{
      color:white;
    }
    .register {
      text-decoration-line: underline;
      color: color.$primary-color;
      cursor: pointer;
      &.registerDark{
        color: #015866;
      }
    }
  }
  @media screen and (max-width: 750px) {
    .logo {
      margin-top: 40px;
    }
    .form_container {
      .label {
        font-size: 18px;
      }
      .confirm_btn {
        font-size: 18px;
      }
      .confirm_ld {
        font-size: 18px;
      }
    }
    .register_text {
      font-size: 17px;
      margin-bottom: 14px;
    }
  }
  @media screen and (max-width: 540px) {
    .close {
      width: 22px;
      height: 22px;
    }
    .back {
      width: 22px;
      height: 22px;
    }
    .logo {
      font-size: 25.2px;
      line-height: 37.8px;
    }
    .form_container {
      .label {
        font-size: 14px;
        line-height: 21px;
      }
      .phone_input_container {
        font-size: 14px;
        line-height: 21px;
        .phone {
          width: 14.7px;
          height: 14.7px;
          left: 93px;
        }
      }
      .code_container {
        font-size: 14px;
        line-height: 21px;
        width: 280px;
        height: 39.2px;
        .send_btn {
          height: 39.2px;
          width: 119px;
          font-size: 12.6px;
          line-height: 21px;
        }
        .send_btnRsnd {
          height: 39.2px;
          width: 130px;
          font-size: 10px;
          line-height: 21px;
        }
      }
      .confirm_btn {
        font-size: 14px;
        line-height: 25.2px;
        width: 119px;
        height: 39.2px;
      }
      .confirm_ld {
        font-size: 14px;
        line-height: 25.2px;
        width: 119px;
        height: 39.2px;
      }
    }
    .register_text {
      font-size: 14px;
      line-height: 21px;
    }
  }
  @media screen and (max-width: 370px) {
    .close {
      width: 22px;
      height: 22px;
    }
    .back {
      width: 22px;
      height: 22px;
    }
    .logo {
      font-size: 25.2px;
      line-height: 37.8px;
    }
    .form_container {
    
      .phone_input_container {
        
        .phonei{
          width: 250px
        }
        .phone {
          width: 14.7px;
          height: 14.7px;
          left: 93px;
        }
      }
      .code_container {
        font-size: 14px;
        line-height: 21px;
        width: 250px;
        height: 39.2px;
        .send_btn {
          height: 39.2px;
          width: 99px;
          font-size: 12.6px;
          line-height: 21px;
        }
      }
      .confirm_btn {
        font-size: 14px;
        line-height: 25.2px;
        width: 119px;
        height: 39.2px;
      }
      .confirm_ld {
        font-size: 14px;
        line-height: 25.2px;
        width: 119px;
        height: 39.2px;
      }
    }
    .register_text {
      font-size: 14px;
      line-height: 21px;
    }
  }
}
}
