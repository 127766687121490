@use "../../../../styles/variables.scss" as color;
.register_container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 64.4px;
  padding-right: 42.7px;
  // &.register_containerDark{
  //   background-color: color.$dark-text-color;
  //   height:100%;
  // }
  
  
    .modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1;
    }
  
  
  .close {
    position: absolute;
    top: 13.3px;
    right: 13.3px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    &.closeDark{
      background-color: #015866;
      border-radius: 50%;
    }
  }
  .back {
    position: absolute;
    top: 13.3px;
    left: 13.3px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    &.backDark{
      color:color.$white-text-color;
      background-color: #015866;
      border-radius: 50%;
    }
  }
  .title {
    font-weight: 600;
    font-size: 22.4px;
    line-height: 33.6px;
    color: color.$dark-text-color;
    margin-top: 45.5px;
    margin-bottom: 7.7px;
    &.titleDark{
      color: color.$white-text-color;
    }
  }
  .subtitle {
    font-weight: 500;
    font-size: 19.6px;
    line-height: 29.4px;
    color: color.$dark-text-color;
    &.subtitleDark{
      color: color.$white-text-color;
    }
  }
  .container {
    display: flex;
    align-items: center;
    .picture_container {
      margin-left: 31.5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .pp {
        position: relative;
        width: 165px;
        height: 165px;
        border-radius: 50%;
        background: color.$light-background-color;
        border: 1px solid color.$border-category-dropdown;
        display: flex;
        justify-content: center;
        align-items: center;
        object-fit: cover;
        &.ppDark{
          background-color: #015866;
        }
        .default_img {
          width: 120px;
          height: 120px;
          padding-bottom: 10px;          
          background-size: cover;
        }
        .preview_img {
          width: 165px;
          height: 165px;
          border-radius: 50%;
          background-size: cover;
        }
        .plus_icon {
          position: absolute;
          right: -10px;
          bottom: 10px;
          width: 42.7px;
          height: 42.7px;
          cursor: pointer; 
        }
        
        
        .dropdown {
          position: absolute;
          left: 130px;
          bottom: -70px;
          background: color.$light-background-color;
          border: 1px solid color.$border-category-dropdown;
          border-radius: 10px;
          width: 250px;
          // height: 81.2px;
          z-index: 2;
          font-weight: 400;
          font-size: 11.2px;
          line-height: 16.8px;
          color: #000000;
          &.dropdownDark{
            color: color.$white-text-color;
            background-color: #015866;
          }
          .item {
            cursor: pointer;
            padding: 10px 22px;
            border-bottom: 2px solid color.$border-category-dropdown;
            &:last-child {
              border-bottom: none;
            }
            display: flex;
            .icon {
              margin-right: 12.6px;
              font-size: 18px;
            }
          }
          &.active {
            // background-color: rgba(0, 0, 0, 0.5);
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
            transition: 500ms ease; 
            
          }
          
          
          &.inactive {
            opacity: 0;
            visibility: hidden;
            transform: translateY(-20px);
            transition: 500ms ease;
           
          }
          
        }
        
      
      }
      
      .upload_text {
        margin-top: 29px;
        font-weight: 500;
        font-size: 22px;
        line-height: 33px;
        text-align: center;
        color: color.$dark-text-color;
        &.upload_textDark{
          color:color.$white-text-color
        }
      }
    }
    .line {
      border: 1px solid #8d8d8d;
      height: 400px;
      margin-left: 98px;
      margin-right: 42px;
    }
    .form_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      
      .input_container {
        position: relative;
        margin-bottom: 21px;
        .input {
          width: 277.2px;
          height: 40.6px;
          background: color.$light-background-color;
          border: 1px solid color.$border-category-dropdown;
          border-radius: 10px;
          padding-left: 47.6px;
          &.inputDark{
            background: #015866;
            color:rgb(207, 203, 203)
          }
          &:focus {
            border: 1px solid color.$primary-color;
            outline: none;
          }  
        }
        .inputph {
          width: 277.2px;
          height: 40.6px;
          background: color.$light-background-color;
          border: 1px solid color.$border-category-dropdown;
          border-radius: 10px;
          padding-left: 15px;
          &.inputphDark{
            background: #015866;
            color:rgb(207, 203, 203)
          }
          &:focus {
            border: 1px solid color.$primary-color;
            outline: none;
          }  
        }
        .pass_icon {
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);
          color: color.$light-gray-color;
          font-size: 20px;
          cursor: pointer;
        }
        
        .input_icon {
          position: absolute;
          left: 16px;
          color: color.$border-category-dropdown;
          top: 12px;
        }
        .error_message {
          font-weight: 400;
          font-size: 11.2px;
          line-height: 12px;
          color: color.$error_color;
          position: absolute;
        }
        &.error_input {
          margin-bottom: 25px;
          .input {
            border-color: color.$error_color;
          }
          .input::placeholder {
            color: color.$error_color;
          }
          .input_icon {
            color: color.$error_color;
          }
        }
      }
      .register_btn {
        font-weight: 600;
        font-size: 16.8x;
        line-height: 25.2px;
        color: #ffffff;
        width: 142.8px;
        height: 42px;
        background: color.$primary-color;
        border-radius: 10px;
        &.register_btnDark{
          background-color: #015866;
        }
        &:hover,
        &:focus,
        &:active {
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
          -webkit-transform: scale(1.1);
          transform: scale(1.1);
        }
      }
      .registerload_btn{
        font-weight: 600;
        font-size: 16.8x;
        line-height: 25.2px;
        color: #ffffff;
        width: 142.8px;
        height: 42px;
        background: gray;
        border-radius: 10px;
      }
    }
  }
  
  .account_text {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: color.$dark-text-color;
    margin-top: 20px;
    text-align: center;
    &.account_textDark{
      color:color.$white-text-color
    }
    .login_text {
      text-decoration-line: underline;
      color: color.$primary-color;
      cursor: pointer;
    }
  }
  @media screen and (max-width: 750px) {
    .close {
      width: 22px;
      height: 22px;
    }
    .back {
      width: 22px;
      height: 22px;
    }
    .title {
      font-size: 20px;
      line-height: 30px;
      margin-top: 30px;
      margin-bottom: 4px;
    }
    .subtitle {
      font-weight: 500;
      font-size: 17px;
      line-height: 27px;
      color: color.$dark-text-color;
      margin-bottom: 5px;
    }
    .container {
      flex-direction: column;
      align-items: center;
      height: 100%;
      .line {
        display: none;
      }
      .picture_container{
        margin-left: 0px;
      .pp {
        width: 110px;
        height: 110px;
        .default_img{
        width: 80px;
        height: 80px;
        }
        .preview_img{
        width: 110px;
        height: 110px;
        }
        .plus_icon {
          width: 30px;
          height: 30px;
        }
        .dropdown {
          left: 4px;
          bottom: -55px;
        }
      }
      .upload_text {
        font-size: 18px;
        margin-top: 9px;
      }
    }
    .form_container {
      .input_container {
        margin-bottom: 15px;
      }
    }
    }
    
    .account_text {
      margin-top: 10px;
      margin-bottom: 15px;
      font-size: 12px;
    }  
    
  }
  @media screen and (max-width: 670px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media screen and (max-width: 640px) {
    .container {
      .picture_container {
        .pp {
          .dropdown {
            left: 35px;
            bottom: -55px;
            width: 220px;
            .item {
              padding: 5px 10px;
              .icon {
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 380px) {
    .close {
      width: 22px;
      height: 22px;
    }
    .back {
      width: 22px;
      height: 22px;
    }
    .title {
      font-size: 20px;
      line-height: 30px;
      margin-top: 30px;
      margin-bottom: 4px;
    }
    .subtitle {
      font-weight: 500;
      font-size: 17px;
      line-height: 27px;
      color: color.$dark-text-color;
      margin-bottom: 5px;
    }
    .container {
      height: 100%;
      .picture_container {
        .pp {
          width: 110px;
          height: 110px;
          .default_img{
          width: 80px;
          height: 80px;
          }
          .preview_img{
          width: 110px;
          height: 110px;
          }
          .plus_icon {
            width: 30px;
            height: 30px;
          }
          .dropdown {
            left: 4px;
            bottom: -55px;
            // width: 220px;
            // .item {
            //   padding: 5px 10px;
            //   .icon {
            //     margin-right: 5px;
            //   }
            // }
          }
        }
        .upload_text {
          font-size: 18px;
          margin-top: 9px;
        }
      }
      .form_container {
        .input_container {
          margin-bottom: 5px;
        }
      }
    }
    .account_text {
      font-size: 12px;
    }
  }
  @media screen and (max-width: 330px) {
    .close {
      width: 22px;
      height: 22px;
    }
    .back {
      width: 22px;
      height: 22px;
    }
    .title {
      font-size: 20px;
      line-height: 30px;
      margin-top: 30px;
      margin-bottom: 4px;
    }
    .subtitle {
      font-weight: 500;
      font-size: 17px;
      line-height: 27px;
      color: color.$dark-text-color;
      margin-bottom: 5px;
    }
    .container {
      .picture_container {
        .pp {
          width: 110px;
          height: 110px;
          .default_img{
          width: 80px;
          height: 80px;
          }
          .preview_img{
            width: 110px;
          height: 110px;
          }
          .plus_icon {
            width: 30px;
            height: 30px;
          }
          .dropdown {
            left: -10px;
            bottom: -55px;
            width: 200px;
            // .item {
            //   padding: 5px 10px;
            //   .icon {
            //     margin-right: 5px;
            //   }
            // }
          }
        }
        .upload_text {
          font-size: 18px;
          margin-top: 9px;
        }
      }
      .form_container {
        .input_container {
          margin-bottom: 15px;
          .input{
            width: 250px;
          }
          .inputph{
            width: 250px;
          }
        }
      }
    }
    .account_text {
      font-size: 12px;
    }
  }
}
