.chatBody {
    max-height: 500px;
    // max-width: 850px;
    height: 500px;
    overflow-y: auto;
    // overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;  
    .message {
      margin: 10px;
      padding: 5px 10px;
      border-radius: 5px;
      font-size: 16px;
      max-width: 70%;
  
      &.incoming {
        align-self: flex-start;
        background: #F5F5F5;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
        border-radius: 10px 10px 10px 0px;
        max-width: 400px;
        overflow-wrap: break-word;
      }
  
      &.sent {
        align-self: flex-end;
        background: #0298A6;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
        border-radius: 10px 10px 0px 10px;
        color: white;
        max-width: 400px;
        overflow-wrap: break-word;
        .messageTime {
            color: white;
          }
      
          .sentIcon {
            color: white;
          }
      }
     
  
      .messageFooter {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 12px;
        margin-top: 5px;
      }
  
      .messageTime {
        margin-right: 5px;
      }
  
      .sentIcon {
        font-weight: bold;
        font-size: 15px;
      }
    }
    .prod{
      margin: 10px;
      
      &.incomingp{
        align-self: flex-start;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
        border-radius: 10px 10px 10px 0px;
        max-width: 400px;
        overflow-wrap: break-word;
      }
      &.sentp{
        align-self: flex-end;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
        border-radius: 10px 10px 0px 10px;
        color: white;
        max-width: 400px;
        overflow-wrap: break-word;
      }
      .prodcd{
        width: 380px;
      }
    }
  
    .dateSeparator {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #999;
      margin: 10px 0;
  
      &:before,
      &:after {
        content: "";
        flex-grow: 1;
        height: 1px;
        background-color: #999;
      }
  
      &:before {
        margin-right: 10px;
      }
  
      &:after {
        margin-left: 10px;
      }
    }
  
    @media screen and (min-width: 1800px) {
        max-height: 750px;
        height: 750px;
    }
    @media screen and (min-width: 1600px) and (max-width:1799px) {
        max-height: 650px;
        height: 650px;
    }
    @media screen and (max-width: 475px) {
      .message {
        margin: 10px;
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 16px;
        max-width: 250px;;
    
        &.incoming {
          align-self: flex-start;
          background: #F5F5F5;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
          border-radius: 10px 10px 10px 0px;
          max-width: 250px;
          
        }
    
        &.sent {
        
          max-width: 250px;
          .messageTime {
              color: white;
            }
        
            .sentIcon {
              color: white;
            }
        }
        
      }
      .prod{
        width: 250px;
        &.incomingp{
          width: 230px;
        }
        &.sentp{
          width: 230px;
          
        }
        .prodcd{
          width:250px;
        }
      }
    }
  }
  