.container {
  display: flex;
  justify-content: center;
  align-items: center;
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 839px;
    margin: 40px 0;
    background: #f8f8f9;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    .title {
      max-width: 534px;
      font-weight: 700;
      font-size: 32px;
      line-height: 48px;
      text-align: center;
      color: #353844;
      span {
        color: #e76859;
      }
      margin: 47px 0;
    }
    .line {
      border-top: 1px solid #c2c2c2;
      width: 100%;
      margin: 50px 0;
    }
    .subtitle {
      font-weight: 600;
      font-size: 32px;
      line-height: 48px;
      text-align: center;
      color: #353844;
      max-width: 534px;
    }
    .image {
      width: 423px;
      height: 286px;
      border-radius: 10px;
      margin: 45px 0;
    }
    .text {
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      color: #c2c2c2;
      max-width: 665px;
      span {
        color: #0298a6;
      }
    }
    .cancel {
      background: #ffffff;
      border: 3px solid #0298a6;
      border-radius: 10px;
      width: 153px;
      height: 52px;
      font-weight: 600;
      font-size: 22px;
      line-height: 150%;
      color: #0298a6;
      margin: 65px 0;
    }
  }
  @media screen and (max-width: 1380px) {
    .wrapper {
      width: auto;
      padding: 0 20px;
      .title {
        margin: 37.6px 0;
        margin-bottom: 0px;
        font-size: 25.6px;
        line-height: 38.4px;
      }
      .line {
        margin: 30px 0;
      }
      .subtitle {
        font-size: 25.6px;
        line-height: 38.4px;
        max-width: 70%;
      }
      .image {
        width: 338.4px;
        height: 228.8px;
        border-radius: 10px;
        margin: 25px 0;
      }
      .text {
        font-size: 16px;
        line-height: 24px;
        // max-width: 85%;
      }
      .cancel {
        width: 122.4px;
        height: 41.6px;
        font-size: 17.6px;
        line-height: 120%;
        margin: 52px 0;
      }
    }
  }
  @media screen and (max-width: 730px) {
    .wrapper {
      .title {
        font-size: 23px;
        line-height: 35px;
        margin: 20px 0;
      }
      .line {
        margin: 13px 0;
      }
      .subtitle {
        width: 100%;
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
}
