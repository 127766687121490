@use "../../styles/variables.scss" as color;
.subtitle {
  margin-top: 44px;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  color: #353844;
  @media screen and (max-width: 620px) {
    font-size: 28.8px;
    line-height: 43.2px;
    margin-top: 35.2px;
  }
} 
.categories_container {
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  margin-top: 50px;
  flex-wrap: wrap;
  gap: 0% ;
  
  &.categories_containerDark{
    background-color: color.$dark-text-color;
  }
  &.categories_containerBar{
    margin-left: 20px;
    gap: 0% ;
  }
}
.search {
  margin-top: 55px;
  margin-bottom: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1130px) {
  .categories_container {
    gap: 50px 150px;
}
}
@media screen and (max-width:900px) {
  
  .categories_container {
    &.categories_containerBar{
      margin-left: -50px;
    }
  }
}
@media screen and (max-width: 870px) {
  .categories_container {
    gap: 30px;
  }
}
@media screen and (max-width: 800px) {
  .categories_container {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
}
