@use "../../../../styles/variables.scss" as color;
.user_container {
  margin: 0px auto;
  display: flex;
  gap: 17px;
  .img {
    width: 88px;
    height: 88px;
    border-radius: 50%;
    object-fit: cover;
  }
  .info_container {
    display: flex;
    flex-direction: column;
    .name {
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: color.$dark-text-color;
      display: flex;
      align-items: center;
      &.nameDark{
        color:color.$white-text-color;
      }
      .rate {
        background: color.$primary-color;
        border-radius: 10px;
        padding: 3px 6px;
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
        color: #ffffff;
        margin-left: 24px;
        display: flex;
        align-items: center;
        gap: 2px;
        .icon {
          width: 16px;
          height: 16px;
          display: inline-block;
        }
      }
    }
    .date {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: color.$light-text-color;
      &.dateDark{
        color:color.$white-text-color;
      }
    }
    .view_profile {
      align-self: flex-start;
      font-weight: 600;
      font-size: 20px;
      line-height: 0px;
      color: color.$primary-color;
      cursor: pointer;
      text-decoration: underline;
    }
  }
  @media screen and (max-width: 1380px) {
    margin: 31.5px auto;
    display: flex;
    gap: 11.9px;
    .img {
      width: 61.6px;
      height: 61.6px;
    }
    .info_container {
      .name {
        font-size: 16.8px;
        line-height: 25.2px;
        span {
          padding: 2.1px 6.3px;
          font-size: 15px;
          line-height: 15.4px;
          margin-left: 16.8px;
          .icon {
            width: 11.2px;
            height: 11.2px;
          }
        }
      }
      .date {
        font-size: 11.2px;
        line-height: 16.8px;
      }
      .view_profile {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
}
