@use "../../../../styles/variables.scss" as color;
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    margin-top: 1em;
    font-weight: 600;
    font-size: 2.25rem;
    line-height: 3.375rem;
    color: color.$dark-text-color;
    &.titleDark{
      color:white
    }
  }
  .img {
    margin-top: 2.81em;
  }
  .empty {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2.25rem;
    color: color.$dark-text-color;
    margin-top: 0.625em;
    margin-bottom: 0.625em;
    &.emptyDark{
      color:white
    }
  }
  .text {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.875rem;
    color: color.$light-gray-color;
    margin-bottom: 2.81em;
    &.textDark{
      color:white
    }
  }
  .btn {
    background: color.$primary-color;
    border-radius: 10px;
    color: white;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2.25rem;
    padding: 9px 4.16em;
    margin-bottom: 2.41em;
    &.btnDark{
    background-color: #015866;
    }
  }
  @media screen and (max-width: 480px) {
    font-size: 10px;
    .title,
    .empty {
      font-size: 1.25rem;
    }
    .text {
      font-size: 1.125rem;
    }
    .btn {
      font-size: 0.925rem;
    }
  }
}
