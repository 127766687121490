@use "../../../styles/variables.scss" as color;
.path_container {
  font-weight: 500;
  line-height: 30px;
  color: color.$dark-text-color;
  margin-bottom: 20px;
  .Home{
    // text-decoration: none;
    color:color.$primary-color;
  }
  .spanpath{
    &.spanpathDark{
      color:color.$white-text-color;
    }
  }
  .icon {
    display: inline;
    &.iconDark{
      color: color.$white-text-color;
    }
  }
  .curent_path {
    color: color.$primary-color;
    text-decoration: underline;
    font-size:  23px;
  }
  .disabled_path{
    &.disabled_pathDark{
      color: white;
    }
  }
 
  @media screen and (max-width:620px) {
    .Home{
      font-size:  16px;
    }
    .spanpath{
      font-size:  16px;
      &.spanpathDark{
        color:color.$white-text-color;
      }
    }
    .icon {
      font-size: 16px;
      display: inline;
      &.iconDark{
        color: color.$white-text-color;
      }
    }
    .curent_path {
      font-size:  16px;
      color: color.$primary-color;
      text-decoration: underline;
    }
    .disabled_path{
      font-size:  16px;
      &.disabled_pathDark{
        color: white;
      }
  }
  }
  @media screen and (max-width: 475px) {
    font-size: 13px;
    line-height: 24px;
    padding-top: 10px;
    .Home{
      font-size:  13px;
    }
    .spanpath{
      font-size:  13px;
      &.spanpathDark{
        color:color.$white-text-color;
      }
    }
    .icon {
      font-size: 13px;
      display: inline;
      &.iconDark{
        color: color.$white-text-color;
      }
    }
    .curent_path {
      font-size:  13px;
      color: color.$primary-color;
      text-decoration: underline;
    }
    .disabled_path{
      font-size:  13px;
      &.disabled_pathDark{
        color: white;
      }
  }
  }
}
