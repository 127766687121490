@use "../../../styles/variables.scss" as color;
.card_container {
  margin-bottom: 38px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 30%;
  .card {
    width: 272.7px;
    height: 333px;
    background: color.$light-background-color;
    border: 5px solid color.$secondary-color;
    border-radius: 10px;
    transition: width 0.5s ease-in-out;
    position: relative;
    margin-right: 20px; 
    margin-bottom: 20px; 
    &.cardDark{
      background-color: #015866;
    }
    .card_image {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.5s ease;
      max-height: 100px;
    }
    .subcategories_container {
      width: 120px;
      display: none;
      transition: all 0.5s ease;
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(-50%, -50%);
      
      .subcategories_item {
        color: color.$primary-color;
        border-bottom: 2px solid color.$secondary-color;
        padding-top: 5px;
        padding-bottom: 4px;
        z-index: 3;
        .subcategories_title {
          font-weight: 600;
          line-height: 21.6px;
          text-indent: 10px;
          text-decoration: none;
          font-size: 16px;
          color: color.$primary-color;
          &.subcategories_titleDark{
            color:color.$white-text-color;
          }
        }
        &:last-child {
          border-bottom: 0px;
        }
      }
    }
    &:hover {
      width: 450px;
      .subcategories_container {
        display: block;
        right: -10%;
      }
      .card_image {
        left: 25%;
        z-index: 2;
      }
    }
  }
  .card_title {
    margin-top: 38px;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: #666666;
    text-align: center;
    &.card_titleDark{
      color:color.$white-text-color
    }
  }
  // @media screen and (min-width: 1301px) {
  //   .subcategories_item {
  //     padding-top: 9px;
  //     padding-bottom: 8px;
  //     .subcategories_title {
  //       font-size: 16px;
  //     }
  //   }
  // }
  @media only screen and (max-width: 1300px) {
    .card {
      width: 212px;
      height: 259px;
      &:hover {
        width: 400px;
        .subcategories_container {
          right: -3%;
        }
      }
      .subcategories_container {
        .subcategories_item {
          padding-top: 2px;
          padding-bottom: 2px;
          .subcategories_title {
            font-size: 12px;
          }
        }
      }
      .card_image {
        max-width: 150px;
      }
      .title_container {
        width: 212px;
        .card_title {
          margin-top: 38px;
          font-weight: 600;
          font-size: 25px;
          line-height: 20px;
        }
      }
    }
  }
  @media only screen and (max-width: 630px) {
    .card {
      width: 212px;
      height: 259px;
      &:hover {
        width: 400px;
        .subcategories_container {
          right: -3%;
        }
      }
      .card_image {
        max-width: 150px;
      }
      .subcategories_container {
        .subcategories_item {
          padding-top: 1px;
          padding-bottom: 1px;
          .subcategories_title {
            font-size: 12px;
          }
        }
      }
      .title_container {
        .card_title {
          margin-top: 38px;
          font-weight: 600;
          font-size: 25px;
          line-height: 20px;
        }
      }
    }
  }
  @media only screen and (max-width: 434px) {
    .card {
      &:hover {
        width: 350px;
        .subcategories_container {
          right: -3%;
        }
      }
    }
    .card_image {
      max-width: 130px;
    }
    .subcategories_item {
      padding-top: 1px;
      padding-bottom: 1px;
      .subcategories_title {
        font-size: 11px;
      }
    }
    .title_container {
      .card_title {
        margin-top: 30px;
      }
    }
  }
  
}
