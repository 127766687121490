@use "../../../styles/variables.scss" as color;
.containerdiv {
        display: flex;
        justify-content: space-between;
      }
.drop-file {
    margin-top:30px;
    border: 3px dashed color.$primary-color ; 
    border-radius: 25px;
    z-index: 0;
    width:250px;
    height:250px;
    position: relative;
    border-spacing: 100px;
    br{
        text-align: center;
    }
    .uploadim{
        width: 62px;
        height: 62px;
        margin-left: 80px;
        }
        .drag{
            text-align: center;
        }
        .or {
            text-align: center;
          }
          .uploadbut{
            color: #ffffff;
            background: color.$primary-color;
            margin-left: 60px;
            height: 40px;
            width: 130px;
            border-radius: 10px;
            margin-bottom: 10%;
          }
        .inputF {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: 1;
        }
  }
.drop-file-input input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}
.drop-file-input:hover,
.drop-file-input.dragover {
    opacity: 0.6;
}
.drop-file-input__label {
    text-align: center;
    color: var(--txt-second-color);
    font-weight: 600;
    padding: 10px;
}
.drop-file-input__label img {
    align-items: center;
    width: 100px;
}
.drop-file-preview {
    margin-top: 30px;
}
.drop-file-preview p {
    font-weight: 500;
}
.drop-file-preview__title {
    margin-bottom: 20px;
}
.drop-file-preview__item {
    position: relative;
    display: flex;
    margin-bottom: 10px;
    background-color: var(--input-bg);
    padding: 15px;
    border-radius: 20px;
}
.drop-file-preview__item img {
    width:70px;
    margin-right: 20px;
}
.drop-file-preview__item__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.drop-file-preview__item__del {
    display: flex;
    background-color: var(--box-bg);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: var(--box-shadow);
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease;
}
.drop-file-preview__item:hover .drop-file-preview__item__del {
    opacity: 1;
}
.ProdServ {
    margin-top: 10%;
    margin-left: 20%;
    border: 1px solid gray;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    flex-direction: column;
    height: 150px;
    position: relative;
    
  .catT {
    position: absolute;
    top: -20px; 
    font-size: 14px;
  }
 button {
    padding: 20px 30px;
    background-color: white;
    cursor: pointer;
    font-weight: 500;
    font-size: 15px;
    line-height: 28px;
    border: 0.001px dotted white;
    border-radius: 16px;
    display: inline-block;
    &.active {
      border: 2px solid color.$primary-color !important;
      border-radius: 6px;
      width: 160px;
    //   margin-left: 6%;
      background-color: color.$primary-color !important;
      color: #ffffff;
      height: 115px;
    }
    // &:not(:last-child) {
    //     margin-right: 10px;
    //   }
  }
}
@media (max-width: 1300px) {
    .containerdiv{
        display: block;
    }
    .drop-file {
        width:200px;
        height:200px;
        .uploadim{
            width: 48px;
            height: 48px;
            }
   
        .uploadbut{
         margin-left: 30px;
        } 
      }
      .ProdServ {
    margin-left: 5%;
    border: 1px solid gray;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    flex-direction: column;
    height: 150px;
    position: relative;
    
  .catT {
    position: absolute;
    top: -20px; 
    font-size: 14px;
  }
 button {
    padding: 20px 30px;
    background-color: white;
    cursor: pointer;
    font-weight: 500;
    font-size: 15px;
    line-height: 28px;
    border: 0.001px dotted white;
    border-radius: 16px;
    display: inline-block;
    &.active {
      border: 2px solid color.$primary-color !important;
      border-radius: 6px;
      width: 160px;
    //   margin-left: 6%;
      background-color: color.$primary-color !important;
      color: #ffffff;
      height: 115px;
    }
    // &:not(:last-child) {
    //     margin-right: 10px;
    //   }
  }
}
    
}
@media (max-width: 500px) {
    .containerdiv{
        display: block;
    }
    .drop-file {
        width:150px;
        height:150px;
        margin-left: -30px;
        .uploadim{
            width: 40px;
            height: 40px;
            margin-left: 50px;
            }
            .drag{
              font-size: 10px;
              margin-bottom: 0px;
            }
            .or{
              font-size: 10px;
            }
            br{
              display: none;
            }
        .uploadbut{
         margin-left: 10px;
         height: 25px;
         margin-top:0px;
        } 
      }
      .ProdServ {
    margin-left: -30px;
    border: 1px solid gray;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    flex-direction: column;
    height: 150px;
    position: relative;
    
  .catT {
    position: absolute;
    top: -20px; 
    font-size: 14px;
  }
 button {
    padding: 20px 30px;
    background-color: white;
    cursor: pointer;
    font-weight: 500;
    font-size: 15px;
    line-height: 28px;
    border: 0.001px dotted white;
    border-radius: 16px;
    display: inline-block;
    &.active {
      border: 2px solid color.$primary-color !important;
      border-radius: 6px;
      width: 160px;
    //   margin-left: 6%;
      background-color: color.$primary-color !important;
      color: #ffffff;
      height: 115px;
    }
    // &:not(:last-child) {
    //     margin-right: 10px;
    //   }
  }
}
    
}
