@use "../../../../styles/variables.scss" as color;
.empty_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 45px;
  .subtitle {
    font-weight: 700;
    font-size: 21.58px;
    line-height: 32px;
    text-align: center;
    color: color.$dark-text-color;
    margin-top: 45px;
    &.subtitleDark{
      color: #ffffff;
    }
  }
  .text {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: color.$light-gray-color;
    margin-top: 8.47px;
  }
  .button {
    background: color.$primary-color;
    border-radius: 9px;
    padding: 9px 97px;
    font-weight: 500;
    font-size: 19.2784px;
    line-height: 29px;
    color: #ffffff;
    margin-top: 45px;
    margin-bottom: 50px;
    &.buttonDark{
      background-color: #015866;
    }
    &:hover{
      background-color: #01b1c0;
    }
  }
  @media screen and (max-width: 620px) {
    margin-top: 40px;
    .subtitle {
      font-size: 17.264x;
      line-height: 25.6px;
      margin-top: 35px;
    }
    .text {
      font-size: 14.4px;
      line-height: 21.6px;
    }
    .button {
      padding: 9px 77.6px;
      font-size: 15.42272px;
      line-height: 23.2px;
      margin-top: 35px;
      margin-bottom: 40px;
    }
  }
  @media screen and (max-width: 385px) {
    .button {
      padding: 9px 50px;
    }
  }
}
