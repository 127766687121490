@use "../../../styles/variables.scss" as color;
.card {
  cursor: pointer;
  width: 300px;
  height: 200px;
  background: color.$white-text-color;
  border: 5px solid color.$secondary-color;
  border-radius: 10px;
  // margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &.cardDark{
    background-color: #015866;
  }
  .img {
    max-width: 80px;
  }
  .title {
    font-weight: 600;
    font-size: 25px;
    line-height: 38px;
    display: flex;
    align-items: center;
    color: color.$dark-text-color;
    margin-top: 37px;
    &.titleDark{
      color:color.$white-text-color;
    }
  }
  @media screen and (max-width: 1200px) {
    width: 280px;
    height: 185px;
  }
  @media screen and (max-width: 400px) {
    width: 280px;
    height: 185px;
  }
}
