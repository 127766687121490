@use "../../../../styles/variables.scss" as color;
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  // &.containerDark{
  //   background: color.$dark-text-color;
  // }
  .close {
    position: absolute;
    top: 13.3px;
    right: 13.3px;
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
  .back {
    position: absolute;
    top: 13.3px;
    left: 13.3px;
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
  .logoCont{
    justify-content: center;
    align-items: center;
    display: flex;
  .logo {
    margin-top: 20px;
    margin-bottom: 45.5px;
    font-weight: 500;
    font-size: 28px;
    line-height: 42px;
    text-align: center;
    color: color.$primary-color;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
    transform: scale(2.5);
  }
}
  .verification_container {
    width: 75%;
    margin: 0 auto;
    .verification_title {
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: #353844;
      margin-bottom: 23px;
      &.verification_titleDark{
        color: color.$white-text-color;
      }
    }
    .verification_text {
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: #8d8d8d;
      margin-bottom: 23px;
      .email {
        display: block;
        color: color.$primary-color;
      }
    }
    .code_container {
      width: 100%;
      height: 56px;
      border: 1px solid color.$border-category-dropdown;
      border-radius: 10px;
      position: relative;
      .code_input {
        background: #f8f8f9;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        padding-left: 20px;
        &.code_inputDark{
          background: #015866;
          color: rgb(207, 203, 203);
        }
        &:focus {
          border: 1px solid color.$primary-color;
          outline: none;
        }
      }
      .send_btn {
        position: absolute;
        right: -1px;
        top: -1px;
        height: 56px;
        padding: 0 30px;
        background: #ffffff;
        border: 1px solid color.$border-category-dropdown;
        border-radius: 0px 8px 8px 0px;
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        color: color.$dark-text-color;
        &.send_btnDark{
          background: rgb(207, 203, 203);
          &:hover{
            background: #015866;
            color: white;
  
          }
        }
        &:hover{
          background: color.$primary-color;
          color: white;
        }
      }
      .send_btnRsnd {
        position: absolute;
        right: -1px;
        top: -1px;
        height: 56px;
        padding: 0 30px;
        background: gray;
        border: 1px solid color.$border-category-dropdown;
        border-radius: 0px 8px 8px 0px;
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        color: white;
        cursor: not-allowed;
      }

    }
  }
  .confirm_btn {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #ffffff;
    background: #0298a6;
    border-radius: 10px;
    padding: 12px 51px;
    margin-top: 82px;
    margin-bottom: 82px;
    &.confirm_btnDark{
      background: #015866;
    }
  }
  .disabled {
    background-color: gray;
    color: white;
    cursor: not-allowed;
  }
  @media screen and (max-width: 590px) {
    .close {
      width: 22px;
      height: 22px;
    }
    .back {
      width: 22px;
      height: 22px;
    }
    .logo {
      font-size: 25.2px;
      line-height: 37.8px;
    }
    .verification_container {
      width: 80%;
      .verification_title {
        font-size: 14px;
        line-height: 21px;
      }
      .verification_text {
        font-size: 12.6px;
        line-height: 18.9px;
      }
      .code_container {
        height: 39.2px;
        .code_input {
          font-size: 12.6px;
          line-height: 21px;
        }
        .send_btn {
          height: 39.2px;
          padding: 0 21px;
          font-size: 12.6px;
          line-height: 21px;
        }
        .send_btnRsnd {
          height: 39.2px;
          padding: 0 21px;
          font-size: 12.6px;
          line-height: 21px;
        }
      }
    }
    .confirm_btn {
      font-size: 16.8px;
      line-height: 25.2px;
      padding: 8.4px 35.7px;
      margin-top: 57.4px;
      margin-bottom: 57.4px;
    }
  }
}
