@use "../../../../styles/variables.scss" as color;
.register_container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  // &.register_containerDark{
  //   background-color: color.$dark-text-color;
  //   height: 100%;
  // }
  .close {
    position: absolute;
    top: 13.3px;
    right: 13.3px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    &.closeDark{
      background-color:#015866 ;
      border-radius: 50%;
    }
  }
  .back {
    position: absolute;
    top: 13.3px;
    left: 13.3px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    &.backDark{
      background-color:#015866 ;
      border-radius: 50%;
    }
  }
  .progress_container {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: 30px;
    max-width: 100%;
    width: 467.6px;
    margin-top: 20.5px;
    margin-bottom: 30px;
    &::before {
      content: "";
      background-color: #e0e0e0;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: 4px;
      width: 100%;
      z-index: -1;
    }
    &.progress_containerDark {
      &::before {
        z-index: auto;
      }
    }
    .progress {
      background-color: color.$primary-color;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: 4px;
      width: 0%;
      z-index: -1;
      transition: 0.4s ease;
      &.progressDark{
        background-color: #015866;
      }
    }
    .circle {
      position: relative;
      background-color: color.$light-background-color;
      color: #c1c2c6;
      border-radius: 50%;
      height: 44.8px;
      width: 44.8px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 3px solid #d9d9d9;
      transition: 0.4s ease;
      &.circleDark{
        &.active {
          border-color: #015866;
          color: white;
          background-color:#015866
        }      
      }
      &.active {
        border-color: color.$primary-color;
        color: white;
        background-color: color.$primary-color;
      }
      .icon {
        width: 25px;
        height: 25px;
      }
      .title {
        position: absolute;
        top: 45px;
        font-weight: 600;
        font-size: 12.6px;
        line-height: 18.9px;
        color: #c1c2c6;
        &.titleDark{
          color:color.$white-text-color;
          &.active {
            color:#015866;
          }
        }
        &.active {
          color: #0298a6;
        }
      }
    }
  }
  @media screen and (max-width: 750px) {
    .progress_container {
      width: 50vw;
    }
  }
  @media screen and (max-width: 420px) {
    .progress_container {
      .circle {
        position: relative;
        background-color: color.$light-background-color;
        color: #c1c2c6;
        border-radius: 50%;
        height: 35px;
        width: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 3px solid #d9d9d9;
        transition: 0.4s ease;
        &.circleDark{
          &.active {
            border-color: #015866;
            color: white;
            background-color:#015866
          }      
        }
  
        &.active {
          border-color: color.$primary-color;
          color: white;
          background-color: color.$primary-color;
        }
  
        .icon {
          width: 20px;
          height: 20px;
        }
  
        .title {
          position: absolute;
          top:40px;
          font-weight: 600;
          font-size: 10px;
          line-height: 18.9px;
          color: #c1c2c6;
          &.titleDark{
            color:color.$white-text-color;
            &.active {
              color:#015866;
            }
          }
          &.active {
            color: #0298a6;
          }
        }
      }
    }
  }
  @media screen and (max-width: 380px) {
    .close {
      width: 22px;
      height: 22px;
    }
    .back {
      width: 22px;
      height: 22px;
    }
  }
}
