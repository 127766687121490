@use "../../../styles/variables.scss" as color;
.overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.1);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
}
.modal_styles {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2001;
  background-color: white;
  width: 378px;
  height: 501.9px;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .img {
    width: 90%;
    margin-top: 20px;
  }
  .title {
    margin-top: 38.5px;
    font-weight: 500;
    font-size: 25.2px;
    line-height: 37.8px;
    text-align: center;
    color: #353844;
    max-width: 60%;
  }
  .done {
    margin-top: 38.5px;
    background: #0298a6;
    border-radius: 10px;
    color: white;
    width: 182px;
    height: 52.5px;
    font-weight: 500;
    font-size: 25.2px;
    line-height: 37.8px;
  }
  @media screen and (max-width: 650px) {
    width: 264.6px;
    height: 351.33px;
    .img {
      margin-top: 14px;
    }
    .title {
      margin-top: 26.95px;
      font-size: 17.64px;
      line-height: 26.46px;
    }
    .done {
      margin-top: 26.95px;
      width: 182px;
      height: 52.5px;
      font-size: 17.64px;
      line-height: 26.46px;
    }
  }
}
