@use "../../styles/variables.scss" as color;
.section {
  &.sectionDark{
    background-color: color.$dark-text-color;
  }
  .header {
    padding: 0px 15px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 13px;
    .header__title {
      font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    color: color.$dark-text-color;
    &.header__titleDark{
      color: color.$white-text-color;
    }
    }
 .all {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: color.$primary-color;
    cursor: pointer;
    .S_all{
      text-decoration: none;
      color:color.$primary-color;
      &.S_allDark{
        color:#015866;
      }
    }
    .arrow {
      transform: translateY(-5%);
      display: inline-block;
    }
  }
  @media screen and (max-width: 570px) {
    padding: 0px;
    
    .title {
      font-size: 20px;
    }
    .all {
      font-size: 18px;
    }
  }
  }
  
  .swiper__section {
    padding-left: 20px;
    
    .myswiper {
      height: 1017px;
      .swiperslide {
        height: calc((100% - 30px) / 2) !important;
      }
      @media screen and (max-width: 1400px) {
        height: 508px;
      }
    }
    @media (max-width: 1290px) {
      .swiperslide {
        flex-basis: 25%;
      }
    }
    @media (max-width: 930px) {
      .swiperslide {
        flex-basis: 33%;
      }
    }
    @media (max-width: 580px) {
     
      .swiperslide {
        flex-basis: 40%;
      }
    }
    @media (max-width: 475px) {
      .swiperslide {
        flex-basis: 50%;
      }
    }
    @media (max-width: 385px) {
      .swiperslide {
        flex-basis: 60%;
      }
    }
    @media (max-width: 335px) {
      .swiperslide {
        flex-basis: 70%;
      }
    }
  }
}
@media screen and (max-width: 570px) {
  
  .section {
    .header {
  .header__title {
    font-size: 20px;
  }
  .see_all {
    font-size: 18px;
  }
    }
  }
}
