@use "../../../../styles/variables.scss" as color;
.VDBut{
    background-color: white;
    color: color.$primary-color;
    border: 2px solid color.$primary-color;
    font-weight: 500;
    height: 30px;
    padding-bottom: 2px;
    margin-top:-10px ;
    width: 90px;
    border-radius:10px ;
    &:hover{
        background-color: color.$primary-color;
        color: white;
    }
    @media (max-width: 1050px) {
        font-size: 15px;
    }
}