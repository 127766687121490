.dropdown-basic-button{
    background-color: white;
}
.text{
    cursor: pointer;
    color: inherit;
}
.text:hover{
    color: #0298a6;
}
.textDark{
    color:white
}
.textDark:hover{
    color: #0298a6;
}
.subcategorie_text{
    text-decoration: none;
    color: inherit;
}
.subcategorie_text:hover{
    color: lightgray;
}
