.container {
  padding: 0 3.75rem;
  overflow-x: hidden;
  &.containerDark{
  background-color:#353844;
  }
  @media screen and (max-width: 735px) {
    padding: 0 2.75rem;
  }
  @media screen and (max-width: 690px) {
    padding: 0 1.5rem;
  }
  @media screen and (max-width: 500px) {
    padding: 0 1rem;
  }
}
