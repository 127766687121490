.container {
  height: 500px;
  overflow-y: auto;
  max-height: 500px;
  margin-bottom: 50px;

  .table {
    width: 653px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 10px;

  }

  .table td,
  .table th {
    border: 1px solid #ddd;
    height: 75px;
    text-align: center;
    vertical-align: middle;

  }

  .table_heading {
    background: #0298a6;
    border-radius: 10px;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: white;

    &.table_headingDark {
      background-color: #015866;
    }
  }

  .name_container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .name_content {
      display: flex;
      align-items: center;
      width: 150px;

      .img {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        object-fit: cover;
        display: inline-block;
        position: absolute;
        left: 17px;
        top: 50%;
        transform: translateY(-50%);
      }

      .name {
        margin-left: 50px;
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        text-decoration-line: underline;
        color: #353844;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100px;

        &.nameDark {
          color: white
        }
      }

    }
  }

  .starting_container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .starting_content {
      display: flex;
      align-items: center;
      justify-content: center;

      .starting {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: #353844;

        &.startingDark {
          color: white
        }
      }

    }
  }









  .amount {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #353844;

    &.amountDark {
      color: white;
    }
  }

  .time {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #353844;

    &.timeDark {
      color: white;
    }
  }

  @media screen and (max-width: 730px) {
    .table {
      width: 90vw;
    }

    .table td,
    .table th {
      height: 60px;
    }

    .table_heading {
      font-size: 19.2px;
      line-height: 28.8px;
    }

    .name_container {
      .name_content {
        .name {
          margin-left: 50px;
          font-size: 12.8px;
          line-height: 19.2px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 80px;
        }
      }
    }

    .amount {
      font-size: 16px;
      line-height: 24px;
    }

    .time {
      font-size: 12px;
      line-height: 17.6px;
    }
  }

  @media screen and (max-width: 570px) {
    .table {
      width: 97vw;
    }

    .table_heading {
      font-size: 15.36px;
      line-height: 23.04px;
    }

    .name_container {
      .name_content {
        .name {
          margin-left: 50px;
          font-size: 12.8px;
          line-height: 19.2px;
        }

        .img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  @media screen and (max-width: 500px) {

    .name_container {
      .name_content {
        width: 100px;

        .name {
          margin-left: 60px;
          font-size: 12.8px;
          line-height: 19.2px;
          width: 100px;
          /* Adjust the width as needed */
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

      }
    }
  }

  @media screen and (max-width: 450px) {
    .name_container {
      .name_content {
        .name {
          margin-left: 60px;
          font-size: 12.8px;
          line-height: 19.2px;
          width: 65px;
          /* Adjust the width as needed */
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

      }
    }
    .starting_container {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
  
      .starting_content {
        display: flex;
        align-items: center;
        justify-content: center;
  
        .starting {
          margin-top: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-weight: 600;
          font-size: 14px;
          line-height: 28px;
          color: #353844;
  
          &.startingDark {
            color: white
          }
        }
  
      }
    }
  

    .amount {
      font-size: 15px;
      line-height: 24px;
      max-width: 80px;
      word-wrap: break-word;
    }

    .time {
      font-size: 10px;
      line-height: 17.6px;
    }
  }
}