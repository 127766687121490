
@use "../../../styles/variables.scss" as color;
$lightgray: #f8f9fa;
$gray: #dee2e6;
.wrapper {
  background-color: #f6ffff;
  display: flex;
  flex-direction: column;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bolder;
    }
    
    .calendar {
        position: relative;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      color:gray ;
    }
      .date{
        padding-left: 6px;
        // margin-top: -20%;
        color: color.$primary-color;
      }
    
      .datepicker_wrapper {
        position: absolute;
        top: calc(100% + 10px);
        left: auto;
        right: 0;
        z-index: 1;
      }
      
      .react-datepicker {
        position: relative;
      }
    
    
    .title {
      font-weight: 600;
      font-size: 32px;
      line-height: 48px;
      color: #353844;
    }
    .calendar_container {
      display: flex;
      align-items: center;
      .today {
        font-weight: 600;
        font-size: 16px;
        line-height: 120%;
        color: #a1a7ad;
        .calendar_icon {
          display: inline-block;
        }
      }
    }
  
  .filter_container {
    margin-top: 36px;
    display: flex;
    gap: 30px;
    .filter {
      width: 158px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ffffff;
      border: 1px solid #a1a1a1;
      border-radius: 10px;
    }
    .input_container {
      position: relative;
      .search_icon {
        position: absolute;
        top: 50%;
        left: 18.8px;
        transform: translateY(-50%);
      }
      input {
        width: 800px;
        height: 50px;
        background: #ffffff;
        border: 1px solid #a1a1a1;
        border-radius: 10px;
        padding-left: 52px;
      }
    }
  }
  .card_container {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .flex {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    .sales_container {
      width: 913px;
      height: 372px;
      background: #ffffff;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
      border-radius: 10.5px;
      .salesChart{
        width: 700px;
        height: 300px;
        display: flex; /* Use flexbox to arrange them horizontally */
        align-items: center; /* Vertically align them in the middle */
      }
      
    }
    .products_added_container {
      width: 420px;
      height: 372px;
      background: #ffffff;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
      border-radius: 10.5px;
    }
  }
  .bottom_container {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    .top_users {
      width: 915px;
      height: 518px;
      background: #ffffff;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      max-height: 518px;
      overflow-y: auto;
      .table {
        width:900px;
        margin-bottom: 20px;
        border-radius: 15px;
        overflow: hidden;
        margin-left: 10px;
        table {
          width: 100%;
          border-collapse: separate;
          border-spacing: 0;
          text-align: left;
          font-size: 14px;
          border: 2px solid $gray;
      
      
          th,
          td {
            width: 100px;
            padding: 12px;
            vertical-align: middle;
            border-top: none;
            border-left: none;
            border-right: none;
            
            &:first-child {
              border-left: none;
              border-radius: 0 0 0 0;
            }
      
            &:last-child {
              border-right: none;
              border-radius: 0 0 0 0;
            }
          }
      
          thead {
            tr {
              background-color: darkgray;
              color: black;
            }
            
          }
      
          tbody {
            tr:nth-child(odd) {
              background-color: white;
            }
      
            tr:nth-child(even) {
              background-color: rgb(235, 231, 231);
            }
      
            tr:first-child {
              background-color: white;
              color: #fff;
            }
          }
        
      }  
    }
    .smtable{
      width: 300px;
      border-radius: 10px;
      margin-bottom: 10px;
      margin-left: 10px;
      td {
        width: 100px;
        padding: 12px;
        vertical-align: middle;
        border-top: none;
        border-left: none;
        border-right: none;
        
        &:first-child {
          border-left: none;
          border-radius: 0 0 0 0;
        }
  
        &:last-child {
          border-right: none;
          border-radius: 0 0 0 0;
        }
      }
  
      thead {
        tr {
          background-color: darkgray;
          color: black;
        }
        
      }
  
      tbody {
        tr:nth-child(odd) {
          background-color: white;
        }
  
        tr:nth-child(even) {
          background-color: rgb(235, 231, 231);
        }
  
        tr:first-child {
          background-color: white;
          color: #fff;
        }
      }
    
  
    }
      .customername{
        font-weight: 600;
        .uInfo{
          display: flex;
          align-items: center;
          .customerImage{
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-right: 10px;
          }
        }
      }
      .sold{
        background: #B7F1F1;
        border-radius: 5px;
        font-weight: 600;
        display: flex;
        align-items: center;
        width: 60px;
        height: 30px;
        justify-content: center;
        p{
          color: #0298A6;
          padding-top: 15px;
        }
      }
    }
    .average {
      width: 420px;
      height: 334px;
      background: #ffffff;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      .dough{
        width: 450px;
      height: 300px;
      justify-content: center;
      align-items: center;
      display: flex;
      }
    }
    .online_users {
  
      margin-top: 20px;
    }
  }
  .label{
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-weight: 600;
  }
  @media (min-width: 1550px){
    .upcontainer{
      .flex{
        .sales_container{
          width: 65%;
        }
        .products_added_container{
          width: 30%;
        }
      }
    }
    .bottom_container{
      .top_users{
        width: 65%;
      }
      .dcontainer{
        width: 30%;
        .average{
        width: 100%;
        }
      }
        // .online_users{
        //   width:100px;
        // }
      
    }
  }
  @media (max-width: 1470px) {
    .header {
      flex-direction: column;
      align-items: flex-start;
    }
      .calendar {
      position: relative;
      // display:block;
      align-items: center;
      }
      .datepicker_wrapper {
        position: absolute;
        top: calc(100% + 10px);
        left: 0;
        right: auto;
        z-index: 1;
      }
      .upcontainer{
        display: flex;
        justify-content: space-evenly;
    .card_container {
      flex-direction: column;
    }
    .card_container > * {
      margin-bottom: 16px;
    }
    .flex {
      flex-direction: column;
      .sales_container,
      .products_added_container {
        width: 400px;
        height: 230px;
        margin-bottom: 16px;
        .salesChart{
          width: 390px;
          display: flex;
          flex-direction: column; /* Display them vertically */
          align-items: center; /* Horizontally align them in the middle */
        }
      }
      
    }
  }
    .bottom_container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex;
      .dcontainer{
        margin-top: 10px;
        display: flex;
        .average{
          margin-right: 30px;
        }
      }
    }
  }
  @media (max-width:1000px) {
    .upcontainer{
      display: flex;
      flex-direction: column;
      justify-content: center;
    .card_container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .card_container > * {
      margin-bottom: 16px;
    }
    .flex {
      flex-direction: flex;
      justify-content: center;
      align-items: center;
      .sales_container {
        width: 420px;
        height: 300px;
        justify-content: center;
        align-items: center;
        .salesChart{
          width: 400px;
          height: 300px;
          display: flex;
          flex-direction: column; /* Display them vertically */
          align-items: center; /* Horizontally align them in the middle */
        }
      }
      .products_added_container {
        width: 420px;
        height: 230px;
        margin-bottom: 16px;
      }
    }
    }
    .bottom_container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
        .top_users{
          max-width: 450px;
          .table {
            max-width:440px;
          }
        }
      .dcontainer{
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        width: 420px;
       
      }
    }
  }
  
  @media (max-width:600px) {
    .bottom_container {
      
  
      .top_users {
        max-width: 400px;
      }
      .dcontainer{
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
          align-items: center;
          .average{
           margin-left: 30px;
          }
        .online_users{
          justify-content: center;
          align-items: center;
        }
       
      }
    }
  }
  @media (max-width:500px) {
    .upcontainer{
     
    .flex {
      .sales_container,
      .products_added_container {
        max-width: 250px;
        height: 230px;
        margin-bottom: 16px;
        .salesChart {
          max-width: 240px;
          display: flex;
          flex-direction: column; /* Display them vertically */
          align-items: center; /* Horizontally align them in the middle */
        }               
      }
    }
    }
  
    .bottom_container {
      flex-direction: column;
        .top_users{
          max-width: 250px;
          
        }
        .average{
          max-width:250px ;
          .dough{
            max-width: 240px;
          }
        }
      .dcontainer{
        margin-top: 10px;
        display: flex;
        flex-direction: column;
      }
    }
  }
  
  @media (max-width:390px) {
    .upcontainer{
      display: flex;
      flex-direction: column;
      justify-content: start;
    .card_container {
      flex-direction: column;
      justify-content: start;
      align-items: start;
    }
    
    .flex {
      flex-direction: flex;
      justify-content: start;
      align-items: start;
    }
    }
    .bottom_container {
      flex-direction: column;
      justify-content: start;
      align-items: start;
      .dcontainer{
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        width: 420px;
        justify-content: start;
        align-items: start;
        .average{
          margin-left: 0;
        }
       
      }
    }
  }
}
.salesChartContainer {
  display: flex; /* Use flexbox to arrange them horizontally */
  align-items: center; /* Vertically align them in the middle */
}

.datePickerContainer {
  /* Add any styles you want for the DatePicker container here */
  margin-left: 20px; /* Add spacing between the Line chart and DatePicker */
}