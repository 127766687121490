.leftI {
    display: flex;
    align-items: center;
    margin-left: 5%;
    margin-top: 10%;
    font-weight: 600;
  
    .image-container {
        margin-right: 6%;
        margin-top: -8%;
        width: 60px;
        height: 60px;
        object-fit: cover;
        .Product-image{
            width: 60px;
            height: 60px; 
            object-fit: cover;
        }
       
    }
    .text{
        font-size:13px ;
        font-weight: 500;
        font-family:poppins;
    }
    @media (min-width: 1600px){
        height: 100px;   
    }
    @media (max-width: 1050px){
        display: block;
        margin-left: 0;
        margin-top: 0;
        padding: 0;
    }
}