.container {
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
  
.card_container {
    align-self: flex-start;
    margin-top: 36px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
    margin-left: 50px;
    margin-bottom: 40px;
    
  }
  .empty{
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    .txt{
      &.txtDark{
        color: white;
      }
    }
    .btn {
      margin-top: 30px;
      background: #0298a6;
      border-radius: 10px;
      color: white;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 2.25rem;
      padding: 9px 4.16em;
      margin-bottom: 2.41em;
      &.btnDark{
      background-color: #015866;
      }
      &:hover{
        background-color: #01b1c0;
      }
    }
  }
  @media screen and (max-width: 1465px) {
    .card_container {
      gap: 30px;
    }
  }
  @media screen and (max-width: 1455px) {
    .card_container {
      column-gap: 200px;
      row-gap: 100px;
    }
  }
  @media screen and (max-width: 1200px) {
    .card_container {
      margin-left: 0;
    }
  }
  @media screen and (max-width: 1140px) {
    .card_container {
      column-gap: 150px;
      row-gap: 60px;
    }
  }
  @media screen and (max-width: 1090px) {
    .card_container {
      column-gap: 100px;
      row-gap: 60px;
    }
  }
  @media screen and (max-width: 1040px) {
    .card_container {
      column-gap: 60px;
      row-gap: 50px;
    }
  }
  @media screen and (max-width: 950px) {
    .card_container {
      column-gap: 40px;
      row-gap: 40px;
    }
  }
  @media screen and (max-width: 900px) {
    .card_container {
      // flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}