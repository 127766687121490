.overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
}
.modal_styles {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2001;
  background-color: white;
  width: 716.8px;
  height: 85%;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  &.modal_stylesDark {
    background-color: #2e2e2e;
  }
  .wrapper {
    position: relative;
    display: flex;
    justify-content: space-around;
    padding: 50px 40px;
    .close {
      position: absolute;
      top: 13.3px;
      right: 13.3px;
      width: 32px;
      height: 32px;
      cursor: pointer;
    }
    .user_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      .image {
        width: 100.8px;
        height: 100.8px;
        border-radius: 50%;
        object-fit: cover;
      }
      .name_container {
        display: flex;
        align-items: center;
        gap: 8.4px;
        .name {
          font-weight: 600;
          font-size: 25.2px;
          line-height: 105%;
          color: #353844;
          &.nameDark {
            color: white
          }
        }
        .rating {
          display: flex;
          align-items: center;
          gap: 4.9px;
          font-weight: 400;
          font-size: 10.08px;
          line-height: 15.4px;
          padding: 2.1px 6.3px;
          background: #0298a6;
          border-radius: 10.08px;
          color: white;
          &.ratingDark {
            color: #015866;
          }
          .rating_icon {
            transform: translateY(-10%);
          }
        }
      }
      .member_text {
        margin-top: 8.4px;
        font-weight: 400;
        font-size: 16.8px;
        line-height: 25.2px;
        color: #353844;
        &.member_textDark {
          color: white
        }
      }
    }
    .main_container {
      display: flex;
      flex-direction: column;
      .rating_container {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #353844;
        .stars {
          margin-top: 14px;
          display: flex;
          gap: 11.2px;
          .star {
            color: #d9d9d9;
            width: 34.3px;
            height: 34.3px;
            cursor: pointer;
            &.gold {
              color: gold;
            }
          }
        }
      }
      .overall {
        &.overallDark {
          color: white;
        }
      }
      .line {
        border-top: 2px solid #c2c2c2;
        width: 100%;
        margin-top: 28px;
        margin-bottom: 28px;
      }
      .question {
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
        color: #353844;
        &.questionDark {
          color: white
        }
      }
      .textarea {
        margin-top: 14px;
        background: #f8f8f9;
        border: 1px solid #d9d9d9;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        width: 287px;
        height: 132.3px;
        resize: none;
        padding: 6.16px 10.92px;
        font-size: 13px;
      }
      .question_container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .question {
          max-width: 187.6px;
        }
        .switch_container {
          display: flex;
          width: 56px;
          height: 27.2px;
          border-radius: 24.8px;
          background-color: #a1a1a1;
          transition: all 350ms ease;
          &.active {
            background-color: #0298a6;
          }
          .button {
            height: 27.2px;
            width: 28px;
            background-color: white;
            border-radius: 50%;
            transition: all 350ms ease;
            &.active {
              margin-left: 28px;
            }
          }
        }
      }
      .submit {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #ffffff;
        padding: 4px 23.1px;
        background: #0298a6;
        border-radius: 10px;
        align-self: center;
        &.submitDark {
          background-color: #015866;
        }
        &:hover {
          background: #01b1c0;
        }
      }
      .submit_loading {
        background: gray;
        color: #ffffff;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #ffffff;
        padding: 4px 23.1px;
        border-radius: 10px;
        align-self: center;
        &.submitDark {
          background-color: #015866;
        }
      }
    }
  }
  @media screen and (max-width: 750px) {
    width: 90vw;
    height: auto;
    .wrapper {
      padding: 50px 20px;
      justify-content: space-between;
    }
  }
  @media screen and (max-width: 630px) {
    .wrapper {
      flex-direction: column;
      padding: 25px;
      .user_container {
        .member_text {
          font-size: 13.44px;
          line-height: 20.16px;
        }
      }
      .main_container {
        margin-top: 25px;
        .rating_container {
          font-size: 12.6px;
          line-height: 16.8px;
        }
        .line {
          margin-top: 15px;
          margin-bottom: 15px;
        }
        .question {
          font-size: 12.6px;
          line-height: 16.8px;
        }
        .textarea {
          width: 100%;
        }
      }
    }
  }
}