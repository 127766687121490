@use "../../../../styles/variables.scss" as color;
.notification-menu-container {
  position: relative;
  .triangle {
    content: "";
    position: absolute;
    top: 35px;
    right: 6.5px;
    height: 20px;
    width: 20px;
    background: color.$light-background-color;
    transform: rotate(45deg);
  }
  .dropdown-menuu {
    position: absolute;
    top: 45px;
    left: -50px;
    background-color: color.$light-background-color;
    border-radius: 8px;
    max-height: 400px;
    overflow-y: auto;
    z-index: 3;
    &.dropdown-menuuDark {
      background-color: color.$dark-text-color;
    }
  }
  .dropdown-menuu.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: 500ms ease;
  }
  .dropdown-menuu.inactive {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: 500ms ease;
  }
  .dropdownItem {
    // width: 388px;
    border-bottom: 1px solid #ACACAC;
    margin-left: -33px;
    margin-top: 10px;
    .wrapper {
      padding: 10px 19px;
      display: flex;
      align-items: center;
    }
    .image__container {
      position: relative;
      .icon {
        font-size: 25px;
        color: black
      }
      .icondark {
        font-size: 25px;
        color: white;
      }
      img {
        width: 35px;
        height: 35px;
        opacity: 0.5;
        transition: 500ms;
      }
      .circle {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: color.$primary-color;
        border: 1px solid #ffffff;
        position: absolute;
        top: 1px;
        right: 0px;
      }
    }
    .text__container {
      margin-left: 11px;
      transition: 500ms;
      width: 284px;
      .text {
        &.textDark {
          color: white;
        }
      }
      h4 {
        font-weight: 600;
        font-size: 15px;
        line-height: 21px;
        color: color.$dark-text-color;
      }
      h6 {
        font-weight: 400;
        font-size: 13px;
        color: #5d5c5c;
      }
      p {
        font-weight: 500;
        font-size: 11px;
        line-height: 16px;
        color: color.$dark-text-color;
        margin-top: 4px;
      }
    }
  }
}
.custom-link {
  text-decoration: none;
  /* Remove the underline */
}