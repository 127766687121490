@use "../../../styles/variables.scss" as color;
.header__section {
  // position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2000;
  .header {
    height: 39px;
    background: color.$primary-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 34px;
    padding-right: 67px;
    &.headerDark{
      background-color: #015866;
    }
    .link{
      text-decoration: none;
    .contact {
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      margin-top: 7px;
      color: #ffffff;
      // margin-left: 34px;
      cursor: pointer;
      text-decoration: none;
    }
  }
  
    .center {
      display: flex;
      justify-content: center;
      align-items: center;
      .icon {
        height: 25.92px;
        color: white;
      }
      .download {
        margin-left: 8px;
        // margin-right: 67px;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        color: color.$white-text-color;
        cursor: pointer;
        text-decoration: none;
      }
    }
    @media screen and (max-width: 750px) {
      padding-right: 30px;
      padding-left: 30px;
    }
  }
}
