.section {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .info {
    position: absolute;
    top: 70%; /* Adjust the top position */
    left: 95%; /* Adjust the left position */
    transform: translate(-70%, -95%);
    width: 100%;
    text-align: center;
    z-index: 1;
    .icons {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      // flex-direction: column;
      .icon {
        width: 240px;
        height: 65px;
        cursor: pointer;
        margin: 0 10px;
        &:hover{
          transform: scale(1.03);
        }
      }
     
    }
  }
  .infomb {
    position: absolute;
    bottom: 10%; /* Adjust the top position */
    left: 0%; /* Adjust the left position */
    // transform: translate(-70%, -95%);
    width: 100%;
    text-align: center;
    z-index: 1;
    .iconsmb {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      // flex-direction: column;
      .iconmb {
        width: 240px;
        height: 65px;
        cursor: pointer;
        margin: 0 10px;
        &:hover{
          transform: scale(1.03);
        }
      }
     
    }
  }
  @media screen and (max-width: 1400px) {
    .info {
      top: 80%; /* Adjust the top position */
      left: 100%; /* Adjust the left position */
      transform: translate(-74.5%, -100%);
      .icons {
        width: 100%;
        .icon {
          width: 200px;
          height: 65px;
        }
      }
    }
}
  @media screen and (max-width: 1150px) {
    .info {
     
      .icons {
        .icon {
          width: 180px;
          height: 65px;
        }
      }
    }
}
  @media screen and (max-width: 1150px) {
    .info {
     
      .icons {
        .icon {
          width: 150px;
          height: 50px;
        }
      }
    }
}
  @media screen and (max-width: 1150px) {
    .info {
     
      .icons {
        .icon {
          width: 120px;
          height: 40px;
        }
      }
    }
}
  @media screen and (max-width: 650px) {
    .infomb {
      .iconsmb {
        .iconmb {
          width: 110px;
          height: 40px;
        }
      }
    }
}
}
//   @media screen and (max-width: 1500px) {
//     height: 600px;
//     .info {
//       .mainTitle {
//         font-size: 44.8px;
//         line-height: 67.2px;
//       }
//       .secondTitle {
//         font-size: 28px;
//         line-height: 42px;
//         margin-top: 53.9px;
//       }
//       .icons {
//         margin-top: 77px;
//         .icon {
//           width: 186.83px;
//           height: 63.35px;
//           margin-right: 20.3px;
//         }
//       }
//     }
//   }
//   @media screen and (max-width: 1300px) {
//     height: 550px;
//   }
//   @media screen and (max-width: 1200px) {
//     height: 500px;
//   }
//   @media screen and (max-width: 1070px) {
//     height: 450px;
//     .info {
//       .mainTitle {
//         font-size: 31.36px;
//         line-height: 47.04px;
//       }
//       .secondTitle {
//         font-size: 28px;
//         line-height: 42px;
//         margin-top: 37.73px;
//       }
//       .icons {
//         margin-top: 53.9px;
//         .icon {
//           width: 130.781px;
//           height: 44.345px;
//           margin-right: 14.21px;
//         }
//       }
//     }
//   }
//   @media screen and (max-width: 930px) {
//     height: 400px;
//   }
//   @media screen and (max-width: 820px) {
//     height: 370px;
//   }
//   @media screen and (max-width: 770px) {
//     .info {
//       .mainTitle {
//         font-size: 21.952px;
//         line-height: 32.9px;
//       }
//       .secondTitle {
//         font-size: 19.6px;
//         line-height: 29.4px;
//         margin-top: 26.411px;
//       }
//       .icons {
//         margin-top: 37.73px;
//         .icon {
//           width: 91.5px;
//           height: 31.04px;
//           margin-right: 9.9px;
//         }
//       }
//     }
//   }
//   @media screen and (max-width: 720px) {
//     height: 330px;
//   }
//   @media screen and (max-width: 620px) {
//     height: 290px;
//   }
//   @media screen and (max-width: 530px) {
//     height: 250px;
//     .info {
//       .mainTitle {
//         font-size: 15.3664px;
//         line-height: 23.03px;
//       }
//       .secondTitle {
//         font-size: 13.72px;
//         line-height: 20.58px;
//         margin-top: 18.4877px;
//       }
//       .icons {
//         margin-top: 26.411px;
//         .icon {
//           width: 64.05px;
//           height: 21.728px;
//           margin-right: 6.93px;
//         }
//       }
//     }
//   }
//   @media screen and (max-width: 430px) {
//     height: 210px;
//   }
//   @media screen and (max-width: 400px) {
//     height: 200px;
//   }
//   @media screen and (max-width: 365px) {
//     height: 180px;
//   }
// }
