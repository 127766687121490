input::-ms-reveal,
input::-ms-clear {
  display: none;
}
.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #0298a6;
  color: #fff;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
  transition: opacity 0.3s;
  opacity: 0.7;
  z-index: 9999;
}
.scroll-to-top-button:hover{
  background-color: #0298a6;
  opacity: 1;
}