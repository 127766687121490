@use "../../../../styles/variables.scss" as color;
.login_container {
  position: relative;
  display: flex;
  flex-direction: column;
  // &.login_containerDark{
  //   background-color: color.$dark-text-color;
    
  // }
  .close {
    position: absolute;
    top: 13.3px;
    right: 13.3px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    &.closeDark{
      background-color: #015866;
      border-radius: 50%;
    }
  }
  .back {
    position: absolute;
    top: 13.3px;
    left: 13.3px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    &.backDark{
      background-color: #015866;
      border-radius: 50%;
    }
  }
  .logoCont{
    justify-content: center;
    align-items: center;
    display: flex;
  .logo {
    margin-top: 45.5px;
    font-weight: 500;
    font-size: 28px;
    line-height: 42px;
    text-align: center;
    color: color.$primary-color;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
    transform: scale(2.5);
  }
}
  .welcome {
    font-weight: 600;
    font-size: 22.4px;
    line-height: 33.6px;
    color: color.$dark-text-color;
    text-align: center;
    &.welcomeDark{
      color:color.$white-text-color;
    }
  }
  .slogan {
    font-weight: 500;
    font-size: 16.8px;
    line-height: 25.2px;
    color: color.$dark-text-color;
    text-align: center;
    &.sloganDark{
      color:color.$white-text-color;
    }
  }
  .form_container {
    margin-top: 37.8px;
    display: flex;
    padding: 0 42px;
    justify-content: space-between;
    .form {
      display: flex;
      flex-direction: column;
      .label {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: color.$dark-text-color;
        margin-bottom: 9.8px;
        &.labelDark{
          color:color.$white-text-color;
        }
      }
      .input_container {
        position: relative;
        margin-bottom: 31.5px;
        .input {
          width: 277.2px;
          height: 40.6px;
          background: color.$light-background-color;
          border: 1px solid color.$border-category-dropdown;
          border-radius: 10px;
          padding-left: 47.6px;
          &.inputDark{
            background: #015866;
            color:rgb(207, 203, 203)
          }
          &:focus {
            border: 1px solid color.$primary-color;
            outline: none;
          }
        }
        .pass_icon {
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);
          color: color.$light-gray-color;
          font-size: 20px;
          cursor: pointer;
        }
        .input_icon {
          position: absolute;
          left: 16px;
          color: color.$border-category-dropdown;
          top: 12px;
        }
        .error_message {
          font-weight: 400;
          font-size: 11.2px;
          line-height: 16.8px;
          color: color.$error_color;
          position: absolute;
        }
        &.error_input {
          .input {
            border-color: color.$error_color;
          }
          .input::placeholder {
            color: color.$error_color;
          }
          .input_icon {
            color: color.$error_color;
          }
        }
      }
      .forget_container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        .message {
          position: absolute;
          bottom: -25px;
          left: 0;
        }
        .remember_container {
          display: flex;
          align-items: center;
          gap: 5px;
          input {
            accent-color: color.$primary-color;
            width: 14px;
            height: 14px;
          }
          .remember {
            font-weight: 600;
            font-size: 11.2px;
            line-height: 16.8px;
            color: color.$dark-text-color;
            &.rememberDark{
              color: color.$white-text-color;
            }
          }
        }
        .forget {
          font-weight: 600;
          font-size: 11.2px;
          line-height: 24px;
          text-decoration-line: underline;
          color: color.$primary-color;
          cursor: pointer;
        }
      }
      .login_btn {
        background: color.$primary-color;
        border-radius: 10px;
        width: 107.8px;
        height: 43.4px;
        margin: 25px auto;
        color: white;
        font-weight: 600;
        font-size: 16.8px;
        line-height: 25.2px;
        text-align: center;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-property: box-shadow, transform;
        transition-property: box-shadow, transform;
        &.login_btnDark{
          background-color: #015866;
        }
        &:hover,
        &:focus,
        &:active {
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
          -webkit-transform: scale(1.1);
          transform: scale(1.1);
        }
      }
    }
    .border {
      border: 1px solid #8d8d8d;
      height: 315px;
    }
    .continue {
      display: flex;
      flex-direction: column;
      .continue_btn {
        width: 271.6px;
        height: 42px;
        background: color.$light-background-color;
        border: 1px solid color.$border-category-dropdown;
        border-radius: 10px;
        margin-bottom: 26.6px;
        &.continue_btnDark{
          background-color: #015866;
          color:color.$white-text-color;
        }
        &:hover {
          transition: 0.7s;
          border: 1.5px solid color.$primary-color;
          box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
          // background-color: rebeccapurple;
        }
        font-weight: 400;
        font-size: 12.6px;
        line-height: 18.9px;
        color: color.$dark-text-color;
        display: flex;
        align-items: center;
        .btn_icon {
          width: 30px;
          height: 30px;
          margin-left: 18.76px;
          margin-right: 13.86px;
        }
      }
      .no_account {
        margin-top: 11.2px;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #8d8d8d;
        text-align: center;
        &.no_accountDark{
          color: white;
        }
        .register_link {
          text-decoration-line: underline;
          color: color.$primary-color;
          cursor: pointer;
        }
      }
    }
  }
  @media screen and (max-width: 750px) {
    .logo {
      margin-top: 25.5px;
    }
    .form_container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 25px;
      .form {
        .label {
          margin-bottom: 8px;
        }
        .input_container {
          margin-bottom: 25px;
        }
        .login_btn {
          width: 50%;
          margin: 30px auto;
          
          
        }
      }
      .border {
        display: none;
      }
      .continue {
        .continue_btn {
          margin-bottom: 18px;
        }
        .no_account {
          margin-top: 2px;
          margin-bottom: 20px;
        }
      }
    }
  }
  @media screen and (max-width: 380px) {
    .close {
      width: 22px;
      height: 22px;
    }
    .back {
      width: 22px;
      height: 22px;
    }
    .logo {
      margin-top: 15px;
      font-size: 22px;
    }
    .welcome {
      font-size: 21px;
      line-height: 30px;
    }
    .slogan {
      font-size: 15.5px;
      line-height: 24px;
    }
    .form_container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 25px;
      .form {
        .label {
          margin-bottom: 4px;
        }
        .input_container {
          margin-bottom: 10px;
        }
        .login_btn {
          width: 50%;
          margin: 20px auto;
        }
      }
      .border {
        display: none;
      }
      .continue {
        .continue_btn {
          margin-bottom: 9px;
        }
        .no_account {
          margin-top: 2px;
          margin-bottom: 20px;
          
        }
      }
    }
  }
  @media screen and (max-width: 340px) {
    .close {
      width: 22px;
      height: 22px;
    }
    .back {
      width: 22px;
      height: 22px;
    }
    .logo {
      margin-top: 15px;
      font-size: 22px;
    }
    .welcome {
      font-size: 21px;
      line-height: 30px;
    }
    .slogan {
      font-size: 15.5px;
      line-height: 24px;
    }
    .form_container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 25px;
      .form {
        .label {
          margin-bottom: 4px;
        }
        .input_container {
          margin-bottom: 10px;
          .input{
            width: 250px;
          }
        }
        .login_btn {
          width: 50%;
          margin: 20px auto;
        }
      }
      .border {
        display: none;
      }
      .continue {
        .continue_btn {
          margin-bottom: 9px;
          width: 250px;
        }
        .no_account {
          margin-top: 2px;
          margin-bottom: 20px;
        }
      }
    }
  }
}
