.container {
  margin: 45px auto;
  margin-bottom: 0;
  width: auto;
  max-width: 80%;
  margin-bottom: 0px;
  padding-bottom: 20px;
  .empty{
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    .txt{
      &.txtDark{
        color: white;
      }
    }
    .btn {
      margin-top: 30px;
      background: #0298a6;
      border-radius: 10px;
      color: white;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 2.25rem;
      padding: 9px 4.16em;
      margin-bottom: 2.41em;
      &.btnDark{
      background-color: #015866;
      }
    }
  }
  .wrapper {
    background: #f8f8f9;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin-bottom:-16px ;
    width: 100%;
    &.wrapperDark{
      background-color: #015866;
    }
  }
  @media screen and (max-width: 645px) {
    max-width: 100%;
  }
}
