@use "../../../styles/variables.scss" as color;
.wrapper {
  display: flex;
  .form_container {
    width: 30vw;
    height: 100vh;
    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    .form {
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        font-weight: 600;
        font-size: 32px;
        line-height: 48px;
        color: #353844;
        margin-top: 30px;
        margin-bottom: 25px;
      }
      .picture_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .pp {
          position: relative;
          width: 165px;
          height: 165px;
          border-radius: 50%;
          background: color.$light-background-color;
          border: 1px solid color.$border-category-dropdown;
          display: flex;
          justify-content: center;
          align-items: center;
          &.ppDark{
            background-color: #015866;
          }
  
          .default_img {
            width: 120px;
            height: 120px;
            padding-bottom: 10px;          
            background-size: cover;
          }
          .preview_img {
            width: 165px;
            height: 165px;
            border-radius: 50%;
            background-size: cover;
          }
  
          .plus_icon {
            position: absolute;
            right: -10px;
            bottom: 10px;
            width: 42.7px;
            height: 42.7px;
            cursor: pointer; 
          }
          
          
          .dropdown {
            position: absolute;
            left: 130px;
            bottom: -70px;
            background: color.$light-background-color;
            border: 1px solid color.$border-category-dropdown;
            border-radius: 10px;
            width: 250px;
            // height: 81.2px;
            z-index: 2;
            font-weight: 400;
            font-size: 11.2px;
            line-height: 16.8px;
            color: #000000;
            &.dropdownDark{
              color: color.$white-text-color;
              background-color: #015866;
  
            }
  
            .item {
              cursor: pointer;
              padding: 10px 22px;
              border-bottom: 2px solid color.$border-category-dropdown;
              &:last-child {
                border-bottom: none;
              }
              display: flex;
  
              .icon {
                margin-right: 12.6px;
                font-size: 18px;
              }
            }
  
            &.active {
              // background-color: rgba(0, 0, 0, 0.5);
              opacity: 1;
              visibility: visible;
              transform: translateY(0);
              transition: 500ms ease; 
              
            }
            
            
            &.inactive {
              opacity: 0;
              visibility: hidden;
              transform: translateY(-20px);
              transition: 500ms ease;
             
  
            }
            
          }
          
        
        }
        .upload_text {
          margin-top: 14px;
          margin-bottom: 22px;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: color.$dark-text-color;
        }
      }
      .input_container {
        position: relative;
        margin-bottom: 21px;
        .input {
          width: 277.2px;
          height: 40.6px;
          background: color.$light-background-color;
          border: 1px solid color.$border-category-dropdown;
          border-radius: 10px;
          padding-left: 47.6px;
        }
        .input_icon {
          position: absolute;
          left: 16px;
          top: 12px;
        }
        .pass_icon {
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);
          color: color.$light-gray-color;
          font-size: 20px;
          cursor: pointer;
        }
        .error_message {
          font-weight: 400;
          font-size: 11.2px;
          line-height: 16.8px;
          color: color.$error_color;
          position: absolute;
          // bottom: -17px;
          left: 6px;
        }
        &.error_input {
          margin-bottom: 25px;
          .input {
            border-color: color.$error_color;
          }
          .input::placeholder {
            color: color.$error_color;
          }
          .input_icon {
            color: color.$error_color;
          }
        }
      }
      .register_btn {
        font-weight: 600;
        font-size: 16.8x;
        line-height: 25.2px;
        color: #ffffff;
        width: 142.8px;
        height: 42px;
        background: color.$primary-color;
        border-radius: 10px;
        &:hover{
          background: #01b1c0;    
        }
      }
      .account {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-top: 15px;
        color: #353844;
        span {
          text-decoration-line: underline;
          color: #0298a6;
        }
      }
    }
  }
  .image_container {
    width: 70vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f6ffff;
  }
}
@media screen and (max-width: 960px) {
  .wrapper {
  .form_container {
    width: 100%;
    background-image: url("../../../assests/svg/dashboard register.svg");
    background-size: 100% 100%; 
    background-repeat: no-repeat;
  }
  .image_container {
   display: none;
  }
}
}
