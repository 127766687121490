@use "../../styles/variables.scss" as color;
@import "~bootstrap/scss/bootstrap";
$lightgray: #f8f9fa;
$gray: #dee2e6;
.tableChoices {
    margin-top: 30px;
    // margin-left: 22%;
    border: 1px solid gray;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    margin-bottom: 30px;
    // float: right;
  
    button {
      padding: 8px 15px;
      cursor: pointer;
      font-weight: 500;
      font-size: 15px;
      line-height: 28px;
      border: 0.001px dotted white;
      border-radius: 16px;
  
      &.active {
        border: 2px solid color.$primary-color !important;
        border-radius: 11px;
        width: 100%; /* Change width to 100% */
        background-color: color.$primary-color !important;
        color: #ffffff;
      }
  
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
  .table {
    // width:850px;
    margin-bottom: 20px;
    border-radius: 15px;
    overflow: hidden;
    max-height: 700px;
  overflow-y: auto;
    // margin-left: 20px;
    table {
      width: 100%;
      border-collapse: separate;
      text-align: left;
      font-size: 14px;
      border: 2px solid $gray;
  
  
      th,
      td {
        width: 100px;
        padding: 12px;
        vertical-align: middle;
        border-top: none;
        border-left: none;
        border-right: none;
        
        &:first-child {
          border-left: none;
          border-radius: 0 0 0 0;
        }
  
        &:last-child {
          border-right: none;
          border-radius: 0 0 0 0;
        }
      }
  
      thead {
        tr {
          background-color: darkgray;
          color: black;
        }
        
      }
  
      tbody {
        tr:nth-child(odd) {
          background-color: white;
        }
  
        tr:nth-child(even) {
          background-color: rgb(235, 231, 231);
        }
  
        tr:first-child {
          background-color: white;
          color: #fff;
        }
      }
    
  }  
}
.customername{
    font-weight: 600;
    .uInfo{
      display: flex;
      align-items: center;
      width: 150px;
      margin-right: 10px;
      .customerImage{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 10px;
      }
    }
  }
  .rating {
    display: inline-block;
    margin-left: 7px;
    background: color.$primary-color;
    border-radius: 10px;
    padding: 4px 5px;
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    color: #ffffff;
    
    .icon {
      // width: 16px;
      // height: 16px;
      font-size: 13px;
      transform: translateY(-1px);
      display: inline-block;
    }
  }
  .pagination {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  
    .page-item {
      &:not(.active) {
        a {
          background-color: $lightgray;
          border-color: $lightgray;
          color: #000;
        }
  
        &:hover a {
          background-color: $gray;
          border-color: $gray;
          color: #fff;
        }
      }
  
      &.active {
        a {
          background-color: $gray;
          border-color: $gray;
          color: #fff;
        }
      }
    }
  
    .page-link {
      border-radius: 10px;
      margin: 0 2px;
    }
  }
  
  @media (max-width: 1300px) {
    .table {
      table{
        
        th {
          width: 20px;
          padding: 5px;
          vertical-align:middle;
          border-top: none;
          border-left: none;
          border-right: none;
        }
        td {
          width: 20px;
          padding: 0px;
          vertical-align: middle;
          border-top: none;
          border-left: none;
          border-right: none;
        }
      }
    }
    }
  @media (max-width: 980px) {
    .table{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
      
    }
  @media (max-width: 490px) {
    .table{
        margin-left: 0;
        
    }
    .tableChoices{
      margin-left: 0px;
      width: 250px;
      button {
        padding: 8px 15px;
        cursor: pointer;
        font-weight: 500;
        font-size: 10px;
        line-height: 28px;
        border: 0.001px dotted white;
        border-radius: 16px;
    
        &.active {
          border: 2px solid color.$primary-color !important;
          border-radius: 11px;
          width: 100%; 
          background-color: color.$primary-color !important;
          color: #ffffff;
        }
      }
    }
      
    }
    .loaderContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh; /* Make the container cover the entire viewport height */
    }