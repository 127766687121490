@use "../../../styles/variables.scss" as color;
.item {
  cursor: pointer;
  background: #ffffff;
  border: 3px solid #bcbcbc;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 695px;
  height: 178px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 46px;
  padding-left: 52px;
  position: relative;
  &.itemDark{
    background-color: #2e2e2e;
  }
  &.defaultBorder {
    border: 3px solid color.$primary-color;
    &.defaultBorderDark{
      border: 3px solid #015866 ;
    }
  }
  .default_container {
    position: absolute;
    top: 0;
    right: 0;
    .default_text {
      position: absolute;
      top: 18px;
      right: 7px;
      rotate: 42deg;
      color: white;
      font-weight: 600;
      font-size: 16px;
    }
  }
  .icon_container {
    background: color.$primary-color;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    width: 86px;
    height: 86px;
    display: flex;
    justify-content: center;
    align-items: center;
    .image {
      height: 70%;
    }
  }
  .details_container {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-top: 20px;
    .title {
      font-weight: 600;
      font-size: 32px;
      line-height: 150%;
      color: color.$primary-color;
    }
    .text {
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: #a1a1a1;
    }
  }
  .three_dots {
    position: absolute;
    top: 0; 
    left: 0;
    margin: 8px; 
    color: gray; 
    cursor: pointer;
    &:hover{
      color: color.$primary-color;
    }
  }
  .dropdown {
    position: absolute;
    top: 24px;
    left: 0;
    padding: 4px 0;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    z-index: 1;
    &.dropdownDark{
      background-color: color.$dark-text-color;
    }
  }
  
  .dropdown_itemDel {
    padding: 8px 16px;
    font-weight: 500;
    display: flex;
    color: red;
    cursor: pointer;
    .trash{
      margin: 5px;
    }
  }
  .dropdown_item {
    padding: 8px 16px;
    font-weight: 500;
    display: flex;
    color: color.$primary-color;
    cursor: pointer;
    width: 150px;
    border-bottom: 1px solid rgb(232, 237, 239);
  }
  
  .dropdown_item:hover {
    background-color: #f4f4f4;
  }
  
  @media screen and (max-width: 750px) {
    width: 556px;
    height: 142.4px;
    gap: 36.8px;
    padding-left: 41.6px;
    .icon_container {
      width: 68.8px;
      height: 68.8px;
    }
    .details_container {
      .title {
        font-size: 25.6px;
        line-height: 120%;
      }
      .text {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  @media screen and (max-width: 580px) {
    width: 444.8px;
    height: 113.92px;
    gap: 29.44px;
    padding-left: 33.28px;
    .icon_container {
      width: 55.04px;
      height: 55.04px;
    }
    .details_container {
      .title {
        font-size: 20.48px;
        line-height: 96%;
      }
      .text {
        font-size: 12.8px;
        line-height: 19.2px;
      }
    }
  }
  @media screen and (max-width: 470px) {
    width: 355.84px;
    height: 91.136px;
    gap: 23.552px;
    padding-left: 26.624px;
    .default_container {
      .default_text {
        top: 20px;
        right: 12px;
        font-size: 13px;
      }
    }
    .icon_container {
      width: 44.032px;
      height: 44.032px;
    }
    .details_container {
      .title {
        font-size: 16.384px;
        line-height: 76.8%;
      }
      .text {
        font-size: 10.24px;
        line-height: 15.36px;
      }
    }
  }
  @media screen and (max-width: 400px) {
    width: 300px;
    height: 91.136px;
    gap: 23.552px;
    left: -20px;
    padding-left: 26.624px;
    .default_container {
      .default_text {
        top: 20px;
        right: 12px;
        font-size: 13px;
      }
    }
    .icon_container {
      width: 44.032px;
      height: 44.032px;
    }
    .details_container {
      .title {
        font-size: 16.384px;
        line-height: 76.8%;
      }
      .text {
        font-size: 10.24px;
        line-height: 15.36px;
      }
    }
  }
}
