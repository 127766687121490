
@use "../../styles/variables.scss" as color;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap");
//color.$primary-color
.container-c {
  height: 100vh;
  box-sizing: border-box;
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bolder;
}
.calendar {
    position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color:gray ;
  .date{
    // padding-left: 6px;
    // margin-top: -20%;
    color: color.$primary-color;
  }
  .datepicker-wrapper {
    position: relative;
    margin-top: 10px;
  }
  
  .react-datepicker {
    position: absolute;
    top: 100%;
    left:auto;
    right: 0;
    z-index: 1;
  }
  
}
.toolbar {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}
.filter-button {
  background-color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
}
.search_input {
  display: flex;
  align-items: center;
  input[type="text"] {
    padding: 10px;
    border-radius: 5px;
    border: none;
    font-size: 14px;
    flex: 1;
  }
  .search_icon {
    background-color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    margin-left: 10px;
    font-weight: bold;
    cursor: pointer;
  }
}
.content {
  display: grid;
  grid-template-columns: repeat(12, 1fr); 
  grid-gap: 10px;
}
.left-panel {
  background-color: white;
  border-radius: 10px;
  grid-column: 1 / span 4;
  // width:37%;
  overflow-y: auto;
  max-height: 700px;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    
  .left-title{
    font-weight: bolder; 
    padding-top:5% ;
    padding-left:5% ;
    padding-bottom: 4%;
    font-size: 20px;
      
    .hr{
      width: 100%;
    } 
 }
 .threeChoices {
    margin-top: 10%;
    margin-left: 20%;
    border: 1px solid gray;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
 button {
    padding: 8px 15px;
    background-color: white;
    cursor: pointer;
    font-weight: 500;
    font-size: 15px;
    line-height: 28px;
    border: 0.001px dotted white;
    border-radius: 16px;
    &.active {
      border: 2px solid color.$primary-color !important;
      border-radius: 11px;
      width: 110px;
      background-color: color.$primary-color !important;
      color: #ffffff;
    }
    &:not(:last-child) {
        margin-right: 10px;
      }
  }
}
}
.right-panel {
  background-color: white;
  padding: 40px;
  grid-column: 5 / span 8;
  overflow-y: auto;
  max-height: 700px;
  // width: 50%;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  // margin-left: 20px;
  .ProdServ {
    margin-top: 10%;
    margin-left: 40%;
    border: 1px solid gray;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    flex-direction: column;
    height: 150px;
    position: relative;
    
  .catT {
    position: absolute;
    top: -25px; 
    font-size: 14px;
    font-weight: 600;
  }
  
  button {
    padding: 20px 30px;
    background-color: white;
    cursor: pointer;
    font-weight: 500;
    font-size: 15px;
    line-height: 28px;
    border: 0.001px dotted white;
    border-radius: 16px;
    display: inline-block;
    &.activeps {
      border: 2px solid color.$primary-color !important;
      border-radius: 6px;
      width: 160px;
    //   margin-left: 6%;
      background-color: color.$primary-color !important;
      color: #ffffff;
      height: 115px;
  
    }
    // &:not(:last-child) {
    //     margin-right: 10px;
    //   }
  }
  }
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
 
}
.fstdiv{
  margin-bottom:10% ;
}
.right-title{
  font-weight: bolder; 
  margin-top:-2% ;
  font-size: 20px;
  flex:1;
}
.resetDiv{
  margin-top: -2.5%;
  flex:0;
  margin-left: 20px;
  .reset{
  color: color.$primary-color;
  font-weight: 500;
  }
  
}
.subtitle {
  font-weight: 500;
  font-size: 13px;
  margin-bottom: 5px;
  margin-top: 10%
}
.CategName {
  width: 100%;
}
.CategName input[type="text"] {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 6px;
  font-size: 16px;
  width: 100%;
  
}
.CategName #fieldInput {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 6px;
  font-size: 16px;
  width: 80%;
  margin-bottom: 2%;
  align-items: center;
  display: inline-flex;
  
}
.but{
  margin-left: 10px;
}
.fieldtype{
  background: color.$primary-color;
  color: white;
  width: 20%;
  padding-top: 1.8%;
  padding-bottom: 1.5%;
  border-radius:5px ;
}
.dropd{
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 200px;
  right:200px;
}
.dropd button {
  width: 100%;
  text-align: left;
  padding: 5px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.plus-button-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 5%;
}
.inner-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.plus-button {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #007bff;
  color: #ffffff;
  text-align: center;
  font-size: 24px;
  line-height: 30px;
  cursor: pointer;
}
.plus-button::before {
  content: '+';
  display: block;
  position: absolute;
  width: 20px;
  height: 3px;
  background-color: #ffffff;
  top: 14px;
  left: 5px;
  transform: rotate(45deg);
}
.plus-button-container span {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  align-items: center;
}
:root {
  --body-bg: #f5f8ff;
  --box-bg:white;
  --input-bg: #f5f8ff;
  --txt-color: #2f2d2f;
  --txt-second-color: #ccc;
  --border-color: #4267b2;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.bigbox{
  display: flex;
  align-items: center;
  justify-content: center;
}
.box{
  background-color: rgba(0, 0, 0, 0);
  padding: 10px;
  border-radius: 20px;
  display: flex;
  margin-left:-50px ;
}
.button-row {
  display: flex;
  justify-content: center;
align-items: center;
  padding-top: 10%;
  
}
.AddSub {
  padding: 10px 60px;
  background-color: color.$primary-color;
  color: white;
  border: 2px solid color.$primary-color;
  border-radius: 5px;
  // margin-left: 30%;
  margin-bottom: 30px;
  &:hover{
    background-color: white;
    color:color.$primary-color;
    
  }
}
.Create {
  padding: 10px 60px;
  background-color: color.$primary-color;
  color: #fff;
  border: none;
  border-radius: 5px;
  // margin-top: 10%;
  margin-left: 35%;
 
  &:hover{
    background-color: white;
    color:color.$primary-color;
    border: 2px solid color.$primary-color;
  }
}
.step-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: color.$primary-color;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}
.step-label {
  text-align: center;
  margin-top: 5px;
  font-size: 10px;
  font-weight: bold;
  color: color.$primary-color;
}
.opt{
  color: #0298a6;
}
@media (max-width: 1300px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }
    .calendar {
    // position: absolute;
    display:block;
    align-items: center;
    top:20px
    }
    .toolbar{
      flex-direction: column;
    align-items: flex-start;
    }
    .left-panel{
    .threeChoices {
      margin-top: 10%;
      // margin-left: 30%;
      width: 190px;
  
  
   button {
      padding: 8px 8px;
      
    }
  }
}
  }
@media (max-width: 1050px) {
    .calendar {
    // position: absolute;
    display:block;
    align-items: center;
    top:20px;
   .date{
      padding-left: 6px;
      margin-top:0;
      color: color.$primary-color;
    }
    }
    
  
  }
  @media (max-width: 460px){
  .left-panel{
    .threeChoices {
      margin-top: 10%;
      margin-left: 20%;
      width: 190px;
  
  
   button {
      padding: 8px 8px;
      
    }
  }
}
  }
  @media (max-width: 900px){
   .container-c{
    background-color: white;
   }
    .content {
      display: flex;
      flex-direction: column;
      .left-panel{
        width: 70%;
        margin-bottom: 50px;
      }
      .right-panel{
        width: 70%;
        .ProdServ{
          margin-left:50px ;
          margin-bottom: 50px;
          margin-top: 80px;
        }
      }
    }
    .Create{
      width: 200px;
      height: 30px;
      font-size: 13px;
      line-height: 10px;
      margin-left: 20%;
    }
    .bigbox{
      display: block;
    }
    .box{
      margin-left: 50px;
    }
    
  
  }
  @media (max-width: 990px){
    .fieldtype{
      height: 35px;
      font-size: 13px;
      line-height: 10px;
    }
    .AddSub{
      height: 35px;
      font-size: 13px;
      line-height: 10px;
      // margin-left: 100px;
      display: flex;
      align-items: center;
    }
  }
  @media (max-width: 600px){
  //  .Create{
  //   margin-left: 10%;
  //  }
   .fieldtype{
    height: 35px;
    font-size: 10px;
    line-height: 10px;
    // width: 50px;
  }
  .AddSub{
    height: 35px;
    font-size: 13px;
    line-height: 10px;
    margin-left: 50px;
  }
  }
  @media (max-width: 550px){
    .content {
      display: flex;
      flex-direction: column;
      .left-panel{
        width: 100%;
        margin-bottom: 50px;
        margin-left: -30px;
      }
      .right-panel{
        width: 100%;
        margin-left: -30px;
      }
    }
    .right-title{
      margin-bottom: 30px;
      h3{
        font-size: 15px;
      }
    }
    .resetDiv{
      margin-bottom: 30px;
    }
    .step{
      margin-top: -15px;
    }
    
    // .step-label{
    //   margin-bottom: 30px;
    // }
   .Create{
    margin-left:20%;
   }
   .fieldtype{
    height: 35px;
    font-size: 8px;
    line-height: 10px;
    // width: 50px;
  }
  .AddSub{
    height: 35px;
    font-size: 13px;
    line-height: 10px;
    margin-left: 30px;
  }
  }
  @media (max-width: 400px){
   .Create{
    width:150px;
    padding:0;
    margin-left: 20px;
   }
   .dropd{
    right:100px;
  }
  .AddSub{
    margin-left: 5px;
  }
  }
  @media (max-width: 365px){
    .toolbar{
      margin-left: -30px;
    }
    .left-panel{
    .threeChoices{
      margin-left: 5px;
    }
  }
   .Create{
    width:150px;
    padding:0;
    margin-left: 0px;
   }
   
  }
}
