@use "../../../../styles/variables.scss" as color;
.toggle-container{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.toggle-container .switch-on {
    background-color: color.$primary-color !important; 
  }
  
  .toggle-container .switch-off {
    background-color: gray !important; 
  }
