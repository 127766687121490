.container {
  display: flex;
  flex-direction: column;
  margin-top: 45px;
  margin-bottom: 50px;
  .text {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #353844;
  }
}
