@use "../../styles/variables.scss" as color;
.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .image_section {
        .label {
            font-weight: 600;
            font-size: 25px;
            color: #0298a6;
            display: flex;
            justify-content: center;
        }
        .wrapper {
            display: flex;
            margin-top: 45px;
            column-gap: 41px;
            row-gap: 52px;
            flex-wrap: wrap;
            .image_container {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100px;
                height: 100px;
                background: #f8feff;
                border: 2.6px solid #ace0e0;
                border-radius: 5.3px;
                &.image_containerDark {
                    background-color: #015866;
                }
                .image {
                    width: 70%;
                }
                .full_image {
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }
    .input_container{
        margin-top: 20px;
        .input {
            background: #ffffff;
            border: 1px solid #d9d9d9;
            border-radius: 10px;
            width: 200px;
            height: 30px;
            line-height: 32px;
            padding-left: 20px;
            margin-bottom: 20px;
        }
    
    .editButton {
        background: #0298a6;
        color: white;
        border-radius: 10px;
        width: 80px;
        height: 30px;
        margin-left: 30px;
    }
    }
    ////field
    
.CategName {
    width: 100%;
  }
  
  .CategName input[type="text"] {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 6px;
    font-size: 16px;
    width: 100%;
    
  }
  .CategName #fieldInput {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 6px;
    font-size: 16px;
    width: 80%;
    margin-bottom: 2%;
    align-items: center;
    display: inline-flex;
    
  }
  
  .but{
    margin-left: 10px;
  }
  
  
  .fieldtype{
    background: color.$primary-color;
    color: white;
    width: 20%;
    padding-top: 1.8%;
    padding-bottom: 1.5%;
    border-radius:5px ;
  }
  
  .dropd{
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    width: 200px;
    right:200px;
  }
  .dropd button {
    width: 100%;
    text-align: left;
    padding: 5px;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  .plus_button_container {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 5%;
  }
  .inner-div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .plus_button {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #007bff;
    color: #ffffff;
    text-align: center;
    font-size: 24px;
    line-height: 30px;
    cursor: pointer;
  }
  
//   .plus_button::before {
//     content: '+';
//     display: block;
//     position: absolute;
//     width: 20px;
//     height: 3px;
//     background-color: #ffffff;
//     top: 14px;
//     left: 5px;
//     transform: rotate(45deg);
//   }
  
  .plus_button_container span {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    align-items: center;
  }
  :root {
    --body-bg: #f5f8ff;
    --box-bg:white;
    --input-bg: #f5f8ff;
    --txt-color: #2f2d2f;
    --txt-second-color: #ccc;
    --border-color: #4267b2;
    --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  
  .box{
    background-color: rgba(0, 0, 0, 0);
    padding: 10px;
    border-radius: 20px;
    
  
  }
  .button_row {
    display: flex;
    justify-content: center;
  
  align-items: center;
    padding-top: 10%;
    
  }
  .AddSub {
    padding: 10px 60px;
    background-color: color.$primary-color;
    color: white;
    border: 2px solid color.$primary-color;
    border-radius: 5px;
    // margin-left: 30%;
    margin-bottom: 30px;
  }
}