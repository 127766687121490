@use "../../../../styles/variables.scss" as color;
.profile-menu-container {
  position: relative;
  .dropdown-menu-info {
    position: absolute;
    top: 39px;
    right: -130px;
    background: color.$header-color;
    width: 288px;
    border-radius: 10px;
    z-index: 3;
    
    
    &.dropdown-menu-infoDark{
      background: color.$dark-text-color;
    }
  }
  .dropdown-menu-info.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: 500ms ease;
  }
  .dropdown-menu-info.inactive {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: 500ms ease;
  }
.link{
  text-decoration: none;
  color: rgb(58, 58, 58);
  &:hover{
    color: color.$primary-color;
  }
  &.linkDark{
    color:color.$white-text-color;
  }
  
}
.AccUL{
  margin-left: -35px;
}
  .profile__container {
    display: flex;
    justify-content: space-around;
    // gap: 6px;
    align-items: center;
    // padding: 19px 0px 19px 15px;
    padding-top: 19px;
    // padding-left: 9px;
    padding-bottom: 15px;
    margin-left: -50px;
    .profile__picture {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      object-fit: cover;
    }
    .details__container {
      display: flex;
      flex-direction: column;
      margin-left: -50px;
      .name {
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: color.$dark-text-color;
        display: flex;
        align-items: center;
        &.nameDark{
          color: color.$white-text-color;
        }
        .rating {
          display: inline-block;
          margin-left: 7px;
          background: color.$primary-color;
          border-radius: 10px;
          padding: 4px 5px;
          font-weight: 500;
          font-size: 10px;
          line-height: 14px;
          color: #ffffff;
          
          .icon {
            // width: 16px;
            // height: 16px;
            font-size: 13px;
            transform: translateY(-1px);
            display: inline-block;
          }
        }
      }
      .email {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: color.$light-text-color;
        &.emailDark{
          color: color.$white-text-color;
        }
      }
      .edit {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: color.$primary-color;
        cursor: pointer;
      }
    }
  }
  .section__title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: color.$dark-text-color;
    margin-left: 11px;
    // margin-bottom: 14px;
    .section__titleDark{
      color:color.$white-text-color
    }
    
    &:last-child {
      // margin-bottom: 9px;
      cursor: pointer;
    }
    .logout__icon {
      display: inline-block;
      margin-right: 8px;
      width: 17px;
      height: 17px;
      &.logout__iconDark{
        color:color.$white-text-color;
      }
    }
  }
  .section__title_A {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: color.$dark-text-color;
    margin-left: -20px;
    // margin-bottom: 14px;
    &.section__title_ADark{
      color: color.$white-text-color;
    }
    &:last-child {
      // margin-bottom: 9px;
      cursor: pointer;
    }
    .logout__icon {
      display: inline-block;
      margin-right: 8px;
      width: 17px;
      height: 17px;
    }
  }
  .section__title.title {
    margin-top: 13px;
    &.titleDark{
      color: color.$white-text-color;
    }
    
  }
   #lgout {
    margin-top: 13px;
    color: color.$error-color;
    &.titleDark{
      color: color.$error-color;
    }
    
  }
  .section__settings {
    // margin-left: 23px;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: color.$dark-text-color;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    &:hover{
      color: color.$primary-color;
    }
    .section__settingsDark{
      color:color.$white-text-color;
    }
    .setting__icon {
      display: inline-block;
      margin-right: 16px;
      width: 17px;
      height: 17px;
      .setting__iconDark{
        color: color.$white-text-color;
      }
      
    }
   
  
  }
 
  .line {
    border: 0.5px solid color.$light-text-color;
    margin-top: 7px;
    margin-bottom: 2px;
  }
}
@media screen and (max-width: 800px) {
  .profile-menu-container {
    .dropdown-menu-info {
      right: -80px;
    }
  }
 
}
@media screen and (max-height: 780px) {
  .profile-menu-container {
    .dropdown-menu-info {
      max-height: 600px;
      overflow-y: auto;
    
  }
}
}
@media screen and (max-height: 800px) {
  .profile-menu-container {
    .dropdown-menu-info {
     max-height: 650px;
     overflow-y: auto;
    
  }
}
}
@media screen and (max-height: 720px) {
  .profile-menu-container {
    .dropdown-menu-info {
     max-height: 500px;
     overflow-y: auto;
    
  }
}
}
@media screen and (max-height: 500px) {
  .profile-menu-container {
    .dropdown-menu-info {
     max-height: 300px;
     overflow-y: auto;
    
  }
 
}
}