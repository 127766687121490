@use "../../../styles/variables.scss" as color;
.overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
}
.modal_styles {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2001;
  background-color: white;
  width: 716.8px;
  height: 550.2px;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  &.modal_stylesDark{
    background: color.$dark-text-color;
  }
  .close {
    cursor: pointer;
    position: absolute;
    top: 13.3px;
    right: 13.3px;
    width: 32px;
    height: 32px;
    &.closeDark{
      background-color:#98dae0;
      border-radius: 50%;
    }
    
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .title {
      font-weight: 600;
      font-size: 20.48px;
      line-height: 30.72px;
      color: color.$dark-text-color;
      margin-top: 73.6px;
      &.titleDark{
        color:color.$white-text-color;
      }
    }
    .type_container {
      margin-top: 94.08px;
      display: flex;
      column-gap: 88.32px;
      .card_container {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        .card {
          border: 5px solid #98dae0;
          border-radius: 10px;
          width: 152.96px;
          height: 152.4032px;
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover{
            transform: scale(1.1);
            transition: transform 0.3s ease-in-out;
          }
          .img {
            width: 70%;
          }
        }
        .orDark{
          color:color.$white-text-color
        }
        .type {
          text-align: center;
          font-weight: 600;
          font-size: 20.48px;
          line-height: 30.72px;
          color: color.$dark-text-color;
          margin-top: 27.52px;
          &.typeDark{
            color:color.$white-text-color
          }
        }
      }
      .middle {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 9px;
        .or{
          font-weight: 600;
          font-size: 20.48px;
          line-height: 30.72px;
          color: color.$dark-text-color;
          &.orDark{
            color: color.$white-text-color;
          }
        }
        .line {
          height: 112px;
          border-left: 3px solid color.$dark-text-color;
          &.lineDark{
            border-left: 3px solid color.$white-text-color;
          }
        }
      }
    }
  }
  @media screen and (max-width: 750px) {
    width: 80vw;
    height: auto;
    .container {
      margin-bottom: 20px;
      .type_container {
        margin-top: 50px;
        flex-direction: column;
        align-items: center;
        .card_container {
          .type {
            margin-top: 17px;
          }
        }
        .middle {
          flex-direction: row;
          column-gap: 9px;
          margin-top: 20px;
          margin-bottom: 20px;
          .line {
            height: 3px;
            width: 200px;
            border-left: none;
            border-top: 3px solid color.$dark-text-color;
            &.lineDark{
              border-top: 3px solid color.$white-text-color;

            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 620px) {
    .container {
      .title {
        text-align: center;
        font-size: 16.384px;
        line-height: 24.576px;
      }
      .type_container {
        .middle {
          .line {
            width: 120px;
          }
        }
      }
    }
  }
}
