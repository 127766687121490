@use "../../../../styles/variables.scss" as color;
.overlay {
    position: fixed;
    background-color: rgba(0, 0, 0, 0);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2000;
  }
  
  .modal_styles {
    position: fixed;
    top: 50%;
    left: 70%;
    transform: translate(-50%, -50%);
    z-index: 2001;
    background-color: white;
    width: 300px;
    height: 550px;
    box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    &.modal_stylesDark{
      background: color.$dark-text-color;
    }
  .container{
    margin-top: 5px;
    .close {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 13.3px;
      width: 32px;
      height: 32px;
      &.closeDark{
        background-color:#98dae0;
        border-radius: 50%;
      }
     
  
    }
.title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  .radio-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    label {
      margin-bottom: 10px;
    }
  }
  input[type='text'] {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  label.checkbox-label {
    display: flex;
    align-items: center;
    input[type='checkbox'] {
      margin-right: 10px;
    }
  }
  button[type='submit'] {
    background-color: #0298A6;;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  }  
}