@use "../../styles/variables.scss" as color;
.secedit{
  padding-bottom: 30px;
.container {
  width: 90vw;
  // margin: 3rem auto;
  background: color.$light-background-color;
  border-radius: 10px;
  padding: 73px 50px;
  &.containerDark{
    background-color: #272932;
  }
  .wrapper {
    display: flex;
    justify-content: space-between;
    .account_container {
      display: flex;
      .picture_container {
        position: relative;
        // width: 169px;
        // height: 169px;
        .pp {
          width: 169px;
          height: 169px;
          border-radius: 50%;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          object-fit: cover ;
        }
        .camera_container {
          position: absolute;
          right: 0;
          bottom: 0;
          background: color.$primary-color;
          border: 5px solid #ffffff;
          width: 51px;
          height: 51px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          .camera_icon {
            color: white;
            font-size: 24px;
          }
        }
        .change_image {
          cursor: pointer;
          position: absolute;
          right: 8px;
          bottom: -40px;
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;
          color: color.$primary-color;
        }
      }
      .info_container {
        margin-left: 43px;
        display: flex;
        flex-direction: column;
        .name {
          font-weight: 500;
          font-size: 36px;
          line-height: 150%;
          color: color.$dark-text-color;
          &.nameDark{
            color:color.$white-text-color;
          }
        }
        .details_container {
          display: flex;
          gap: 65px;
          .details {
            font-weight: 700;
            font-size: 24px;
            line-height: 150%;
            color: color.$dark-text-color;
            text-align: center;
            &.detailsDark{
              color:color.$white-text-color;
            }
            span {
              display: block;
            }
            &.spanDark{
              color:color.$white-text-color;
            }
          }
        }
      }
    }
    .rating_container {
      width: 378px;
      height: 155px;
      background: color.$light-background-color;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      .score_container {
        display: flex;
        flex-direction: column;
        margin-top: 19px;
        margin-left: 15px;
        .score {
          font-weight: 600;
          font-size: 32px;
          line-height: 150%;
          color: color.$dark-text-color;
          span {
            font-size: 20px;
          }
        }
        .score_text {
          font-weight: 600;
          font-size: 14px;
          line-height: 150%;
          color: #b3b3b3;
        }
        .score_star {
          margin-top: 11px;
          display: flex;
          font-size: 25px;
          color: color.$yellow-color;
        }
      }
      .stars_container {
        display: flex;
        flex-direction: column;
        margin-top: 19px;
        margin-right: 15px;
        .star {
          font-weight: 600;
          font-size: 14px;
          line-height: 150%;
          color: #b3b3b3;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .line {
            margin-left: 8px;
            display: inline-block;
            width: 91px;
            border-top: 7px solid gray;
            border-radius: 10px;
          }
        }
      }
    }
  }
  .button_container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .update_btn {
      font-weight: 500;
      font-size: 24px;
      line-height: 150%;
      color: white;
      background-color: color.$primary-color;
      padding: 10px 15px;
      border-radius: 10px;
      margin-bottom: 10px;
      &:hover{
        background-color: #01b1c0;
  
      }
      &.update_btnDark{
        background: #015866;
      }
    }
    .edit_password {
      font-weight: 500;
      font-size: 19px;
      line-height: 150%;
      color: color.$primary-color;
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
    }
    .delete_profile {
      font-weight: 500;
      font-size: 19px;
      line-height: 150%;
      color: color.$error-color;
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
      .icd{
        margin-top: 5px;
      }
    }
  }
  .shareCont {
    display: flex;
    flex-direction: column;
  }
  
  .share {
    color: color.$primary-color;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: underline;
    margin-bottom: 20px;
  }
  
  .shareic {
    margin-top: 2px;
    margin-right: 5px;
    font-size: 21px;
  }
  
  // Edit Section ba3ed user w rating
  .edit_section {
    margin-top: 77px;
    .edit_title {
      font-weight: 700;
      font-size: 28px;
      line-height: 150%;
      color: color.$dark-text-color;
      &.edit_titleDark{
        color:color.$white-text-color;
      }
    }
    .form_container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      column-gap: 10px;
      margin-top: 37px;
      margin-bottom: 65px;
      .label_input_container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 580px;
        .label {
          font-weight: 500;
          font-size: 24px;
          line-height: 150%;
          color: color.$dark-text-color;
          &.labelDark{
            color:color.$white-text-color;
          }
        }
       
        .input_container {
          position: relative;
        
            
          .input {
            background: #ffffff;
            border: 1px solid #d9d9d9;
            border-radius: 10px;
            width: 376px;
            height: 58px;
            padding-right: 10px;
            font-weight: 500;
            font-size: 21px;
            line-height: 32px;
            color: color.$dark-text-color;
            padding-left: 72px;
            &.inputDark{
              background: #015866;
              color:rgb(207, 203, 203);
              border:1px solid #015866
            }
          }
          .input_icon {
            position: absolute;
            left: 26px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 19px;
            color: color.$dark-text-color;
          }
        }
        .phonei{
          &.phoneiDark{
            background-color:#015866;
            border: none;
          }
          
          ::placeholder {
            background-color: transparent;
          }
        }
        
      }
    }
  }
  // Reviews section
  .reviews_section {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    .reviews_title {
      font-weight: 700;
      font-size: 28px;
      line-height: 150%;
      color: #353844;
      &.reviews_titleDark{
        color:#015866;
      }
    }
    .sort_container {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: #353844;
    
      .hide {
        margin-left: 14px;
        text-decoration: none;
        cursor: pointer;
        &.hideDark{
          color: #015866;
        }
      }
      .hide:hover {
        font-weight: bold;
      }
      .sort {
        background: color.$light-background-color;
        width:60px;
        &.sortDark{
          background-color: #015866;
          color:white
        }
      }
      .label{
        display: inline-block;
        margin-right: 10px;
        &.labelDark{
          color:color.$white-text-color;
        }
      }
      .sort_icon {
        display: inline-block;
        width: 40px;
        height: 40px;
        &.sort_iconDark{
          color: color.$white-text-color;
        }
      }
    }
  }
  .reviews {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media screen and (min-width: 1900px) {
   width: 70vw;
    .wrapper{
    .edit_section {
      .form_container {
        row-gap: 00px;
      }
    }
  }
  }
  @media screen and (max-width: 1411px) {
    .edit_section {
      .form_container {
        row-gap: 20px;
      }
    }
  }
  @media screen and (max-width: 1050px) {
    padding: 58.4px 40px;
    .wrapper {
      .account_container {
        .info_container {
          margin-left: 23px;
        }
      }
    }
  }
  @media screen and (max-width: 1000px) {
    width: 80vw;
    .wrapper {
      flex-direction: column;
      justify-content: unset;
      align-items: center;
      .account_container {
        .info_container {
          margin-left: 43px;
        }
        margin-bottom: 70px;
      }
    }
  }
  @media screen and (max-width: 800px) {
    .edit_section {
      .edit_title {
        font-size: 22.4px;
        line-height: 120%;
      }
      .form_container {
        .label_input_container {
          .label {
            font-size: 19.2px;
            line-height: 120%;
          }
          .input_container {
            .input {
              width: 300.8px;
              height: 46.4px;
              padding-left: 57.6px;
              font-size: 16.8px;
              line-height: 25.6px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 880px) {
    .wrapper {
      .account_container {
        .picture_container {
          width: 118.3px;
          height: 118.3px;
          .pp {
            width: 118.3px;
            height: 118.3px;
          }
          .camera_container {
            width: 35.7px;
            height: 35.7px;
            .camera_icon {
              font-size: 16.8px;
            }
          }
          .change_image {
            bottom: -28px;
            font-size: 14px;
            line-height: 21px;
          }
        }
        .info_container {
          .name {
            font-size: 25.2px;
            line-height: 105%;
          }
          .details_container {
            margin-top: 10px;
            gap: 45.5px;
            .details {
              font-size: 16.8px;
              line-height: 105%;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 700px) {
    .edit_section {
      .form_container {
        .label_input_container {
          flex-direction: column;
          justify-content: unset;
          align-items: flex-start;
          row-gap: 15px;
        }
      }
    }
    .button_container {
      .edit_password {
        font-size: 14px;
        line-height: 120%;
      }
      .update_btn {
        font-size: 19.2px;
        line-height: 120%;
      }
      .delete_profile {
        font-size: 14px;
        line-height: 120%;
        .icd{margin-top: 2px;}
      }
      
    }
    .reviews_section {
      .reviews_title {
        font-size: 22.4px;
        line-height: 120%;
      }
    }
  }
  @media screen and (max-width: 555px) {
    width: 90vw;
    padding: 51.1px 35px;
  }
  @media screen and (max-width: 480px) {
    .wrapper {
      .rating_container {
        width: 340.2px;
        height: 139.5px;
        .score_container {
          margin-top: 17.1px;
          margin-left: 13.5px;
          .score_text {
            font-size: 12.6px;
            line-height: 135%;
          }
        }
      }
    }
  }
  @media screen and (max-width: 470px) {
    .wrapper {
      .account_container {
        flex-direction: column;
        align-items: center;
        .info_container {
          margin-top: 55px;
          margin-left: 0;
        }
        .picture_container {
          width: 135.2px;
          height: 135.2px;
          .pp {
            width: 135.2px;
            height: 135.2px;
          }
          .camera_container {
            width: 40.8px;
            height: 40.8px;
            .camera_icon {
              font-size: 19.2px;
            }
          }
          .change_image {
            bottom: -32px;
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 400px) {
    padding: 51.1px 15px;
  }
  @media screen and (max-width: 360px) {
    .edit_section {
      .edit_title{
        font-size:20px;
       margin-left: 18%;
      }
      
    .form_container {
      .label_input_container {
        align-items: center;
        .label {
          font-size: 19.2px;
          line-height: 120%;
        }
        .input_container {
          .input {
            width: 250px;
        }
      }
      .phonei{
        width: 250px;
      }
      }
      }
    }
     .reviews_section {
      .reviews_title {
      font-size: 22px;
    }
    .sort_container {
      font-size: 15px;
      .sort_icon {
        width: 30px;
        height: 30px;
      }
    }
  
  }
  .button_container{
    align-items: center;
  }
}
}
}