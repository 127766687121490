@use "../../styles/variables.scss" as color;
.container {
  margin-top: 45px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .img_container {
    .button_container {
      margin-top: 43px;
      display: flex;
      justify-content: space-between;
      .btn {
        background: #ffffff;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 20px 30px;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: color.$primary-color;
        cursor: pointer;
        &:hover{
          transform: scale(1.03);
    
        }
        &.btnDark{
          background-color: color.$dark-text-color;
        }
        .btn_icon {
          display: inline-block;
          margin-right: 15px;
        }
      }
    }
  }
  .form_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    .title {
      font-weight: 600;
      font-size: 32px;
      line-height: 48px;
      color: color.$dark-text-color;
      text-align: center;
      margin-bottom: 44px;
      &.titleDark{
        color:white
      }
    }
    .form_container {
      background: #ffffff;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
      width: 583px;
      height: 494px;
      &.form_containerDark{
        background-color:color.$dark-text-color;
      }
      .wrapper {
        padding: 32px 36px 25px 36px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .input_container {
          position: relative;
          width: 100%;
          .input {
            background: #f8f8f9;
            border: 1px solid #d9d9d9;
            border-radius: 10px;
            height: 66px;
            width: 100%;
            padding-left: 109px;
            margin-bottom: 25px;
            &.inputDark{
              background: #015866;
              color:rgb(207, 203, 203)
            }
          }
          .input_icon {
            position: absolute;
            top: 37%;
            left: 53px;
            transform: translateY(-50%);
            color: #c4c4c4;
            font-size: 24px;
          }
        }
        .text_area {
          background: color.$light-background-color;
          border: 1px solid #d9d9d9;
          border-radius: 10px;
          width: 100%;
          height: 170px;
          padding: 4px 24px;
          resize: none;
          &.text_areaDark{
            background: #015866;
            color:rgb(207, 203, 203)
          }
        }
        .send {
          background: color.$primary-color;
          border-radius: 10px;
          width: 204px;
          height: 60px;
          border-radius: 10px;
          font-weight: 600;
          font-size: 24px;
          line-height: 36px;
          color: white;
          margin-top: 25px;
          margin-left: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          &.sendDark{
            background-color: #015866;
          }
          &:hover{
            background: #01b1c0;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1380px) {
    flex-direction: column;
    justify-self: unset;
    align-items: center;
    .img_container {
      .button_container {
        .btn {
          display: flex;
          flex-direction: column;
          align-items: center;
          .btn_icon {
            margin-bottom: 7px;
            margin-left: 15px;
          }
        }
      }
    }
    .form_section {
      margin-top: 34px;
    }
  }
  @media screen and (max-width: 660px) {
    
    .img_container {
      .button_container {
        .btn {
          font-size: 15px;
          line-height: 22px;
          padding: 10px 15px;
          .btn_icon {
            size: 10px;
          }
        }
      }
    }
    .form_section {
     
      .form_container {
        width: 90vw;
        .wrapper {
          padding: 20px;
          .send{
            margin-left: 25px;
          }
          .input_container {
            .input {
              padding-left: 70px;
            }
            .input_icon {
              left: 20px;
              top: 37%;
            }
          }
        }
      }
    }
    
  }
  
}
