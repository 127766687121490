@use "../../../styles/variables.scss" as color;
.wrapper {
  display: flex;
  .image_container {
    width: 70vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f6ffff;
  }
  .form_container {
    width: 30vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .form_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        font-weight: 600;
        font-size: 32px;
        line-height: 48px;
        color: #353844;
        margin-bottom: 42px;
      }
      .form {
        display: flex;
        flex-direction: column;
        .label {
          font-weight: 400;
          font-size: 20px;
          line-height: 30px;
          color: color.$dark-text-color;
          margin-bottom: 9.8px;
        }
        .input_container {
          position: relative;
          margin-bottom: 31.5px;
          .input {
            width: 396px;
            height: 58px;
            background: color.$light-background-color;
            border: 1px solid color.$border-category-dropdown;
            border-radius: 10px;
            padding-left: 47.6px;
          }
          .pass_icon {
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            color: color.$light-gray-color;
            font-size: 20px;
            cursor: pointer;
          }
          .input_icon {
            position: absolute;
            left: 16px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 20px;
          }
          .error_message {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: color.$error_color;
            position: absolute;
            // bottom: -20px;
            left: 6px;
          }
          &.error_input {
            .input {
              border-color: color.$error_color;
            }
            .input::placeholder {
              color: color.$error_color;
            }
            .input_icon {
              color: color.$error_color;
            }
          }
        }
        .forget_container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          .message {
            position: absolute;
            bottom: -25px;
            left: 0;
          }
          .remember_container {
            display: flex;
            align-items: center;
            gap: 5px;
            input {
              accent-color: color.$primary-color;
              width: 18px;
              height: 18px;
            }
            .remember {
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;
              color: color.$dark-text-color;
            }
          }
          .forget {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            text-decoration-line: underline;
            color: color.$primary-color;
            cursor: pointer;
          }
        }
        .login_btn {
          background: color.$primary-color;
          border-radius: 10px;
          width: 107.8px;
          height: 43.4px;
          margin: 30px auto;
          color: white;
          font-weight: 600;
          font-size: 18px;
          line-height: 25.2px;
          &:hover{
            background: #01b1c0;
      
          }
        }
      }
      .or_container {
        display: flex;
        align-items: center;
        gap: 18px;
        .line {
          border-top: 1px solid #000000;
          width: 165px;
        }
        p {
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          color: #353844;
        }
      }
      .continue {
        align-self: flex-start;
        margin-top: 30px;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: #353844;
      }
      .btn_container {
        margin-top: 15px;
        display: flex;
        gap: 30px;
        button {
          width: 68px;
          height: 61px;
          background: #f8f8f9;
          border: 1px solid #d9d9d9;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
@media screen and (max-width: 1300px) {
  .wrapper {
  .form_container {
    width: 100%;
  }
}
}
@media screen and (max-width: 700px) {
  .wrapper {
    .form_container {
      width: 100%;
      
    }
  .image_container {
   display: none;
 
  }
}
}
@media screen and (max-width: 430px) {
  .wrapper {
  
    .image_container {
     display: none;
    }
  
    .form_container {
      width: 100%;
      .form_wrapper {
        .title {
          font-size: 23px;
        }
  
        .form {
          .label {
            font-size: 15px;
          }
  
          .input_container {
            .input {
              width: 250px;
              height: 48px;
              
            }
          }
        }
  
        .or_container {
  
          .line {
             width: 100px;
          }
  
          p {
            font-size:20px;
          }
        }
  
        .continue {
          font-size: 15px;
        }
  
        .btn_container {
  
          button {
            width: 48px;
            height: 41px;
                      }
        }
      }
    }
  }
}
  
