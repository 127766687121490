.empty{
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    .txt{
      &.txtDark{
        color: white;
      }
    }
    .btn {
      margin-top: 30px;
      background: #0298a6;
      border-radius: 10px;
      color: white;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 2.25rem;
      padding: 9px 4.16em;
      margin-bottom: 2.41em;
      &.btnDark{
      background-color: #015866;
      }
    }
  }