.delete{
    font-size: 15px;
    font-weight: 500;
    color: red;
    display: inline-flex;
    align-items: center;
    margin: 0;
    padding: 0;
}
.threedots {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 15px;
    position: relative;
    justify-content: center;}
.dotDrop{
    position: absolute;
    top: 100%;
    left: -200px;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    width: 200px;
  }
  .dotDrop button {
    width: 100%;
    text-align: left;
    padding: 5px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    .delete{
        font-size: 15px;
        font-weight: 500;
        color: red;
        display: inline-flex;
        align-items: center;
        margin: 0;
        padding: 0;
        .icd{
            margin-right:10px ;
            color: red;
        }
    }
  }
