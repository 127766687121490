@use "../../styles/variables.scss" as color;
@import "~bootstrap/scss/bootstrap";
$lightgray: #f8f9fa;
$gray: #dee2e6;
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bolder;
  
  .calendar {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-top:-1.7%;
    font-weight: bold;
    color:gray ;
  }
    .dates{
      padding-left: 6px;
      color: color.$primary-color;
    }
  
    .datepicker-wrapper {
      position: relative;
      margin-top: 10px;
    }
    
    .react-datepicker {
      position: absolute;
      top: 100%;
      left:auto;
      right: 0;
      z-index: 1;
    }
}
  
  .toolbar {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
  }
  
  .filter-button {
    background-color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: bold;
    border: 1px solid lightgray;
    cursor: pointer;
  }
  
  .search_pinput {
    display: flex;
    align-items: center;
  
    input[type="text"] {
      padding: 10px;
      border-radius: 5px;
      border: 1px solid lightgray;
      font-size: 14px;
      flex: 1;
      width: 1400px;
    }
  
    .search_picon {
      background-color: white;
      border: none;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid lightgray;
      margin-left: 10px;
      font-weight: bold;
      cursor: pointer;
    }
  }
  
  .tableChoices {
    margin-top: 30px;
    // margin-left: 22%;
    border: 1px solid gray;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
  
    button {
      padding: 8px 15px;
      cursor: pointer;
      font-weight: 500;
      font-size: 15px;
      line-height: 28px;
      border: 0.001px dotted white;
      border-radius: 16px;
  
      &.active {
        border: 2px solid color.$primary-color !important;
        border-radius: 11px;
        width: 100%; /* Change width to 100% */
        background-color: color.$primary-color !important;
        color: #ffffff;
      }
  
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
  
  .AddProdBut{
    display: flex;
    align-items: center;
    background-color: color.$primary-color;
    color: white;
    font-weight: 500;
    width: 150px;
    padding-left: 12px;
    border-radius: 12px;
    margin-right: 50px;
    &:hover{
        background-color: white;
        color: color.$primary-color;
        border: 2px solid color.$primary-color;
    }
   
}
  
  
  .content {
    display: flex;
    margin-top: 20px;
  }
  .container_t {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;}
    .smdtable{
      max-width: 300px;
       border-radius: 10px;
       margin-bottom: 50px;
     
     
       td {
         width: 100px;
         padding: 5px;
         vertical-align: middle;
         border-top: none;
         border-left: none;
         border-right: none;
         
         &:first-child {
           border-left: none;
           border-radius: 0 0 0 0;
         }
     
         &:last-child {
           border-right: none;
           border-radius: 0 0 0 0;
         }
         
       }
       
       thead {
         tr {
           background-color: darkgray;
           color: black;
         }
         
       }
       tbody {
         tr:nth-child(odd) {
           background-color: white;
         }
     
         tr:nth-child(even) {
           background-color: rgb(235, 231, 231);
         }
     
         tr:first-child {
           background-color: white;
           color: #fff;
         }
       }
     }
     
     .table {
      // width:850px;
      margin-bottom: 20px;
      border-radius: 15px;
      overflow: hidden;
      // margin-left: 20px;
      table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;
        text-align: left;
        font-size: 14px;
        border: 2px solid $gray;
    th,
    td {
      width: 100px;
      padding: 12px;
      vertical-align: middle;
      border-top: none;
      border-left: none;
      border-right: none;
      &:first-child {
        border-left: none;
        border-radius: 0 0 0 0;
      }
      &:last-child {
        border-right: none;
        border-radius: 0 0 0 0;
      }
    }
    thead {
      tr {
        background-color: darkgray;
        color: black;
      }
    }
    tbody {
      tr:nth-child(odd) {
        background-color: white;
      }
      tr:nth-child(even) {
        background-color: rgb(235, 231, 231);
      }
      tr:first-child {
        background-color: white;
        color: #fff;
      }
    }
  
}
}
.flexC {
    display: flex;
    justify-content: space-between;
    align-items:center;   
    padding-left: 38%;
    margin-top: -2%;
.offers {
display: flex;
flex-direction: row;
align-items: center;
gap: 17.68px;
font-style: normal;
font-weight: 700;
font-size: 18.0357px;
line-height: 22px;
color: color.$dark-text-color;
span {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  transform: translateY(10%);
  margin-right: 11px;
  display: inline-block;
}
.bids_circle {
  background-color: #e76859;
}
.offers_circle {
  background-color: #ffcb3c;
}
.services_circle {
  background-color: #59aef7;
}
.textC{
    font-size: 15px;
}
}
}
.pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  .page-item {
    &:not(.active) {
      a {
        background-color: $lightgray;
        border-color: $lightgray;
        color: #000;
      }
      &:hover a {
        background-color: $gray;
        border-color: $gray;
        color: #fff;
      }
    }
    &.active {
      a {
        background-color: $gray;
        border-color: $gray;
        color: #fff;
      }
    }
  }
  .page-link {
    border-radius: 10px;
    margin: 0 2px;
  }
}
@media (max-width: 1300px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }
    .calendar {
    // position: absolute;
    display:block;
    align-items: center;
    top:20px
    }
   
      .toolbar {
        display: flex;
        justify-content: space-between;
        margin: 20px 0;
      }
  }
@media (max-width: 730px) {
   
      .toolbar {
        margin-top: 50px;
        display: block;       
        margin-left: -30px;
        .searchad{
          margin-left: -65px;
          display: flex;
          justify-content: space-around;
        .search-input{
          margin-top: 10px;
        }
        .AddProdBut{
          height: 50px;
          align-items: center;
          justify-content: center;
          margin-top: 10px;
        }
      }
    }
  }
@media (max-width: 620px) {
   
      .toolbar {
        margin-top: 50px;
        display: block;       
        margin-left: 0px;
        .searchad{
          margin-left: 0px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        .search-input{
          margin-top: 10px;
          margin-left: 3px;
        }
        .AddProdBut{
          height: 50px;
          align-items: center;
          justify-content: center;
          margin-top: 10px;
        }
      }
    }
    .offers {
      margin-left: -100px;
    
      }
  }
@media (max-width: 485px) {
  .toolbar{
    .searchad{
      .search_picon{
        margin-right:80px ;
      }
    }
  }
  .tableChoices{
    margin-left: 0px;
    width: 180px;
    button {
      padding: 8px 18px;
      cursor: pointer;
      font-weight: 500;
      font-size: 10px;
      line-height: 28px;
      border: 0.001px dotted white;
      border-radius: 16px;
  
      &.active {
        border: 2px solid color.$primary-color !important;
        border-radius: 11px;
        width: 100%; 
        background-color: color.$primary-color !important;
        color: #ffffff;
      }
    }
  }
    
  }
