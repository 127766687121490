.date{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 19%;
    margin-left: -70%;
    .dateText{
        font-size:13px ;
        font-weight: 500;
        font-family:poppins;
        display: flex;
    
    }
    @media (max-width: 1050px){
        text-align: start;
        padding-top: 0%;
    }
    }