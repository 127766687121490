.container{
    position: fixed;
    background-color: rgba(0, 0, 0, 0.4);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2000;
}
.hideit{
    display: none;
}
.MobileBanner {
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: #fff;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
    transition: opacity 0.5s ease, visibility 0.5s ease; /* Add transitions for smooth appearance and disappearance */
    opacity: 0; 
    visibility: hidden; 
    z-index: 1000;
    &.MobileBannerDark{
        background-color: #353844;
    }
   
    &.MobileBannerShow{
        opacity: 1; 
       visibility: visible;
    }
    &.MobileBannerHide{
        opacity: 0; 
        visibility: hidden;
    }
    .logoCont{
        justify-content: center;
        align-items: center;
        display: flex;
      .logo {
        margin-top: 45.5px;
        font-weight: 500;
        font-size: 28px;
        line-height: 42px;
        text-align: center;
        width: 50px;
        height: 50px;
        justify-content: center;
        align-items: center;
        display: flex;
        transform: scale(2.5);
      }
    }
   p {
    font-size: 16px;
    margin-bottom: 12px;
    font-weight: 600;
  }
  
   .Download {
    background-color: #0298a6;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin: 4px;
    cursor: pointer;
    width: 170px;
    height: 40px;
    border-radius: 10px;
    line-height: 15px;
    margin-bottom: 30px;
    &.DownloadDark{
        background-color: #015866;
    }
  
    &:hover {
      background: #01b1c0;
    }
  }
  
   .DontDownload {
    color:#0298a6;
    margin-bottom: 20px;
    display: flex;
    
    &.DontDownloadDark{
        color: white;
    }
  }
}

