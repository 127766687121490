.ReportCirc {
    display: inline-block;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    text-align: center;
    vertical-align: middle;
    line-height: 25px;
    font-size: 10px;
    font-weight: bold;
    margin-right: 10px;  
    margin-left: 10px;  
    background-color: rgb(199, 33, 33);
    .number {
      color: white;
      text-transform: uppercase;
    }
  }
  