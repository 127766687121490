@use "../../../styles/variables.scss" as color;
.item {
  display: flex;
  align-items: center;
  position: relative;
  .line {
    position: absolute;
    bottom: 0;
    border-top: 1.7px solid #e7e7e7;
    width: 100%;
  }
  .image {
    width: 114px;
    height: 114px;
    border-radius: 50%;
    margin-top: 18.7px;
    margin-left: 55.3px;
    margin-bottom: 44.4px;
    object-fit: cover;
  }
  .info_container {
    display: flex;
    flex-direction: column;
    margin-left: 60.6px;
    .name {
      font-weight: 700;
      font-size: 28px;
      line-height: 42px;
      color: color.$dark-text-color;
      cursor: pointer;
      &.nameDark{
        color: white;
      }
    }
    .text {
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      display: flex;
      align-items: center;
      &.sure {
        color: #006770;
      }
      &.notsure {
        color: color.$primary-color;
      }
      .icon {
        display: inline-block;
        margin-right: 10px;
      }
    }
    .button_container {
      margin-top: 16px;
      display: flex;
      gap: 43.5px;
      button {
        width: 129.76px;
        height: 40.64px;
        border-radius: 6.5px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
      .accept {
        background: color.$primary-color;
        color: white;
      }
      .reject {
        background: #ffffff;
        border: 3px solid color.$primary-color;
        color: color.$primary-color;
      }
    }
  }
  .stats_container {
    // margin-left: 170px;
    .time {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: color.$dark-text-color;
      position: absolute;
      top:10px;
      right: 10px;
      &.timeDark{
        color: white;
      }
    }
    .distance {
      margin-top: 19px;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: color.$light-text-color;
      &.distanceDark{
        color: white;
      }
    }
  }
  @media screen and (max-width: 1050px) {
    // justify-content: space-between;
    .image {
      width: 79.8px;
      height: 79.8px;
      border-radius: 50%;
      margin-top: 13.09px;
      margin-left: 38.71px;
      // margin-right: 50px;
      margin-bottom: 31.08px;
    }
    .info_container {
      margin-left: 20px;
      .name {
        font-size: 19.6px;
        line-height: 29.4px;
      }
      .text {
        font-size: 11.2px;
        line-height: 105%;
        .icon {
          margin-right: 7px;
        }
      }
      .button_container {
        margin-top: 11.2px;
        gap: 30.45px;
        button {
          width: 90.832px;
          height: 28.448px;
          font-size: 11.2px;
          line-height: 16.8px;
          margin-bottom: 10px;
        }
      }
    }
    .stats_container {
      margin-right: 30px;
      margin-left: 0;
      .time {
        font-size: 11.2px;
        line-height: 16.8px;
      }
      .distance {
        margin-top: 13.3px;
        font-size: 11.2px;
        line-height: 16.8px;
      }
    }
  }
  @media screen and (max-width: 550px) {
    .image {
      margin-left: 18px;
      // margin-right: 0;
    }
    .stats_container {
      margin-right: 10px;
    }
  }
  @media screen and (max-width: 450px) {
    .image {
      margin-left: 5px;
      width: 60px;
      height: 60px;
    }
    .stats_container {
      margin-right: 5px;
      .time {
        font-size: 8.96px;
        line-height: 13.44px;
      }
      .distance {
        margin-top: 9px;
        font-size: 8.96px;
        line-height: 13.4px;
      }
    }
    .info_container {
      .name{
        font-size: 15px;
      }
      .text {
        .icon {
          width: 15px;
          height: 15px;
        }
      }
      .button_container {
        gap: 10px;
        button {
          width: 72.6656px;
          height: 22.7584px;
          font-size: 8.96px;
          line-height: 13.44px;
        }
      }
    }
  }
  @media screen and (max-width: 390px) {
    .image {
      margin-left: 5px;
      width: 40px;
      height: 40px;
    }
    .info_container {
      .name{
        font-size: 10px;
      }
      
  }
}
}