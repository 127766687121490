@use "../../../../../styles/variables.scss" as color;
.section {
  // &.sectionDark{
  //   background-color: color.$dark-text-color;
  // }
  .title {
    font-weight: 500;
    font-size: 19.6x;
    line-height: 29.4px;
    color: #353844;
    margin-bottom: 15px;
    text-align: center;
    &.title{
      color:color.$white-text-color
    }
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
  .container {
    display: flex;
    align-items: center;
    .picture_container {
      margin-left: 55px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .pp {
        position: relative;
        width: 165px;
        height: 165px;
        border-radius: 50%;
        background: color.$light-background-color;
        border: 1px solid color.$border-category-dropdown;
        display: flex;
        justify-content: center;
        align-items: center;
        object-fit: cover;
        &.ppDark{
          background-color: #015866;
        }
        .default_img {
          width: 120px;
          height: 120px;
          padding-bottom: 10px;          
          background-size: cover;
        }
        .preview_img {
          width: 165px;
          height: 165px;
          border-radius: 50%;
          background-size: cover;
        }
        .plus_icon {
          position: absolute;
          right: -10px;
          bottom: 10px;
          width: 42.7px;
          height: 42.7px;
          cursor: pointer;
        }
        .dropdown {
          position: absolute;
          left: 130px;
          bottom: -48px;
          background: color.$light-background-color;
          border: 1px solid color.$border-category-dropdown;
          border-radius: 10px;
          width: 250px;
          z-index: 2;
          font-weight: 400;
          font-size: 11.2px;
          line-height: 16.8px;
          color: #000000;
          &.dropdownDark{
            color: color.$white-text-color;
            background-color: #015866;
          }
          .item {
            cursor: pointer;
            padding: 10px 22px;
            text-align: center;
            .icon {
              margin-right: 12.6px;
              font-size: 18px;
              display: inline-block;
            }
            .center_text {
              display: block;
              font-weight: 500;
              color: #666666;
            }
          }
          &.active {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
            transition: 500ms ease;
          }
          &.inactive {
            opacity: 0;
            visibility: hidden;
            transform: translateY(-20px);
            transition: 500ms ease;
          }
        }
      }
      .upload_text {
        margin-top: 29px;
        font-weight: 500;
        font-size: 22px;
        line-height: 33px;
        text-align: center;
        color: color.$dark-text-color;
        &.upload_textDark{
          color: color.$white-text-color;
        }
      }
    }
    .line {
      border: 1px solid #8d8d8d;
      height: 315px;
      margin-left: 98px;
      margin-right: 42px;
    }
    .form_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      .input_container {
        position: relative;
        margin-bottom: 21px;
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        /* Firefox */
        // input[type="number"] {
        //   -moz-appearance: textfield;
        // }
        .input {
          width: 277.2px;
          height: 40.6px;
          background: color.$light-background-color;
          border: 1px solid color.$border-category-dropdown;
          border-radius: 10px;
          padding-left: 47.6px;
          &.inputDark{
            background: #015866;
            color:rgb(207, 203, 203)
          }
          &:focus {
            border: 1px solid color.$primary-color;
            outline: none;
          }
        }
        .input_icon {
          width: 22px;
          height: 22px;
          position: absolute;
          left: 16px;
          top: 12px;
          color: color.$border-category-dropdown;
        }
        .error_message {
          font-weight: 400;
          font-size: 11.2px;
          line-height: 16.8px;
          color: color.$error_color;
          position: absolute;
          
        }
        .dropdown {
          width: 277.2px;
          height: 40.6px;
          background: color.$light-background-color;
          border: 1px solid color.$border-category-dropdown;
          border-radius: 10px;
          padding-left: 47.6px;
          // padding-right: 20px;
          &.dropdownDark{
            background: #015866;
            color:rgb(207, 203, 203)
          }
        }
        &.error_input {
          .input {
            border-color: color.$error_color;
          }
          .dropdown {
            border-color: color.$error_color;
          }
        }
      }
      .register_btn {
        font-weight: 600;
        font-size: 16.8x;
        line-height: 25.2px;
        color: #ffffff;
        width: 142.8px;
        height: 42px;
        background: color.$primary-color;
        border-radius: 10px;
        &.register_btnDark{
          background-color: #015866;
        }
      }
    }
  }
  .account_text {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: color.$dark-text-color;
    margin-top: 27px;
    text-align: center;
    &.account_textDark{
      color:color.$white-text-color
    }
    .login_text {
      text-decoration-line: underline;
      color: color.$primary-color;
      cursor: pointer;
      &.login_text{
        color:#015866
      }
    }
  }
  @media screen and (max-width: 750px) {
    .container {
      flex-direction: column;
      align-items: center;
      .line {
        display: none;
      }
      .picture_container {
        margin-left: 0;
        .upload_text {
          margin-top: 18px;
          margin-bottom: 18px;
        }
      }
    }
    .account_text {
      margin-top: 20px;
      margin-bottom: 15px;
    }
  }
  @media screen and (max-width: 640px) {
    .container {
      .picture_container {
        
        .pp {
          
          .dropdown {
            left: 35px;
            bottom: -55px;
            width: 220px;
            .item {
              padding: 5px 10px;
              .icon {
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 380px) {
    .title {
      font-size: 20px;
      line-height: 30px;
      margin-top: 30px;
      margin-bottom: 4px;
    }
    .container {
      .picture_container {
        .pp {
          width: 110px;
          height: 110px;
          border-radius: 50%;
          .default_img{
            width: 80px;
            height: 80px;
            }
            .preview_img{
            width: 110px;
            height: 110px;
            }
          .plus_icon {
            width: 30px;
            height: 30px;
          }
          .dropdown {
            left: 4px;
            bottom: -55px;
          }
        }
        .upload_text {
          font-size: 18px;
          margin-top: 9px;
        }
      }
      .form_container {
        .input_container {
          margin-bottom: 15px;
        }
      }
    }
    .account_text {
      font-size: 12px;
    }
  }
  @media screen and (max-width: 340px) {
   .container{
      .form_container {
        .input_container {
          margin-bottom: 15px;
          .input{
            width: 250px;
          }
          .dropdown{
            width: 250px;
          }
        }
      }
    }
    }
    .account_text {
      font-size: 12px;
    }
  }
