.container {
  display: flex;
  flex-direction: column;
  gap: 37px;
  .image {
    width: 189px;
    height: 189px;
    border-radius: 50%;
    object-fit: cover;
  }
  .btn {
    width: 162px;
    height: 48px;
    border-radius: 10px;
    background: #0298a6;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
    &.btnDark{
      background-color: #015866;
    }
    &.btnUnflw{
      width: 162px;
      height: 48px;
      border-radius: 10px;
      background: white;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      border: 2px solid #0298a6;
      color:#0298a6 ;
    }
  }
}
