.wrapper {
  display: flex;
  // justify-content: space-between;
  gap: 90px;
  margin-top: 50px;
  margin-bottom: 40px;
  .main {
    width: 65%;
    display: flex;
    // justify-content: space-between;
    column-gap: 69px;
    row-gap: 54px;
    flex-wrap: wrap;
  }
  .aside {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .name_container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 21px;
      margin-top: 16px;
      .name {
        font-weight: 600;
        font-size: 36px;
        line-height: 150%;
        color: #353844;
      }
      .rating {
        background: #0298a6;
        border-radius: 14.4px;
        padding: 3px 8px;
        font-weight: 400;
        font-size: 14.4px;
        line-height: 22px;
        color: #f8f8f9;
        display: flex;
        align-items: center;
        gap: 5px;
        .rating_icon {
          color: white;
          display: inline-block;
        }
      }
    }
    .member {
      margin-top: 12px;
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;
      color: #353844;
      text-align: center;
    }
    .account_info {
      margin-top: 24px;
      margin-bottom: 24px;
      font-weight: 600;
      font-size: 24px;
      line-height: 150%;
      text-align: center;
      color: #353844;
      display: flex;
      gap: 65px;
      span {
        display: block;
      }
    }
  }
  @media screen and (max-width: 1500px) {
    .main {
      column-gap: 0;
      justify-content: space-between;
    }
  }
  @media screen and (max-width: 1400px) {
    .main {
      width: 75%;
    }
  }
  @media screen and (max-width: 1330px) {
    .main {
      width: 65%;
      flex-direction: column;
      align-items: center;
    }
    .aside {
      width: 30%;
    }
  }
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    .aside {
      width: 100%;
    }
    .main {
      width: 100%;
    }
  }
}
