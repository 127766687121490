.subtitle {
    margin-top: 44px;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    color: #353844;
  
    @media screen and (max-width: 620px) {
      font-size: 28.8px;
      line-height: 43.2px;
      margin-top: 35.2px;
    }
  }
  
  .categories_container {
    display: flex;
    // flex-direction: row;
    justify-content: space-around;
    margin-top: 50px;
    flex-wrap: wrap;
    gap: 50px 200px;
  }
  
  @media screen and (max-width: 800px) {
    .categories_container {
      gap: 50px 150px;
    }
  }
  @media screen and (max-width: 770px) {
    .categories_container {
      flex-direction: column;
      align-items: center;
      gap: 30px;
    }
  }
  