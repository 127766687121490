@use "../../../../styles/variables.scss" as color;
.date_container {
  background: #f8f8f9;
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 462px;
  height: 294px;
  position: relative;
  &.date_containerDark{
    background: #015866;
  }
  .wrapper {
    padding: 0 16px;
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 7px;
      .title {
        font-weight: 600;
        font-size: 96px;
        line-height: 144px;
        color: color.$primary-color;
      }
      .offers_container {
        display: flex;
        flex-direction: column;
        row-gap: 6px;
        font-style: normal;
        font-weight: 700;
        font-size: 18.0357px;
        line-height: 22px;
        color: color.$dark-text-color;
        margin-top: 10px;
        
        &.offers_containerDark{
          color: color.$white-text-color;
        }
        span {
          width: 18.04px;
          height: 18.04px;
          border-radius: 50%;
          transform: translateY(10%);
          margin-right: 11px;
          display: inline-block;
          
        }
        .bids_circle {
          background-color: #e76859;
          margin-right: 10px;
        }
        .offers_circle {
          background-color: #ffcb3c;
          margin-right: 10px;
        }
        .services_circle {
          background-color: #59aef7;
          margin-right: 10px;
        }
      }
    }
    .full_date {
      margin-top: 2px;
      font-weight: 600;
      font-size: 32px;
      line-height: 48px;
      color: #6f6f6f;
      &.full_dateDark{
        color:color.$white-text-color
      }
    }
    .deals {
      margin-top: 15px;
      font-weight: 600;
      font-size: 32px;
      line-height: 48px;
      color: color.$primary-color;
    }
  }
  @media screen and (max-width: 1500px) {
    width: 369.6px;
    height: 237px;
    .wrapper {
      padding: 0 12.8px;
      .flex {
        margin-top: 5.6px;
        .title {
          font-size: 76.8px;
          line-height: 115.2px;
        }
        .offers_container {
          row-gap: 14.144px;
          font-size: 14.42856px;
          line-height: 17.6px;
          span {
            width: 14.432px;
            height: 14.432px;
            margin-right: 8.8px;
          }
        }
      }
      .full_date {
        margin-top: 1.6px;
        font-size: 25.6px;
        line-height: 38.4px;
      }
      .deals {
        margin-top: 12px;
        font-size: 25.6px;
        line-height: 38.4px;
      }
    }
  }
  @media screen and (max-width: 700px) {
    width: 332.64px;
    height: 214.48px;
    .wrapper {
      padding: 0 11.52px;
      .flex {
        margin-top: 5.04px;
        .title {
          font-size: 69.12px;
          line-height: 103.68px;
        }
        .offers_container {
          row-gap: 12.72px;
          font-size: 12.98px;
          line-height: 15.84px;
          span {
            width: 12.98px;
            height: 12.98px;
            margin-right: 7.92px;
          }
        }
      }
      .full_date {
        margin-top: 1.6px;
        font-size: 23.04px;
        line-height: 34.56px;
      }
      .deals {
        margin-top: 10.8px;
        font-size: 23.04px;
        line-height: 34.56px;
      }
    }
  }
  @media screen and (max-width: 370px) {
    width: 300.64px;
    height: 214.48px;
  }
}
