.section {
  // padding: 0 65px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &.sectionDark{
    background-color:#353844 ;
  }
  // .path {
  //   margin-top: 22px;
  // }
  .title {
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    color: #353844;
    text-align: center;
    margin-top: 9px;
    &.titleDark{
      color: white;
    }
  }
  @media screen and (max-width: 1000px) {
    padding: 0 40px;
  }
  @media screen and (max-width: 932px) {
    padding: 0 25px;
  }
  @media screen and (max-width: 620px) {
    .title {
      font-size: 28.8px;
      line-height: 43.2px;
    }
  }
  @media screen and (max-width: 500px) {
    padding: 0 14px;
  }
  @media screen and (max-width: 370px) {
    padding: 0 7px;
  }
}
