.threedotR {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 15px;
    position: relative;
    justify-content: center;
}
.dotDropR{
    position: absolute;
    top:100%;
    left: -150px;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index:9999;
    width: 200px;
  }
  .dotDropR button {
    width: 100%;
    text-align: left;
    padding: 5px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    display: block;
    &:hover{
        background-color: #cccccc21;
    }
  }
  
  .role{
    font-size: 15px;
    font-weight: 500;
    color: #0298a6;
    display: inline-flex;
    align-items: center;
    margin: 0;
    padding: 0;
}
.selectDropdown {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    font-size: 15px;
    font-weight: 500;
    color: #0298a6;
  }
