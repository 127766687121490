@use "../../../../styles/variables.scss" as color;
.section {
  // margin-top: 63px;
  &.sectionDark{
    // margin-top: 0px;
    background-color: color.$dark-text-color;
  }
  .noCOD {
    // position: relative; // This is important to ensure the text overlays on the image
    display: flex; // In case you want the container to take the size of the image(inline-blk)
    justify-content: center;
    align-items: center;
    //   img {
  //     display: block; // Ensures the image takes up its own space
  //     width: 100%; // Set the width as needed
  //     height: auto; // Maintain aspect ratio of the image
  //   }
  
    .overlay_text {
      // position: absolute; // Position the text absolutely within the container
      top: 50%; // Adjust as needed to center vertically
      left: 50%; // Adjust as needed to center horizontally
      // transform: translate(-50%, -50%); // Center the text perfectly
      color: #0298a6; // Set the text color
      font-size: 30px; // Set the font size
      font-weight: bold; // Set the font weight
      margin-top: 15%;
      // Add more styles as needed
    }
  }
  
  .title {
    font-weight: 700;
    font-size: 60px;
    line-height: 90px;
    color: color.$dark-text-color;
    text-align: center;
    margin-bottom: 11.26px;
    &.titleDark{
      color: color.$white-text-color;
    }
    @media screen and (max-width: 850px) {
        font-size: 42px;
    }
    @media screen and (max-width: 630px) {
        font-size: 29px;
    }
    @media screen and (max-width: 510px) {
        font-size: 20px;
    }
  }
//carousel
.carousel {
  height: 515.04px;
  position: relative;
  .myswiper {
    width: 100%;
    .swiperslide {
      .imageContainer {
        position: relative;
      }
      
      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity value as desired */
        cursor: pointer;
      }
      img {
        width: 100%;
        height: 515.04px;
        object-fit: cover;
        background-repeat: no-repeat;
      }
      .product__title {
        font-weight: 700;
        font-size: 75px;
        line-height: 112px;
        text-align: center;
        color: #ffffff;
        position: absolute;
        top: 10%;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        max-width: 100%; 
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      
      }
      
      .time_container {
        display: flex;
        position: absolute;
        top:50%;
        left: 50%;
        transform: translateX(-50%);
        .flip_clock {
          --fcc-flip-duration: 0.5s; 
          --fcc-digit-block-width: 80px; 
          --fcc-digit-block-height: 80px; 
          --fcc-digit-font-size: 50px;
          --fcc-digit-color: white; 
          --fcc-background:#0298a6; 
          --fcc-label-color: #ffffff;
          --fcc-divider-color: #ffffff66;
        }
        .time_square {
          width: 220px;
          height: 220px;
          border-radius: 15px;
          background-color: transparent;
          backdrop-filter: blur(6px);
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .text {
          font-weight: 700;
          font-size: 75px;
          line-height: 112px;
          text-align: center;
          color: #ffffff;
        }
    
        .center {
          height: 220px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 20px;
        }
      }
    
      .price_container {
        position: absolute;
        top:70%;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
    
        max-width: 100%; 
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        .price {
          font-weight: 700;
          font-size: 75px;
          line-height: 112px;
        }
        .old {
          color: color.$red-color;
          text-decoration-line: line-through;
        }
        .new {
          color: color.$green-color;
        }
        .arrow {
          color: white;
          width: 114px;
          height: 90px;
          margin: 0 100px;
        }
      }
      .view_details_container{
        position: absolute;
        top:35%;
        left: 51%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        .view_details{
          padding: 5px 19px;
        background: color.$error-color;
        border: none;
        border-radius: 10px;
        font-weight: 500;
        font-size: 22px;
        line-height: 150%;
        color: white;
        cursor: pointer;
        &:hover{
          background-color: #fa3e3e;
        }
        }
      }
      @media screen and (max-width: 850px) {
        .title {
          font-size: 42px;
        }
        .product__title {
          font-size: 52.5px;
        }
        .time_container {
          .time_square {
            width: 154px;
            height: 154px;
          }
          .flip_clock {
            --fcc-flip-duration: 0.5s; 
            --fcc-digit-block-width: 70px; 
            --fcc-digit-block-height: 70px; 
            --fcc-digit-font-size: 50px;
            --fcc-digit-color: white; 
            --fcc-background:#0298a6; 
            --fcc-label-color: #ffffff;
            --fcc-divider-color: #ffffff66;
          }
          .text {
            font-size: 52.5px;
          }
          .center {
            height: 154px;
          }
        }
        .price_container {
          .price {
            font-size: 52.5px;
          }
        }
      }
      
      @media screen and (max-width: 690px) {
        .title {
          font-size: 29.4px;
        }
        .product__title {
          font-size: 36.75px;
        }
        .time_container {
          .time_square {
            width: 107.8px;
            height: 107.8px;
          }
          .flip_clock {
            --fcc-flip-duration: 0.5s; 
            --fcc-digit-block-width: 50px; 
            --fcc-digit-block-height: 50px; 
            --fcc-digit-font-size: 40px;
            --fcc-digit-color: white; 
            --fcc-background:#0298a6; 
            --fcc-label-color: #ffffff;
            --fcc-divider-color: #ffffff66;
          }
          .center {
            height: 107.8px;
          }
          .text {
            font-size: 36.75px;
          }
        }
        .price_container {
          .price {
            font-size: 30px;
          }
          .arrow {
            width: 79.5px;
            height: 63px;
            margin: 0 75px;
          }
        }
      }
      
      @media screen and (max-width: 520px) {
        .title {
          font-size: 20.58px;
        }
        .product__title {
          font-size: 25.725px;
        }
        .time_container {
          .time_square {
            width: 75.46px;
            height: 75.46px;
          }
          .flip_clock {
            --fcc-flip-duration: 0.5s; 
            --fcc-digit-block-width: 30px; 
            --fcc-digit-block-height: 40px; 
            --fcc-digit-font-size: 35px;
            --fcc-digit-color: white; 
            --fcc-background:#0298a6; 
            --fcc-label-color: #ffffff;
            --fcc-divider-color: #ffffff66;
          }
          .center {
            height: 75.46px;
          }
          .text {
            font-size: 25.725px;
          }
        }
        .price_container {
          .price {
            font-size: 25px;
          }
          .arrow {
            width: 40px;
            margin: 0 50px;
          }
        }
      }
      
      
    }
  
    }
}
}
.bg {
  height: 515.04px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  .countdown {
    font-size: 40px;
    font-weight: 600;
    color: white;
  }
}
.bg2 {
  height: 515.04px;
  width: auto;
  background-color: gray;
  background-size: cover;
}
.flex {
  display: flex;
  .btn {
    font-size: 30px;
    color: rgb(104, 93, 93);
    z-index: 2;
    cursor: pointer;
    font-weight: bolder;
    
  }
  .left {
    position: absolute;
    top: 50%;
    right: 1px;
    width:33px;
    height: 70px;
    border-radius: 50px  0 0 50px ;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.379);
    display: flex; 
    align-items: center; 
    justify-content: center; 
    padding-left: 5px;
    &:hover{
      background-color: white;
    }
  }
  .right {
    position: absolute;
    top: 50%;
    left: 1px;
    transform: translateY(-50%);
    width:33px;
    height: 70px;
    border-radius:0 50px 50px 0;
    background-color: rgba(255, 255, 255, 0.379);
    display: flex; 
    align-items: center; 
    justify-content: center; 
    padding-right: 5px;
    &:hover{
      background-color: white;
    }  
  }
}
  @media screen and (max-width: 850px) {
    .flex {    
      .btn {
        font-size: 78px;
      }
  }
}
  @media screen and (max-width: 650px) {
    .flex {    
      .btn {
        font-size: 60px;
      }
  }
}
  @media screen and (max-width: 480px) {
    .flex {    
      .btn {
        font-size:35px;
      }
  }
}
//
  
