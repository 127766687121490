@use "../../../../styles/variables.scss" as color;
.favorite_container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  .skip {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 16.8px;
    line-height: 25.2px;
    color: color.$dark-text-color;
    cursor: pointer;
    &.skipDark{
      color:color.$white-text-color;
    }
  }
  .logoCont{
    justify-content: center;
    align-items: center;
    display: flex;
  .logo {
    margin-top: 45px;
    font-weight: 500;
    font-size: 28px;
    line-height: 42px;
    text-align: center;
    color: color.$primary-color;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
    transform: scale(2.5);
  }
}
  .subtitle {
    font-weight: 500;
    font-size: 19.6px;
    line-height: 29.4px;
    text-align: center;
    color: color.$dark-text-color;
    margin-top: 15.4px;
  }
  .text {
    margin-top: 9.1px;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    line-height: 21px;
    color: #a9a9a9;
    max-width: 70%;
  }
  .cards_container {
    margin-top: 15.4px;
    display: flex;
    width: 90%;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    max-height: 230px;
    overflow-y: scroll;
  }
  .done {
    background: #0298a6;
    border-radius: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 19.2px;
    line-height: 28.8px;
    color: white;
    padding: 8px 16px;
    margin-top: 20px;
    margin-bottom: 25px;
    &.doneDark{
      background-color: #015866;
    }
  }
  @media screen and (max-width: 750px) {
    .cards_container {
      overflow-y: scroll;
    }
  }
  @media screen and (max-width: 500px) {
    .logo {
      margin-top: 20px;
    }
    .subtitle {
      margin-top: 10px;
    }
    .text {
      margin-top: 7px;
      max-width: 90%;
    }
  }
}
