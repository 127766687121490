@use "../../../../styles/variables.scss" as color;
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    color: color.$dark-text-color;
    margin-top: 36px;
    margin-bottom: 45px;
    &.titleDark{
      color:white
    }
  }
  .category__container {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: color.$dark-text-color;
    display: flex;
    gap: 50px;
    .category__item {
      padding: 0 18px;
      margin-bottom: 45px;
      cursor: pointer;
      &.category__itemDark{
        color:white
      }
      &.active {
        border-bottom: 2px solid color.$primary-color;
        &.activeDark{
          border-bottom: 2px solid #015866;
        }
      }
    }
  }
  .cards__container {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    // justify-content: center;
    gap: 80px;
    // margin-bottom: 170px;
  }
  @media screen and (max-width: 1608px) {
    .cards__container {
      gap: 40px;
    }
  }
  @media screen and (max-width: 870px) {
    .cards__container {
      gap: 20px;
    }
  }
  @media screen and (max-width: 800px) {
    .title {
      font-size: 25.2px;
      line-height: 37.8px;
      margin-top: 25.2px;
      margin-bottom: 36px;
    }
    .category__container {
      font-size: 14px;
      line-height: 21px;
      color: color.$dark-text-color;
      display: flex;
      gap: 35px;
      .category__item {
        padding: 0 12.6px;
        margin-bottom: 25.2px;
      }
    }
  }
  @media screen and (max-width: 640px) {
    .cards__container {
      gap: 60px;
      justify-content: center;
    }
  }
  @media screen and (max-width: 500px) {
    .category__container {
      gap: 15px;
    }
  }
  // @media screen and (max-width: 400px) {
  //   .category__container {
  //     gap: 0px;
  //   }
  // }
  @media screen and (max-width: 440px) {
    .cards__container {
      flex-direction: column;
      align-items: center;
    }
  }
}
