@use "../../../../../styles/variables.scss" as color;
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  .image_container {
    background: color.$light-background-color;
    border: 3.5px solid #ace0e0;
    border-radius: 10px;
    width: 99.4px;
    height: 99.4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &.image_containerDark{
      background-color: #015866;
    }
    .img {
      max-width: 80%;
      max-height: 80%;
    }
  }
  &.active {
    .image_container {
      border: 3.5px solid color.$primary-color;
    }
  }
  .title {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #666666;
    margin-top: 10px;
  }
}
