@use "../../../../styles/variables.scss" as color;
.Roverlay {
    position: fixed;
    background-color: rgba(0, 0, 0, 0);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2000;
  }
  
  .Rmodal_styles {
    position: fixed;
    top: 50%;
    left:50%;
    transform: translate(-50%, -50%);
    z-index: 2001;
    background-color: white;
    width: 300px;
    height: 450px;
    box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    
  .Rcontainer{
    margin-top: 5px;
    display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
    .Rclose {
      cursor: pointer;
      position: absolute;
      top: 3px;
      right: 13.3px;
      width: 25px;
      height: 25px;
      &.closeDark{
        background-color:#98dae0;
        border-radius: 50%;
      }
     
  
    }
.Rtitle {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    
  }
  
  .checkbox-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
   
    label {
      margin-bottom: 10px;
      font-weight: 500;
    }
  }
  input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid #ccc;
    outline: none;
    margin-right: 10px;
  }
  input[type="radio"]:checked {
    background-color: #0298A6;
  }
  .inp {
    width: 80%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  .checkb{
    margin-right: 10px;
  }
  .submit {
    background-color: #0298A6;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    &:hover{
      background: #01b1c0;    }
    
  }
  .checked{
    background-color: gray;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: not-allowed;
   
  }
  }  
}
  .Rmodal_stylesDark {
    position: fixed;
    top: 50%;
    left:70%;
    transform: translate(-50%, -50%);
    z-index: 2001;
    background-color: #353844;
    width: 300px;
    height: 450px;
    box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    
  .Rcontainer{
    margin-top: 5px;
    display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
    .Rclose {
      cursor: pointer;
      position: absolute;
      top: 3px;
      right: 13.3px;
      width: 25px;
      height: 25px;
      &.closeDark{
        background-color:#98dae0;
        border-radius: 50%;
      }
     
  
    }
.RtitleDark {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    color: white;
    
  }
  .RlbDark{
    color:white;
  }
  
  .radio-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
   
    label {
      margin-bottom: 10px;
      font-weight: 500;
    }
  }
  input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid #ccc;
    outline: none;
    margin-right: 10px;
  }
  input[type="radio"]:checked {
    background-color: #0298A6;
  }
  .inpDark{
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    color:rgb(207,203,203);
    background-color: #015866;
  }
  .checkb{
    margin-right: 10px;
  }
  // .submitDark {
  //   background-color: #015866;
  //   color: #fff;
  //   border: none;
  //   padding: 10px 20px;
  //   border-radius: 5px;
  //   cursor: pointer;
    
  // }
  }  
}
@media screen and (max-width: 700px) {
    .Rmodal_styles{
        left: 50%;
    }
    .Rmodal_stylesDark{
        left: 50%;
    }
}