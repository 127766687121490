.container {
  margin-top: 45px;
  // margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  .wrapper {
    background: #f8f8f9;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 745px;
    height: 667px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    &.wrapperDark{
      background-color: #282828;
    }
    .input_container {
      position: relative;
      margin-top: 35px;
      .input {
        width: 423px;
        height: 42px;
        background: #f8f8f9;
        box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        padding-left: 46px;
        &.inputDark{
          color: rgb(207,203,203);
          background-color: #015866 ;
        }
      }
      .icon {
        position: absolute;
        top: 50%;
        left: 11px;
        transform: translateY(-50%);
      }
    }
    .title {
      margin-top: 35px;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: #353844;
      &.titleDark{
        color:white
      }
    }
    .map {
      margin-top: 35px;
      width: 423px;
      height: 286px;
    }
    .default_container {
      margin-top: 35px;
      width: 423px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .subtitle {
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        color: #353844;
        &.subtitleDark{
          color: white;
        }
      }
    }
    .save_btn {
      margin-top: 45px;
      background: #0298a6;
      border-radius: 10px;
      padding: 9px 82px;
      margin-bottom: 5%;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      color: #ffffff;
      &.save_btnDark{
        background-color: #015866;
      }
    }
    .saveload_btn {
      margin-top: 45px;
      background: gray;
      border-radius: 10px;
      padding: 9px 82px;
      margin-bottom: 5%;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      color: #ffffff;
    }
  }
  @media screen and (max-width: 550px) {
    .wrapper {
      .input_container {
        margin-top: 28px;
        .input {
          width: 338.4px;
          padding-left: 36.8px;
        }
        .icon {
          left: 8.8px;
        }
      }
      .title {
        margin-top: 28px;
        font-size: 19.2px;
        line-height: 28.8px;
      }
      .map {
        margin-top: 28px;
        width: 338.4px;
        height: 228.8px;
      }
      .default_container {
        margin-top: 28px;
        width: 338.4px;
        .subtitle {
          font-size: 16px;
          line-height: 120%;
        }
      }
      .save_btn {
        margin-top: 36px;
        padding: 7.2px 65.6px;
        font-size: 19.2px;
        line-height: 28.8px;
      }
    }
  }
  @media screen and (max-width: 410px) {
    .wrapper {
      .input_container {
        margin-top: 25.2px;
        .input {
          width: 304.56px;
          padding-left: 33.12px;
        }
        .icon {
          left: 7.9px;
        }
      }
      .title {
        margin-top: 25.2px;
        font-size: 17.28px;
        line-height: 25.92px;
      }
      .map {
        margin-top: 25.2px;
        width: 304.56px;
        height: 205.92px;
      }
      .default_container {
        margin-top: 25.2px;
        width: 304.56px;
        .subtitle {
          font-size: 14.4px;
          line-height: 108%;
        }
      }
      .save_btn {
        margin-top: 32.4px;
        padding: 6.48px 59px;
        font-size: 17.28px;
        line-height: 23px;
      }
    }
  }
}
