@use "../../styles/variables.scss" as color;
.chat_page {
  display: flex;
  height: 100vh;
}
.left {
  flex: 1;
  background-color: #F8F8F9;
  margin-bottom: 5%;
  flex-direction: column;
  border-top: 2px solid #efefef;
  max-height: 600px;
  overflow-y: auto;
  border-right: 2px solid rgba(0, 0, 0, 0.2);
  margin-top: 0;
}
.right {
  flex: 2;
  border-right: 2px solid rgba(0, 0, 0, 0.2);
  max-height: 600px;
  .nochat {
    width: 30vw;
    height: auto;
  }
}
.right_open {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .nochat {
    width: 30vw;
    height: auto;
  }
}
.go_back_button{
  display: none;
}
@media screen and (max-width: 830px) {
  .chat_page {
    flex-direction: column;
  }
  
  .left.hidden {
    display: none;
  }
  .right {
    flex: initial;
    border-right: none;
    max-height: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  }
  .right_open {
    flex: initial;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .nochat {
    display: none;
  }
  .startchat{
    display: none;
  }
  .go_back_button{
    display:flex;
    color: color.$primary_color;
    font-size: large;
  }
}
