.negotiate {
  margin-top: 11px;
  width: 143px;
  height: 52px;
  background: #ffffff;
  border: 3px solid #0298a6;
  border-radius: 10px;
  font-weight: 500;
  font-size: 22px;
  line-height: 150%;
  color: #0298a6;
  margin-bottom: 35px;
  &:hover {
    border: 3px solid #01b1c0;
    color: #01b1c0;
  }
  &.negotiateDark {
    background-color: #006770;
    border: 3px solid white;
    color: white;
  }
}
.line {
  border-top: 2px solid #9d9d9d;
  width: 100%;
}
.coming_btn {
  width: 70%;
  height: 52px;
  border-radius: 10px;
  margin: 25px auto;
  font-weight: 500;
  font-size: 22px;
  line-height: 150%;
  color: white;
  
  &.now {
    background: #0298a6;
    &:hover{
      background-color: #01b1c0;
    }
  }
  &.sure {
    background: #006770;
    &:hover{
      background-color: #008592;
    }
  }
  .btn_icon {
    display: inline-block;
    margin-right: 10px;
  }
}
@media screen and (max-width: 1380px) {
  .negotiate {
    margin-top: 7.7px;
    width: 100.1px;
    height: 36.4px;
    font-size: 15.4px;
    line-height: 105%;
    margin-bottom: 24.5px;
  }
  .coming_btn {
    width: 75%;
    height: 36.4px;
    margin: 17.5px auto;
    font-size: 15.4px;
    line-height: 105%;
    .btn_icon {
      margin-right: 7px;
      width: 20px;
      height: 20px;
    }
    .acceptLoad {
      background-color: gray;
    }
  }
}
@media screen and (max-width: 520px) {
  .coming_btn {
    width: 85%;
  }
}