.chatheader {
    display: flex;
    align-items: center;
    border-top: 2px solid rgba(0, 0, 0, 0.2);
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    
  }
.chatheaderDark {
    display: flex;
    align-items: center;
    border-top: 2px solid rgba(203, 197, 197, 0.2);
    border-bottom: 2px solid rgba(203, 197, 197, 0.2);
    
  }
  
  .profile-pic-header {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 20px;
    margin-left: 10px;
    object-fit: cover;
    cursor: pointer;
  }
  .content-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
  }
  .detailsheader{
  .nameheader {
    font-size: 15px;
    font-weight: 700;
    padding-top: 15px;
  }
  .nameheaderDark {
    font-size: 15px;
    font-weight: 700;
    color: white;
    padding-top: 15px;
  }
  
  .last-seenheader {
    font-size: 10px;
    color: #888;
    margin-top: -10px;
    margin-bottom: 3px;
  }
  .last-seenheaderDark {
    font-size: 10px;
    color: rgb(226, 226, 226);
    margin-top: -10px;
    margin-bottom: 3px;
  }
  .last-seenheader {
    font-size: 10px;
    color: #888;
    margin-top: -10px;
    margin-bottom: 3px;
  }
}
.icons-containerh {
    display: flex;
.iconh {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    color:#0298a6;
    cursor: pointer;
  }
.iconhDark {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    color:#015866;
    cursor: pointer;
  }
}
.dotDrop{
  position: absolute;
  top: 100%;
  left: -200px;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 200px;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.dotDrop button {
  width: 100%;
  text-align: left;
  padding: 5px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  .edit{
      font-size: 15px;
      font-weight: 500;
      display: inline-flex;
      align-items: center;
      margin: 0;
      padding: 0;
      .ic{
          margin-right:10px ;
      }
  }
  .delete{
      font-size: 15px;
      font-weight: 500;
      color: red;
      display: inline-flex;
      align-items: center;
      margin: 0;
      padding: 0;
      .icd{
          margin-right:10px ;
          color: red;
      }
  }
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  width: 400px;
}
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
textarea {
  width: 100%;
  resize: vertical;
}
button[type='submit'] {
  margin-top: 10px;
}
.confirm_message {
  font-weight: 500;
  font-size: 19.2px;
  line-height: 28.8px;
  margin-bottom: 15px;
  &.confirm_messageDark{
      color:white
  }
  span{
      font-weight: 600;
      color:#ba2c2d;
  }
}
.buttons_container {
  display: flex;
  gap: 20px;
  button {
    border-radius: 10px;
    padding: 10.4px 35.2px;
    font-weight: 600;
    font-size: 19.2px;
    line-height: 28.8px;
    color: #ffffff;
  }
  .confirm {
    background-color:  #0298a6;
  }
  .cancel {
    background-color: #ba2c2d;
  }
}