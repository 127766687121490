@use "../../styles/variables.scss" as color;
.noti_section {
  padding: 22px 65px;
  &.noti_sectionDark{
  background-color: color.$dark-text-color;
  }
  .sets{
    display: flex;
    float: right;
    align-items: end;
    justify-content: space-between;
    text-decoration: underline;
    .butsets{
      color: color.$primary-color;
      font-weight: 500;
      text-decoration: underline;
      display: flex;
      text-align: center;
      justify-content: center;
      position: absolute;
      right:50px;
      .ic{
        text-decoration: underline;
      display: flex;
      text-align: center;
      justify-content: center;
      margin-top: 5px;
      margin-right: 5px;
      }
    }
  }
  .container {
    margin: 1rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      font-weight: 600;
      font-size: 36px;
      line-height: 54px;
      color: color.$dark-text-color;
      &.titleDark{
        color:white;
      }
    }
    .img {
      max-width: 400px;
      margin-top: 45px;
    }
    .subtitle {
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      color: color.$dark-text-color;
      margin-top: 23px;
      &.subtitleDark{
        color:white;
      }
    }
    .text {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: color.$light-gray-color;
      margin-top: 9px;
      text-align: center;
    }
    .btn {
      margin-top: 50px;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      color: white;
      padding: 9px 149px;
      background: color.$primary-color;
      border-radius: 10px;
      &.btnDark{
        background-color: #015866;
      }
      &:hover{
        background-color: #01b1c0;
      }
    }
  
  .notification{
    // margin-top: 50px;
    display: flex;
    background-color: white;
    text-decoration: none;
    // height: 80px;
    padding: 20px;
    align-items: center;
    cursor: pointer;
    color: black;
    border-bottom: 1px solid rgba(0, 0, 0, 0.333);
    &.notificationDark{
      background-color: color.$dark-text-color;
      border-bottom: 1px solid white;
    }
       .imgnot{
        margin-right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        .imgn{
            width: 30px;
            height: 30px;
            padding: 0px;
            align-items: center;
            justify-content: center;
            &.imgnDark{
              color:white
            }
        }
       }
       .notifyCont{
        display: block;
        width: 300px;
       .notificationTitle{
        font-weight: 600;
        font-size: 15px;
        &.notificationTitleDark{
          color: white;
        }
       }
       .notificationContent{
        font-weight: 400;
        color: #5d5c5c;
        font-size: 13px;
        &.notificationContentDark{
          color: white;
        }
       }
      }
       .time{
        font-weight: 600;
        font-size: 10px;
        width: 100px;
        color: #0298a6;
        padding:15px ;
       }
      }
    }
    @media (max-width: 1000px){
      .container{
        width: 100%;
        .notification{
           width:100%;
            display: flex;
            flex-direction: column;
            align-items: start;
            
            .imgnot {
                display: flex;
                width: 60px;
                height: 60px;
                justify-content: flex-start;
                .imgn {
                    display: flex;
                    flex-direction: column;
                    width: 40px;
                    height: 40px;
                }
              }
              
            .time{
              display: flex;
              margin-left: -12px;
          }
        }
            
        }
    }
    @media (max-width: 380px){
        .header{
            display: block;
        }
    
        }
      
      
  @media screen and (max-width: 620px) {
    .sets{
      float: left;
    }
    .container {
      .title {
        font-size: 28.8px;
        line-height: 43.3px;
      }
      .img {
        margin-top: 36px;
      }
      .subtitle {
        font-size: 19.2px;
        line-height: 28.8px;
        margin-top: 18.4px;
      }
      .text {
        font-size: 16px;
        line-height: 24px;
        margin-top: 7.2px;
      }
      .btn {
        margin-top: 40px;
        font-size: 19.2px;
        line-height: 28.8px;
        padding: 9px 119.2px;
      }
      .notification{ 
           .notifyCont{
            display: block;
            width: 300px;
           .notificationTitle{
            max-width: 250px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
           }
           .notificationContent{
            max-width: 300px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
           }
          }
          }
    }
  }
  @media screen and (max-width: 440px) {
    .img {
      width: 300px;
    }
    .container {
      .notification{ 
           .notifyCont{
           .notificationTitle{
            max-width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
           }
           .notificationContent{
            max-width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
           }
          }
          }
    }
  }
}
