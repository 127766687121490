@use "../../../styles/variables.scss" as color;
.date_container {
  background: #f8f8f9;
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 462px;
  height:100px;
  position: relative;
  &.date_containerDark{
    background: #015866;
  }
  .wrapper {
    padding: 0 45px;
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 7px;
      .title {
        font-weight: 600;
        font-size: 60px;
        line-height: 100px;
        color: color.$primary-color;
      }
    
    .full_date {
      font-weight: 600;
      font-size: 32px;
      color: #6f6f6f;
      .day{
        color: black;
      }
      &.full_dateDark{
        color:color.$white-text-color
      }
    }
    }
}
@media screen and (max-width: 600px){
    width: 250px;
    .wrapper {  
      .flex {
        .title {
          font-size: 40px;
        }
      .full_date {
        font-size: 16px;
      }
      }
  }
}
@media screen and (max-width: 400px){
    .wrapper {  
      padding: 0;
  }
}
}