.container {
    width: 90%;
    height: 100%;
    // background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .image_section {
        .label {
            font-weight: 600;
            font-size: 25px;
            color: #0298a6;
            display: flex;
            justify-content: center;
        }
        .wrapper {
            display: flex;
            margin-top: 45px;
            column-gap: 41px;
            row-gap: 52px;
            flex-wrap: wrap;
            .image_container {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100px;
                height: 100px;
                background: #f8feff;
                border: 2.6px solid #ace0e0;
                border-radius: 5.3px;
                position: relative;
                &.image_containerDark {
                    background-color: #015866;
                }
                .remove_button {
                    position: absolute;
                    top: -10px;
                    right:-5px;
                    background-color: #e76859;
                    color: white;
                    border-radius: 50%;
                    width: 30px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: bold;
                    cursor: pointer;
                  }
                .image {
                    width: 70%;
                }
                .full_image {
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
        .phb {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 50px;
            .addphotos {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #0298a6;
                color: white;
                font-size: 15px;
                font-weight: 600;
                width: 180px;
                height: 40px;
                border-radius: 10px;
                &:hover{
                    background: #04b7c7;
                }
            }
            .addphotosLoading {
                display: flex;
                align-items: center;
                justify-content: center;
                background: gray;
                color: white;
                font-size: 15px;
                font-weight: 600;
                width: 180px;
                height: 40px;
                border-radius: 10px;
            }
        }
    }
    .compsection {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 50px;
        .label {
            color: #0298a6;
            font-weight: 600;
            font-size: 25px;
            margin-bottom: 30px;
        }
        .cmptype {
            .label {
                color: black;
                font-size: medium;
                font-weight: 500;
                margin-right: 30px;
            }
            .input {
                background: #ffffff;
                border: 1px solid #d9d9d9;
                border-radius: 10px;
                width: 200px;
                height: 30px;
                line-height: 32px;
                padding-left: 20px;
                margin-bottom: 20px;
            }
            .compadd {
                background: #0298a6;
                color: white;
                border-radius: 10px;
                width: 80px;
                height: 30px;
                margin-left: 30px;
                    &:hover{
                        background: #04b7c7;
                    }
                
            }
            .adding {
                background: gray;
                color: white;
                border-radius: 10px;
                width: 80px;
                height: 30px;
                margin-left: 30px;
            }
        }
    }
    .faqinputs {
        display: flex;
        flex-direction: column;
        .faqinput {
            background: #ffffff;
            border: 1px solid #d9d9d9;
            border-radius: 10px;
            height: 35px;
            line-height: 32px;
            padding-left: 20px;
            margin-bottom: 20px;
        }
        .faqarea {
            background: #ffffff;
            border: 1px solid #d9d9d9;
            border-radius: 10px;
            padding-left: 20px;
        }
        .adbt {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 5px;
            .compadd {
                background: #0298a6;
                color: white;
                border-radius: 10px;
                width: 80px;
                height: 30px;
                margin-left: 30px;
                margin-bottom: 10px;
                &:hover{
                    background: #04b7c7;
                }
            }
            .adding {
                background: gray;
                color: white;
                border-radius: 10px;
                width: 80px;
                height: 30px;
                margin-left: 30px;
                margin-bottom: 10px;
            }
            .dlt{
                background: #ba2c2d;
                color: white;
                border-radius: 10px;
                width: 80px;
                height: 30px;
                margin-left: 30px;
                margin-bottom: 10px;
                &:hover{
                    background: #d12e2e;
                }
            }
        }
    }
    @media screen and (max-width: 615px) {
        .compsection {
            .cmptype {
                display: flex;
                flex-direction: column;
                .compadd {
                    background: #0298a6;
                    color: white;
                    border-radius: 10px;
                    width: 80px;
                    height: 30px;
                    margin-left: 65px;
                    margin-bottom: 30px;
                }
            }
        }
    }
}