@use "../../../styles/variables.scss" as color;
$search-bar-height: 100px;
$search-bar-padding: 20px;
.search_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .center {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .result {
    display: block;
  }
  .chips_container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 10px;
    .chip {
      background-color: color.$light-gray-color;
      color: color.$primary-color;
      border-radius: 10px;
      font-size: 16px;
      font-weight: 600;
      margin-right: 10px;
      margin-bottom: 10px;
      height: 35px;
      padding: 10px;
      display: flex;
      align-items: center;
      &.selected_chip {
        background-color: transparent;
        color: color.$primary-color;
        border: 2px solid;
      }
      .chip_remove_button {
        background-color: transparent;
        border: none;
        color: inherit;
        cursor: pointer;
        font-size: 16px;
        margin-left: 10px;
      }
    }
  }
  .input_container {
    display: flex;
    position: relative;
    .search_input {
      width: 939px;
      height: 73px;
      background: color.$light-background-color;
      border: 1px solid color.$light-gray-color;
      box-shadow: color.$box-shadow-input;
      border-radius: 10px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      padding-left: 62px;
      font-size: 24px;
      &.search_inputDark {
        background: #015866;
        border: 1px solid #014550;
        color: color.$light-gray-color;
      }
      &.search_inputImported {
        width: 300px;
        height: 45px;
        border-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        box-shadow: none;
        background-color: transparent;
        font-size: 18px;
        padding-left: 10px;
      }
    }
    .search_icon {
      position: absolute;
      top: 26.5px;
      left: 17.25px;
      color: color.$light-gray-color;
      width: 21.38px;
      height: 21.38px;
      &.search_iconImported {
        display: none;
      }
    }
    .search_button {
      width: 209px;
      height: 73px;
      background: color.$primary-color;
      box-shadow: color.$box-shadow-input;
      border-radius: 0px 10px 10px 0px;
      font-weight: 600;
      font-size: 32px;
      line-height: 48px;
      text-align: center;
      color: color.$white-text-color;
      &.search_buttonDark {
        background-color: #014550;
      }
      &.search_buttonImported {
        display: none;
      }
      &:hover {
        background: #01b1c0;
      }
    }
  }
  .dropdown {
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    border: 1px solid color.$light-gray-color;
    border-top: none;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: color.$box-shadow-input;
    z-index: 1;
    &.dropdownDark {
      background-color: color.$dark-text-color;
    }
    &.dropdownImported {
      width: 300px;
      max-height: 200px;
      overflow-y: auto;
    }
    .result {
      display: block;
      padding: 10px;
      font-size: 20px;
      line-height: 1.2;
      border-bottom: 1px solid color.$light-gray-color;
      text-decoration: none;
      color: color.$primary-color;
      &.resultDark {
        color: #015866;
      }
      &:last-of-type {
        border-bottom: none;
      }
      &:hover {
        background-color: color.$light-gray-color;
        cursor: pointer;
      }
    }
  }
  @media only screen and (max-width: 1300px) {
    .input_container {
      .search_input {
        width: 563.4px;
        height: 60px;
      }
      .search_icon {
        position: absolute;
        top: 21px;
        left: 15px;
      }
      .search_button {
        width: 125.4px;
        height: 60px;
        margin-left: -5px;
        font-size: 19.2px;
      }
    }
  }
  @media screen and (max-width: 750px) {
    .input_container {
      .search_input {
        width: 453.5px;
        height: 55px;
        font-size: 17px;
        padding-left: 50px;
      }
      .search_icon {
        top: 21px;
        width: 16px;
        height: 16px;
      }
      .search_button {
        width: 100.9px;
        height: 55px;
        margin-left: -5px;
        font-size: 15px;
        line-height: normal;
      }
    }
  }
  @media screen and (max-width: 610px) {
    .input_container {
      .search_input {
        width: 385.5px;
        height: 47px;
        padding-left: 45px;
      }
      .search_button {
        width: 85.8px;
        height: 47px;
      }
      .search_icon {
        top: 16px;
      }
    }
  }
  @media screen and (max-width: 480px) {
    .input_container {
      .search_input {
        width: 295.1px;
        height: 45px;
        font-size: 15px;
        padding-left: 40px;
        &.search_inputImported {
          width: 200px;
          height: 45px;
          border-radius: 10px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          box-shadow: none;
          background-color: transparent;
          font-size: 18px;
          padding-left: 10px;
        }
      }
      .search_button {
        width: 65.6px;
        height: 45px;
        font-size: 13px;
      }
      .search_icon {
        top: 16px;
        width: 15px;
        height: 15px;
      }
    }
  }
  @media screen and (max-width: 330px) {
    .input_container {
      .search_input {
        width: 230px;
        height: 45px;
        font-size: 15px;
      }
      .search_button {
        width: 65.6px;
        height: 45px;
        font-size: 13px;
      }
      .search_icon {
        top: 16px;
        width: 15px;
        height: 15px;
      }
    }
  }
}
.categoryTitleBlack {
  color: #015866;
  &.resultDark {
    color: color.$primary-color;
  }
}