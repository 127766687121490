@use "../../../../styles/variables.scss" as color;
.overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3000;
}
.modal_styles {
  position: fixed;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  z-index: 3001;
  background-color: white;
  width: 531.2px;
  height: 700px;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  animation-name: animatem;
  animation-duration: 2s;
  &.modal_stylesDark{
    background-color: color.$dark-text-color;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .icon {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 19px;
      height: 19px;
      color: #000000;
      cursor: pointer;
      &.iconDark{
        color: white;
      }
    }
    .title {
      margin-top: 49.6px;
      font-weight: 600;
      font-size: 28.8px;
      line-height: 43.2px;
      text-align: center;
      color: #353844;
      margin-bottom: 21.6px;
      &.titleDark{
        color: color.$white-text-color;
      }
    }
    .coming {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 19.2x;
      line-height: 120%;
      margin-top: 38px;
      .coming_icon {
        margin-right: 4px;
        width: 19.176px;
        height: 32.016px;
      }
      .comingsure_icon {
        margin-right: 4px;
        width: 25.256px;
        height: 31.2px;
      }
      color: #0298a6;
      span {
        color: #006770;
        margin-left: 8px;
      }
    }
    .text {
      margin-top: 29.6px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      max-width: 348px;
      color: #353844;
      &.textDark{
        color: color.$white-text-color;
      }
    }
    .waiting {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #0298a6;
      margin-top: 18px;
    }
    .waiting_text {
      margin-top: 8.8px;
      max-width: 339.2px;
      font-weight: 400;
      font-size: 12.8px;
      line-height: 19.2px;
      text-align: center;
      color: #a1a1a1;
    }
    .btn_container {
      margin-top: 30px;
      width: 70%;
      display: flex;
      justify-content: space-between;
      button {
        width: 160px;
        height: 50.104px;
        border-radius: 10px;
        font-weight: 500;
        font-size: 19.2px;
        line-height: 28.8px;
      }
      .shopping {
        background: #0298a6;
        color: white;
      }
      .cancel {
        color: #0298a6;
        border: 4px solid #0298a6;
      }
    }
  }
  @media screen and (max-width: 630px), screen and (max-height: 700px) {
    width: 424.96px;
    height: 600px;
    .container {
      .title {
        margin-top: 39.68px;
        font-size: 23.04px;
        line-height: 34.56px;
        margin-bottom: 17.28px;
      }
      .coming {
        font-size: 15.36x;
        line-height: 96%;
        margin-top: 30.4px;
      }
      .text {
        margin-top: 23.68px;
        font-size: 12.8px;
        line-height: 19.2px;
        max-width: 348px;
      }
      .waiting {
        font-size: 12.8px;
        line-height: 19.2px;
        margin-top: 14.4px;
      }
      .waiting_text {
        margin-top: 7.04px;
        max-width: 271.36px;
        font-size: 10.24px;
        line-height: 15.36px;
      }
      .btn_container {
        margin-top: 37.12px;
        width: 70%;
        button {
          width: 128px;
          height: 40.08px;
          font-size: 15.36px;
          line-height: 23.04px;
        }
      }
    }
  }
  @media screen and (max-width: 440px) {
    width: 95vw;
    height: auto;
    .container {
      .btn_container {
        margin-bottom: 20px;
        width: 95%;
      }
    }
  }
}
@keyframes animatem {
  from {
    top: 50%;
    right: 100%;
  }
  to {
    top: 50%;
    right: 50%;
  }
}
