.card_container {
  width: 419px;
  height: 181px;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.card_containerOn {
  width: 419px;
  height: 181px;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.profile {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.text_container {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-top: 10px;
}
.primary_text {
  font-size: 15px;
}
.primary_texton {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  margin-left: 100px;
}
.secondary_text {
  font-size: 25px;
  font-weight: bold;
}
.added {
  color: #0298a6;
  font-weight: 600;
  margin-left: 40px;
}
.down {
  margin-left: -2.5px;
  margin-top: -30px;
}
.downb {
  margin-left: 2.5px;
  margin-top: -20px;
}
@media (min-width: 1550px) {
  .card_container {
    width: 30%;
    height: 181px;
    &.card_containerOn {
      width: 100%;
    }
  }
}
// @media (max-width: 1470px){
//   .card_container {
//     width: 100%;
//     height: 181px;
//   }
// }
@media (max-width: 600px) {
  .card_container {
    width: 250px;
    height: 181px;
  }

  .primary_text {
    font-size: 13px;
  }
  .primary_texton {
    font-weight: 600;
    margin-left: 50px;
  }
  .secondary_text {
    font-size: 18px;
    font-weight: bold;
  }
  .added {
    color: #0298a6;
    font-weight: 600;
    margin-left: 10px;
    font-size: 8px;
  }
  .down {
    margin-left: -1px;
    margin-top: -30px;
    width: 250px;
    height: 100px;
  }
  .downb {
    margin-left: 1px;
    margin-top: -15px;
    width: 250px;
    height: 100px;
  }
}

.test {
  cursor: pointer;
}
