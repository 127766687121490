.search_chat {
  display: flex;
  flex-direction: column;
  align-items: center;
   
}
.search_input {
  display: flex;    
  position: relative;
  width: 20vw;
.searchinp{
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  margin-top: 20px;
  align-items: center;
  padding-left: 100px;
  border: none;
 
}
.searchinpDark{
  box-shadow: 1px 2px 4px rgba(24, 24, 24, 0.2);
  margin-bottom: 10px;
  margin-top: 20px;
  align-items: center;
  padding-left: 100px;
  border: none;
  background-color: #015866 ;
  color:rgb(207,203,203);
}
.searchic{
      position: absolute;
      top: 33px;
      left: 5px;
      width:18px;
      height:18px;
      color: gray;
      
}
@media screen and (max-width:1000px) {
margin-left: -50px;
}
@media screen and (max-width:550px) {
margin-left: -100px;
}
@media screen and (max-width:400px) {
margin-left: -150px;
}
}
.search_input input {
  padding: 5px 10px;
  @media screen and (max-width:400px) {
    width: 200px;
    }
}
.button_container {
  display: flex;
  justify-content: center;
  width: 20vw;
  margin-bottom: 18px;
  
}
.button_containerDark {
  display: flex;
  justify-content: center;
  width: 20vw;
  margin-bottom: 18px;
  color: white;
  
}
.button_container button.active {
  border-bottom: 2px solid #0298a6;
  
}
.button_containerDark button.active {
  border-bottom: 2px solid #0298a6;
  
}
.button_container button {
  margin-right: 15%;
}
.button_containerDark button {
  margin-right: 15%;
}
