.overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
}
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2001;
  background-color: white;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  // width: 716.8px;
  // height: 550.2px;
  padding: 30px;
  &.modalDark{
    background-color: #015866;
  }
  .close {
    position: absolute;
    top: 13.3px;
    right: 13.3px;
    width: 32px;
    height: 32px;
    cursor: pointer;
  }

/* Add these changes to your styles.module.scss file */

.buttons_container {
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  align-items: center; /* Center items horizontally */
  text-align: center; /* Center text within buttons */
  margin-top: 20px; /* Add some spacing from the message */
}

/* Center Confirm and Cancel buttons on mobile screens */
@media (max-width: 767px) {
  .buttons_container {
    flex-direction: row; /* Stack buttons horizontally on small screens */
    justify-content: center; /* Center buttons horizontally */
  }

  .buttons_container button {
    margin: 0 10px; /* Add some spacing between buttons */
  }
}

  @media screen and (max-width: 480px) {
    width: 95vw;
    height: auto;
  }
  @media screen and (max-width: 350px) {
    width: 100vw;
    height: auto;
  }
}
