@use "../../../../styles/variables.scss" as color;
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  .close {
    position: absolute;
    top: 13.3px;
    right: 13.3px;
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
  .back {
    position: absolute;
    top: 13.3px;
    left: 13.3px;
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
  .logoCont{
    justify-content: center;
    align-items: center;
    display: flex;
  .logo {
    margin-top: 45.5px;
    font-weight: 500;
    font-size: 28px;
    line-height: 42px;
    text-align: center;
    color: color.$primary-color;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
    transform: scale(2.5);
  }
}
  .form_container {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .label {
      align-self: flex-start;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: color.$dark-text-color;
      margin-bottom: 15px;
      &.labelDark{
        color:white;
      }
    }
    .email_container {
      position: relative;
      .input {
        width: 400px;
        height: 56px;
        border: 1px solid color.$border-category-dropdown;
        border-radius: 10px;
        padding-left: 20px;
        background: #f8f8f9;
        &.inputDark{
          background-color: #015866;
          color:rgb(207,203,203)
        }
      }
      .error_message {
        font-weight: 400;
        font-size: 11.2px;
        line-height: 16.8px;
        color: color.$error_color;
        position: absolute;
        left: 6px;
      }
      &.error_input {
        .input {
          border-color: color.$error_color;
        }
        .input::placeholder {
          color: color.$error_color;
        }
      }
    }
    .code_container {
      width: 400px;
      height: 56px;
      border: 1px solid color.$border-category-dropdown;
      border-radius: 10px;
      margin-top: 30px;
      position: relative;
      .code_input {
        background: #f8f8f9;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        padding-left: 20px;
        &:focus {
          outline: none;
        }
      }
      .send_btn {
        position: absolute;
        right: -1px;
        top: -1px;
        height: 56px;
        width: 170px;
        background: #ffffff;
        border: 1px solid color.$border-category-dropdown;
        border-radius: 0px 8px 8px 0px;
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        color: color.$dark-text-color;
        &:hover{
          background: color.$primary-color;
          color: white;
        }
      }
      .backend_error {
        position: absolute;
        top: -32px;
        left: 5px;
      }
    }
  }
  .confirm_btn {
    margin-top: 30px;
    width: 170px;
    height: 56px;
    left: 0px;
    top: 0px;
    background: color.$primary-color;
    border-radius: 10px;
    font-weight: 600;
    font-size: 20px;
    line-height: 36px;
    color: #ffffff;
    &.confirm_btnDark{
      background-color: #015866;
    }
    &:hover{
      background: #01b1c0;
    }
  }
  .register_text {
    margin-top: 40px;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #8d8d8d;
    &.register_textDark{
      color:white;
    }
    .register {
      text-decoration-line: underline;
      color: color.$primary-color;
      cursor: pointer;
      &.registerDark{
        color: #015866;
      }
    }
  }
  @media screen and (max-width: 750px) {
    .logo {
      margin-top: 40px;
    }
    .form_container {
      .label {
        font-size: 18px;
      }
      .confirm_btn {
        font-size: 18px;
      }
    }
    .register_text {
      font-size: 17px;
      margin-bottom: 14px;
    }
  }
  @media screen and (max-width: 540px) {
    .close {
      width: 22px;
      height: 22px;
    }
    .back {
      width: 22px;
      height: 22px;
    }
    .logo {
      font-size: 25.2px;
      line-height: 37.8px;
    }
    .form_container {
      .label {
        font-size: 14px;
        line-height: 21px;
      }
      .form_container {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .label {
          align-self: flex-start;
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;
          color: color.$dark-text-color;
          margin-bottom: 15px;
        }
      }
        .email_container {
          position: relative;
          .input {
            width: 300px;
            height: 50px;
            border: 1px solid color.$border-category-dropdown;
            border-radius: 10px;
            padding-left: 20px;
            background: #f8f8f9;
          }
          .error_message {
            font-weight: 400;
            font-size: 11.2px;
            line-height: 16.8px;
            color: color.$error_color;
            position: absolute;
            left: 6px;
          }
    
          &.error_input {
            .input {
              border-color: color.$error_color;
            }
            .input::placeholder {
              color: color.$error_color;
            }
          }
        }
      
      .code_container {
        font-size: 14px;
        line-height: 21px;
        width: 280px;
        height: 39.2px;
        .send_btn {
          height: 39.2px;
          width: 119px;
          font-size: 12.6px;
          line-height: 21px;
        }
      }
      .confirm_btn {
        font-size: 14px;
        line-height: 25.2px;
        width: 119px;
        height: 39.2px;
      }
    }
    .register_text {
      font-size: 14px;
      line-height: 21px;
    }
  }
  
  @media screen and (max-width: 400px) {
  
    .form_container {
      .label {
        font-size: 14px;
        line-height: 21px;
      }
      .form_container {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .label {
          align-self: flex-start;
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;
          color: color.$dark-text-color;
          margin-bottom: 15px;
        }
      }
        .email_container {
          position: relative;
          .input {
            width: 250px;
            height: 40px;
            border: 1px solid color.$border-category-dropdown;
            border-radius: 10px;
            padding-left: 20px;
            background: #f8f8f9;
          }
          .error_message {
            font-weight: 400;
            font-size: 11.2px;
            line-height: 16.8px;
            color: color.$error_color;
            position: absolute;
            left: 6px;
          }
    
          &.error_input {
            .input {
              border-color: color.$error_color;
            }
            .input::placeholder {
              color: color.$error_color;
            }
          }
        }
      
      .code_container {
        font-size: 14px;
        line-height: 21px;
        width: 280px;
        height: 39.2px;
        .send_btn {
          height: 39.2px;
          width: 119px;
          font-size: 12.6px;
          line-height: 21px;
        }
      }
      .confirm_btn {
        font-size: 14px;
        line-height: 25.2px;
        width: 119px;
        height: 39.2px;
      }
    }
    .register_text {
      font-size: 14px;
      line-height: 21px;
    }
  }
  
  
}
