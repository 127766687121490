@use "../../styles/variables.scss" as color;
.wrapper {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  .label {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: #353844;
    &.labelDark{
      color:white
    }
  }
  .title_container {
    margin-top: 98px;
    display: flex;
    align-items: center;
    column-gap: 108px;
    white-space: nowrap;
    .input_container {
      width: 90%;
      position: relative;
      .input {
        width: 100%;
        height: 58px;
        padding-left: 42px;
        background: #ffffff;
        border: 1px solid #d9d9d9;
        border-radius: 10px;
        &.inputDark{
          background-color: #015866 ;
          color:rgb(207,203,203);
        }
        &.error_input {
          border-color: color.$error_color;
          &::placeholder {
            color: color.$error_color;
          }
        }
      }
      .error_message {
        font-size: 15px;
        line-height: 21px;
        color: color.$error_color;
        position: absolute;
        left: 6px;
      }
    }
  }
  .desc_container {
    margin-top: 95px;
    .textarea_container {
      position: relative;
      .text_area {
        margin-top: 30px;
        height: 252px;
        width: 100%;
        background: #ffffff;
        border: 1px solid #c2c2c2;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        resize: none;
        padding: 20px 40px;
        &.text_areaDark{
          background-color: #015866 ;
          color:rgb(207,203,203);
        }
        &.error_input {
          border-color: color.$error_color;
          &::placeholder {
            color: color.$error_color;
          }
        }
      }
      .error_message {
        font-size: 15px;
        line-height: 21px;
        color: color.$error_color;
        position: absolute;
        left: 6px;
      }
    }
  }
  .condDisc{
    flex-direction: column;
    display: flex;
    flex-direction: column;
    align-items: center;
  .condition_container {
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .switch_container {
      margin-top: 10px;
    }
  }
  .discount_container{
    margin-top: 10px;
    margin-bottom: 0;
    margin-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .discount_container {
    margin-top: 20px;
    margin-bottom: 95px;
    margin-left: 30px;
    .switch_container {
      margin-top: 20px;
      display: flex;
      border: 2px solid #0298a6;
      border-radius: 15px;
      width: 316px;
      .switch_btn {
        width: 158px;
        height: 66px;
        color: black;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &.switch_btnDark{
          color: white;
        }
        &.active {
          background: #0298a6;
          color: white;
          border-radius: 12px;
          &.activeDark{
            background:#015866;
          }
        }
      }
    }
  }
}
  .price_container {
    margin-top: 95px;
    display: flex;
    gap: 30px;
    .input_container {
      position: relative;
      .input {
        width: 342px;
        height: 47px;
        background: #f8f8f9;
        border: 2px solid #0298a6;
        border-radius: 10px;
        padding-left: 91px;
        &.inputDark{
          background-color: rgb(207,203,203) ;
          // color:rgb(207,203,203);
          border: 2px solid #015866;
        }
        &.error_input {
          // border-color: color.$error_color;
          &::placeholder {
            color: color.$error_color;
          }
        }
      }
      .error_message {
        font-size: 15px;
        line-height: 21px;
        color: color.$error_color;
        position: absolute;
        left: 6px;
      }
      .currency {
        background: #0298a6;
        border-radius: 10px 0px 0px 10px;
        width: 74px;
        height: 47px;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: #f8feff;
        &.currencyDark{
          background-color: #015866;
        }
      }
    }
      @media screen and (max-width: 500px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top:10px;
        margin-bottom:-10px;
        .input_container{
        .input{
          width: 250px;
        }
      }
    }
    //   @media screen and (max-width: 500px) {
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    //     .label{
    //       margin-top: -50px;
    //     }
    //     .input_container{
    //       margin-top: -55px;
    //     .input{
    //       width: 250px;
    //     }
    //   }
    // }
    @media screen and (max-width: 360px) {
      .price_container{
      .input_container {
        margin-left: 0;
        .input {
        width: 300px;
      }
      }
    }
  }
    
  }
  .image_section {
    margin-top: 95px;
    .wrapper {
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      margin-top: 45px;
      column-gap: 41px;
      row-gap: 52px;
      flex-wrap: wrap;
      flex-direction: row;
      .image_container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 124px;
        height: 124px;
        background: #f8feff;
        border: 2.6px solid #ace0e0;
        cursor: pointer;
        border-radius: 5.3px;
        position: relative;
        &.image_containerDark{
          background-color: #015866;
        }
        .remove_button {
          position: absolute;
          top: -10px;
          right:-5px;
          background-color: #e76859;
          color: white;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          cursor: pointer;
        }
        .image {
          width: 70%;
        }
        .full_image {
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
  .location_section {
    margin-top: 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 44px;
    .location_container {
      width: 644px;
      height: 178px;
      background: #ffffff;
      border: 3px solid #0298a6;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      display: flex;
      align-items: center;
      padding: 22px 16px 15px 52px;
      gap: 46px;
      .image_container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 86px;
        height: 86px;
        border-radius: 50%;
        background: #0298a6;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        &.image_containerDark{
          background-color: #015866;
        }
        .img {
          height: 70%;
        }
      }
      .details_container {
        .title {
          font-weight: 600;
          font-size: 32px;
          line-height: 150%;
          color: #0298a6;
        }
        .text {
          font-weight: 400;
          font-size: 20px;
          line-height: 30px;
          color: #a1a1a1;
          max-width: 444px;
        }
      }
    }
  
  .vertical_line {
    height: 196.9px;
    border-left: 1px solid #000000;
  }
  .new_address_container {
    .new_address_text {
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      color: #000000;
      &.new_address_textDark{
        color: white;
      }
    }
    .location {
      margin-top: 42px;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      border-radius: 10px;
      width: 542px;
      height: 74px;
      padding-left: 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-weight: 500;
      font-size: 21px;
      line-height: 32px;
      color: #c2c2c2;
      position: relative;
      &.locationDark{
        background-color: transparent;
      }
      .icon {
        position: absolute;
        top: 50%;
        right: 24px;
        transform: translateY(-50%);
      }
     
    }
    .location:hover {
      background-color: transparent;
      color: #0298a6;
      cursor: pointer;
    }
    .dropdown{
      color: #0298a6;
      background-color: transparent;
      display: block;
      top: 100%;
      left: 0;
      z-index: 1;
      width: 542px;
      border: 1px solid #d9d9d9;
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      ul {   
        margin-top: 10px;
        .places {
          &:hover{
          color: #0077ff;
          }
          cursor: pointer;
          font-weight: 600;
          font-size: 20px;
          border-bottom: 1px solid rgb(231, 235, 237);
          
       }
       
    }
    .addloc{
      height: 40px;
      margin-top: 10px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    .add_button{
      border-radius: 50%;
      align-items: center;
      height: 20px;
      width: 20px;
      color: #0298a6;
      font-size: 15px;
      line-height: 15px;
      margin: 4px;
      cursor: pointer;
     }
    }
  }
  }
}
  .last_section {
    display: flex;
    justify-content: space-between;
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      
    }
    .labelt{
      font-weight: 600;
      margin-bottom: 15px;
      &.labeltDark{
        color: white;
      }
    }
    .datein{
      margin-bottom: 15px;
    }
    .text {
      margin-top: 17px;
      max-width: 599px;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: #4a4a4b;
      &.textDark{
        color: white;
      }
    }
  }
  @media screen and (max-width: 1370px) {
    .image_section {
      .wrapper{
        grid-template-columns: repeat(6, 1fr);
      }
    }
    .location_section {
      column-gap: 30.8px;
      .location_container {
        width: 450.8px;
        height: 124.6px;
        padding: 15.4px 11.2px 10.5px 36.4px;
        gap: 32.2px;
        .image_container {
          width: 60.2px;
          height: 60.2px;
        }
        .details_container {
          .title {
            font-size: 22.4px;
            line-height: 105%;
          }
          .text {
            font-size: 14px;
            line-height: 21px;
            max-width: 310.8px;
          }
        }
      }
      .vertical_line {
        height: 137.83px;
      }
      .new_address_container {
        .new_address_text {
          font-size: 14px;
          line-height: 21px;
        }
        .location {
          margin-top: 29.4px;
          width: 379.4px;
          height: 51.8px;
          padding-left: 16.8px;
          font-size: 14.7px;
          line-height: 22.4px;
        }
        .dropdown{
          width: 379px;
        }
      }
    }
    .last_section {
      flex-direction: column;
      gap: 35px;
      .vertical_line {
        display: none;
      }
    }
  }
  @media screen and (max-width: 1020px) {
    .image_section {
      .wrapper{
        grid-template-columns: repeat(4, 1fr);
      }
    }
    .location_section {
      flex-direction: column;
      .vertical_line {
        display: none;
      }
      .new_address_container {
        margin-top: 30px;
      }
      .dropdown{
        width: 380px;
      }
    }
  }
  @media screen and (max-width: 890px) {
    .condDisc{
      flex-direction: column;
      display: flex;
      flex-direction: column;
      align-items: center;
    .condition_container {
      margin-top: 35px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .switch_container {
        margin-top: 10px;
      }
    }
    .discount_container{
      margin-top: 10px;
      margin-bottom: 0;
      margin-left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
    .image_section {
      margin-top: 35px;
      .wrapper {
        margin-top: 10px;
        column-gap: 15px;
        row-gap: 10px;
        .image_container {
          width: 99.2px;
          height: 99.2px;
        }
      }
    }
  }
  @media screen and (max-width: 530px) {
    .category_container {
      flex-wrap: wrap;
      .category_input {
        margin-top: 20px;
        column-gap: 32px;
      }
    }
    .form_container {
      .form_input_wrapper {
        .input {
          width: 90vw;
        }
      }
    }
    .location_section {
      column-gap: 30.8px;
      .location_container {
        width: 315.56px;
        height: 87.22px;
        padding: 10.78px 7.84px 7.35px 25.48px;
        gap: 22.54px;
        .image_container {
          width: 42.14px;
          height: 42.14px;
        }
        .details_container {
          .title {
            font-size: 15.68px;
            line-height: 73.5%;
          }
          .text {
            font-size: 12px;
            line-height: 16px;
            max-width: 217.56px;
          }
        }
      }
      .new_address_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        .new_address_text {
          font-size: 14px;
          line-height: 21px;
        }
        .location {
          margin-top: 10px;
          width: 90vw;
        }
        .dropdown{
          width: 90vw;
        }
      }
    }
    .button_container {
      button {
        width: 166.4px;
        height: 48px;
        font-size: 23.04px;
        line-height: 34.56px;
        
      }
    }
  }
  @media screen and (max-width: 470px) {
    .image_section {
      .wrapper{
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
  @media screen and (max-width: 350px) {
    .image_section{
      .wrapper{
              margin-left: 50px;
              grid-template-columns: repeat(2, 1fr);
            }
    }
  }
    @media screen and (max-width: 730px) {
      margin-top: 76px;
      .input_container {
        .input {
          width: 342px;
          height: 37.6px;
          padding-left: 72.8px;
        }
        .currency {
          width: 59.2px;
          height: 37.6px;
          font-size: 19.2px;
          line-height: 28.8px;
        }
      }
    }
    @media screen and (max-width: 530px) {
      margin-top: 35px;
      flex-direction: column;
      gap: 10px;
    }
  
  .line {
    border-top: 1px solid #a1a1a1;
    margin: 95px auto;
    width: 80vw;
  }
  .timer_section {
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 590px;
    }
    .text {
      margin-top: 17px;
      max-width: 599px;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: #4a4a4b;
      @media screen and (max-width: 620px) {
        margin-top: 13.6px;
        max-width: 479.2px;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .post {
    align-self: center;
    margin-top: 59px;
    width: 260px;
    height: 75px;
    font-weight: 500;
    font-size: 36px;
    line-height: 54px;
    border-radius: 10px;
    background: #0298a6;
    color: #ffffff;
    margin-bottom: 50px;
   
    &:hover{
      background: #01b1c0;
    }
    &.postDark{
      background-color: #015866;
    }
  }
  .postload{
    background:gray;
    color: #ffffff;
    align-self: center;
    margin-top: 59px;
    width: 260px;
    height: 75px;
    font-weight: 500;
    font-size: 36px;
    line-height: 54px;
    border-radius: 10px;
    margin-bottom: 50px;
  }
  
 
  @media screen and (max-width: 730px) {
    .label {
      font-size: 25.6px;
      line-height: 38.4px;
    }
    .title_container {
      margin-top: 78.4px;
      column-gap: 86.4px;
      .input {
        height: 46.4px;
        padding-left: 42px;
      }
    }
    .desc_container {
      margin-top: 76px;
      .textarea_container {
        .text_area {
          margin-top: 24px;
          height: 201.6px;
          padding: 16px 32px;
        }
      }
    }
    .line {
      margin: 35px auto;
    }
  }
  @media screen and (max-width: 620px) {
    .title_container {
      flex-direction: column;
      .input {
        margin-top: 10px;
        width: 100%;
      }
    }
    .desc_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 35px;
      .textarea_container {
        width: 90%;
        .text_area {
          margin-top: 10px;
        }
      }
    }
    .price_container {
      width: 90%;
    }
    .post {
      margin-top: 35px;
      width: 208px;
      height: 60px;
      font-size: 28.8px;
      line-height: 43.2px;
    }
    .postload {
      margin-top: 35px;
      width: 208px;
      height: 60px;
      font-size: 28.8px;
      line-height: 43.2px;
    }
  }
 
  @media screen and (max-width: 530px) {
    .post {
      width: 166.4px;
      height: 48px;
      font-size: 23.04px;
      line-height: 34.56px;
    }
    .postload {
      width: 166.4px;
      height: 48px;
      font-size: 23.04px;
      line-height: 34.56px;
    }
  }
   .line2 {
    width: 70vw;
    height: 1px;
    border-top: 1px solid #a1a1a1;
    margin: 50px auto;
  }
  
}
//dynamic
.fieldContainer {
  display: flex;
  flex-direction: column;
  .label{
    margin-bottom: 15px;
    margin-top: 20px;
  }
 
}
.inputString{
  width: 342px;
  height: 47px;
  background: #f8f8f9;
  border: 2px solid #0298a6;
  border-radius: 10px;
  padding-left: 9px;
  &.inputStringDark{
    background: #015866;
    color:rgb(207,203,203)
  }
  &.error_input {
    border-color: color.$error_color;
    &::placeholder {
      color: color.$error_color;
    }
  }
}
.listb{
  width: 200px;
  height: 47px;
  background: #f8f8f9;
  border: 2px solid #0298a6;
  // padding-left: 9px;
  color: #0298a6;
  align-items: center;
  text-align: center;
  justify-content: center;
  display: flex;
  &.listbdark{
    background: #015866;
    color:rgb(207,203,203)
  }
}
.drop{
  width: 200px;
  background: #f8f8f9;
  border: 2px solid #0298a6;
  border-top:1px;
  border-radius:  0 0 15px 15px ;
  max-height: 300px;
  overflow-y: auto;
  position: absolute;
  z-index: 1;
  &.dropdark{
    background: #015866;
    color:rgb(207,203,203)
  }
  .droplist{
    color: #0298a6;
    display: flex;
   
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #0298a60f;
  cursor: pointer;
  &.droplistdark{
    color: rgb(207,203,203);
  }
  &:hover{
    background-color: #cbcaca29;
    // border-radius: 10px;
  }
  
  }
}
.error_message {
  font-size: 15px;
  line-height: 21px;
  color: color.$error_color;
}
.listContainer {
  flex: 1;
  display:flex;
  flex-direction: column;
  justify-content: flex-end;
  
}
.Boolean_container {
  margin-top: 20px;
  // margin-bottom: 95px;
  .switch_container {
    margin-top: 20px;
    display: flex;
    border: 2px solid #0298a6;
    border-radius: 15px;
    width: 316px;
    .switch_btn {
      width: 158px;
      height: 66px;
      color: black;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &.switch_btnDark{
        color: white;
      }
      &.active {
        background: #0298a6;
        color: white;
        border-radius: 12px;
        &.activeDark{
          background-color: #015866;
        }
      }
    }
  }
}
.title {
  margin-right: 10px;
}
.radioContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid gray;
  border-radius: 12px;
    button {
      flex: 1 1 50%; 
      border: 0.001px dotted white;
      border-radius: 16px;      padding: 10px;
      background-color: #fff;
      cursor: pointer;
      &.active {
        background-color: #0077ff;
        color: #fff;
        border: 2px solid color.$primary-color !important;
        border-radius: 11px;
      }
    }
    
  }
  @media screen and (max-width:890px) {
    .label {
      font-size: 25.6px;
      line-height: 38.4px;
    }
    .category_container {
      column-gap: 42.4px;
      margin-top: 45.6px;
      .category_input {
        column-gap: 40px;
        .img_container {
          width: 99.2px;
          height: 99.2px;
        }
        .title {
          font-size: 25.6px;
          line-height: 38.4px;
        }
        .subtitle {
          font-size: 20px;
          line-height: 24px;
        }
        .edit_btn {
          margin-top: 6.4px;
          width: 86.4px;
          height: 38.4px;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
    // .price_container {
    //   flex-direction: column;
    //   align-items: center;
    //   justify-content: center;
    //   margin-left: 0px;
    //   .label{
    //     text-align: start;
    //     align-items: start;
    //     justify-content: start;
    //   }
    //   .input_container {
    //     margin-top: -50px;

    //   }
    //   .checkboxes {
    //     margin-left:85px ;
    //     margin-top: 40px;
    //   }
      
    // }
    .quantity_container {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 0px;
      .input_container {
        margin-top: 10px;
        margin-left: 0px;      
        
    }
  }
    .title_container {
      margin-top: 78.4px;
      column-gap: 86.4px;
      .input {
        height: 46.4px;
        padding-left: 42px;
      }
    }
    .desc_container {
      margin-top: 76px;
      .description_wrapper {
        .text_area {
          margin-top: 24px;
          height: 201.6px;
          padding: 16px 32px;
        }
      }
    }
    .condDisc{
      flex-direction: column;
      display: flex;
      flex-direction: column;
      align-items: center;
    .condition_container {
      margin-top: 35px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .switch_container {
        margin-top: 10px;
      }
    }
    .discount_container{
      margin-top: 10px;
      margin-bottom: 0;
      margin-left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
    .condition_container {
      margin-top: 35px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .switch_container {
        margin-top: 10px;
      }
    }
   
    
    .form_container {
      .form_input_wrapper {
        .input {
          margin-top: 36px;
          margin-bottom: 40px;
          width: 70vw;
          height: 48px;
          padding-left: 15.2px;
        }
        .error_message {
          bottom: 15px;
        }
      }
      .transmission_type_container {
        margin-top: 32px;
        width: 250.4px;
        font-size: 19.2px;
        line-height: 28.8px;
        .switch_btn {
          width: 125.2px;
          height: 52.8px;
        }
      }
    }
    .image_section {
      margin-top: 35px;
      .wrapper {
        margin-top: 10px;
        column-gap: 15px;
        row-gap: 10px;
        .image_container {
          width: 99.2px;
          height: 99.2px;
        }
      }
    }
    .fieldContainer{
      display: flex;
      flex-direction: column;
      align-items: center;
      .label{
        text-align: center;
      }
    }
    // .location_section{
    // }
  }
  @media screen and (max-width: 360px) {
    .price_container{
    .input_container {
      margin-left: 0;
      .input {
      width: 300px;
    }
    }
  }
  .inputString{
    width: 300px;
    &.inputStringDark{
      background: #015866;
      color:rgb(207,203,203)
    }
  }
}
