@use "../../styles/variables.scss" as color;
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  .form_section {
    margin-top: 45px;
    margin-bottom: 50px;
    width: 709px;
    height: 500px;
    background: #f8f8f9;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &.form_sectionDark{
      background-color: #2a2b2e;
    }
    .subtitle {
      font-weight: 600;
      font-size: 32px;
      line-height: 150%;
      color: color.$dark-text-color;
      margin-top: 19px;
      &.subtitleDark{
        color: color.$white-text-color;
      }
    }
    .text {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: color.$light-text-color;
      max-width: 50%;
      text-align: center;
      margin-top: 1px;
      &.textDark{
        color: color.$white-text-color;
      }
    }
    .input_container {
      margin-top: 23px;
      position: relative;
      .input {
        background: #ffffff;
        border: 1px solid #d9d9d9;
        border-radius: 10px;
        width: 423px;
        height: 58px;
        padding-left: 94px;
        &.inputDark{
          background: #015866;
          color:rgb(207, 203, 203)
        }
      }
      .pass_icon {
        position: absolute;
        right: 25px;
        top: 50%;
        transform: translateY(-50%);
        color: color.$light-gray-color;
        font-size: 20px;
        cursor: pointer;
      }
      .icon {
        position: absolute;
        left: 38px;
        top: 50%;
        transform: translateY(-50%);
        color: color.$light-gray-color;
        font-size: 20px;
      }
      p {
        font-weight: 400;
        font-size: 11.2px;
        line-height: 16.8px;
        color: color.$error_color;
        position: absolute;
        // bottom: -17px;
        left: 6px;
      }
    }
    .button {
      width: 320px;
      height: 58px;
      background: color.$primary-color;
      border-radius: 10px;
      font-weight: 500;
      font-size: 28px;
      line-height: 150%;
      color: white;
      margin-top: 23px;
      margin-bottom: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      &.buttonDark{
        background-color: #015866;
      }
      &:hover{
        background: #01b1c0;  
      }
    }
  }
  @media screen and (max-width: 770px) {
    .form_section {
      width: 85vw;
      height: 400px;
      .subtitle {
        font-size: 25.6px;
        line-height: 120%;
        margin-top: 15.2px;
      }
      .text {
        font-size: 12.8px;
        line-height: 19.2px;
      }
      .input_container {
        margin-top: 18.4px;
        .input {
          width: 338.4px;
          height: 46.4px;
          padding-left: 75.2px;
        }
        .pass_icon {
          right: 20x;
          font-size: 16px;
        }
        .icon {
          left: 30.4px;
          font-size: 16px;
        }
        p {
          // bottom: -13.6px;
          left: 4.8px;
          font-size: 8.96px;
          line-height: 13.44px;
        }
      }
      .button {
        width: 256px;
        height: 50px;
        font-size: 18px;
        // line-height: 120%;
        margin-top: 18.4px;
      }
    }
  }
  @media screen and (max-width: 420px) {
    .form_section {
      margin-top: 30px;
      margin-bottom: 40px;
      .input_container {
        .input {
          width: 304.56px;
          height: 41.76px;
          padding-left: 67.68px;
        }
        .pass_icon {
          right: 20x;
          font-size: 16px;
        }
        .icon {
          left: 30.4px;
          font-size: 16px;
        }
        p {
          // bottom: -13.6px;
          left: 4.8px;
          font-size: 8.96px;
          line-height: 13.44px;
        }
      }
      .button {
        width: 204.8px;
        height: 37.12px;
        font-size: 17.92px;
        // line-height: 96%;
        margin-top: 14.72px;
      }
    }
  }
  @media screen and (max-width: 420px) {
    .form_section {
      margin-top: 30px;
      margin-bottom: 40px;
      .input_container {
        .input {
          width: 250px;
          height:35px;
          padding-left: 67.68px;
        }
        .pass_icon {
          right: 20x;
          font-size: 16px;
        }
        .icon {
          left: 30.4px;
          font-size: 16px;
        }
        p {
          // bottom: -13.6px;
          left: 4.8px;
          font-size: 8.96px;
          line-height: 13.44px;
        }
      }
      .button {
        width: 204.8px;
        height: 37.12px;
        font-size: 17.92px;
        
      }
    }
  }
}
