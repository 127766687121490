.secblk{
    padding: 20px;
.container{
    display: flex;
    align-items: center;
    padding: 20px;
    border-bottom:3px solid gray;
    justify-content: space-between;
    .imgcont{
        display: flex;
        align-items: center;
        .img{
            width: 70px;
            height: 70px;
            border-radius: 50px;
            object-fit: cover;
        }
        p{
            font-weight: 600;
            padding-left: 15px;
        }
        .name{
            &.nameDark{
                color:white
            }
        }
    }
    .btnCont{
        .block{
            background-color: #0298a6;
            font-weight: 600;
            width: 80px;
            border-radius: 10px;
            height: 35px;
            color: white;
            &.blockDark{
                background-color: #015866;
            }
        }
        .unblock{
            background-color: lightgray;
            font-weight: 600;
            width: 80px;
            border-radius: 10px;
            height: 35px;
        }
    }
    @media screen and (max-width:435px) {
        .imgcont{
            .img{
                width: 45px;
                height: 45px;
            }
            p{
                font-size: 13px;
            }
        }
        .btnCont{
            .unblock{
                width: 50px;
                font-size: 12px;
            }
            .block{
                width: 50px;
                font-size: 12px;
            }
        }
    }
}
}