.wrapper {
  display: flex;
  background-color: #f6ffff;
  .sidebar {
    width: 50px;
    height: 100vh;
    background-color: white;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    transition: all 0.4s ease;
    position: fixed;
    z-index: 999;
    &.active {
      width: 230px;
    }
    .top_section {
      display: flex;
      align-items: center;
      padding: 20px 15px;
      .logo {
        font-size: 30px;
        width: 70px;
        color: #9ba1aa;
        transform: scale(2.5);
        margin-left: 50px;
      }
      .bars {
        display: flex;
        font-size: 25px;
        margin-left: 50px;
        color: #9ba1aa;
      }
    }
    .link {
      display: flex;
      color: #9ba1aa;
      padding: 10px 15px;
      gap: 15px;
      transition: all 0.5s;
      text-decoration: none;
      &:hover {
        background: lightskyblue;
        color: #0298a6;
        transition: all 0.5s;
      }
      &.active_link {
        background: lightskyblue;
        color: #0298a6;
      }
    }
    .icon,
    .link_text {
      font-size: 15px;
    }
  }
  .user_profile{
    padding-left: 15px ;
    display: flex;
    align-items: center;
    margin-top: auto;
    margin-bottom: 20px;
    cursor: pointer;
    &:hover{
    background: #44ebeb57;
      color: #0298a6;
    }
  .profile_picture {
    width: 50px;
    height: 50px;
    background-color: #ccc;
    border-radius: 50%;
    .pp{
      width: 50px;
    height: 50px;
      border-radius: 50%;
    }
  }
  
  .username {
    margin-left: 5px;
    font-weight: 600;
  }
}
  .logout_button {
    padding: 20px 15px;
    display: flex;
    align-items: center;
    // color: #BA2C2D;
    font-weight: 600;
    .logtic{
      margin-right: 5px;
    }
  }
  
  .profile_picturecl{
    width: 30px;
    height: 30px;
    background-color: #ccc;
    border-radius: 50%;
    margin-left: -8px;
    .ppcl{
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
  
  }
  .lgtcl{
    // color: #BA2C2D;
    margin-left:10px ;
    margin-bottom: 10px;
  }
  .main {
    padding-left: 40px;
    padding-right: 30px;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-left: 50px;
    width: 100%;
  }
}
