.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 50px;
  .left_side {
    display: flex;
    flex-direction: column;
    gap: 50px;
    .bid {
      align-self: center;
      background: #0298a6;
      border-radius: 10px;
      width: 296px;
      height: 62px;
      font-weight: 600;
      margin-bottom: 50px;
      font-size: 24px;
      line-height: 36px;
      color: #ffffff;
      &:hover{
        background-color: #01b1c0;
      }
      &.bidDark{
        background-color: #015866;
      }
    }
    .end_bid {
      align-self: center;
      background: #e76859;
      border-radius: 10px;
      width: 190px;
      margin-right: 20px;
      height: 62px;
      font-weight: 600;
      margin-bottom: 50px;
      font-size: 20px;
      line-height: 36px;
      color: #ffffff;
      &:hover{
        background-color: #e87567;
      }
      
    }
    .end_bid1 {
      align-self: center;
      background: #e76859;
      border-radius: 10px;
      width: 190px;
      margin-right: 20px;
      height: 62px;
      font-weight: 600;
      margin-bottom: 50px;
      font-size: 20px;
      line-height: 36px;
      color: #ffffff;
      
    }
    .end_bid_s {
      align-self: center;
      background: white;
      border: 2px solid #e76859;
      border-radius: 10px;
      width: 190px;
      margin-right: 20px;
      height: 62px;
      font-weight: 600;
      margin-bottom: 50px;
      font-size: 20px;
      line-height: 36px;
      color: #e76859;
      &:hover{
        color: #e87567;
        border: 2px solid #e87567;
      }
      
    }
  }
  @media screen and (max-width: 1370px) {
    flex-direction: column;
    justify-content: unset;
    align-items: center;
    gap: 60px;
  }
  @media screen and (max-width: 730px) {
    .left_side {
      gap: 30px;
      .bid {
        width: 236.8px;
        height: 49.6px;
        font-size: 15px;
        line-height: 28.8px;
      }
      .end_bid {
        width: 140px;
        height: 49.6px;
        font-size: 15px;
        line-height: 28.8px;
      }
      .end_bid_s {
        width: 140px;
        height: 49.6px;
        font-size: 15px;
        line-height: 28.8px;
      }
      gap: 59.2;
    }
  }
}
.confirm_title {
  font-weight: 600;
  font-size: 25.6px;
  line-height: 38.4px;
  color: #353844;
  margin-bottom: 15px;
  &.confirm_titleDark{
    color:white
}
}
.confirm_message {
  font-weight: 500;
  font-size: 19.2px;
  line-height: 28.8px;
  color:#353844;
  margin-bottom: 15px;
  &.confirm_messageDark{
    color:white
}
}
.buttons_container {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  button {
    border-radius: 10px;
    padding: 10.4px 35.2px;
    font-weight: 600;
    font-size: 19.2px;
    line-height: 28.8px;
    color: #ffffff;
  }
  .confirm {
    background-color:  #e76859;
    &:hover{
      background-color: #e87567;
    }
  }
  .cancel {
    color: #e76859;
    border: 2px solid #e76859;
    &:hover{
      color: #e87567;
      border: 2px solid #e87567;
    }
  }
}