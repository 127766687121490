@use "../../styles/variables.scss" as color;
.section {
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  gap: 69px;
  &.sectionDark{
    margin-bottom: 0px;
  }
  .left_side {
    display: flex;
    flex-direction: column;
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .description_container {
      max-width: 580px;
      margin-top: 30px;
      .title {
        font-weight: 600;
        font-size: 32px;
        line-height: 48px;
        color: #353844;
        &.titleDark{
          color:color.$white-text-color;
        }
      }
      .text {
        margin-top: 21px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #4a4a4b;
        max-width: 400px;
        word-wrap: break-word;
        white-space: pre-wrap;
        &.textDark{
          color:color.$white-text-color;
        }
      }
    }
    .table_container {
      color: #353844;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin-top: 70px;
      justify-content: space-between;
      &.table_containerDark{
        color:color.$white-text-color;
       
        tr {
          height: 74px;
          width: 100%;
  
          &:nth-child(even) {
            background-color: #015866; 
          }
        }
      }
      table {
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        width: 588px;
      }
      tr {
        height: 74px;
        width: 100%;
        &:nth-child(even) {
          background-color: #f3f3f3; 
        }
      }
      td {
        padding: 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  .right_side {
    width: 576px;
    background: #f8f8f9;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    &.right_sideDark{
      background:#015866;
    }
    .wrapper {
      padding: 35px;
      display: flex;
      flex-direction: column;
      .user_container {
        margin: 45px auto;
      }
      .price {
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
        color: #353844;
        &.priceDark{
          color:color.$white-text-color;
        }
      }
      .oldpd{
        display: flex;
      .oldprice {
        font-weight: 700;
        font-size: 28px;
        line-height: 48px;
        color:  #e76859;
        text-decoration: line-through;
        // &.oldpriceDark{
        //   color:color.$red-color;
        // }
      }
      .highestBid {
        font-weight: 700;
        font-size: 26px;
        line-height: 48px;
        color:  #e76859;
        // &.highestBidDark{
        //   color:color.$red-color;
        // }
      }
      .disc {
        font-weight: 700;
        font-size: 25px;
        line-height: 48px;
        color:  #e76859;
        margin-left:10px ;
        // &.discDark{
        //   color:color.$red-color;
        // }
      }
    }
      .description {
        margin-top: 18px;
        max-width: 470px;
        font-weight: 600;
        font-size: 24px;
        word-wrap: break-word;
        line-height: 36px;
        color: #353844;
        margin-bottom: 35px;
        &.descriptionDark{
          color:color.$white-text-color;
        }
      }
      .location {
        font-weight: 400;
        font-size: 24px;
        line-height: 36px;
        color: #4a4a4b;
        margin-bottom: 29px;
        display: flex;
        align-items: center;
        gap: 5px;
        &.locationDark{
          color:color.$white-text-color;
        }
        .icon {
          display: inline-block;
          color: #0298a6;
          font-size: 30px;
        }
      }
      .sold__container{
        background-color: color.$error-color;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        .soldtxt{
          color: white;
          font-size: 20px;
          font-weight: 600;
          padding-top: 15px;
        }
      }
      .line {
        border-top: 2px solid #9d9d9d;
        width: 100%;
      }
    }
    .safety_container {
      padding-left: 10px;
      padding-right: 10px;
      margin-top: 15px;
      .safety_title {
        font-weight: 600;
        font-size: 32px;
        line-height: 48px;
        color: #353844;
        margin-bottom: 37px;
        &.safety_titleDark{
          color:color.$white-text-color;
        }
      }
      ul {
        list-style: circle;
      }
      .li {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #4a4a4b;
        margin-bottom: 14px;
        &.liDark{
          color:color.$white-text-color;
        }
      }
    }
  }
  @media screen and (max-width: 1450px) {
    justify-content: space-between;
    gap: 0;
  }
 
  @media screen and (max-width: 1380px) {
    justify-content: center;
    gap: 50px;
    flex-wrap: wrap;
    .left_side {
      .description_container {
        max-width: 406px;
        margin-top: 21px;
        .title {
          font-size: 22.4px;
          line-height: 33.6px;
        }
        .text {
          margin-top: 14.7px;
          font-size: 11.2px;
          line-height: 16.8px;
        }
      }
      .table_container {
        font-size: 11.2px;
        line-height: 16.8px;
        margin-top: 49px;
        table {
          width: 411.6px;
        }
        tr {
          height: 51.8px;
        }
        td {
          padding: 17.5px;
        }
      }
    }
    .right_side {
      width: 403.2px;
      .wrapper {
        padding: 24.5px;
        .user_container {
          margin: 2px auto;
        }
        .price {
          font-size: 22.4px;
          line-height: 33.6px;
        }
        .oldpd{
        .oldprice {
          font-size: 19px;
        }
        .highestBid {
          font-size: 18px;
        }
        .disc{
          font-size: 15px;
        }
      }
        .description {
          margin-top: 12.6px;
          max-width: 329px;
          font-size: 16.8px;
          line-height: 25.2px;
          margin-bottom: 24.5px;
        }
        .location {
          font-size: 16.8px;
          line-height: 25.2px;
          margin-bottom: 20.3px;
          .icon {
            margin-right: 3.5px;
            font-size: 21px;
          }
        }
      }
      .safety_container {
        padding-left: 7px;
        padding-right: 7px;
        .safety_title {
          font-size: 22.4px;
          line-height: 33.6px;
          margin-bottom: 25.9px;
        }
        li {
          font-size: 11.2px;
          line-height: 16.8px;
          margin-bottom: 9.8px;
        }
      }
    }
  }
  @media screen and (max-width: 1070px) {
    flex-direction: column;
    align-items: center;
    .right_side {
      margin-left: -70px;
      .wrapper {
        padding: 10px;
      }
    }
  }
  @media screen and (max-width: 520px) {
    .left_side {
      .description_container {
        max-width: 324.8x;
        margin-top: 16.8px;
        .title {
          font-size: 17.92px;
          line-height: 26.88px;
        }
        .text {
          margin-top: 11.76px;
          font-size: 8.96px;
          line-height: 13.44px;
        }
      }
      .table_container {
        font-size: 8.96px;
        line-height: 13.44px;
        margin-top: 39.2px;
        table {
          width: 329.28px;
        }
        tr {
          height: 41.44px;
        }
        td {
          padding: 14px;
        }
      }
    }
    .right_side {
      width: 330px;
      margin-left: 0;
      .wrapper {
        padding: 10px;
      }
    }
  }
  @media screen and (max-width: 340px) {
    .left_side {
      .table_container {
        table {
          width: 310px;
        }
    }
  }
    .right_side {
      width: 310px;
      .wrapper {
        padding: 10px;
      }
    
  }
  }
  // @media screen and (max-width: 400px) {
  //   align-items: flex-start;
  // }
}
.fixed_container {
  position: fixed;
  top: 50%; 
  right: 0px;
  z-index: 999;
  transform: translateY(-50%);
  .fixed_button {
    background-color: #0298a6;
    color: white;
    width: 96px;
    height: 71px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #f3f2f2;
    margin-bottom: 1px;
    cursor: pointer;
    &:hover{
      background: #01b1c0
    }
    &.fixed_buttonDark{
      background-color: #015866;
    }
    &.first {
      border-radius: 4px 0px 0px 0px;
    }
    &.third {
      border-radius: 0px 0px 0px 4px;
    }
    .fixed_icon {
      display: block;
      margin: 0 auto;
      width: 26px;
      height: 26px;
      margin-top: 10px;
    }
  }
  @media screen and (max-width: 1000px) {
    .fixed_button {
      width: 67.2px;
      height: 49.7px;
      font-size: 9px;
      line-height: 1px;
      padding-top: 16px;
      .fixed_icon{
        margin-top: 0px;
      }
      
      p{
        padding-top: 10px;
      }
    }
     
  
  }
  
}
@media screen and (max-width: 550px) {
  .fixed_container{
    position: absolute;
    top:60%;
  .fixed_button {
    width:55px;
    height: 50px;
    p{
      padding-top: 8px;
      text-align: center;
      font-size: 8px;
    }
    .fixed_icon {
      width: 18px;
      height: 18px;
    }
  }
}
}
