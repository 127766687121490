@use "../../../styles/variables.scss" as color;
.container {
  margin-top: 45px;
  // margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .list {
    display: flex;
    flex-direction: column;
    gap: 34px;
  }
  .add_btn {
    margin-top: 45px;
    background: color.$primary-color;
    border-radius: 10px;
    padding: 9px 88px;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #ffffff;
    margin-bottom: 40px;
    &.add_btnDark{
      background-color: #015866;
    }
  }
  @media screen and (max-width: 470px) {
    .add_btn {
      margin-top: 36px;
      padding: 7.2px 70.4px;
      font-size: 19.2px;
      line-height: 28.8px;
    }
  }
}
