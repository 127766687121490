.small--phone {
  .PhoneInput {
    width: 280px;
    height: 39.2px;
    background: #f8f8f9;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    border: 1px solid #9d9d9d;
  }
  .PhoneInputInput {
    background: #f8f8f9;
    padding-left: 35px;
  }
  .PhoneInputInput:focus {
    outline: none;
  }
  .PhoneInputCountryIcon {
    width: 28px;
    height: auto;
    margin: 0 auto;
  }
  .PhoneInputCountry {
    width: 60px;
    border-right: 2px solid #d9d9d9;
  }
  .PhoneInputCountrySelectArrow {
    display: none;
  }
}
