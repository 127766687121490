@use "../../styles/variables.scss" as color;
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .wrapper {
    display: flex;
    flex-direction: column;
    .card_type {
      display: flex;
      align-items: center;
      margin-top: 45px;
      margin-bottom: 26px;
      .card_text {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: #353844;
        margin-right: 37px;
      }
      .img_container {
        background: #ffffff;
        border: 2px solid #b1b1b1;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 64px;
        margin-right: 14px;
        padding: 5px;
        cursor: pointer;
        img {
          height: 50px;
        }
        &.active {
          border: 2px solid #0298a6;
        }
      }
    }
    .input_container {
      position: relative;
      margin-bottom: 20px;
      margin-top: 15px;
      &.input_containerDark{
      color: white;
      }
      .input {
        background: #f8f8f9;
        border: 1px solid #d9d9d9;
        border-radius: 10px;
        width: 423px;
        height: 58px;
        padding-left: 15px;
       
        &:focus{
          border-color: #0298a6 !important;
          outline: none;
        }
        &.inputDark{
          background-color: #015866 ;
          color: rgb(207,203,203);
        }
      }
      .error_message {
        font-weight: 400;
        font-size: 11.2px;
        line-height: 16.8px;
        color: color.$error_color;
        position: absolute;
        bottom: -16px;
        left: 6px;
      }
      &.error_input {
        input {
          border-color: color.$error_color;
        }
      }
    }
    .small_input_container {
      display: flex;
      gap: 33px;
      position: relative;
      .input_container {
        .small_input {
          width: 160px;
          height: 58px;
          padding-left: 15px;
          background: #f8f8f9;
          border: 1px solid #d9d9d9;
          border-radius: 10px;
          &:focus{
            border-color: #0298a6 !important;
            outline: none;
          }
          &.small_inputDark{
            background-color: #015866 ;
            color: rgb(207,203,203);
          }
        }
        .error_message {
          font-weight: 400;
          font-size: 11.2px;
          line-height: 16.8px;
          color: color.$error_color;
          position: absolute;
          bottom: -35px;
          left: 6px;
        }
        &.error_input {
          input {
            border-color: color.$error_color;
          }
        }
      }
    }
    .checkbox_container {
      align-self: center;
      margin-top: 15px;
      display: flex;
      align-items: center;
      gap: 17px;
      .checkbox {
        width: 22px;
        height: 22px;
        border-radius: 2px;
        accent-color: #0298a6;
      }
      .label {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: #353844;
      }
    }
    .add {
      align-self: center;
      margin-top: 20px;
      margin-bottom: 50px;
      background: #0298a6;
      border-radius: 10px;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: white;
      width: 162px;
      height: 48px;
      &:hover{
        background: #01b1c0;
      }
      &.addDark{
        background-color: #015866;
      }
    }
    .saveload_btn{
      align-self: center;
      margin-top: 20px;
      margin-bottom: 50px;
      background: gray;
      border-radius: 10px;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: white;
      width: 162px;
      height: 48px;
      cursor: not-allowed;
    }
    @media screen and (max-width: 565px) {
      .card_type {
        margin-bottom: 20.8px;
        .card_text {
          font-size: 19.2px;
          line-height: 28.8px;
          margin-right: 29.6px;
        }
        .img_container {
          width: 96px;
          height: 51.2px;
          margin-right: 11.2px;
          img {
            height: 40px;
          }
        }
      }
      .input_container {
        margin-bottom: 16px;
        .input {
          width: 350px;
          height: 46.4px;
          padding-left: 12px;
        }
      }
      .small_input_container {
        gap: 26.4px;
        .input_container {
          .small_input {
            width: 100px;
            height: 46.4px;
            padding-left: 12px;
          }
          .error_message {
            bottom: -50px;
          }
        }
      
        .smaller_input_container {
        .input_container {
        .smaller_input{
          width: 89px;
          height: 46.4px;
        }
        .error_message {
          bottom: -65px;
          left: 7px;
        }
      }
    }
  }
      .checkbox_container {
        gap: 17px;
        margin-top: 40px;
        .checkbox {
          width: 17.6px;
          height: 17.6px;
        }
        .label {
          font-size: 14.4px;
          line-height: 21.6px;
        }
      }
      .add {
        margin-top: 28x;
        margin-bottom: 40px;
        font-size: 16px;
        line-height: 24px;
        width: 129.6px;
        height: 38.4px;
      }
    }
  
  
    @media screen and (max-width: 440px) {
      .card_type {
        margin-bottom: 20.8px;
        .card_text {
          font-size: 19.2px;
          line-height: 28.8px;
          margin-right: 29.6px;
        }
        .img_container {
          width: 96px;
          height: 51.2px;
          margin-right: 11.2px;
          img {
            height: 40px;
          }
        }
      }
      .input_container {
        margin-bottom: 16px;
        .input {
          margin-top: 15px;
          width: 200px;
          height: 35px;
          padding-left: 12px;
        }
      }
      .small_input_container {
        gap: 26.4px;
        .input_container {
          .small_input {
            margin-top: 16px;
            width: 100px;
            height: 35px;
            padding-left: 12px;
          }
          .error_message {
            bottom: -50px;
          }
        }
      
        .smaller_input_container {
        .input_container {
        .smaller_input{
          width: 89px;
          height: 46.4px;
        }
        .error_message {
          bottom: -65px;
          left: 7px;
        }
      }
    }
  }
      .checkbox_container {
        gap: 17px;
        margin-top: 40px;
        .checkbox {
          width: 17.6px;
          height: 17.6px;
        }
        .label {
          font-size: 14.4px;
          line-height: 21.6px;
        }
      }
      .add {
        margin-top: 28x;
        margin-bottom: 40px;
        font-size: 16px;
        line-height: 24px;
        width: 129.6px;
        height: 38.4px;
      }
    }
  }
}
@media screen and (max-width: 350px) {
  .container{
    .wrapper{
      .CardNumberElement{
        width: 50px;
      }
    }
  }
  
}
