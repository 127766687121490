@use "../../../../styles/variables.scss" as color;
.overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3000;
}
.modal_styles {
  position: fixed;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  z-index: 3001;
  background-color: white;
  width: 378px;
  height: 560px;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  animation-name: animatem;
  animation-duration: 2s;
  &.modal_stylesDark{
    background-color: color.$dark-text-color;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .icon {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 19px;
      height: 19px;
      color: #000000;
      cursor: pointer;
      &.iconDark{
        color: white;
      }
    }
    .image_container {
      margin-top: 64px;
      display: flex;
      gap: 21px;
      .image {
        width: 112px;
        height: 81px;
        border-radius: 5px;
        object-fit: cover;
      }
      .details {
        max-width: 203px;
        .title {
          font-weight: 700;
          font-size: 18px;
          line-height: 27px;
          color: #353844;
          &.titleDark{
            color: color.$white-text-color;
          }
        }
        .text {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          word-wrap: break-word;
          color: #353844;
          max-width: 200px;
          &.textDark{
            color: color.$white-text-color;
          }
        }
      }
    }
    .input_container {
      margin-top: 30px;
      position: relative;
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }
      .input {
        width: 342px;
        height: 47px;
        background: #f8f8f9;
        border: 2px solid #0298a6;
        border-radius: 10px;
        padding-left: 91px;
        &.inputDark{
          background-color: color.$dark-text-color;
          border: 2px solid #015866;
          color:rgb(207, 203, 203);
        }
      }
      .dollar {
        position: absolute;
        top: 0;
        left: 0;
        width: 74px;
        height: 47px;
        background: #0298a6;
        border-radius: 10px 0px 0px 10px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: #f8feff;
        &.dollarDark{
          background-color: #015866;
        }
      }
    }
    .text_area {
      margin-top: 28px;
      width: 340px;
      height: 172px;
      background: #f8f8f9;
      border: 1px solid #d9d9d9;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      padding: 8px 13px;
      resize: none;
      &.text_areaDark{
        background-color: color.$dark-text-color;
        border: 2px solid #015866;
        color:rgb(207, 203, 203);
      }
      
    }
    .send {
      margin-top: 12px;
      width: 340px;
      height: 53px;
      background: #0298a6;
      border-radius: 10px;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      color: #ffffff;
      
      &.sendDark{
        background-color: #015866;
      }
      &.sendload{
        background-color: gray;
        cursor:not-allowed;
      }
    }
  }
  @media screen and (max-width: 400px) {
    width: 90%;
    height: 560px;
    .container{
    .input_container {
      .input{
        width: 200px;
      }
    }
    .text_area {
      width: 90%;
    }
    .send {
      width: 80%;
    }
    }
  }
  
}
@keyframes animatem {
  from {
    top: 50%;
    right: 100%;
  }
  to {
    top: 50%;
    right: 50%;
  }
}
