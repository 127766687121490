.price{
text-align: center;
// padding-top: 50%;
justify-content: center;
display: flex;
align-items: center;
margin-left: -80px;
.priceText{
    font-size:13px ;
    font-weight: 500;
    font-family:poppins;
}
@media (max-width: 1050px){
padding: 0;
display: flex;
margin-top: 30%;
}
}