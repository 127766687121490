.chatBody {
    max-height: 488px;
    // max-width: 850px;
    height: 488px;
    overflow-y: auto;
    // overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;  
    .message {
      margin: 10px;
      padding: 5px 10px;
      border-radius: 5px;
      font-size: 16px;
      max-width: 70%;
  
      &.incoming {
        align-self: flex-start;
        background: #F5F5F5;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
        border-radius: 10px 10px 10px 0px;
      }
  
      &.sent {
        align-self: flex-end;
        background: #0298A6;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
        border-radius: 10px 10px 0px 10px;
        color: white;
        max-width: 300px;
        overflow-wrap: break-word;
        .messageTime {
            color: white;
          }
      
          .sentIcon {
            color: white;
          }
      }
  
      .messageFooter {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 12px;
        margin-top: 5px;
      }
  
      .messageTime {
        margin-right: 5px;
      }
  
      .sentIcon {
        font-weight: bold;
        font-size: 15px;
      }
    }
  
    .dateSeparator {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #999;
      margin: 10px 0;
  
      &:before,
      &:after {
        content: "";
        flex-grow: 1;
        height: 1px;
        background-color: #999;
      }
  
      &:before {
        margin-right: 10px;
      }
  
      &:after {
        margin-left: 10px;
      }
    }
  }
  