.switch_container {
  display: flex;
  width: 70px;
  height: 34px;
  background-color: #a1a1a1;
  border-radius: 31px;
  transition: all 350ms ease;
  cursor: pointer;
  &.active {
    background-color: #0298a6;
  }
  .button {
    height: 34px;
    width: 35px;
    background-color: white;
    border-radius: 50%;
    transition: all 350ms ease;
    &.active {
      margin-left: 35px;
    }
  }
  @media screen and (max-width: 630px) {
    width: 56px;
    height: 27.2px;
    border-radius: 24.8px;
    .button {
      height: 27.2px;
      width: 28px;
      background-color: white;
      border-radius: 50%;
      transition: all 350ms ease;
      &.active {
        margin-left: 28px;
      }
    }
  }
}
