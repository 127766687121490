@use "../../../../styles/variables.scss" as color;
.container {
  .title {
    font-weight: 600;
    font-size: 22.4px;
    line-height: 33.6px;
    color: #353844;
    margin-top: 80.5px;
    text-align: center;
    &.titleDark{
      color:color.$white-text-color;
    }
  }
  .subtitle {
    margin-top: 24.5px;
    &.subtitleDark{
      color:color.$white-text-color;
    }
  }
  .service_container {
    cursor: pointer;
    background: #f8f8f9;
    border: 1px solid #111111;
    border-radius: 10px;
    width: 277.2px;
    height: 46.2px;
    position: relative;
    margin-top: 28.7px;
    &.service_containerDark{
      background-color: #015866;
      
      &.active {
        border: 3px solid white;
      }
    }
    .service_text {
      position: absolute;
      left: 22.4px;
      top: 50%;
      transform: translateY(-50%);
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #000000;
      &.service_textDark{
        color: color.$white-text-color;
      }
    }
    .active_icon {
      position: absolute;
      top: 50%;
      right: 5px;
      transform: translateY(-50%);
      width: 16.8px;
      height: 16.8px;
    }
    &.active {
      border: 3px solid #0298a6;
    }
  }
  .text {
    max-width: 414.4px;
    font-weight: 500;
    font-size: 12.6px;
    line-height: 18.9px;
    color: #8d8d8d;
    margin-top: 15px;
    text-align: start;
  }
  .next {
    margin-top: 29.4px;
    width: 217.7px;
    height: 46.2px;
    background: rgba(2, 152, 166, 0.6);
    border-radius: 10px;
    font-weight: 600;
    font-size: 19.6px;
    line-height: 29.4px;
    color: #ffffff;
    &.nextDark{
      background-color: gray;
      &.active_next {
        background: #015866;
      }
    }
    .icon {
      display: inline-block;
      margin-left: 15.4px;
    }
    &.active_next {
      background: #0298a6;
    }
  }
  @media screen and (max-width: 750px) {
    .title {
      margin-top: 40px;
    }
  }
  @media screen and (max-width: 600px) {
    .title {
      font-size: 17.92px;
      line-height: 26.88px;
    }
    .subtitle {
      margin-top: 19.6px;
    }
    .service_container {
      width: 221.76px;
      height: 36.96px;
      margin-top: 22.96px;
      .service_text {
        left: 17.92px;
        font-size: 11.2px;
        line-height: 16.8px;
      }
      .active_icon {
        width: 13.44px;
        height: 13.44px;
      }
    }
    .text {
      max-width: 331.52px;
      font-size: 10.08px;
      line-height: 15.12px;
      margin-top: 12px;
    }
    .next {
      margin-top: 23.52px;
      width: 174.16px;
      height: 36.96px;
      font-size: 15.68px;
      line-height: 23.52px;
    }
  }
  @media screen and (max-width: 480px) {
    padding: 20px;
  }
}
