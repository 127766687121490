@use "../../styles/variables.scss" as color;
.search {
  margin-top: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper {
  .container {   
    margin-top: 45px;
    display: flex;
    justify-content: space-between;
    // margin-bottom: 50px;
    .title {
      font-weight: 600;
      font-size: 36px;
      line-height: 54px;
      color: color.$dark-text-color;
      margin-bottom: 40px;
      &.titleDark{
        color: color.$white-text-color;
      }
    }
    .calender_section {
      display: flex;
      flex-direction: column;
      // align-items: center;
      gap: 40px;
    }
    .feed_section {
      display: flex;
      flex-direction: column;
      padding-bottom: 30px;
      .feeds {
        display: flex;
        flex-direction: column;
        row-gap: 44px;
      }
    }
  }
  @media screen and (min-width: 1600px) {
      .container {
        flex-wrap: wrap;
        gap: 10px;
  
        .feed_section {
          flex: 1;
          max-width: calc(50% - 100px);
        }
      }
  
  }
  @media screen and (max-width: 1500px) {
    .container {
      justify-content: space-around;
    }
  }
  @media screen and (max-width: 1200px) {
    .container {
      justify-content: space-between;
    }
  }
  @media screen and (max-width: 1150px) {
    .container {
      flex-direction: column;
      align-items: center;
      .title {
        font-size: 28.8px;
        line-height: 43.2px;
        margin-bottom: 32px;
        padding-left: 10px;
      }
      .calender_section {
        margin-bottom: 20px;
      }
      .feed_section{
        margin-left: 50px;
      }
    }
  }
  @media screen and (max-width: 700px) {
    .container {
     
      .feed_section{
        margin-left: -10px;
      }
    }
  }
  @media screen and (max-width: 500px) {
    .container {
      .calender_section{
        width: 305px;
      }
      .feed_section{
        margin-left: -90px;
      }
    }
  }
}
