@use "../../../../styles/variables.scss" as color;
.statediv {
    display: inline-block;
    border-radius: 11%;
    width: 85px;
    height: 30px;
    text-align: center;
    vertical-align: middle;
    line-height: 30px;
    font-size: 10px;
    font-weight: bold;
    margin-right: 10px;  
    &.blue {
      background-color: color.$primary-color;
    }
  
    &.red {
      background-color: #e44560;
    }
    &.lightb{
      background-color: #59AEF7;
      
    }
  
    &.gray {
      background-color: gray;
    }
  
    .state {
      color: white;
      text-transform: uppercase;
    }
  }
  