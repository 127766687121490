@use "../../../../styles/variables.scss" as color;
.accBut{
    background-color:color.$primary-color ;
    color:white;
    font-weight: 500;
    height: 30px;
    padding-bottom: 2px;
    margin-top:-10px ;
    width: 65px;
    border-radius:10px ;
    &:hover{
        background-color: white;
        color: color.$primary-color;
        border: 2px solid color.$primary-color;
    }
}
.resBut{
    background-color:white ;
    border: 2px solid color.$primary-color;
    color:color.$primary-color;
    font-weight: 500;
    height: 30px;
    padding-bottom: 2px;
    margin-top:-10px ;
    width: 65px;
    border-radius:10px ;
    &:hover{
        background-color: color.$primary-color;
        color: white;
    }
}