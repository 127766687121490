@use "../../../styles/variables.scss" as color;
.container{
    height: 100px;
    margin-top: 12%;
.left {
    display: flex;
    align-items: center;
    margin-left: 5%;
    margin-top: 10%;
    font-weight: 600;
  
    .image_container {
        margin-right: 6%;
        width: 50px;
        height: 50px;
        cursor: pointer;
    }
}
.right{
    display: flex;
    justify-content: flex-end;
    margin-top: -7%;
    
    .toggle_container{
        display: flex;
        align-items: center;
        flex-direction: column;
    
    label{
        font-size: 10px;
    }
}
.threedots {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 15px;
    position: relative;
    justify-content: center;
    .dots{
        width: 23px;
        height: 23px;
    }
    
    
}
    
}
.sub_cat{
    
    .p6{
        padding-left: 6%;
        padding-top: 2%;
        font-size: 12px;
        color: gray;
        font-weight: bolder;
    }
     
}    .hrr{
        margin-top: 7%;
    }
  .toggle-container .switch-on {
    background-color: color.$primary-color !important; 
  }
  
  .toggle-container .switch-off {
    background-color: gray !important; 
  }
  .dotDrop{
    position: absolute;
    top: 100%;
    left: -200px;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    width: 200px;
  }
  .dotDrop button {
    width: 100%;
    text-align: left;
    padding: 5px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    .edit{
        font-size: 15px;
        font-weight: 500;
        display: inline-flex;
        align-items: center;
        margin: 0;
        padding: 0;
        .ic{
            margin-right:10px ;
        }
    }
    .delete{
        font-size: 15px;
        font-weight: 500;
        color: red;
        display: inline-flex;
        align-items: center;
        margin: 0;
        padding: 0;
        .icd{
            margin-right:10px ;
            color: red;
        }
    }
  }
}
  
// @media only screen and (max-width: 767px) {
  
//   }