@use "../../../../styles/variables.scss" as color;
.line {
  border-top: 2px solid #9d9d9d;
}
.bid_container {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .image_container {
      position: relative;
      .image {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        border: 1px solid #ffffff;
        object-fit: cover;
 
        &.second {
          position: absolute;
          top: 0;
          left: 30px;
          z-index: 2;
        }
        &.third {
          position: absolute;
          top: 0;
          left: 55px;
          z-index: 3;
        }
        &.fourth {
          position: absolute;
          top: 0;
          left: 75px;
          z-index: 4;
        }
      }
      
      .overlay {
        position: absolute;
        top: 0;
        left: 75px;
        z-index: 5;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.4);
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      p {
        font-weight: 600;
        font-size: 20px;
        line-height: 132%;
        color:  #e76859;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 130px;
        width: 130px;
      }
    }
    .timer {
      background-color: color.$primary-color;
      border-radius: 10px;
      padding: 3px 6px;
      display: flex;
      align-items: center;
      .timer__icon {
        color: white;
        width: 18px;
        height: 19.77px;
        margin-right: 1.9px;
      }
      .timer__text {
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        text-align: center;
        color: #ffffff;
      }
    }
  }
  .join {
    background: #0298a6;
    border-radius: 10px;
    color: #ffffff;
    font-weight: 500;
    font-size: 22px;
    line-height: 150%;
    width: 143px;
    height: 52px;
    align-self: center;
    margin-top: 40px;
    margin-bottom: 33px;
    &:hover{
      background-color: #01b1c0;
    }
  }
  .login_btn {
    background: #006770;
    border-radius: 10px;
    color: #ffffff;
    font-weight: 500;
    font-size: 22px;
    line-height: 150%;
    // width: 220px;
    padding: 0 10px;
    height: 52px;
    align-self: center;
    margin-top: 54.5px;
    margin-bottom: 33px;
  }
  @media screen and (max-width: 1380px) {
    .flex {
      .image_container {
        flex-direction: column;
        .image {
          width: 33.6px;
          height: 33.6px;
          &.second {
            left: 24px;
          }
          &.third {
            left: 44px;
          }
          &.fourth {
            left: 60px;
          }
        }
        .overlay {
          left: 60px;
          width: 33.6px;
          height: 33.6px;
        }
        p {
          font-size: 16px;
          line-height: 105.6%;
          left: 0;
          top: 50px;
        }
        .overlayP {
          font-size: 16px;
          line-height: 105.6%;
          top: -10px;
          left: 100px;
        }
      }
    }
    .join {
      margin-top: 27.6px;
      margin-bottom: 26.4px;
      font-size: 17.6px;
      line-height: 120%;
      width: 114.4px;
      height: 41.6px;
    }
  }
}
