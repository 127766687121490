@use "../../../../styles/variables.scss" as color;
.container {
  margin-top: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .category__container {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: color.$dark-text-color;
    display: flex;
    gap: 50px;
    .category__item {
      padding: 0 18px;
      margin-bottom: 45px;
      cursor: pointer;
      &.category__itemDark{
        color:white
      }
      &.active {
        border-bottom: 2px solid color.$primary-color;
        &.activeDark{
          border-bottom: 2px solid #015866;
        }
      }
    }
  }
  .date {
    align-self: flex-start;
    margin-top: 29px;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: color.$dark-text-color;
  }
  .card_container {
    align-self: flex-start;
    margin-top: 36px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
    margin-bottom: 40px;
    
  }
  .order_container {
    align-self: flex-start;
  }
  @media screen and (max-width: 1465px) {
    .card_container {
      gap: 30px;
    }
  }
  @media screen and (max-width: 1404px) {
    .card_container {
      column-gap: 200px;
      row-gap: 100px;
    }
  }
  @media screen and (max-width: 1140px) {
    .card_container {
      column-gap: 150px;
      row-gap: 60px;
    }
  }
  @media screen and (max-width: 1090px) {
    .card_container {
      column-gap: 100px;
      row-gap: 60px;
    }
  }
  @media screen and (max-width: 1040px) {
    .card_container {
      column-gap: 60px;
      row-gap: 50px;
    }
  }
  @media screen and (max-width: 950px) {
    .card_container {
      column-gap: 40px;
      row-gap: 40px;
    }
  }
  @media screen and (max-width: 900px) {
    .card_container {
      // flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  @media screen and (max-width: 800px) {
    .category__container {
      font-size: 14px;
      line-height: 21px;
      color: color.$dark-text-color;
      display: flex;
      gap: 35px;
      .category__item {
        padding: 0 12.6px;
        margin-bottom: 25.2px;
      }
    }
  }
  @media screen and (max-width: 500px) {
    .category__container {
      gap: 15px;
    }
  }
  @media screen and (max-width: 450px) {
    .date {
      margin-top: 24px;
      font-size: 19.2px;
      line-height: 28.8px;
    }
  }
  @media screen and (max-width: 400px) {
    .category__container {
      gap: 0px;
    }
  }
}
