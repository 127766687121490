.profile__container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 19px;
    padding-bottom: 15px;
  
    .profile__picture {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: #ccc; 
      animation: loadingAnimation 1s infinite alternate;
    }
  
    .details__container {
      display: flex;
      flex-direction: column;
  
      .name {
        background-color: #ccc; 
        width: 150px;
        height: 10px;
        margin-left: -50px;
        animation: loadingAnimation 1s infinite alternate;
        .rating {
          display: inline-block;
          background: #ccc; 
          width: 100px;
          height: 10px;
          margin-top: 20px;
          animation: loadingAnimation 1s infinite alternate;
        }
      }
    }
  }
  @keyframes loadingAnimation {
    0% {
      opacity: 0.5;
      transform: translateX(0);
    }
    100% {
      opacity: 1;
      transform: translateX(5px);
    }
  }