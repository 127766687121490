.big--phone {
  .PhoneInput {
    width: 400px;
    height: 56px;
    background: #f8f8f9;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
  }
  .PhoneInputInput {
    background: #f8f8f9;
    padding-left: 50px;
  }
  .PhoneInputInput:focus {
    outline: none;
  }
  .PhoneInputCountryIcon {
    width: 40px;
    height: auto;
    margin: 0 auto;
  }
  .PhoneInputCountry {
    width: 70px;
    border-right: 2px solid #d9d9d9;
  }
  .PhoneInputCountrySelectArrow {
    display: none;
  }
  @media screen and (max-width: 540px) {
    .PhoneInput {
      width: 280px;
      height: 39.2px;
    }
    .PhoneInputInput {
      padding-left: 35px;
    }
    .PhoneInputCountryIcon {
      width: 28px;
    }
    .PhoneInputCountry {
      width: 79px;
    }
  }
}
