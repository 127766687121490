.container {
  margin-top:85px;
  display: flex;
  flex-direction: column;
  align-items: center;
 
  .wrapper {
    max-width: 60vw;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    
    .flex {
      display: flex;
      justify-content: space-between;
      > div {
        margin-right: 20px; /* adjust this value as needed */
      }
      .image_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        .input_container {
          display: flex;
          align-items: center;
          margin-top: 20px;
          input {
            width: 15px;
            height: 15px;
            margin-right: 10px;
          }
          .text {
            font-weight: 600;
            font-size: 15px;
            line-height: 36px;
            color: #353844;
            &.textDark{
              color: white;
            }
          }
        }
      }
      .remove{
        color: #0298a6;
        font-weight: 700;
        font-size: 15px;
        padding-left: 3.5%;
        position: relative;
        &.removeDark{
          color: #015866;
        }
      }
      .removeload{
        color: gray;
        font-weight: 700;
        font-size: 15px;
        padding-left: 3.5%;
      }
    }
  }
  .add_btn {
    background: #0298a6;
    border-radius: 10px;
    margin-top: 45px;
    margin-bottom: 50px;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #ffffff;
    // padding: 17px 108px;
    width: 376px;
    height: 69px;
    &.add_btnDark{
      background-color: #015866;
    }
    &:hover{
      background-color: #01b1c0;
    }
    .icon {
      display: inline-block;
      font-size: 28px;
    }
  }
  
  @media screen and (max-width: 1500px) {
    .wrapper {
      width: 50vw;
      .flex {
        // justify-content: unset;
        .image_container {
          .image {
            width: 305.6px;
            height: 171.2px;
          }
          .input_container {
            .text {
              font-size: 19.2px;
              line-height: 28.8px;
            }
          }
        }
      }
      
    }
    .add_btn {
      font-size: 19.2px;
      line-height: 28.8px;
      width: 300.8px;
      height: 55.2px;
      
      .icon {
        font-size: 22.4px;
      }
    }
  }
  @media screen and (max-width: 1300px) {
    .wrapper {
      width: 90vw;
      .flex {
        flex-direction: column;
        justify-content: unset;
        gap: 30px;
      }
      .remove{
        margin-left: 15.5vw;
      }
      .removeload{
        margin-left: 15.5vw;
      }
    }
  }
  @media screen and (max-width: 900px) {
    .wrapper {
      
      .remove{
        margin-left: 10vw;
      }
      .removeload{
        margin-left: 10vw;
      }
    }
  }
  @media screen and (max-width: 650px) {
    .wrapper {
      
      .remove{
        margin-left: 4vw;
      }
      .removeload{
        margin-left: 4vw;
      }
    }
  }
  @media screen and (max-width: 540px) {
    .wrapper {
      max-width: 150vw;
      overflow-x: hidden;
      .flex {
        .image_container {
          .input_container {
            .text {
              font-size: 15.36px;
              line-height: 23.04px;
            }
            input {
              width: 14px;
              height: 14px;
            }
          }
        }
      }
      .remove{
        margin-left: 11vw;
      }
      .removeload{
        margin-left: 11vw;
      }
    }
  }
  @media screen and (max-width: 370px) {
    .wrapper {
      .remove{
        margin-left: 2vw;
      }
      .removeload{
        margin-left: 2vw;
      }
    }
  }
}
