.overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
}
.modal_styles {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2001;
  background-color: white;
  width: 450px;
  height: 80%;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  &.modal_stylesDark{
    background-color: #2e2e2e;
  }
  .wrapper {
    position: relative;
    display: flex;
    justify-content: space-around;
    padding: 50px 40px;
    .close {
      position: absolute;
      top: 13.3px;
      right: 13.3px;
      width: 32px;
      height: 32px;
      cursor: pointer;
    }
    .texttt{
        &.textttDark{
            color:white
        }
    }
    .main_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      .image {
            width: 70px;
            height: 70px;
            border-radius: 50%;
            object-fit: cover;
            align-items: center;
            justify-content: center;
          }
    
          .name_container {
            display: flex;
            align-items: center;
            gap: 8.4px;
            margin-top: 15px;
    
            .name {
              font-weight: 600;
              font-size: 18px;
              line-height: 105%;
              color: #353844;
              &.nameDark{
                color:white
              }
            }
      }
      .rating_container {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #353844;
        .stars {
          margin-top: 14px;
          display: flex;
          gap: 11.2px;
          .star {
            color: #d9d9d9;
            width: 34.3px;
            height: 34.3px;
            cursor: pointer;
            &.gold {
              color: gold;
            }
          }
        }
      }
      .overall{
        &.overallDark{
          color:white;
        }
      }
      .line {
        border-top: 2px solid #c2c2c2;
        width: 100%;
        margin-top: 28px;
        margin-bottom: 28px;
      }
      .question {
        padding-top: 10px;
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
        color: #353844;
        &.questionDark{
          color:white
        }
      }
      .textarea {
        // margin-top: 14px;
        background: #f8f8f9;
        border: 1px solid #d9d9d9;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        width: 287px;
        height: 132.3px;
        resize: none;
        padding: 6.16px 10.92px;
        padding-bottom: 10px;
        font-size: 13px;
        &.textareaDark{
            background-color: #015866;
            color:rgb(207,203,203) ;
        }
      }
      .price_container {
        margin-top: 20px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content:center;
        flex-direction: column;
        .label{
            font-weight: 700;
            padding-bottom: 10px;
            &.labelDark{
                color:white
            }
            
        }
        .input_container {
          position: relative;
          display: flex;
          flex-direction: column;
          .input {
            width: 250px;
            height: 47px;
            background: #f8f8f9;
            border: 2px solid #0298a6;
            border-radius: 10px;
            padding-left: 91px;
            &.inputDark{
              background-color: rgb(207,203,203) ;
              // color:rgb(207,203,203);
              border: 2px solid #015866;
            }
          
          }
          
          .currency {
            background: #0298a6;
            border-radius: 10px 0px 0px 10px;
            width: 74px;
            height: 47px;
            position: absolute;
            left: 0;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: #f8feff;
            &.currencyDark{
              background-color: #015866;
            }
          }
        }
    }
      .submit {
        margin-top: 20px;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #ffffff;
        padding: 4px 23.1px;
        background: #0298a6;
        border-radius: 10px;
        align-self: center;
        &.submitcheck{
          background-color: gray;
          cursor:not-allowed;
        }
        &.submitDark{
          background-color:#015866;
        }
        &:hover{
          background: #01b1c0;
        }
      }
      .submit_loading{
        margin-top: 20px;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #ffffff;
        padding: 4px 23.1px;
        border-radius: 10px;
        align-self: center;
        background: gray;
      }
    }
  }
  @media screen and (max-width: 1300px) {
    height: auto;
  }
  @media screen and (max-width: 630px) {
    width: 90vw;
    .wrapper {
      flex-direction: column;
      padding: 25px;
      .user_container {
        .member_text {
          font-size: 13.44px;
          line-height: 20.16px;
        }
      }
      .main_container {
        margin-top: 25px;
        .rating_container {
          font-size: 12.6px;
          line-height: 16.8px;
        }
        .line {
          margin-top: 15px;
          margin-bottom: 15px;
        }
        .question {
          font-size: 12.6px;
          line-height: 16.8px;
        }
        .textarea {
          width: 100%;
        }
      }
    }
  }
  @media screen and (max-width: 375px) {
    .texttt{
        font-size: 18px;
    }
  }
}