@use "../../../../styles/variables.scss" as color;
.card_section {
  height: 166px;
  // width: 450px;
  background: #ffffff;
  box-shadow: 0px 2.93px 8.32px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  &.card_sectionDark{
    background-color: #015866;
  }
  .image_container {
    position: relative;
    width: 229px;
    height: 166px;
    .img {
      width: 160px;
      height: 166px;
      object-fit: cover;
      border-radius:10px ;
    }
    .featured_container {
      position: absolute;
      top: -1px;
      right: 0px;
      width: 65px;
      height: 25px;
      background-color: color.$yellow-color;
      padding: 7.91px 9.13px 6.92px 8.91px;
      border-radius: 10px;
      &.featured_containerImported{
        display: none;
      }
      
      .featured_text {
        font-weight: 600;
        font-size: 12px;
        line-height:8px;
        text-align: center;
        color: #000000;
        &.featured_textImported{
          font-size: 10px;
        }
      }
    }
  }
  .details_container {
    width: 176px;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 41px;
    
    .price {
      font-weight:700;
      font-size: 14px;
      line-height: 20px;
      color: color.$dark-text-color;
      margin-left: -50px;
      &.priceImported{
        font-size: 20px;
        margin-left: -25px;
      }
      &.priceDark{
        color: #ffffff;
      }
    }
    .offerd{
      color:color.$primary-color;
      margin-left: -25px;
      font-size: 12px;
      font-weight: 500;
      &.offerdDark{
        color: #015866;
      }
    }
    .title {
      font-weight: 600;
      font-size: 13px;
      line-height: 107%;
      color: color.$dark-text-color;
      margin-left: -50px;
      &.titleImported{
        font-size: 18px;
        margin-left: -25px;
      }
      &.titleDark{
        color: #ffffff;
      }
    }
    .location,
    .time {
      font-weight: 600;
      font-size: 10px;
      line-height: 107%;
      color: color.$light-text-color;
      margin-left: -50px;
      &.locationImported{
        display: none;
      }
      &.timeImported{
        display: none;
      }
      &.locationDark{
        color: #ffffff;
      }
      &.timeDark{
        color: #ffffff;
      }
    }
    // .location {
    //   margin-top: 20px;
    // }
    // .time {
    //   margin-top: 9px;
    // }
    .icon {
      display: inline-block;
      width: 15px;
      height: 15px;
      color: color.$primary-color;
      margin-right: 5px;
    }
    .heart_icon {
      position: absolute;
      top: 1px;
      right: 10px;
      color: color.$primary-color;
      font-size: 22px;
      cursor: pointer;
      &.heart_iconImported{
        display: none;
      }
    }
    .condition_container {
      position: absolute;
      top: -1px;
      right: 35px;
      // z-index: 3;
      // &.condition_containerImported{
      //   display: none;
      // }
      .condition_image {
        width: 52px;
        height: 30.4px;
        
      }
      .condition {
        font-weight: 500;
        font-size: 12.8px;
        line-height: 19.2px;
        text-align: center;
        color: #ffffff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  
  @media screen and (max-width: 880px) {
    height: 132.8px;
    width: 334px;
    &.card_sectionImported{
      width: 380px;
    }
    .image_container {
      width: 130px;
      height: 100px;
      .img {
        width: 130px;
        height: 133px;
        object-fit: cover;
        border-radius:10px ;
      }
      .featured_container {
        width: 50px;
        height: 15.104px;
        right: -40%;
        .featured_text {
          font-size: 9px;
          line-height: 0px;
        }
      }
    }
    .details_container {
      width: 140.8px;
      padding-top: 32.8px;
      left: 20%;
      .price {
        font-size: 10.57448px;
        line-height: 16px;
        &.priceImported{
          margin-left: -35%;
        }
      }
      .title {
        font-size: 9.6px;
        line-height: 10%;
        &.titleImported{
          margin-left: -35%;
        }
      }
      .offerd{
        margin-left: -35%;
      }
      .location,
      .time {
        font-size: 8px;
        line-height: 10%;
      }
  
      .icon {
        width: 12px;
        height: 12px;
        margin-right: 4px;
      }
      .heart_icon {
        // top: 8px;
        right: 11.04px;
      }
      // .condition {
      //   right: 40px;
      //   top: -4px;
      //   padding-right: 50%;
      //   .img_condition {
      //     width: 25.18px;
      //     height: 32.05px;
      //   }
      //   .text_condition {
      //     font-size: 8.8px;
      //     line-height: 12.8px;
      //   }
      // }
    }
  }
  @media screen and (max-width: 730px) {
    height: 132.8px;
    width: 290px;
    .image_container {
      width: 90px;
      height: 100px;
      position: relative;
      .img {
        width: 130px;
        height: 133px;
        object-fit: cover;
        border-radius:10px ;
      }
      .featured_container {
        width: 50px;
        height: 15.104px;
        right: -40%;
        position: absolute;
        left: 100%;
        .featured_text {
          font-size: 8px;
          line-height: 0px;
        }
      }
    }
    .details_container {
      width: 140.8px;
      padding-top: 32.8px;
      left: 20%;
      .price {
        font-size: 10.57448px;
        line-height: 16px;
      }
      .title {
        font-size: 9.6px;
        line-height: 10%;
      }
      .location,
      .time {
        font-size: 8px;
        line-height: 10%;
      }
  
      .icon {
        width: 12px;
        height: 12px;
        margin-right: 4px;
      }
      .heart_icon {
        // top: 8px;
        right: 11.04px;
      }
      // .condition {
      //   right: 40px;
      //   top: -4px;
      //   padding-right: 50%;
      //   .img_condition {
      //     width: 25.18px;
      //     height: 32.05px;
      //   }
      //   .text_condition {
      //     font-size: 8.8px;
      //     line-height: 12.8px;
      //   }
      // }
    }
  }
  @media screen and (max-width: 640px) {
    height: 132.8px;
    width: 100%;
    .image_container {
      width: 70%;
      height: 100px;
      position: relative;
      .img {
        width: 80%;
        height: 133px;
        object-fit: cover;
        border-radius:10px ;
      }
      .featured_container {
        position: absolute;
        width: 50px;
        height: 15.104px;
        left: 80%;
        .featured_text {
          font-size: 8px;
          line-height: 1px;
        }
      }
    }
    .details_container {
      width: 200px;
      padding-top: 32.8px;
      left: 0px;
      .price {
        font-size: 10.57448px;
        line-height: 16px;
        &.priceImported{
          margin-left: -15%;
          font-size: 18px;
        }
      }
      .title {
        font-size: 9.6px;
        line-height: 10%;
        &.titleImported{
          margin-left: -15%;
          font-size: 16px;
        }
      }
      .offerd{
        margin-left: -15%;
      }
      .location,
      .time {
        font-size: 8px;
        line-height: 10%;
      }
  
      .icon {
        width: 12px;
        height: 12px;
        margin-right: 4px;
      }
      .heart_icon {
        // top: 8px;
        right: 11.04px;
      }
      // .condition {
      //   right: 40px;
      //   top: -4px;
      //   padding-right: 50%;
      //   .img_condition {
      //     width: 25.18px;
      //     height: 32.05px;
      //   }
      //   .text_condition {
      //     font-size: 8.8px;
      //     line-height: 12.8px;
      //   }
      // }
    }
  }
  @media screen and (max-width: 490px) {
    height: 132.8px;
    width: 100%;
    .image_container {
      width: 55%;
      height: 100px;
      position: relative;
      .img {
        width: 70%;
        height: 133px;
        object-fit: cover;
        border-radius:10px ;
      }
      .featured_container {
        position: absolute;
        width: 50px;
        height: 15.104px;
        left: 72%;
        .featured_text {
          font-size: 8px;
          line-height: 1px;
        }
      }
    }
    .details_container {
      width: 200px;
      padding-top: 32.8px;
      left: 0px;
      .price {
        font-size: 10.57448px;
        line-height: 16px;
      }
      .title {
        font-size: 9.6px;
        line-height: 10%;
      }
      .location,
      .time {
        font-size: 8px;
        line-height: 10%;
      }
  
      .icon {
        width: 12px;
        height: 12px;
        margin-right: 4px;
      }
      .heart_icon {
        // top: 8px;
        right: 11.04px;
      }
      // .condition {
      //   right: 40px;
      //   top: -4px;
      //   padding-right: 50%;
      //   .img_condition {
      //     width: 25.18px;
      //     height: 32.05px;
      //   }
      //   .text_condition {
      //     font-size: 8.8px;
      //     line-height: 12.8px;
      //   }
      // }
    }
  }
  @media screen and (max-width: 475px) {
    &.card_sectionImported{
    height: 132.8px;
    width:230px;
    }
    .image_container {
      &.image_containerImported{
      width: 130px;
      height: 100px;
      }
      .img {
        &.imgImported{
        width: 130px;
        height: 133px;
        object-fit: cover;
        border-radius:10px ;
        }
      }
      .featured_container {
        &.featured_containerImported{
        width: 50px;
        height: 15.104px;
        right: -40%;
        }
        .featured_text {
          &.featured_textImported{
          font-size: 8px;
          line-height: 1px;
          }
        }
      }
    }
    .details_container {
      &.details_containerImported{
      width: 140.8px;
      padding-top: 32.8px;
      left: 22%;
      }
      .price {
        &.priceImported{
        font-size:13px;
        line-height: 16px;
      }
      }
      .offerd{
        font-size: 10px;
        margin-left: -35%;
      }
      .title {
        &.titleImported{
        font-size: 11px;
        line-height: 10%;
        }
      }
      .location,
      .time {
        font-size: 8px;
        line-height: 10%;
      }
  
      .icon {
        width: 12px;
        height: 12px;
        margin-right: 4px;
      }
      .heart_icon {
        cursor: pointer;
        &.heart_iconImported{
        top: 5px;
        left:52px;
        width: 17px;
        cursor: pointer;
        }
      }
      .condition_container {
        &.condition_containerImported{
          .condition {
            // color: red;
            margin-left: -86%;
          }
        .condition_image {
          &.condition_imageImported{
          width: 40px;
          height: 30.4px;
          margin-left:-85%;
          }
        }
      }
      }
      .condition {
        .conditionImported{
        margin-left: -78%;
        
        .img_condition {
          width: 20px;
          height: 32.05px;
        }
        .text_condition {
          font-size: 8.8px;
          line-height: 12.8px;
        }
      }
    }
    }
  
  }
  @media screen and (max-width: 415px) {
    height: 132.8px;
    width: 100%;
    .image_container {
      width: 60%;
      height: 100px;
      position: relative;
      .img {
        width: 60%;
        height: 133px;
        object-fit: cover;
        border-radius:10px ;
      }
      .featured_container {
        position: absolute;
        width: 50px;
        height: 15.104px;
        left: 62%;
        .featured_text {
          font-size: 8px;
          line-height: 1px;
        }
      }
    }
    .details_container {
      width: 200px;
      padding-top: 32.8px;
      left: 0px;
      .price {
        font-size: 10.57448px;
        line-height: 16px;
      }
      .title {
        font-size: 9.6px;
        line-height: 10%;
      }
      .location,
      .time {
        font-size: 8px;
        line-height: 10%;
      }
  
      .icon {
        width: 12px;
        height: 12px;
        margin-right: 4px;
      }
      .heart_icon {
        // top: 8px;
        right: 11.04px;
      }
      // .condition {
      //   right: 40px;
      //   top: -4px;
      //   padding-right: 50%;
      //   .img_condition {
      //     width: 25.18px;
      //     height: 32.05px;
      //   }
      //   .text_condition {
      //     font-size: 8.8px;
      //     line-height: 12.8px;
      //   }
      // }
    }
  }
  // @media screen and (max-width: 450px) {
  //   height: 132.8px;
  //   width: 324px;
  //   .image_container {
  //     width: 130px;
  //     height: 100px;
  //     .img {
  //       width: 130px;
  //       height: 133px;
  //       object-fit: cover;
  //       border-radius:10px ;
  //     }
  //     .featured_container {
  //       width: 50px;
  //       height: 15.104px;
  //       right: -40%;
  //       .featured_text {
  //         font-size: 8px;
  //         line-height: 1px;
  //       }
  //     }
  //   }
  //   .details_container {
  //     width: 140.8px;
  //     padding-top: 32.8px;
  //     left: 20%;
  //     .price {
  //       font-size: 10.57448px;
  //       line-height: 16px;
  //     }
  //     .title {
  //       font-size: 9.6px;
  //       line-height: 10%;
  //     }
  //     .location,
  //     .time {
  //       font-size: 8px;
  //       line-height: 10%;
  //     }
  
  //     .icon {
  //       width: 12px;
  //       height: 12px;
  //       margin-right: 4px;
  //     }
  //     .heart_icon {
  //       // top: 8px;
  //       right: 11.04px;
  //     }
  //     // .condition {
  //     //   right: 40px;
  //     //   top: -4px;
  //     //   padding-right: 50%;
  //     //   .img_condition {
  //     //     width: 25.18px;
  //     //     height: 32.05px;
  //     //   }
  //     //   .text_condition {
  //     //     font-size: 8.8px;
  //     //     line-height: 12.8px;
  //     //   }
  //     // }
  //   }
  // }
  // @media screen and (max-width: 380px) {
  //   height: 132.8px;
  //   width: 280px;
  //   .image_container {
  //     width: 130px;
  //     height: 100px;
  //     .img {
  //       width: 130px;
  //       height: 133px;
  //       object-fit: cover;
  //       border-radius:10px ;
  //     }
  //     .featured_container {
  //       width: 50px;
  //       height: 15.104px;
  //       right: -40%;
  //       .featured_text {
  //         font-size: 8px;
  //         line-height: 1px;
  //       }
  //     }
  //   }
  //   .details_container {
  //     width: 140.8px;
  //     padding-top: 32.8px;
  //     left: 20%;
  //     .price {
  //       font-size: 10.57448px;
  //       line-height: 16px;
  //     }
  //     .title {
  //       font-size: 9.6px;
  //       line-height: 10%;
  //     }
  //     .location,
  //     .time {
  //       font-size: 8px;
  //       line-height: 10%;
  //     }
  
  //     .icon {
  //       width: 12px;
  //       height: 12px;
  //       margin-right: 4px;
  //     }
  //     .heart_icon {
  //       top: 5px;
  //       right: 33%;
  //     }
  //     .condition_container {
  //       .condition_image {
  //         width: 40px;
  //         height: 30.4px;
  //         margin-left:-85%;
  //       }
  //     }
  //     .condition {
  //       margin-left: -85%;
  //       .img_condition {
  //         width: 20px;
  //         height: 32.05px;
  //       }
  //       .text_condition {
  //         font-size: 8.8px;
  //         line-height: 12.8px;
  //       }
  //     }
  //   }
  // }
}
.confirm_title {
  font-weight: 600;
  font-size: 25.6px;
  line-height: 38.4px;
  color: color.$dark-text-color;
  margin-bottom: 15px;
}
.confirm_message {
  font-weight: 500;
  font-size: 19.2px;
  line-height: 28.8px;
  color: color.$dark-text-color;
  margin-bottom: 15px;
}
.buttons_container {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  button {
    border-radius: 10px;
    padding: 10.4px 35.2px;
    font-weight: 600;
    font-size: 19.2px;
    line-height: 28.8px;
    color: #ffffff;
  }
  .confirm {
    background-color:  color.$primary-color;
  }
  .cancel {
    background-color: color.$red-color;
  }
}