@use "../../../styles/variables.scss" as color;
.card_section {
  display: flex;
  flex-direction: column;
  .card_header {
    height: 28px;
    width: 412px;
    background-color: color.$primary-color;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    &.card_headerDark{
      background-color: #015866;
    }
    .date {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      margin-left: 7px;
    }
    .more_icon {
      margin-right: 10px;
      margin-top: -10px;
      cursor: pointer;
    }
  }
  .EdDelDropMYProdDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 15px;
    position: relative;
    justify-content: center;
  }
.EdDelDropMYProd{
  position: absolute;
  top: 100%;
  left: -200px;
  background-color: #fff;
  border: 1px solid #ccc;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 200px;
  &.EdDelDropMYProdDark{
    background-color: color.$dark-text-color;
  }
}
.EdDelDropMYProd button {
  width: 100%;
  text-align: left;
  padding: 5px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  .edit{
      font-size: 15px;
      font-weight: 500;
      display: inline-flex;
      align-items: center;
      margin: 0;
      padding: 0;
      color: color.$primary-color;
      &.editDark{
        color: #015866;
      }
      .ic{
          margin-right:10px ;
          color: color.$primary-color;
          &.icDark{
            color: #015866;
          }
      }
  }
  
  .delete{
      font-size: 15px;
      font-weight: 500;
      color: red;
      display: inline-flex;
      align-items: center;
      margin: 0;
      padding: 0;
      .icd{
          margin-right:10px ;
          color: red;
      }
  }
}
  .card_container {
    border: 2px solid color.$primary-color;
    // filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 10px;
    width: 412px;
    // height: 220px;
    height: 160px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    // &.card_containeractive{
    //   height: 150px;
    // }
    .top {
      padding-left: 8px;
      padding-top: 8px;
      display: flex;
      .image {
        width: 86px;
        height: 86px;
        border-radius: 10px;
        object-fit: cover;
        cursor: pointer;
      }
      .details_container {
        display: flex;
        flex-direction: column;
        margin-left: 15px;
        // row-gap: 20px;
        .price__containermb {
          display: flex;
          align-items: center;
          &.price__containermbdisc{
            display: flex;
          flex-direction: column;
          align-items: normal;
          }
        .pricemb {
          font-weight:700;
          font-size: 14px;
          line-height: 20px;
          color: color.$dark-text-color;
          // margin-left: -50px;
          max-width: 250px; 
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &.pricembDark{
            color: #ffffff;
          }
        }
        .oldmbpd{
          display: flex;
          // align-items: center;
          margin-top: -20px;
        .oldmbprice {
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 40px;
          // margin-left: -40px;
          color: #e76859;
          text-decoration: line-through;
          max-width: 150px; 
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
    
          
        }
        .discountmb{
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 40px;
          margin-left: 10px;
          color: #e76859;
        
      
      }
    }
  }
        .text {
          font-weight: 600;
          font-size: 15px;
          line-height: 11px;
          color: color.$dark-text-color;
          max-width: 200px;
          &.textDark{
            color: white;
          }
        }
        .button_container {
          .waiting_btn {
            background: color.$primary-color;
            border-radius: 5px;
            width: 86px;
            height: 25px;
            font-weight: 600;
            font-size: 9.8px;
            line-height: 15px;
            color: #ffffff;
            &.waiting_btnDark{
              background-color: #015866;
            }
            &:hover{
              background: #01b1c0;
            }
          }
          .waiting_btn1 {
            border: 2px solid color.$primary-color;
            background: #ffffff;
            border-radius: 5px;
            width: 86px;
            height: 25px;
            font-weight: 600;
            font-size: 9.8px;
            line-height: 15px;
            color: color.$primary-color;
            &.waiting_btnDark1{
              border: 2px solid #01b1c0;
              color: #01b1c0;
            }
          }
          .sell_btn {
            margin-left: 5px;
            width: 86px;
            height: 25px;
            font-weight: 600;
            font-size: 9.81132px;
            line-height: 15px;
            color: color.$primary-color;
            background: #ffffff;
            border: 2px solid color.$primary-color;
            border-radius: 5px;
            &.sell_btnDark{
              border: 2px solid #015866;
              color: #015866;
            }
            &:hover{
              border: 2px solid #01b1c0;
              color: #01b1c0;
            }
          }
          .sell_btnf {
            margin-left: 5px;
            width: 86px;
            height: 25px;
            font-weight: 600;
            font-size: 9.81132px;
            line-height: 15px;
            color: color.$primary-color;
            background: #ffffff;
            border: 2px solid color.$primary-color;
            border-radius: 5px;
            cursor: default;
            &.sell_btnfDark{
              border: 2px solid #015866;
              color: #015866;
            }
          
          }
          .end_deal {
            background: #e76859;
            border-radius: 5px;
            width: 86px;
            height: 25px;
            font-weight: 600;
            font-size: 9.8px;
            line-height: 15px;
            color: #ffffff;
            margin-top: 5px;
            &:hover{
              background:#e87567 ;
            }
          }
        }
      }
      .line {
        border-left: 1px solid color.$light-gray-color;
        height: 100px;
        margin-left: auto;
        margin-right: 10px;
        &.active {
          height: 127px;
        }
        &.sold {
          height: 85.02px;
        }
      }
      .info_container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 10px;
        .text {
          font-weight: 400;
          font-size: 13px;
          line-height: 15px;
          color: color.$dark-text-color;
          margin-bottom: 9px;
          &.textDark{
            color:white;
          }
        }
        .icon {
          display: inline-block;
          // font-size: 10px;
          
          scale: 0.75;
          &.iconDark{
            color: white;
          }
        }
        .status_active {
          background: #e1fafe;
          border: 1px solid #98dae0;
          border-radius: 10px;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          color: color.$dark-text-color;
          padding: 2px 10px;
          align-self: center;
        }
        .status_sold {
          background: #ffe4e4;
          border: 1px solid #c95152;
          border-radius: 10px;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          color: color.$dark-text-color;
          padding: 2px 10px;
          align-self: center;
        }
      }
    }
    .sold_line {
      border-top: 1px solid color.$light-gray-color;
      width: 100%;
      margin-top: 5px;
    }
    .bottom_container {
      display: flex;
      align-items: center;
      .buyer_text {
        font-weight: 800;
        font-size: 16.6329px;
        line-height: 25px;
        color: color.$dark-text-color;
        margin-left: 6px;
        margin-right: 15px;
        &.buyer_textDark{
          color:white;
        }
      }
      .buyer_image {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        object-fit: cover;
        margin-top: 5px;
        margin-right: 8px;
      }
      .buyer_details {
        display: flex;
        flex-direction: column;
        gap: 1px;
        .buyer_name {
          font-weight: 600;
          font-size: 10px;
          line-height: 15px;
          color: color.$dark-text-color;
          &.buyer_nameDark{
            color:white;
          }
          .rating {
            margin-left: 9px;
            background: color.$primary-color;
            border-radius: 6px;
            color: white;
            padding: 2px 3px;
            font-weight: 400;
            font-size: 6px;
            line-height: 9px;
            color: color.$light-background-color;
            .star {
              display: inline-block;
            }
          }
        }
        .member {
          font-weight: 400;
          font-size: 10px;
          line-height: 10px;
          color: color.$dark-text-color;
          text-align: start;
          &.memberDark{
            color:white;
          }
        }
        .buyer_link {
          font-weight: 600;
          font-size: 9px;
          line-height: 12px;
          color: color.$primary-color;
          text-decoration: underline;
          &.buyer_linkDark{
            color: #015866;
          }
        }
      }
    }
  }
  @media screen and (max-width: 450px) {
    .card_header {
      height: 23.8px;
      width: 350.2px;
      .date {
        font-size: 13.6px;
        line-height: 20.4px;
      }
      .more_icon{
        height: 15px;
      }
    }
    .card_container {
      width: 350.2px;
      height: 155px;
      .top {
        padding-left: 6.8px;
        padding-top: 6.8px;
        .image {
          width: 73.1px;
          height: 73.1px;
        }
        .details_container {
          margin-left: 12.75px;
          // row-gap: 17px;
         
          .price {
            font-size: 14.137965px;
            line-height: 21.25px;
          }
          .text {
            font-size: 14px;
            line-height:18px;
            max-width: 100px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .button_container {
            .waiting_btn {
              width: 73.1px;
              height: 21.25px;
              font-size: 8.33px;
              line-height: 12.75px;
            }
            .end_deal {
              width: 73.1px;
              height: 21.25px;
              font-size: 8.33px;
              line-height: 12.75px;
            }
            .sell_btn {
              width: 73.1px;
              height: 21.25px;
              font-size: 8.33px;
              line-height: 12.75px;
            }
            .sell_btnf {
              width: 73.1px;
              height: 21.25px;
              font-size: 8.33px;
              line-height: 12.75px;
            }
          }
        }
        .line {
          height: 85px;
          margin-right: 8.5px;
          &.active {
            height: 107.95px;
          }
          &.sold {
            height: 72.267px;
          }
        }
        .info_container {
          .text {
            font-size: 10px;
            line-height: 12.75px;
            margin-bottom: 7.65px;
          }
          .icon {
            scale: 0.6375;
          }
          .status_active {
            font-size: 10.2px;
            line-height: 15.3px;
            padding: 1.7px 8.5px;
          }
          .status_sold {
            font-size: 10.2px;
            line-height: 15.3px;
            padding: 1.7px 8.5px;
          }
        }
      }
      .sold_line {
        margin-top: 4.25px;
      }
      .bottom_container {
                .buyer_text {
          font-size: 14.13px;
          line-height: 21.25px;
          margin-left: 5.1px;
          margin-right: 12.75px;
        }
        .buyer_image {
          width: 30px;
          height: 30px;
          // margin-top: 4.25px;
          margin-right: 6.8px;
        }
        .buyer_details {
          .buyer_name {
            font-size: 8.5px;
            line-height: 12.75px;
            .rating {
              margin-left: 7.65px;
              padding: 1.7px 2.55px;
              font-size: 5.1px;
              line-height: 7.65px;
            }
          }
          .member {
            font-size: 7.65px;
            line-height: 8.5px;
          }
          .buyer_link {
            font-size: 7.65px;
            line-height: 10.2px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 355px) {
    .card_header {
      height: 23.8px;
      width: 250px;
      .date {
        font-size: 10px;
        line-height:15px;
      }
    }
    .card_container {
      width: 250px;
      height: 180px;
      .top {
        padding-left: 6.8px;
        padding-top: 6.8px;
        .image {
          width: 60px;
          height: 60px;
        }
        .details_container {
          margin-left: 10px;
          // row-gap: 8px;
         
          .price__containermb {
          
          .pricemb {
            font-size: 14px;
          }
          .oldmbpd{
          .oldmbprice {
            font-size: 14px;
          }
          .discountmb{
        
            font-size: 12px;
         
          
          
        }
      }
    }
    .text {
      font-size: 14px;
      line-height: 15px; /* Adjust line-height as needed */
      max-width: 50px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
          .button_container {
            .waiting_btn {
              width: 73.1px;
              height: 21.25px;
              font-size: 8.33px;
              line-height: 12.75px;
            }
            .end_deal {
              width: 73.1px;
              height: 21.25px;
              font-size: 8.33px;
              line-height: 12.75px;
            }
            .sell_btn {
              width: 73.1px;
              height: 21.25px;
              font-size: 8.33px;
              line-height: 12.75px;
              margin-left: 0px;
            }
            .sell_btnf {
              width: 73.1px;
              height: 21.25px;
              font-size: 8.33px;
              line-height: 12.75px;
              margin-left: 0px;
            }
            
          }
        }
        .line {
          height: 85px;
          margin-right: 8.5px;
          &.active {
            height: 107.95px;
          }
          &.sold {
            height: 72.267px;
          }
        }
        .info_container {
          .info_text {
            font-size: 8.5px;
            line-height: 12.75px;
            margin-bottom: 7.65px;
          }
          .icon {
            scale: 0.6375;
          }
          .status_active {
            font-size: 10.2px;
            line-height: 15.3px;
            padding: 1.7px 8.5px;
          }
          .status_sold {
            font-size: 10.2px;
            line-height: 15.3px;
            padding: 1.7px 8.5px;
          }
        }
      }
      .sold_line {
        margin-top: 4.25px;
      }
      .bottom_container {
                .buyer_text {
          font-size: 14.13px;
          line-height: 21.25px;
          margin-left: 5.1px;
          margin-right: 12.75px;
        }
        .buyer_image {
          width: 30px;
          height: 30px;
          // margin-top: 4.25px;
          margin-right: 6.8px;
        }
        .buyer_details {
          .buyer_name {
            font-size: 8.5px;
            line-height: 12.75px;
            .rating {
              margin-left: 7.65px;
              padding: 1.7px 2.55px;
              font-size: 5.1px;
              line-height: 7.65px;
            }
          }
          .member {
            font-size: 7.65px;
            line-height: 8.5px;
          }
          .buyer_link {
            font-size: 7.65px;
            line-height: 10.2px;
          }
        }
      }
    }
  }
}
.buttons_container {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  button {
    border-radius: 10px;
    padding: 10.4px 35.2px;
    font-weight: 600;
    font-size: 19.2px;
    line-height: 28.8px;
    color: #ffffff;
  }
  .confirm {
    background-color:  color.$primary-color;
  }
  .cancel {
    background-color: color.$red-color;
  }
}
.confirm_title {
  font-weight: 600;
  font-size: 25.6px;
  line-height: 38.4px;
  color: color.$dark-text-color;
  margin-bottom: 15px;
  &.confirm_titleDark{
    color:white
}
}
.confirm_message {
  font-weight: 500;
  font-size: 19.2px;
  line-height: 28.8px;
  color: color.$dark-text-color;
  margin-bottom: 15px;
  &.confirm_messageDark{
    color:white
}
}