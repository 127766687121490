@use "../../../../styles/variables.scss" as color;
.link {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  &.linkDark{
    color:color.$white-text-color
  }
}
.categories-menu-container {
  position: relative;
  .menu-trigger {
    color: color.$primary-color;
  }
  .triangle {
    content: "";
    position: absolute;
    top: 35px;
    left: 40px;
    height: 20px;
    width: 20px;
    background: color.$background-category-dropdown;
    transform: rotate(45deg);
    border: 1px solid color.$border-category-dropdown;
  }
  .dropdown-menu {
    position: absolute;
    top: 45px;
    left: -14px;
    border-radius: 8px;
    z-index: 3;
  }
  .dropdown-menu.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: 500ms ease;
  }
  .dropdown-menu.inactive {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: 500ms ease;
  }
  .all_categories_dropdown {
    // width: 288px;
    // height: 613px;
    background: color.$background-category-dropdown;
    border: 1px solid color.$border-category-dropdown;
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    .categories__list {
      display: flex;
      flex-direction: column;
    }
  }
}