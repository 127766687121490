@use "../../../styles/variables.scss" as color;
.loader {
  border: 16px solid color.$background-category-dropdown;
  border-top: 16px solid color.$primary-color;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  
  @media screen and (max-width: 750px) {
    width: 80px;
    height: 80px;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
