.location_section {
  margin-top: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 44px;
  .location_container {
    width: 644px;
    height: 178px;
    background: #ffffff;
    border: 3px solid #0298a6;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 22px 16px 15px 52px;
    gap: 46px;
    .image_container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 86px;
      height: 86px;
      border-radius: 50%;
      background: #0298a6;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      .img {
        height: 70%;
      }
    }
    .details_container {
      .title {
        font-weight: 600;
        font-size: 32px;
        line-height: 150%;
        color: #0298a6;
      }
      .text {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #a1a1a1;
        max-width: 444px;
      }
    }
  }
  .vertical_line {
    height: 196.9px;
    border-left: 1px solid #000000;
  }
  .new_address_container {
    .new_address_text {
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: #000000;
    }
    .location {
      margin-top: 42px;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      border-radius: 10px;
      width: 542px;
      height: 74px;
      padding-left: 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-weight: 500;
      font-size: 21px;
      line-height: 32px;
      color: #c2c2c2;
      position: relative;
      .icon {
        position: absolute;
        top: 50%;
        right: 24px;
        transform: translateY(-50%);
      }
    }
  }
  @media screen and (max-width: 1370px) {
    column-gap: 30.8px;
    .location_container {
      width: 450.8px;
      height: 124.6px;
      padding: 15.4px 11.2px 10.5px 36.4px;
      gap: 32.2px;
      .image_container {
        width: 60.2px;
        height: 60.2px;
      }
      .details_container {
        .title {
          font-size: 22.4px;
          line-height: 105%;
        }
        .text {
          font-size: 14px;
          line-height: 21px;
          max-width: 310.8px;
        }
      }
    }
    .vertical_line {
      height: 137.83px;
    }
    .new_address_container {
      .new_address_text {
        font-size: 14px;
        line-height: 21px;
      }
      .location {
        margin-top: 29.4px;
        width: 379.4px;
        height: 51.8px;
        padding-left: 16.8px;
        font-size: 14.7px;
        line-height: 22.4px;
      }
    }
  }
  @media screen and (max-width: 1020px) {
    flex-direction: column;
    .vertical_line {
      display: none;
    }
    .new_address_container {
      margin-top: 30px;
    }
  }
  @media screen and (max-width: 530px) {
    column-gap: 30.8px;
    .location_container {
      width: 315.56px;
      height: 87.22px;
      padding: 10.78px 7.84px 7.35px 25.48px;
      gap: 22.54px;
      .image_container {
        width: 42.14px;
        height: 42.14px;
      }
      .details_container {
        .title {
          font-size: 15.68px;
          line-height: 73.5%;
        }
        .text {
          font-size: 12px;
          line-height: 16px;
          max-width: 217.56px;
        }
      }
    }
    .new_address_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      .new_address_text {
        font-size: 14px;
        line-height: 21px;
      }
      .location {
        margin-top: 10px;
        width: 90vw;
      }
    }
  }
}
