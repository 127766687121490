
.listb{
    background-color: #0298a6;
    color: white;
    width: 200px;
    height: 50px;
    border-radius: 3%;
    display: flex;
    align-items: center;
    padding-left: 10px;
    
}
.drop{
    margin-top: 5px;
    position:absolute;
    z-index:1;
    border: 1px solid #0298a6;
}
@media screen and (max-width:890px) {
    .listb{
        width: 200px;
        
    }
}