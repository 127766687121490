@use "../../../styles/variables.scss" as color;
.section {
  max-width: 986px;
  margin-top: 25px;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .image_container {
      display: flex;
      align-items: center;
      .image {
        width: 71px;
        height: 71px;
        border-radius: 50%;
        margin-right: 15px;
        object-fit: cover;
      }
      .name_container {
        .name {
          font-weight: 600;
          font-size: 16px;
          line-height: 150%;
          color: color.$dark-text-color;
          &.nameDark{
            color: color.$white-text-color;
          }
        }
        .star {
          display: flex;
          font-size: 15px;
          color: color.$yellow-color;
        }
      }
    }
    .time {
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      &.timeDark{
        color: color.$white-text-color;
      }
    }
  }
  .text {
    margin-top: 25px;
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
    color: color.$dark-text-color;
    &.textDark{
      color: color.$white-text-color;
    }
  }
  .line {
    border-top: 1px solid #000000;
    margin-top: 25px;
    &.lineDark{
      border-top: 1px solid color.$white-text-color;
    }
  }
}
