@use "../../../styles/variables.scss" as color;
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 45px;
  .img {
    margin-bottom: 25.9px;
  }
  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: color.$dark-text-color;
    &.titleDark{
      color: white;
    }
  }
  .text {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: color.$light-gray-color;
    margin-top: 9px;
  }
  .button {
    margin-top: 45px;
    margin-bottom: 45px;
    background: color.$primary-color;
    border-radius: 10px;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: white;
    padding: 9px 100px;
    &.buttonDark{
      background-color: #015866;
    }
    &:hover{
      background-color: #01b1c0;
    }
  }
  @media screen and (max-width: 620px) {
    .img {
      margin-bottom: 20.72px;
    }
    .title {
      font-size: 19.2px;
      line-height: 28.8px;
    }
    .text {
      font-size: 16px;
      line-height: 24px;
      margin-top: 7.2px;
    }
    .button {
      margin-top: 36px;
      font-size: 19.2px;
      line-height: 28.8px;
      padding: 7.2px 80px;
    }
  }
}
