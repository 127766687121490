.main_Title{
    font-size: 25px;
    color: #0298a6;
    // &.main_TitleDark{
    //     color: #015866;
    // }
}
.sub_Title{
    font-size: 23px;
    color: #0298a6;
//     &.sub_TitleDark{
//         color: #015866;
//     }
}
.sub_sub_Title{
    font-size: 20px;
    color: #0298a6;
    // &.sub_subTitleDark{
    //     color: #015866;
    // }
}