@use "../../styles/variables.scss" as color;
.container{
    background-color: white;
    width: 100%;
    height: 87%;
    h6{
        padding: 2%;
    }
    .picture_container{
        margin-left: 10px;
        display: flex;
        
        .pp{
            width: 70px;
            height: 70px;
            border-radius: 50%;
        }
        .change_image{
            border: 1px solid #0298A6;
            border-radius: 8px;
            color: #0298A6;
            height: 30px;
            width: 120px;
            margin-left: 6px;
            margin-top: 15px;
        }
        .remove_image{
            color: #727A84;
            font-size: 14px;
            font-weight: 600;
            margin-left: 6px;
            margin-top: -8px;
        }
    }
        .edit_section {
            margin-top: 77px;
 
            .form_container {
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
              column-gap: 10px;
              margin-top: 37px;
              margin-bottom: 65px;
        
              .label_input_container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width:450px;
                margin-right: 20px;
                margin-left: 20px;
        
                .label {
                  font-weight: 500;
                  font-size: 20px;
                  line-height: 150%;
                  color: color.$dark-text-color;
                  &.labelDark{
                    color:color.$white-text-color;
                  }
                }
               
                .input_container {
                  position: relative;
                
                    
                  .input {
                    background: #ffffff;
                    border: 1px solid #d9d9d9;
                    border-radius: 10px;
                    width: 250px;
                    height: 50px;
        
                    font-weight: 500;
                    font-size: 21px;
                    line-height: 32px;
                    color: color.$dark-text-color;
                    padding-left: 72px;
                    &.inputDark{
                      background: #015866;
                      color:rgb(207, 203, 203);
                      border:1px solid #015866
                    }
                  }
                  .input_icon {
                    position: absolute;
                    left: 26px;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 19px;
                    color: color.$dark-text-color;
                  }
                }
                .phonei{
                    width: 250px;
                    height: 50px;
                  &.phoneiDark{
                    background-color:#015866;
                    border: none;
                  }
                  
                  ::placeholder {
                    background-color: transparent;
                  }
                }
                
              }
            }
            .button_container {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                margin-right: 30px;
                .update_btn {
                  font-weight: 500;
                  font-size: 24px;
                  line-height: 150%;
                  color: white;
                  background-color: color.$primary-color;
                  padding: 10px 15px;
                  border-radius: 10px;
                  margin-bottom: 10px;
                  &:hover{
                    transform: scale(1.03);
              
                  }
                }
            }
          }
          .wrappas{
            display: flex;
            justify-content: center;
            align-items: center;
          .changePassSec{
            margin-top: 45px;
            margin-bottom: 150px;
            width: 709px;
            height: 500px;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            p{
                color:#A1A1A1;
            }
          .input_container {
            margin-top: 23px;
            position: relative;
            .input {
              background: #ffffff;
              border: 1px solid #d9d9d9;
              border-radius: 10px;
              width: 423px;
              height: 58px;
              padding-left: 94px;
              &.inputDark{
                background: #015866;
                color:rgb(207, 203, 203)
              }
            }
            .pass_icon {
              position: absolute;
              right: 25px;
              top: 50%;
              transform: translateY(-50%);
              color: color.$light-gray-color;
              font-size: 20px;
              cursor: pointer;
            }
            .icon {
              position: absolute;
              left: 38px;
              top: 50%;
              transform: translateY(-50%);
              color: color.$light-gray-color;
              font-size: 20px;
            }
            p {
              font-weight: 400;
              font-size: 11.2px;
              line-height: 16.8px;
              color: color.$error_color;
              position: absolute;
              // bottom: -17px;
              left: 6px;
            }
          }
          .buttonpas_container {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            margin-top: 30px;
              .updatepas_btn {
                font-weight: 500;
                font-size: 24px;
                line-height: 150%;
                color: white;
                background-color: color.$primary-color;
                padding: 10px 15px;
                border-radius: 10px;
                margin-bottom: 10px;
                &:hover{
                  background: #01b1c0;            
                }
              }
          }
        }
    }
    @media screen and (max-width: 1357px) {
        .edit_section {
            margin-top: 77px;
 
            .form_container {
              .label_input_container {
                display: block;
              }
            }
        }
    }
    @media screen and (max-width: 700px) {
        width: 90%;
    }
    @media screen and (max-width: 550px) {
        .wrappas{
          .changePassSec{
            width: 250px;
            height: 500px;
          .input_container {
            margin-top: 23px;
            position: relative;
            .input {
                width: 250px;
                font-size: 13px;
            }
        }
    }
}
    
    }
    @media screen and (max-width: 385px) {
        margin-left: -20px;
        width: 95%;
        .edit_section {
            .form_container {    
              .label_input_container {
                .input_container{
                  .input {
                    width: 200px;
                  }
                 
                }
                .phonei{
                    width: 200px;
                }
            }
            }
        }
    
    }
}