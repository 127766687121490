@use "../../../../styles/variables.scss" as color;

.overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3000;
}

.wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3001;
  background-color: white;
  width: 532px;
  height: 556.8px;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &.wrapperdark {
    background-color: #353844;
  }

  .close {
    cursor: pointer;
    position: absolute;
    top: 13.3px;
    right: 13.3px;
    width: 32px;
    height: 32px;
  }

  .title {
    margin-top: 53.6px;
    font-weight: 600;
    font-size: 28px;
    line-height: 41.6px;
    text-align: center;
    color: #353844;

    &.titledark {
      color: white;
    }
  }

  .amount {
    margin-top: 13.6px;
    font-weight: 600;
    font-size: 38.4px;
    line-height: 57.6px;
    text-align: center;
    color: #353844;

    &.amountdark {
      color: white;
    }
  }

  .container {
    margin-top: 13.6px;
    width: 100%;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 10px 10px 0px 0px;

    .suggestions {
      display: flex;
      justify-content: space-around;
      font-weight: 500;
      font-size: 19.2px;
      line-height: 28.8px;
      text-align: center;
      color: #0298a6;
    }

    .calculator {
      display: flex;
      justify-content: space-around;
      font-weight: 500;
      font-size: 25.6px;
      line-height: 38.4px;
      text-align: center;
      color: #353844;
      border-bottom: 1px solid rgb(237, 235, 235);

      span {
        cursor: pointer;
        position: relative;

        &.spandark {
          color: white;
        }

      }

      .icon {
        position: absolute;
        top: 10%;
        right: -7px;
      }
    }
  }

  .confirmLd {
    margin-top: 31.2px;
    background: gray;
    border-radius: 10px;
    width: 236.8px;
    height: 50.104px;
    font-weight: 500;
    font-size: 19.2px;
    line-height: 28.8px;
    color: white;

  }

  .confirm {
    margin-top: 31.2px;
    background: #0298a6;
    border-radius: 10px;
    width: 236.8px;
    height: 50.104px;
    font-weight: 500;
    font-size: 19.2px;
    line-height: 28.8px;
    color: white;

    &.confirmDark {
      background-color: #015866;
    }

    &.disabled {
      background: #ace0e0;
    }
  }

  @media screen and (max-width: 660px) {
    width: 90vw;
    height: auto;

    .title {
      margin-top: 42.88px;
      font-size: 22.4px;
      line-height: 33.28px;
    }

    .amount {
      margin-top: 10.88px;
      font-size: 30.72px;
      line-height: 46.08px;
    }

    .container {
      margin-top: 10.88px;

      .suggestions {
        font-size: 15.36px;
        line-height: 23.04px;
      }

      .calculator {
        font-size: 20.48px;
        line-height: 30.72px;

        .icon {
          position: absolute;
          top: 10%;
          right: -7px;
        }
      }
    }

    .confirm {
      margin-bottom: 31.2px;
      width: 189.44px;
      height: 40.0832px;
      font-size: 15.36px;
      line-height: 23.04px;
    }

    .confirmLd {
      margin-bottom: 31.2px;
      width: 189.44px;
      height: 40.0832px;
      font-size: 15.36px;
      line-height: 23.04px;
    }
  }
}