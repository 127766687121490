@use "../../../styles/variables.scss" as color;
.overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
}
.join {
  background: #006770;
  border-radius: 10px;
  color: #ffffff;
  font-weight: 500;
  font-size: 22px;
  line-height: 150%;
  width: 143px;
  height: 52px;
  align-self: center;
  margin-top: 34.5px;
  margin-bottom: 33px;
}
.modal_styles {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2001;
  background-color: white;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  &.modal_stylesDark{
    background: color.$dark-text-color;
  }
  .close {
    cursor: pointer;
    position: absolute;
    top: 13.3px;
    right: 13.3px;
    width: 32px;
    height: 32px;
    &.closeDark{
      background-color:#98dae0;
      border-radius: 50%;
    }
   
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .title {
      font-weight: 600;
      font-size: 20.48px;
      line-height: 30.72px;
      color: color.$dark-text-color;
      margin-top: 73.6px;
      
      &.titleDark{
        color:white;
      }
    }
    .type_container {
      margin-top: 94.08px;
      display: flex;
      column-gap: 88.32px;
      .card_container {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        .card {
          border: 5px solid #98dae0;
          border-radius: 10px;
          width: 152.96px;
          height: 152.4032px;
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover{
            transform: scale(1.1);
            transition: transform 0.3s ease-in-out;
          }
          .img {
            width: 70%;
          }
        }
        .orDark{
          color:color.$white-text-color
        }
        .type {
          text-align: center;
          font-weight: 600;
          font-size: 20.48px;
          line-height: 30.72px;
          color: color.$dark-text-color;
          margin-top: 27.52px;
          &.typeDark{
            color:color.$white-text-color
          }
        }
      }
      .middle {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 9px;
        .or{
          font-weight: 600;
          font-size: 20.48px;
          line-height: 30.72px;
          color: color.$dark-text-color;
          &.orDark{
            color: color.$white-text-color;
          }
        }
        .line {
          height: 112px;
          border-left: 3px solid color.$dark-text-color;
          &.lineDark{
            border-left: 3px solid color.$white-text-color;
          }
        }
      }
    }
  }
  @media screen and (max-width: 750px) {
    width: 100vw;
    height: auto;
    .container {
      margin-bottom: 20px;
      .type_container {
        margin-top: 50px;
        flex-direction: column;
        align-items: center;
        .card_container {
          .type {
            margin-top: 17px;
          }
        }
        .middle {
          flex-direction: row;
          column-gap: 9px;
          margin-top: 20px;
          margin-bottom: 20px;
          .line {
            height: 3px;
            width: 200px;
            border-left: none;
            border-top: 3px solid color.$dark-text-color;
          }
        }
      }
    }
  }
  @media screen and (max-width: 620px) {
    .container {
      .title {
        text-align: center;
        font-size: 16.384px;
        line-height: 24.576px;
      }
      .type_container {
        .middle {
          .line {
            width: 120px;
          }
        }
      }
    }
  }
}
.section {
  margin-top: 60px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  gap: 69px;
  .left_side {
    display: flex;
    flex-direction: column;
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .description_container {
      max-width: 580px;
      margin-top: -80px; 
      .title {
        font-weight: 600;
        font-size: 32px;
        line-height: 48px;
        color: #353844;
        &.titleDark{
          color:white;
        }
      }
      .text {
        margin-top: 21px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #4a4a4b;
        max-width: 400px;
        word-wrap: break-word;
        white-space: pre-wrap;
        &.textDark{
          color:white;
        }
      }
    }
    .table_container {
      color: #353844;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin-top: 70px;
      margin-right: 50%;
      &.table_containerDark{
        color:color.$white-text-color;
        tr {
          height: 74px;
          width: 100%;
  
          &:nth-child(even) {
            background-color: #015866; 
          }
        }
      }
      table {
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        width: 588px;
      }
      tr {
        height: 74px;
        width: 100%;
        &:nth-child(even) {
          background-color: #f3f3f3;
        }
      }
      td {
        padding: 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  .right_side {
    width: 576px;
    background: #f8f8f9;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    &.right_sideDark{
      background:#015866;
    }
    .wrapper {
      padding: 35px;
      display: flex;
      flex-direction: column;
      .user_container {
        margin: 45px auto;
      }
      .price {
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
        color: #353844;
        &.priceDark{
          color:color.$white-text-color;
        }
      }
      .description {
        margin-top: 18px;
        max-width: 470px;
        font-weight: 600;
        word-wrap: break-word;
        font-size: 24px;
        line-height: 36px;
        color: #353844;
        margin-bottom: 35px;
        &.descriptionDark{
          color:color.$white-text-color;
        }
      }
      .location {
        font-weight: 400;
        font-size: 24px;
        line-height: 36px;
        color: #4a4a4b;
        margin-bottom: 29px;
        display: flex;
        align-items: center;
        gap: 5px;
        &.locationDark{
          color: white;
        }
        .icon {
          display: inline-block;
          color: #0298a6;
          font-size: 30px;
        }
      }
      .line {
        border-top: 2px solid #9d9d9d;
        width: 100%;
      }
    }
    .safety_container {
      padding-left: 10px;
      padding-right: 10px;
      .safety_title {
        font-weight: 600;
        font-size: 32px;
        line-height: 48px;
        color: #353844;
        margin-bottom: 37px;
        &.safety_titleDark{
          color: white;
        }
      }
      ul {
        list-style: circle;
      }
      li {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #4a4a4b;
        margin-bottom: 14px;
        &.liDark{
          color: white;
        }
      }
    }
  }
  @media screen and (max-width: 1450px) {
    justify-content: space-between;
    gap: 0;
  }
  @media screen and (max-width: 1380px) {
    justify-content: center;
    gap: 50px;
    flex-wrap: wrap;
    .left_side {
      .description_container {
        max-width: 406px;
        margin-top: -30px;
        .title {
          font-size: 22.4px;
          line-height: 33.6px;
        }
        .text {
          margin-top: 14.7px;
          font-size: 11.2px;
          line-height: 16.8px;
        }
      }
      .table_container {
        font-size: 11.2px;
        line-height: 16.8px;
        margin-top: 49px;
        table {
          width: 411.6px;
        }
        tr {
          height: 51.8px;
        }
        td {
          padding: 17.5px;
        }
      }
    }
    .right_side {
      width: 403.2px;
      .wrapper {
        padding: 24.5px;
        .user_container {
          margin: 2px auto;
        }
        .price {
          font-size: 22.4px;
          line-height: 33.6px;
        }
        .description {
          margin-top: 12.6px;
          max-width: 329px;
          font-size: 16.8px;
          line-height: 25.2px;
          margin-bottom: 24.5px;
        }
        .location {
          font-size: 16.8px;
          line-height: 25.2px;
          margin-bottom: 20.3px;
          .icon {
            margin-right: 3.5px;
            font-size: 21px;
          }
        }
      }
      .safety_container {
        padding-left: 7px;
        padding-right: 7px;
        .safety_title {
          font-size: 22.4px;
          line-height: 33.6px;
          margin-bottom: 25.9px;
        }
        li {
          font-size: 11.2px;
          line-height: 16.8px;
          margin-bottom: 9.8px;
        }
      }
    }
  }
  @media screen and (max-width: 1070px) {
    flex-direction: column;
    align-items: center;
    .right_side {
      margin-left:-70px;
      .wrapper {
        padding: 10px;
      }
    }
  }
  @media screen and (max-width: 520px) {
    .left_side {
      .description_container {
        max-width: 324.8x;
        margin-top: -80px;
        .title {
          font-size: 17.92px;
          line-height: 26.88px;
        }
        .text {
          margin-top: 11.76px;
          font-size: 8.96px;
          line-height: 13.44px;
        }
      }
      .table_container {
        font-size: 8.96px;
        line-height: 13.44px;
        margin-top: 39.2px;
        table {
          width: 329.28px;
        }
        tr {
          height: 41.44px;
        }
        td {
          padding: 14px;
        }
      }
    }
    .right_side {
      width: 330px;
      margin-left:0;
      .wrapper {
        padding: 10px;
      }
    }
  }
  @media screen and (max-width: 400px) {
    align-items: flex-start;
  }
}
.fixed_container {
  position: fixed;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  .fixed_button {
    background-color: #0298a6;
    color: white;
    width: 96px;
    height: 71px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #f3f2f2;
    margin-bottom: 1px;
    &.first {
      border-radius: 4px 0px 0px 0px;
    }
    &.third {
      border-radius: 0px 0px 0px 4px;
    }
    .fixed_icon {
      display: block;
      margin: 0 auto;
      width: 26px;
      height: 26px;
    }
  }
  @media screen and (max-width: 1000px) {
    .fixed_button {
      width: 67.2px;
      height: 49.7px;
      font-size: 9.8px;
      line-height: 14.7px;
    }
    .fixed_icon {
      // width: 14px;
      height: 14px;
    }
  }
}
