@use "../../../../styles/variables.scss" as color;
.section {
  padding: 60px 0;
  @media screen and (max-width: 950px) {
    padding: 60px 0;
  }
  &.sectionDark{
    background-color: color.$dark-text-color;
  }
  @media screen and (max-width: 570px) {
    padding: 45px 0;
  }
  @media screen and (max-width: 410px) {
    padding: 10px 0;
  }
}
.flex {
  padding: 0px 15px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 13px;
  .title {
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    padding-left: 20px;
    color: color.$dark-text-color;
    @media screen and (max-width: 950px) {
      padding-left: 5px;
    }
    &.titleDark{
      color:color.$white-text-color;
    }
  }
  .all {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: color.$primary-color;
    cursor: pointer;
    // padding-right: 45px;
    // @media screen and (max-width: 1440px) {
    //   padding-right: 30px;
    // }
    // @media screen and (max-width: 570px) {
    //   padding-right: 10px;
    // }
    .S_all{
      text-decoration: none;
      color:color.$primary-color;
      padding-right: 45px;
      @media screen and (max-width: 1440px) {
        padding-right: 30px;
      }
      @media screen and (max-width: 570px) {
        padding-right: 10px;
      }
      &:hover{
        color:#01b1c0 ;
      }
      &.S_allDark{
        color:#015866;
      }
    }
    
    .arrow {
      transform: translateY(-5%);
      display: inline-block;
    }
  }
  @media screen and (max-width: 570px) {
    padding: 0px;
    
    .title {
      font-size: 20px;
    }
    .all {
      font-size: 18px;
    }
  }
  }
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  &:hover{
    transform: scale(1.03);
  }
  .card {
    width: 193.97px;
    height: 193.97px;
    background: #f8feff;
    border: 5px solid color.$secondary-color;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 10px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    &.cardDark{
      background-color:#015866;
    }
   
    .image {
      width: 50%;
    }
  }
  .dummy_content {
    width: 100px;
    height: 193.97px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}
  .name {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    margin-top: 12px;
    cursor: pointer;
    &.nameDark{
      color:color.$white-text-color
      
    }
  }
  @media screen and (max-width: 950px) {
    .swiperS {
      flex-basis: 10%;
    }
    .card {
      width: 130px;
      height: 130px;
      border: 5px solid color.$secondary-color;
      .image {
        max-width: 90px;
      }
    }
  }
  @media screen and (max-width: 650px) {
    .swiperS {
      flex-basis: 25%;
    }
    .card {
      width: 100px;
      height: 100px;
      border: 3px solid color.$secondary-color;
      // .image {
      //   max-width: 60px;
      // }
    }
    .name{
      font-size: 18px;
    }
  }
  @media screen and (max-width: 465px) {
    .swiperS {
      flex-basis: 25%;
    }
    .card {
      width: 90px;
      height: 90px;
      border: 2px solid color.$secondary-color;
      box-shadow: 0px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
      // .image {
      //   max-width: 50px;
      // }
    }
    .name{
      font-size: 15px;
    }
  }
  @media screen and (max-width: 391px) {
    .swiperS {
      flex-basis: 25%;
    }
    .card {
      width: 80px;
      height: 80px;
      border: 3px solid color.$secondary-color;
      box-shadow: 0px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
      
      // .image {
      //   max-width:50px;
      // }
    }
    .name{
      font-size: 15px;
    }
  }
  @media screen and (max-width: 321px) {
    .swiperS {
      flex-basis: 25%;
    }
    .card {
      width: 60px;
      height: 60px;
      border: 3px solid color.$secondary-color;
      box-shadow: 0px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
      
      // .image {
      //   max-width: 70px;
      // }
    }
    .name{
      font-size: 12px;
    }
  }
}
.containerSkel {
  display: flex;
  // align-items: center;
  overflow: hidden;    
  padding-left:60px;
  .cardSkell {
    width: 193.97px;
    height: 193.97px;
    background-color: #E8E8E8;;
    border-radius: 10px;
    display: flex;
    margin-right: 50px;
    position: relative;
    justify-content: space-between;
    overflow: hidden;    
    .imageSkel {
      max-width: 150px;
    }
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, transparent, #E8E8E8, transparent);
    animation: slide-in 1s ease-in-out infinite;
  }
@keyframes slide-in {
0% {
  left: -100%;
}
100% {
  left: 100%;
}
}
@media screen and (max-width: 950px) {
 
  .cardSkell {
    width: 170px;
    height: 130px;
    padding-left: 10px;
    .imageSkel {
      max-width: 90px;
    }
  }
}
@media screen and (max-width: 650px) {
  
  .cardSkell {
    width: 100px;
    height: 100px;
    .imageSkel {
      max-width: 90px;
    }
  }
  .nameSkel{
    font-size: 18px;
  }
}
@media screen and (max-width: 530px) {
  .cardSkell {
    width: 100px;
    height: 100px;
    margin-right: 10px;
   
    .imageSkel {
      max-width: 90px;
    }
  }
  .nameSkel{
    font-size: 15px;
  }
}
@media screen and (max-width: 450px) {
 
  .cardSkell {
    width: 80px;
    height: 80px;
    margin-right: 10px;
    .imageSkel {
      max-width: 90px;
    }
  }
  .nameSkel{
    font-size: 15px;
  }
}
@media screen and (max-width: 350px) {
  
  .cardSkell {
    width: 60px;
    height: 60px;
    margin-right:10px ;
    .imageSkel {
      max-width: 100px;
    }
  }
  .nameSkel{
    font-size: 12px;
  }
}
}
@media screen and (max-width: 1400px) {
  .swiperS {
    flex-basis:25%;
  }
}
