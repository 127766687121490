.chatInput{
    background-color: #D9D9D9;
    height: 50px;
}
.blockedContent{
  background-color: #0298a6;
  height: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.blktxt {
  margin-right: 10px;
  color: white;
  font-weight: 600;
  margin-top: 11px;
}
.blkbtn {
  display: flex;
  align-items: center;
  background-color: #BA2C2D;
  border: none;
  padding: 5px 10px;
  border-radius: 6px;
  cursor: pointer;
  color: white;
}
.blkic{
  padding-right: 2px;
}
.inputContainerin {
    display: flex;
    align-items: center;
  }
  
  .iconin {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 8px;
    font-size: 20px;    
    cursor: pointer;
  }
  
  .inputField {
    margin-top: 10px;
    width:100%;
    height: 30px;
    border-radius:7px ;
    padding-left: 10px;
    &::placeholder{
        font-size: 12px;
       
    }
    font-size: 14px;
  }
  @media screen and (max-width: 460px) {
    .blktxt {
      font-size: 12px;
    }
    
    .blkbtn {
      width: 80px;
      font-size: 10px;
    }
  }