@use "../../../../styles/variables.scss" as color;
.sell_section {
  padding: 23px 65px;
  &.sell_sectionDark{
    background-color: color.$dark-text-color;
  }
  .title {
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    text-align: center;
    color: color.$dark-text-color;
    &.titleDark{
      color:white
    }
  }
  .search_container {
    margin-top: 45px;
    margin-bottom: 45px;
  }
  .how_to_sell {
    width: 85vw;
    height: 290.21px;
    margin: 0 auto;
    background-color: color.$primary-color;
    position: relative;
    margin-bottom: 280px;
    &.how_to_sellDark{
      background-color: #1e4951;
    }
    .how_title {
      font-weight: 500;
      font-size: 36px;
      line-height: 54px;
      text-align: center;
      color: white;
    }
    .card_container {
      position: absolute;
      right: 50%;
      bottom: 0;
      transform: translate(50%, 50%);
      display: flex;
      flex-direction: row;
      gap: 25px;
    }
  }
  @media screen and (max-width: 900px) {
    .how_to_sell {
      height: 650px;
      .card_container {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        top: 15%;
        right: 50%;
        transform: translate(50%, 0);
        width: 70%;
      }
    }
  }
  @media screen and (max-width: 612px) {
    padding: 23px 40px;
    .title {
      font-size: 28.8px;
      line-height: 43.2px;
      margin-top: 10px;
    }
    .how_to_sell {
      height: 1500px;
      margin-bottom: 50px;
      .card_container {
        top: 5%;
      }
    }
  }
  @media screen and (max-width: 450px) {
    padding: 23px 25px;
  }
}
