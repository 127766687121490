@use "../../../styles/variables.scss" as color;
.overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
}
.modal_styles {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2001;
  background-color: white;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 716.8px;
  height: 550.2px;
  @media screen and (max-width: 750px) {
    width: 80vw;
    height: auto;
  }
  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    .close {
      position: absolute;
      top: 13.3px;
      right: 13.3px;
      width: 32px;
      height: 32px;
      cursor: pointer;
    }
    .back {
      position: absolute;
      top: 13.3px;
      left: 13.3px;
      width: 32px;
      height: 32px;
      cursor: pointer;
    }
    .logo {
      font-weight: 500;
      font-size: 36px;
      line-height: 54px;
      text-align: center;
      color: color.$primary-color;
      margin-top: 60px;
      margin-bottom: 40px;
    }
    .verification_container {
      width: 75%;
      margin: 0 auto;
      .verification_title {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: #353844;
        margin-bottom: 23px;
      }
      .verification_text {
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #8d8d8d;
        margin-bottom: 23px;
        .email {
          display: block;
          color: color.$primary-color;
        }
      }
      .code_container {
        width: 100%;
        height: 56px;
        border: 1px solid color.$border-category-dropdown;
        border-radius: 10px;
        position: relative;
        .code_input {
          background: #f8f8f9;
          width: 100%;
          height: 100%;
          border-radius: 10px;
          padding-left: 20px;
          &:focus {
            outline: none;
          }
        }
        .send_btn {
          position: absolute;
          right: -1px;
          top: -1px;
          height: 56px;
          padding: 0 30px;
          background: #ffffff;
          border: 1px solid color.$border-category-dropdown;
          border-radius: 0px 8px 8px 0px;
          font-weight: 500;
          font-size: 18px;
          line-height: 30px;
          color: color.$dark-text-color;
        }
      }
    }
    .confirm_btn {
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: #ffffff;
      background: #0298a6;
      border-radius: 10px;
      padding: 12px 51px;
      margin-top: 82px;
      margin-bottom: 82px;
    }
    @media screen and (max-width: 590px) {
      .close {
        width: 22px;
        height: 22px;
      }
      .back {
        width: 22px;
        height: 22px;
      }
      .logo {
        font-size: 25.2px;
        line-height: 37.8px;
      }
      .verification_container {
        width: 80%;
        .verification_title {
          font-size: 14px;
          line-height: 21px;
        }
        .verification_text {
          font-size: 12.6px;
          line-height: 18.9px;
        }
        .code_container {
          height: 39.2px;
          .code_input {
            font-size: 12.6px;
            line-height: 21px;
          }
          .send_btn {
            height: 39.2px;
            padding: 0 21px;
            font-size: 12.6px;
            line-height: 21px;
          }
        }
      }
      .confirm_btn {
        font-size: 16.8px;
        line-height: 25.2px;
        padding: 8.4px 35.7px;
        margin-top: 57.4px;
        margin-bottom: 57.4px;
      }
    }
  }
}
