@use "../../styles/variables.scss" as color;
.chat_page {
  display: flex;
  height: 700px;
}
.startchatDark{
  color: white;
}
.findtxtDark{
  color:whitesmoke
}
.noconv{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  .nochatp{
    width: 150px;
    height: 150px;
  }
  .findtxt{
    font-size: 14px;
    color:rgb(76, 76, 76);
  }
  .goshop{
    box-sizing: border-box;
    background: #0298A6;
    border-radius: 10px;
    color: white;
    font-weight: 600;
    margin-bottom: 10px;
    width: 280px;
    height: 40px;
    &:hover{
      background: #01b1c0;
    }
    
  }
  .goshopDark{
    box-sizing: border-box;
    background: #015866 ;
    border-radius: 10px;
    color: white;
    font-weight: 600;
    margin-bottom: 10px;
    width: 280px;
    height: 40px;
  
      &:hover{
        background: #01b1c0;  
      
    }
  }
}
.left {
  flex: 1;
  background-color: #F8F8F9;
  margin-bottom: 5%;
  flex-direction: column;
  border-top: 2px solid #efefef;
  max-height: 600px;
  overflow-y: auto;
  border-right: 2px solid rgba(0, 0, 0, 0.2);
  margin-top: 0;
  margin-left: 0;
}
.leftDark {
  flex: 1;
  background-color:#2a2b2e;
  margin-bottom: 5%;
  flex-direction: column;
  border-top: 2px solid #585858;
  max-height: 600px;
  overflow-y: auto;
  border-right: 2px solid rgba(0, 0, 0, 0.2);
  margin-top: 0;
  margin-left: 0;
}
.right {
  flex: 2;
  border-right: 2px solid rgba(0, 0, 0, 0.2);
  max-height: 600px;
  .nochat {
    width: 30vw;
    height: auto;
  }
}
.right_open {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .nochat {
    width: 30vw;
    height: auto;
  }
}
.go_back_button{
  display: none;
}
.cardbut{
  color: #0298A6;
  font-weight: 600;
  margin-top: -30px;
}
.prodcard{
  width: 400px;
  margin-left: 50px;
  margin-top: -180px;
  margin-bottom: 15px;
  transform: translatey(0);
  transition: transform 3s ease;
}
.prodcardhidden {
  transform: translatey(100%);
  margin-top: 0;
  margin-bottom: 0;
  // display: none;
}

@media screen and (min-width: 1800px) {
  .left {
    max-height: 850px;
    height: 850px;

  }
  .leftDark {
    height: 850px;
    max-height: 850px;
  }
  .right {
    height: 850px;
    max-height: 850px;

  }
}
@media screen and (min-width: 1600px) and (max-width:1799px) {
  .left {
    max-height: 750px;
    height: 750px;

  }
  .leftDark {
    height: 750px;
    max-height: 750px;
  }
  .right {
    height: 750px;
    max-height: 750px;

  }
}
@media screen and (max-width: 830px) {
  .chat_page {
    flex-direction: column;
  }
  
  .left.hidden {
    display: none;
  }
  .right {
    flex: initial;
    border-right: none;
    max-height: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  }
  .right_open {
    flex: initial;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .nochat {
    display: none;
  }
  .startchat{
    display: none;
  }
  .startchatDark{
    display: none;
  }
  .go_back_button{
    display:flex;
    color: color.$primary_color;
    font-size: large;
    cursor: pointer;
  }
}
