.wrapper {
  display: flex;
  justify-content: space-between;
  .aside {
    width: 25%;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 41px;
    .btn_container {
      align-self: flex-start;
      margin-top: 12px;
      font-weight: 500;
      font-size: 28px;
      line-height: 150%;
      display: flex;
      flex-direction: column;
      gap: 6px;
      align-items: flex-start;
      .share {
        color:#0298a6;
       display: flex;
       cursor: pointer;
      }
      
      .shareic {
        margin-top: 2px;
        margin-right: 5px;
        // font-size: 21px;
      }
      .block {
        color: #f73434;
      }
      .report {
        color: #0298a6;
      }
    }
  }
  .confirm_title {
    font-weight: 600;
    font-size: 25.6px;
    line-height: 38.4px;
    margin-bottom: 15px;
    &.confirm_titleDark{
        color:white
    }
  }
  
 
  .main_section {
    width: 65%;
    margin-top: 49px;
    margin-bottom: 50px;
    .mobile_section {
      display: none;
    }
    .user_info_container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .name_container {
        display: flex;
        align-items: center;
        gap: 66px;
        .name {
          font-weight: 600;
          font-size: 36px;
          line-height: 150%;
          color: #353844;
          &.nameDark{
            color:white;
          }
        }
        .rating {
          padding: 3px 15px;
          background: #0298a6;
          border-radius: 19.6154px;
          display: flex;
          align-items: center;
          color: white;
          font-weight: 400;
          font-size: 19.6154px;
          line-height: 29px;
          margin-top: 10px;
          &.ratingDark{
            background-color: #015866 ;
          }
          .rating_icon {
            margin-left: 11px;
          }
        }
      }
      .member_text {
        margin-top: 25px;
        font-weight: 400;
        font-size: 24px;
        line-height: 36px;
        color: #353844;
        margin-bottom: 0;
        &.member_textDark{
          color: white;
        }
      }
      .account_info {
        display: flex;
        gap: 64px;
        text-align: center;
        font-weight: 700;
        font-size: 24px;
        line-height: 150%;
        color: #353844;
        &.account_infoDark{
          color: white;
        }
        .span {
          font-weight: 500;
          display: block;
          .spanDark{
            color:white;
          }
        }
      }
    }
    .shareCont {
      display: flex;
      flex-direction: column;
    }
    
  
    
    .line {
      width: 100%;
      border-top: 2px solid #c2c2c2;
      margin-top: 71px;
      margin-bottom: 42px;
      &.lineDark{
        color:whitesmoke
      }
    }
    .container_header {
      display: flex;
      justify-content: space-between;
      .title {
        font-weight: 700;
        font-size: 28px;
        line-height: 150%;
        color: #353844;
        &.titleDark{
          color: white;
        }
      }
      .see_all{
        font-weight: 500;
          font-size: 28px;
          line-height: 150%;
          color: #0298a6;
      }
      .reviewsort{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .see_all {
          font-weight: 500;
          font-size: 28px;
          line-height: 150%;
          color: #0298a6;
          &.see_allDark{
            color: #015866;
          }
          .revic{
            display: inline-block;
            width: 25px;
            height: 25px;
            margin-right: 15px;
          }
        }
        
        .label{
          &.labelDark{
            color:white;
          }
        }
      .select {
        margin-left: 20px;
        font-size: 16px;
        color: #555;
        
        
      }
      .sort_icon {
        display: inline-block;
        width: 40px;
        height: 40px;
        &.sort_iconDark{
          color:white
        }
      }
    
  
    }
  }
    
    .products_container {
      margin-top: 48px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      row-gap: 19px;
      margin-bottom: 20px;
      
    }
    .see_all{
      font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        color: #0298a6;
        float: right;
    }
  }
 
  @media screen and (max-width: 1300px) {
    // flex-direction: column;
    .aside {
      display: none;
    }
    .main_section {
      width: 100%;
      .user_info_container {
        display: none;
      }
      .mobile_section {
        display: block;
        .more {
          display: flex;
          justify-content: flex-end;
          position: relative;
          .more_icon {
            font-size: 40px;
            &.more_iconDark{
              color:white;
            }
          }
          .menu {
            position: absolute;
            top: 37px;
            background: #ffffff;
            border-radius: 10px;
            border: 1px solid #c2c2c2;
            li {
              border-bottom: 1px solid #c2c2c2;
              padding: 8px 16px;
              &:last-child {
                border-bottom: none;
              }
            }
            &.active {
              opacity: 1;
              visibility: visible;
              transform: translateY(0);
              transition: 500ms ease;
            }
            &.inactive {
              opacity: 0;
              visibility: hidden;
              transform: translateY(-20px);
              transition: 500ms ease;
            }
            .report_mobile {
              font-weight: 500;
              font-size: 20px;
              line-height: 150%;
              color: #000000;
            }
            .block_mobile {
              color: #f73448;
              font-weight: 500;
              font-size: 20px;
              line-height: 150%;
            }
          }
        }
        .image_container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .image_mobile {
            width: 100.8px;
            height: 100.8px;
            border-radius: 50%;
            object-fit: cover;
          }
          .account_info {
            font-weight: 600;
            font-size: 24px;
            line-height: 180%;
            text-align: center;
            color: #353844;
            display: flex;
            gap: 39.6px;
            &.account_infoDark{
              color:white;
            }
            .span {
              display: block;
              font-weight: 500;
            }
          }
        }
        .flex {
          margin-top: 15.6px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .name_container_mobile {
            display: flex;
            align-items: center;
            .name {
              font-weight: 600;
              font-size: 24px;
              line-height: 180%;
              color: #353844;
              &.nameDark{
                color:white;
              }
            }
            .rating {
              margin-left: 6px;
              padding: 3.6px 6px;
              background: #0298a6;
              border-radius: 19px;
              display: flex;
              align-items: center;
              color: white;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              margin-top: 10px;
              &.ratingDark{
                background-color: #015866;
              }
              .rating_icon {
                font-size: 12px;
                margin-left: 4.8px;
              }
            }
          }
          .member_text_mobile {
            font-weight: 400;
            font-size: 16.8px;
            line-height: 25.2px;
            color: #353844;
            &.member_text_mobileDark{
              color: white;
            }
          }
          .follow_mobile {
            font-weight: 600;
            font-size: 24px;
            line-height: 180%;
            color: #ffffff;
            width: 146.4px;
            height: 46.08px;
            background: #0298a6;
            border-radius: 10px;
            &.follow_mobileDark{
              background-color: #015866;
            }
            &.btnUnflw{
              font-weight: 600;
            font-size: 24px;
            line-height: 180%;
            color: #ffffff;
            width: 146.4px;
            height: 46.08px;
            background: white;
            color: #0298a6;
            border: 2px solid #0298a6;
            border-radius: 10px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 650px) {
    .main_section {
      .mobile_section {
        .image_container {
          .image_mobile {
            width: 84px;
            height: 84px;
          }
          .account_info {
            font-size: 17px;
            line-height: 150%;
            gap: 30px;
          }
        }
        .flex {
          margin-top: 13px;
          .name_container_mobile {
            .name {
              font-size: 20px;
              line-height: 150%;
            }
            .rating {
              margin-left: 5px;
              padding: 3px 5px;
              font-size: 10px;
              line-height: 15px;
              .rating_icon {
                font-size: 10px;
                margin-left: 4px;
              }
            }
          }
          .number_text_mobile {
            font-size: 14px;
            line-height: 21px;
          }
          .follow_mobile {
            font-size: 20px;
            line-height: 150%;
            width: 122px;
            height: 32px;
            &.btnUnflw{
              font-size: 20px;
            line-height: 150%;
            width: 122px;
            height: 32px;
            }
          }
        }
      }
      .line {
        margin-top: 56.8px;
        margin-bottom: 33.6px;
      }
      .container_header {
        .title {
          font-size: 22.4px;
          line-height: 120%;
        }
        .reviewsort{
        .see_all {
          font-size: 18px;
          line-height: 120%;
        }
      }
        .products_container {
          margin-top: 38.4px;
        }
      }
    }
  }
  @media screen and (max-width: 400px) {
    .main_section {
      .mobile_section {
        .image_container {
          .image_mobile {
            width: 84px;
            height: 84px;
          }
          .account_info {
            font-size: 12px;
            line-height: 150%;
            gap: 30px;
          }
        }
      }
    }
  }
}
.confirm_message {
  font-weight: 500;
  font-size: 19.2px;
  line-height: 28.8px;
  margin-bottom: 15px;
  &.confirm_messageDark{
      color:white
  }
  span{
      font-weight: 600;
      color:#ba2c2d;
  }
}
.buttons_container {
  display: flex;
  gap: 20px;
  button {
    border-radius: 10px;
    padding: 10.4px 35.2px;
    font-weight: 600;
    font-size: 19.2px;
    line-height: 28.8px;
    color: #ffffff;
  }
  .confirm {
    background-color:  #0298a6;
  }
  .cancel {
    background-color: #ba2c2d;
  }
}
