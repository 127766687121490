@use "../../../../styles/variables.scss" as color;
.SideDrawer {
  z-index: 200;
  position: fixed;
  overflow-y: auto;
  top: 0;
  height: 100vh;
  width: 100%;
  background-color: white;
  transition: transform 0.3s ease;
  &.SideDrawerDark{
    background-color: color.$dark-text-color;
  }
  &.hide {
    transform: translateX(-100%);
  }
  &.show {
    transform: translateX(0);
  }
  padding-left: 1.43rem;
  padding-right: 1.43rem;
  .toggle__icon {
    margin-top: 3.8rem;
    font-size: 2rem;
    width: 30px;
    height: 30px;
    cursor: pointer;
    &.toggle__iconDark{
    color: white;
    }
  }
  // * profile container
  .profile__container {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    
    .profile__picture {
      width: 3.56rem;
      height: 3.56rem;
      border: 2px solid color.$primary-color;
      border-radius: 50%;
      object-fit: cover;
    }
    .profile__details {
      margin-left: 1.43rem;
      .name {
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.5rem;
        color: color.$dark-text-color;
        &.nameDark{
          color: color.$white-text-color;
        }
      }
      .email {
        font-weight: 500;
        font-size: 0.75rem;
        line-height: 1.125rem;
        color: color.$light-text-color;
        &.emailDark{
          color: color.$white-text-color;
        }
      }
      .edit__link {
        font-weight: 500;
        font-size: 0.75rem;
        line-height: 1.125rem;
        color: color.$primary-color;
      }
    }
  }
  .line {
    border: 1px solid color.$light-text-color;
    margin-top: 13px;
  }
  .icon {
    display: inline-block;
    color: color.$dark-text-color;
    font-size: 1.2rem;
    &.iconDark{
      color:color.$white-text-color ;
    }
  }
  // * account container
  .account__container {
    position: relative;
    .account__container__title {
      display: flex;
      // justify-content: space-between;
      align-items: center;
      .account__title {
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 1.875rem;
        color: color.$primary-color;
        &.account__titleDark{
          color:white
        }
      }
      .account__icon {
        font-size: 50px;
        color: color.$primary-color;
        margin-left: 10px;
      }
      margin-bottom: 0.8rem;
    }
    .account__list {
      position: relative;
      bottom: 0;
      left: 0;
      .account__list__item {
        // margin-left: 0.93rem;
        margin-bottom: 0.7rem;
        font-weight: 600;
        font-size: 0.93rem;
        line-height: 1.375rem;
        color: color.$primary-color;
        .icon {
          margin-right: 2.68rem;
        
        &.iconDark{
          color:white;
        }
      }
        .link{
          text-decoration: none;
          color: color.$primary-color;
          &.linkDark{
            color:white;
            &:hover{
              color:#01b1c0;
            }
          }
          
        }
      }
      &.show {
        transform: translateY(0);
        transition: 500ms ease;
      }
      &.hide {
        display: none;
        transform: translateY(-20px);
      }
    }
  }
  .linkb{
    text-decoration: none;
    color: color.$primary-color;
    &:hover{
      color:#01b1c0;
    }
    #lgt{
      color:color.$error-color
    }
    &.linkbDark{
      color:color.$white-text-color;
      &:hover{
        color:color.$primary-color;
      }
    }
  }
  #lgt{
    text-decoration: none;
    color: color.$error-color;
    &.linkbDark{
      color:color.$white-text-color;
    }
  }
  // * menu container
  .menu__container {
    margin-top: 1rem;
    .menu__container__list {
      .menu__container__item {
        font-weight: 600;
        font-size: 0.93rem;
        line-height: 150%;
        color: color.$dark-text-color;
        margin-bottom: 1rem;
        .icon {
          margin-right: 2.5rem;
          &.iconDark{
            color:color.$white-text-color ;
          }
        }
      }
    }
    .account__container__title {
      display: flex;
      // justify-content: space-between;
      align-items: center;
      .account__title {
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 1.875rem;
        color: color.$primary-color;
        &.account__titleDark{
          color:white
        }
      }}
  }
  // *setting container
  .setting__container {
    .title {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: color.$dark-text-color;
      margin-bottom: 1.125rem;
      &.titleDark{
        color:color.$white-text-color;
      }
    }
    .list__item {
      margin-bottom: 1rem;
      font-weight: 600;
      font-size: 0.93rem;
      line-height: 1.375rem;
      position: relative;
      cursor: pointer;
      color: color.$dark-text-color;
      .icon {
        margin-right: 2.72rem;
        &.iconDark{
          color:color.$white-text-color ;
        }
      }
      .toggle {
        position: absolute;
        right: 0.5rem;
        display: inline-block;
      }
    }
  }
  @media screen and (min-width: 450px) {
    .profile__container {
      .profile__picture {
        width: 4.5rem;
        height: 4.5rem;
      }
      .profile__details {
        margin-left: 2rem;
        .name {
          font-size: 1.5rem;
          line-height: 2.6rem;
        }
        .email,
        .edit__link {
          font-size: 1.1rem;
          line-height: 1.4rem;
        }
      }
    }
    .icon {
      font-size: 1.7rem;
      &.iconDark{
        color:color.$white-text-color ;
      }
    }
    .account__container {
      margin-top: 0.4rem;
      .account__container__title {
        .account__title {
          font-size: 1.6rem;
        }
        .account__icon {
          font-size: 1.3rem;
        }
      }
      .account__list {
        .account__list__item {
          font-size: 1.2rem;
          line-height: 1.5rem;
        }
      }
    }
    .menu__container {
      margin-top: 1.5rem;
      .menu__container__list {
        .menu__container__item {
          font-size: 1.2rem;
          margin-bottom: 1.2rem;
          .icon {
            margin-right: 2.8rem;
            &.iconDark{
              color:color.$white-text-color ;
            }
          }
        }
      }
    }
    .setting__container {
      margin-top: 3rem;
      .title {
        font-size: 24px;
        margin-bottom: 1.25rem;
        &.titleDark{
          color:color.$white-text-color;
        }
      }
      .list__item {
        font-size: 1.1rem;
        line-height: 1.5rem;
      }
    }
  }
}
