.card_container {
  width: 242.26px;
  height: 351.75px;
  background: #f8f8f9;
  box-shadow: 0px 4.65894px 4.65894px rgba(0, 0, 0, 0.25);
  border-radius: 36.1068px;
  display: flex;
  flex-direction: column;
  align-items: center;
  display: inline-block;
  &.card_containerDark{
    background: #015866;
  }
  .wrapper {
    padding: 48px 20px;
    position: relative;
    .img {
      max-width: 205px;
    }
    .title {
      font-weight: 600;
      font-size: 17.471px;
      line-height: 26px;
      color: #353844;
      margin-top: 33px;
      margin-bottom: 39px;
      text-align: center;
      &.titleDark{
        color:white
      }
    }
    .text {
      font-weight: 400;
      font-size: 17.471px;
      line-height: 26px;
      text-align: center;
      color: #c2c2c2;
    }
    .circle {
      position: absolute;
      top: 0;
      right: 50%;
      transform: translate(50%, -50%);
      width: 74.63px;
      height: 74.63px;
      border-radius: 50%;
      background: #f8f8f9;
      .circleDark{
        background-color: #015866;
      }
      .circle__wrapper {
        padding: 11px;
        &.circle__wrapperDark{ 
        background: #015866;
        border-radius: 50%;
        }
        .circle__number {
          width: 51.41px;
          height: 51.41px;
          border-radius: 50%;
          border: 2.32947px solid #0298a6;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          font-size: 41.9305px;
          line-height: 63px;
          color: #0298a6;
          &.circle__numberDark{
            border: 2.32947px solid white;
            color: white;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1270px) {
    width: 169.582px;
    height: 246.225px;
    .wrapper {
      padding: 33.6px 14px;
      .img {
        max-width: 143.5px;
      }
      .title {
        font-size: 12.2297px;
        line-height: 18.2px;
        margin-top: 23.1px;
        margin-bottom: 27.3px;
      }
      .text {
        font-size: 12.2297px;
        line-height: 18.2px;
      }
      .circle {
        width: 52.241px;
        height: 52.241px;
        .circle__wrapper {
          padding: 7.7px;
          .circle__number {
            width: 35.987px;
            height: 35.987px;
            font-size: 29.35px;
            line-height: 44.1px;
          }
        }
      }
    }
  }
}
