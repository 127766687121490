.popup {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    position: relative;
.img{
        margin-top: 50px;
        width: 200px;
        height: 166px;
        margin-bottom: 40px;
        background-color: rgb(170, 165, 165);
        animation: loadingAnimation 1s infinite alternate;
}
.title{
        height: 15px;
        width: 150px;
        background-color: rgb(170, 165, 165);
        margin-bottom: 40px;
        border-radius: 20px;
        animation: loadingAnimation 1s infinite alternate;
}
.title1{
    height: 15px;
    width: 100px;
        border-radius: 20px;
        background-color: rgb(170, 165, 165);
        margin-bottom: 40px;
        animation: loadingAnimation 1s infinite alternate;
}
@media screen and (max-width: 1000px)  {
    .img{
        margin-top: 50px;
        width: 180px;
        height: 150px;
        
}
}
@media screen and (max-height: 710px)  {
    .img{
        margin-top: 50px;
        width: 180px;
        height: 150px;
        
}
}
}
@keyframes loadingAnimation {
    0% {
      opacity: 0.5;
      transform: translateX(0);
    }
    100% {
      opacity: 1;
      transform: translateX(5px);
    }
  }
