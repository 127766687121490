.table_container {
  color: #353844;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  &.table_containerDark{
    color:white;
    tr {
      height: 74px;
      width: 100%;
      
      &:nth-child(odd) {
        background-color:#353844 ;
          }
      &:nth-child(even) {
        background-color: #015866; 
      }
    }
  }
  table {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 588px;
    thead {
      .title {
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
        color: #353844;
        &.titleDark{
          color: white;
        }
      }
      .price {
        font-weight: 700;
        font-size: 30px;
        line-height: 45px;
        color: #0298a6;
        &.priceDark{
          color: #015866;
        }
      }
      tr {
        height: 74px;
        width: 100%;
      }
      td {
        padding: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    tbody {
      tr {
        height: 74px;
        width: 100%;
        &:nth-child(odd) {
          background-color: #f3f3f3;
        }
      }
      td {
        padding: 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .left{
        &.leftDark{
          color: white;
        }
      }
    }
  }
  @media screen and (max-width: 730px) {
    table {
      width: 90vw;
      thead {
        .title {
          font-size: 25.6px;
          line-height: 38.4px;
        }
        .price {
          font-size: 24px;
          line-height: 36px;
        }
        tr {
          height: 59.2px;
        }
        td {
          padding: 12px;
        }
      }
      tbody {
        tr {
          height: 59.2px;
        }
        td {
          padding: 20px;
        }
      }
    }
  }
  @media screen and (max-width: 440px) {
    table {
      width: 90vw;
      thead {
        .title {
          font-size: 25.6px;
          line-height: 38.4px;
        }
        .price {
          font-size: 19px;
          line-height: 36px;
          max-width: 150px;
          word-wrap: break-word;
        }
        tr {
          height: 59.2px;
        }
        td {
          padding: 12px;
        }
      }
      tbody {
        tr {
          height: 59.2px;
        }
        td {
          padding: 20px;
        }
      }
    }
  }
}
