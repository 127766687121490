@use "../../../../styles/variables.scss" as color;
:root {
  --bg-color: $header-color;
}
.container {
  height: 89px;
  background: color.$header-color;
  position: relative;
  &.containerDark {
    background-color: #2a2b2e;
  }
  .sidebar {
    position: absolute;
    top: 0;
    right: -100%;
    transition: all 0.4s ease;
    background-color: white;
    &.hide {
      display: none;
    }
    &.show {
      display: block;
      right: 0px;
      width: 100vw;
      height: 100vh;
    }
  }
  .wrapper {
    padding: 20px 65px 20px 77px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo__container {
      display: flex;
      align-items: center;
      // cursor: pointer;
      margin-left: -40px;
      .menu_icon {
        display: none;
        &.menu_iconDark{
          color: color.$white-text-color;
        }
      }
      .logo {
        font-weight: 800;
        font-size: 36px;
        line-height: 44px;
        display: flex;
        color: #0298a6;
        margin-right: 27px;
        text-decoration: none;
        position: relative;
        .logo_img{
          width: 50px;
          height: 50px;
          transform: scale(2.5);
        }
        .clickableDiv {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          cursor: pointer;
        }
      }
      .location_container {
        display: flex;
        align-items: center;
        color: color.$primary-color;
        .location_icon {
          color: color.$primary-color;
          width: 23.43px;
          height: 31.57px;
          // cursor: pointer;
          margin-right: 10px;
        }
        .location_select {
          font-size: 20px;
          line-height: 150%;
          color: color.$dark-text-color;
          background-color: color.$header-color;
          cursor: pointer;
          border: 0;
          &.location_selectDark{
            background-color: transparent;
            color: color.$white-text-color;
          }
        }
        
      }
    }
    .right__container {
      display: flex;
      align-items: center;
      gap: 35px;
      // margin-right: 65px;
      .language_container {
        display: flex;
        align-items: center;
        .language_icon {
          color: color.$dark-text-color;
          width: 33.31px;
          height: 33.58px;
          cursor: pointer;
          margin-right: 3.7px;
          &.language_iconDark{
            color:color.$white-text-color;
          }
        }
        .language_select {
          font-weight: 500;
          font-size: 20px;
          line-height: 150%;
          color: color.$dark-text-color;
          cursor: pointer;
          background-color: color.$header-color;
          border: 0;
          &.language_selectDark{
            color: color.$white-text-color;
            background-color: transparent
          }
        }
      }
      .DarkM{
        font-size: 25px;
        cursor: pointer;
        &.DarkMDark{
          color:  #015866;
        }
      }
      .icon {
        width: 31.9px;
        height: 29.9px;
        color: color.$dark-text-color;
        cursor: pointer;
        &.iconDark{
          color: color.$white-text-color;
        }
      }
      .unr{
        position: relative;
      .iconU {
        width: 31.9px;
        height: 29.9px;
        color: color.$dark-text-color;
        cursor: pointer;
        &.iconUDark{
          color: color.$white-text-color;
        }
      }
      .dot {
        position: absolute;
        top: 2px;
        right: 5px;
        width: 10px;
        height: 10px;
        background-color: color.$primary-color;
        border-radius: 50%;
      }
    }
      .sell {
        padding: 5px 19px;
        background: color.$primary-color;
        box-shadow: inset -2px 2px 0px rgba(0, 0, 0, 0.25);
        border: none;
        border-radius: 10px;
        font-weight: 500;
        font-size: 22px;
        line-height: 150%;
        color: white;
        cursor: pointer;
        &.sellDark{
          background-color: #015866;
          &:hover {
            background: rgb(221, 219, 219);
            color: #015866;
            transition: 0.7s;
          }
        }
        .plus {
          width: 16px;
          height: 17px;
          display: inline-block;
        }
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
        &:hover {
          background: white;
          color: color.$primary-color;
          transition: 0.7s;
        }
        &:active {
          background-color: color.$secondary-color;
        }
      }
      .login {
        background: #ffffff;
        border: 2px solid color.$primary-color;
        border-radius: 10px;
        padding: 4px 19px;
        font-weight: 500;
        font-size: 22px;
        line-height: 150%;
        color: color.$primary-color;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
        &.loginDark{
            background: rgb(221, 219, 219);
            color: #015866;
            transition: 0.7s;
            border: 2px solid #015866;
            &:hover {
              background: #015866;
              color: rgb(221, 219, 219);
              transition: 0.7s;
            }
        }
        &:hover {
          background: color.$primary-color;
          color: white;
          transition: 0.7s;
        }
        &:active {
          background-color: color.$secondary-color;
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .wrapper {
      padding: 20px 50px 20px 50px;
      .logo__container {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-left: -20px;
      }
    }
  }
  @media screen and (max-width: 960px) {
    .wrapper {
      padding: 20px 20px 20px 50px;
      .right__container {
        gap: 20px;
      }
    }
  }
  @media screen and (max-width: 900px) {
    .wrapper {
      padding: 20px 50px;
      .logo__container {
        .menu_icon {
          margin-left: 10px;
          display: inline;
          transform: translateY(0%);
          margin-top: -5px;
          margin-right: 21.5px;
          color: color.$dark-text-color;
        }
        .logo{
          display: flex;
          align-items: center;
          justify-content: center;
          
        .logo_img{
          width: 40px;
          height: 40px;
        }
        .clickableDiv {
          position: absolute;
          top: 50%;
          left: 90%;
          transform: translate(-50%, -50%);
          width: 60%;
          height: 100%;
          cursor: pointer;
        }
      }
      
        .location_container {
          display: none;
        }
       
      }
      .midLogoContainer{
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .clickableDiv {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          cursor: pointer;
        }
        .logo_img{
          width: 40px;
          height: 40px;
          transform: scale(2.5);
        }
      }
      .right__container {
        margin-right: 0px;
        .language_container {
          display: none;
        }
        .hide {
          display: none;
        }
      }
      .DarkM{
        display: none;
      }
    }
  }
  @media screen and (max-width: 750px) {
    .wrapper {
      padding: 20px 30px;
      .right__container {
        margin-right: 0px;
      }
    }
  }
  @media screen and (max-width: 500px) {
    .wrapper {
      .right__container {
        .login {
          padding: 4px 11px;
          font-size: 18px;
        }
        .sell {
          padding: 5px 11px;
          font-size: 18px;
        }
      }
    }
  }
  @media screen and (max-width: 430px) {
    height: 70px;
    .wrapper {
      padding: 15px 26px 24px 23px;
      .logo__container {
        .logo {
          font-size: 24px;
        }
        .menu_icon {
          margin-left: 10px;
          transform: translateY(0%);
          margin-top: -5px;
          width: 30px;
          height: 30px;
          margin-right: 17.5px;
        }
      }
      .right__container {
        gap: 17px;
        .icon {
          width: 30px;
          height: 30px;
        }
        .login {
          padding: 1.5px 9px;
          font-size: 16px;
        }
        .sell {
          font-size: 16px;
          padding: 3px 9px;
          .plus {
            width: 10px;
            height: 10px;
            transform: translateY(-15%);
          }
        }
      }
    }
  }
  @media screen and (max-width: 350px) {
    .wrapper {
      padding: 15px 15px 24px 15px;
      .logo__container{
        margin-left: 0;
      }
      .right__container {
        gap: 7px;
      }
    }
  }
}
