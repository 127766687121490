@use "../../../../styles/variables.scss" as color;
.navbar {
  height: 66px;
  display: flex;
  align-items: center;
  background-color: white;
  &.navbarDark{
    background-color: color.$dark-text-color;
  }
  &.active {
    background-color: white;
    &.activeBar{
      backdrop-filter:unset;
    }
    &.navbarDark{
      background-color:  color.$dark-text-color;
    }
  }
   
  .sidebar {
    position: fixed;
    left:0px;
    top: 180px;
    width: 260px;
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
    // height: 605px;
    max-height: 605px;
    overflow-y: auto;
    transform: translateX(-300px);
    &.sidebarDark{
      background-color: color.$dark-text-color;
      height: 615px;
      max-height: 615px
    }
    &.open {
      transform: translateX(0);
    }
    .filtercont{
      display: flex;
      align-items: center;
      margin: 20px 5px 20px 20px;
      .filteric{
        margin-right: 10px;
        cursor: pointer;
        &.filtericDark{
          color: color.$white-text-color;
        }
      }
      .filter {
        font-weight: bold;
        &.filterDark{
          color: color.$white-text-color;
        }
      }
      .filterad{
        cursor: pointer;
        margin-left: auto; 
        &.filteradDark{
          color: color.$white-text-color;
        }
    }
    }
    .eleline {
      height: 0.5px; 
      width: 190px;
      background-color: rgb(185, 176, 176); 
      margin-left: 20px; 
      margin-bottom: 15px;
    }
    .elementcont{
      display: flex;
      align-items:center;
      margin-left: 30px;
      flex-direction: column;
    }
    .element{
      font-size: 16px;
      font-weight: 500;
      display:flex;
      align-items: start;
      width: 100%;
      position: relative;
      &.elementDark{
        color: color.$white-text-color;
      }
  
    }
    .elementad{
      cursor: pointer;
      position: absolute;
      top: 40%;
      right: 0%;
      transform: translate(-50%, -50%);
    }
  
    .subelements {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .subelement{
        &.subelementDark{
          color:color.$white-text-color;
        }
      }
      .select_input_row {
        display: flex;
        margin-left: -50px;
        margin-bottom: 5px;
      }
      .select_option{
        width: 50px;
        font-size: 15px;
        margin-right: 10px;
        border-radius: 5px;
        &.select_optionDark{
          background-color: #015866;
        }
      }
      .small_input{
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        width: 60px;
        border-radius: 5px;
        font-size: 13px;
        padding-left: 2px;
        margin-right: 3px;
        margin-left: 3px;
        &.small_inputDark{
          background-color: #015866;
        }
      }
      .large_input{
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        width: 150px;
        border-radius: 5px;
        font-size: 13px;
        margin-right: 3px;
        margin-left: -40px;
        &.large_inputDark{
          background-color: #015866;
        }
      }
      .condition{
        display: block;
        margin-bottom: 10px;
        font-size: 15px;
        font-weight: 500;
        margin-left: -30px;
        .radio{
          display: inline-block;
          margin-right: 5px;
          
        }
      }
    }
    .reset{
      font-size: 15px;
      margin-left: 100px;
      text-decoration: underline;
      font-weight: 500;
    }
    .subeleline {
      height: 0.5px; 
      width: 190px;
      background-color: rgb(185, 176, 176); 
      margin-left: -50px; 
      margin-bottom: 15px;
    }
    .distance{
      margin-left: -30px;
    }
    .distanceText{
      font-size: 13px;
      margin-left: -30px;
    }
    
  }
  .navbar__items {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: color.$dark-text-color;
    display: flex;
    align-items: center;
    .filter_icon {
      font-size: 30px;
      margin-right: 10px;
      cursor: pointer;
      color: #0298a6;
    }
    .all__categories {
      display: flex;
      align-items: center;
      cursor: pointer;
      .link {
        text-decoration: none;
        color: inherit;
        cursor: pointer;
        &.linkDark{
          color:color.$white-text-color
        }
  
      &:hover {
        color: #0298a6;
      }
    }
  }
    .icon {
      font-size: 30px;
    }
    .link {
      text-decoration: none;
      color: inherit;
      cursor: pointer;
      &.linkDark{
        color:color.$white-text-color
      }
      &:hover{
        color: color.$primary-color; ;
      }
    }
    
    .line {
      height: 42px;
      border: 2px solid color.$light-text-color;
      background-color: color.$light-text-color;
      margin-left: 17px;
      margin-right: 19px;
    }
 
  }
  
  .apply{
    width: 100px;
      height: 35px;
      background: color.$primary-color;
      border-radius: 10px;
      font-weight: 500;
      font-size: 15px;
      line-height: 150%;
      color: white;
      margin-top: 23px;
      margin-bottom: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 28%;
    &:hover{
      background: #01b1c0;
    }
  }
  @media screen and (max-width: 1060px) {
    .navbar__items {
      // margin-left: 50px;
      // justify-content: space-between;
      .line {
        margin-left: 13.6px;
        margin-right: 15.2px;
      }
    }
  }
  @media screen and (max-width: 1100px) {
    .navbar__items {
      // margin-left: 27px;
      .line {
        margin-left: 10.88px;
        margin-right: 12.1px;
      }
      .link{
        font-size: 16px;
      }
    }
  }
  @media screen and (max-width: 900px) {
    display: none;
  }
}