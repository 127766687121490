@use "../../../../styles/variables.scss" as color;
.rating_container {
  width: 327px;
  height: 139.5px;
  background: color.$light-background-color;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  &.rating_containerDark {
    background: #272932;
  }
  .score_container {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    margin-left: 13.5px;
    .score {
      font-weight: 600;
      font-size: 16.7263px;
      line-height: 150%;
      color: color.$dark-text-color;
      margin-bottom: 16px;
      &.scoreDark {
        color: #015866;
      }
    }
    .score_text {
      font-weight: 600;
      font-size: 8.9px;
      line-height: 150%;
      color: #b3b3b3;
    }
    .score_star {
      margin-top: 11px;
      display: flex;
      font-size: 25px;
      color: color.$yellow-color;
    }
  }
  .stars_container {
    display: flex;
    flex-direction: column;
    margin-top: 19px;
    margin-right: 15px;
    gap: 8px;
    .star {
      font-weight: 600;
      font-size: 8.9px;
      line-height: 150%;
      color: #b3b3b3;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .line {
        margin-left: 8px;
        display: inline-block;
        width: 100px; /* Adjust the width as needed */
        height: 7px;
        border: 1px solid gray;
        background-color: gray;
        border-radius: 10px;
        overflow: hidden; /* Hide overflowing content */
      }
      
      .filled {
        height: 100%;
        background-color: #ffcc3c;
        border-radius: 10px;
      }
     
    }
  }
}
@media screen and (max-width: 380px) {
  .rating_container {
    width: 250px;
    height: 139.5px;
    .stars_container {
      margin-top: 4px;
      margin-right: 9px;
      gap: 0;
      .star {
        font-weight: 600;
        font-size: 7px;
        line-height: 140%;
        .line {
          margin-left: 6px;
          width: 85px;
          .filled {
            background-color: color.$yellow-color;
            height: 100%;
            border-radius: 10px;
          }
        }
      }
    }
  }
}