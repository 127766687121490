@use "../../../../styles/variables.scss" as color;
.card_section {
  height: 166px;
  background: #ffffff;
  box-shadow: 0px 2.93px 8.32px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  &.card_sectionDark{
    background-color: color.$dark-text-color;
  }
  .image_container {
    position: relative;
    width: 229px;
    height: 166px;
    border-radius: 10px;
    margin-right: 10px;
    .img {
      height: 166px;
      border-radius: 10px;
    }
    .featured_containerr {
      width: 76px;
      height: 18.88px;
      background: color.$yellow-color;
      border-radius: 4.5px 0px;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .featured_textt {
        font-weight: 600;
        margin-top: 20%;
        font-size: 12px;
        color: #000000;
      }
    }
  }
  .details_containerr {
    width: 176px;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 14px;
    .pricee {
      font-weight: 700;
      font-size: 13.2181px;
      color: color.$dark-text-color;
      &.priceeDark{
        color: color.$white-text-color;
      }
    }
    .titlee {
      font-weight: 600;
      font-size: 12px;
      max-width: 60px;
      word-wrap: break-word;
      line-height: 1%;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: color.$dark-text-color;
      margin-top: 13.35px;
      &.titleeDark{
        color: color.$white-text-color;
      }
    }
    .locationn,
    .timee {
      font-weight: 600;
      font-size: 10px;
      line-height: 1%;
      color: color.$light-text-color;
      &.locationnDark,.timee{
        color: color.$white-text-color;
      }
    }
    .icon {
      display: inline-block;
      width: 15px;
      height: 15px;
      color: color.$primary-color;
      margin-right: 5px;
    }
    
    .heart_icon {
      position: absolute;
      top: 1px;
      right: 13.8px;
      color: color.$primary-color;
      font-size: 18px;
    }
    .condition_containerr {
      position: absolute;
      top: -1px;
      right: 35px;
      z-index: 3;
      .condition_image {
        width: 52px;
        height: 30.4px;
        
      }
      .conditionn {
        font-weight: 500;
        font-size: 12.8px;
        line-height: 19.2px;
        text-align: center;
        color: #ffffff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  
  }
  @media screen and (max-width: 630px), screen and (max-height: 800px) {
    height: 132.8px;
    .image_container {
      width: 183.2px;
      height: 132.8px;
      .img {
        height: 132.8px;
        border-radius: 10px;
  
      }
      .featured_container {
        width: 60.8px;
        height: 15.104px;
        .featured_text {
          font-size: 8px;
          line-height: 12px;
        }
      }
    }
    .details_container {
      width: 140.8px;
      padding-top: 32.8px;
      padding-left: 11.2px;
      .price {
        font-size: 10.57448px;
        line-height: 16px;
      }
      .title {
        font-size: 9.6px;
        line-height: 85.6%;
        margin-top: 10.68px;
      }
      .location,
      .time {
        font-size: 8px;
        line-height: 85.6%;
      }
      .location {
        margin-top: 16px;
      }
      .time {
        margin-top: 7.2px;
      }
      .icon {
        width: 12px;
        height: 12px;
        margin-right: 4px;
      }
      .heart_icon {
        top: 8px;
        right: 11.04px;
      }
      .condition {
        right: 40px;
        top: -4px;
        .img_condition {
          width: 25.18px;
          height: 32.05px;
        }
        .text_condition {
          font-size: 8.8px;
          line-height: 12.8px;
        }
      }
    }
  }
  @media screen and (max-width: 380px) {
    height: 132.8px;
    width: 280px;
    .image_container {
      width: 130px;
      height: 100px;
      margin-right: 10px;
      .img {
        width: 130px;
        height: 133px;
        object-fit: cover;
        border-radius:10px ;
      }
      .featured_container {
        width: 50px;
        height: 15.104px;
        right: -40%;
        .featured_text {
          font-size: 8px;
          line-height: 1px;
        }
      }
    }
    .details_containeer {
      width: 140.8px;
      padding-top: 32.8px;
      left: 20%;
      .pricee {
        font-size: 10.57448px;
        line-height: 16px;
      }
      .titlee {
        font-size: 9.6px;
        line-height: 10%;
      }
      .locationn,
      .timee {
        font-size: 8px;
        line-height: 10%;
      }
  
      .icon {
        width: 12px;
        height: 12px;
        margin-right: 4px;
      }
      .heart_icon {
        top: 5px;
        right: 33%;
      }
      .condition_containerr {
        .condition_image {
          width: 40px;
          height: 30.4px;
          margin-left:-85%;
        }
      }
      .conditionn {
        margin-left: -78%;
        .img_conditionn {
          width: 20px;
          height: 32.05px;
        }
        .text_conditionn {
          font-size: 8.8px;
          line-height: 12.8px;
        }
      }
    }
  }
}
