@use "../../../styles/variables.scss" as color;
.section {
  padding: 0 65px;
  margin-top: 0;
  min-height: 100vh;
  &.sectionDark{
    background-color: color.$dark-text-color;
  }
// &.sectionBar{
//   margin-left: 200px;
// }
  //
  max-width: 1920px;
  margin: 0 auto;
  // .path {
  //   margin-top: 22px;
  // }
  .title {
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
    color: #353844;
    text-align: center;
    margin-top: 9px;
    &.titleDark{
      color:color.$white-text-color;
    }
  }
  @media screen and (max-width: 1000px) {
    padding: 0 40px;
  }
  
  @media screen and (max-width: 400px) {
    .path {
      max-width: 300px;
  overflow-wrap: break-word; 
    }
  }
  @media screen and (max-width: 932px) {
    padding: 0 25px;
  }
  @media screen and (max-width: 620px) {
    .title {
      font-size: 28.8px;
      line-height: 43.2px;
    }
  }
  @media screen and (max-width: 500px) {
    padding: 0 14px;
  }
  @media screen and (max-width: 370px) {
    padding: 0 7px;
  }
}
