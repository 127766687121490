@use "../../../styles/variables.scss" as color;
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .category__container {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: color.$dark-text-color;
    display: flex;
    gap: 50px;
    .category__item {
      padding: 0 18px;
      margin-bottom: 0px;
      cursor: pointer;
      &.category__itemDark{
        color:white
      }
      &.active {
        border-bottom: 2px solid color.$primary-color;
        &.activeDark{
          border-bottom: 2px solid #015866;
        }
      }
    }
  }
  .date {
    align-self: flex-start;
    margin-top: 29px;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: color.$dark-text-color;
    &.dateDark{
      color: white;
    }
  }
  .card_container {
    margin-left: 110px;
    align-self: flex-start;
    margin-top: 36px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
    margin-bottom: 40px;
    
  }
  
  @media screen and (min-width: 1550px) {
    .card_container {
      margin-left: 0;
    }
  }
  @media screen and (max-width: 1550px) {
    .card_container {
      margin-left: 50px;
    }
  }
  @media screen and (max-width: 1480px) {
    .card_container {
      gap: 30px;
      margin-left: 0;
    }
  }
  @media screen and (max-width: 1430px) {
    .card_container {
      column-gap:10px ;
    }
  }
  @media screen and (max-width: 1390px) {
    .card_container {
      justify-content: space-between;
      display: flex;
      flex-wrap: wrap;
    }
  }
  // @media screen and (max-width: 1090px) {
  //   .card_container {
  //     column-gap: 100px;
  //     row-gap: 60px;
  //   }
  // }
  // @media screen and (max-width: 1040px) {
  //   .card_container {
  //     column-gap: 60px;
  //     row-gap: 50px;
  //   }
  // }
  // @media screen and (max-width: 950px) {
  //   .card_container {
  //     column-gap: 40px;
  //     row-gap: 40px;
  //   }
  // }
  @media screen and (max-width: 900px) {
    .card_container {
      flex-direction: column;
      align-items: center;
    }
  }
  @media screen and (max-width: 800px) {
    .category__container {
      font-size: 14px;
      line-height: 21px;
      color: color.$dark-text-color;
      display: flex;
      gap: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      .category__item {
        padding: 0 12.6px;
        margin-bottom: 25.2px;
      }
    }
  }
  @media screen and (max-width: 500px) {
    .category__container {
      gap: 15px;
    }
  }
  @media screen and (max-width: 450px) {
    .date {
      margin-top: 24px;
      font-size: 19.2px;
      line-height: 28.8px;
    }
  }
  @media screen and (max-width: 400px) {
    .category__container {
      gap: 0px;
    }
  }
}
.search__section {
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  &.search__sectionDark{
    background-color: color.$dark-text-color;
  }
  .title {
    font-weight: 600;
    font-size: 40px;
    line-height: 60px;
    text-align: center;
    color: color.$dark-text-color;
    &.titleDark{
      color: color.$white-text-color;
    }
  }
  .search__type {
    margin-bottom: 30px;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: color.$dark-text-color;
    border: 2px solid color.$primary-color;
    border-radius: 15px;
    &.search__typeDark{
      border: 2px solid #015866;
    }
    button {
      padding: 15px 31px;
      background-color: white;
      cursor: pointer;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      color: color.$dark-text-color;
      //   border: hidden;
      border-radius: 16px;
      &.buttonDark{
        background-color: color.$dark-text-color;
        color:color.$white-text-color
      }
      &.active {
        border: 2px solid color.$primary-color;
        border-radius: 12px;
        background-color: color.$primary-color;
        color: white;
      }
      &.activeDark{
        background-color:#015866 ;
        border: 2px solid #015866;
        border-radius: 12px;
      }
    }
  }
  @media screen and (max-width: 410px) {
    padding: 0;
  }
  @media screen and (max-width: 450px) {
    padding: 0;
    .search__type {
      font-size: 12px;
      button {
        padding: 6.75px 15.1875px;
          min-width: 70px;
          height: 40px;
          font-size: 12px;
          line-height: 16.875px;
        &.active {
          height: 42px;
            width: 80px;
        }
      }
    }
}
  .search__component {
    margin-top: 50px;
  }
  .search__filter {
    margin-top: 17px;
    display: flex;
    .button {
      background: color.$light-background-color;
      border: 3px solid color.$primary-color;
      border-radius: 15px;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      color: color.$primary-color;
      width: 256px;
      height: 66px;
      padding: 15px 20px 15px 36px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      &.buttonDark{
        background: color.$dark-text-color;
      }
    }
    .price__button {
      margin-left: 95px;
      margin-right: 95px;
    }
    .select_container {
      position: relative;
      .select_icon {
        position: absolute;
        top: 50%;
        right: 10px;
        color: color.$primary-color;
        transform: translateY(-50%);
      }
      .select {
        // padding: 8px 12px;
        width: 256px;
        height: 66px;
        background: color.$light-background-color;
        border: 3px solid color.$primary-color;
        border-radius: 15px;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: color.$primary-color;
        cursor: pointer;
        margin-right: 10px;
        // padding: 15px 20px 15px 36px;
        // replace dafault styling (arrow)
        // appearance: none;
        // -webkit-appearance: none;
        // -moz-appearance: none;
        &.selectDark{
          background-color:color.$dark-text-color ;
        }
        &:focus,
        &:hover {
          outline: none;
          // border: 1px solid #bbb;
        }
        // option {
        //   //we can only styles
        //   // background: ;
        //   // color: ;
        // }
      }
    }
  }
  @media screen and (max-width: 995px) {
    .search__filter {
      width: 500px;
      .button{
      width: 150px;
      font-size: 20px;
      padding: 12px 12px 12px 10px;
      }
      .price__button {
        margin-left: 35px;
        margin-right: 35px;
      }
  
    
    .select_container {
    .select {
      width: 160px;
      font-size: 18px;
      // padding: 12px 12px 12px 10px;
    }
  }
  }
}
  @media screen and (max-width: 510px) {
    .search__filter {
      margin-left: 150px;
      .button{
      width: 100px;
      font-size: 12px;
      height: 50px;
      padding: 12px 12px 12px 10px;
      }
      .price__button {
        margin-left: 20px;
        margin-right: 20px;
        padding-left: 30px;
      }
  
    
    .select_container {
      .select_icon {
        position: absolute;
        top: 50%;
        right: 10px;
        width: 12px;
      }
    .select {
      width: 110px;
      height: 50px;
      font-size: 12px;
      // padding: 4px 6px 12px 12px;
    }
  }
  }
}
  @media screen and (max-width: 360px) {
    .search__filter {
      margin-left: 200px;
      .button{
      width: 80px;
      font-size: 12px;
      height: 50px;
      padding: 12px 12px 12px 10px;
      }
      .price__button {
        margin-left: 20px;
        margin-right: 20px;
        // padding-left: 30px;
      }
  
    
    .select_container {
      .select_icon {
        position: absolute;
        top: 50%;
        right: 10px;
        width: 12px;
      }
    .select {
      width: 93px;
      height: 42px;
      font-size: 9px;
      // padding: 4px 6px 12px 12px;
    }
  }
  }
}
}
