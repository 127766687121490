@use "../../../../../styles/variables.scss" as color;
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-weight: 500;
    font-size: 19.6px;
    line-height: 29.4px;
    color: #353844;
    text-align: center;
    margin-bottom: 22px;
  }
 
  .form_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .input_container {
      position: relative;
      margin-bottom: 19px;
      .input {
        width: 277.2px;
        height: 40.6px;
        background: color.$light-background-color;
        border: 1px solid color.$border-category-dropdown;
        border-radius: 10px;
        padding-left: 47.6px;
        outline: none;
        &.inputDark{
          background: #015866;
          color:rgb(207, 203, 203)
        }
        &:focus {
          border: 1px solid color.$primary-color ;
          outline: none;
        }
      }
      .pass_icon {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        color: color.$light-gray-color;
        font-size: 20px;
        cursor: pointer;
      }
      .input_icon {
        position: absolute;
        left: 16px;
        top: 12px;
        color: color.$border-category-dropdown;
        ;
      }
      .error_message {
        font-weight: 400;
        font-size: 11.2px;
        line-height: 16.8px;
        color: color.$error_color;
        position: absolute;
        
      }
      &.error_input {
        .input {
          border-color: color.$error_color;
        }
      }
    }
    .phone_input_container {
      position: relative;
      margin-bottom: 19px;
      .phone_input{
        &.phone_inputDark{
          background: #015866;
          color:rgb(207, 203, 203)
        }
      }
      
      
      .phone {
        position: absolute;
        width: 21px;
        height: 21px;
        top: 50%;
        left: 72px;
        transform: translateY(-50%);
      
      }
      
      &:focus {
        border: 1px solid #aeaeae !important;
        outline: none !important;
      }
      
      }
    
    
  }
  .remember_container {
    position: relative;
    max-width: 60%;
    input {
      accent-color: color.$primary-color;
      width: 15px;
      height: 15px;
      margin-right: 20px;
      transform: translateY(150%);
      
    }
    
    .remember {
      font-weight: 600;
      font-size: 11.2px;
      line-height: 16.8px;
      color: color.$dark-text-color;
      text-align: center;
      text-justify: inter-word;
      margin-left: 10px;
      &.rememberDark{
        color: color.$white-text-color;
      }
      .terms {
        color: color.$primary-color;
        text-decoration: underline;
      }
    }
    .error_message {
      font-weight: 400;
      font-size: 11.2px;
      line-height: 2px;
      color: color.$error_color;
      position: relative;
     
      left: 30%;
    }
  }
  .verify {
    font-weight: 600;
    font-size: 11.2px;
    line-height: 16.8px;
    color: #353844;
    gap: 9.8px;
    // margin-top: -17px;
    
    &.verifyDark{
      color:color.$white-text-color
    }
    .exclamation_icon {
      display: inline-block;
      transform: translateY(-10%);
      margin-right: 10px;
      
    }
    
  }
  .btn {
    padding: 12px 45px;
    background: #0298a6;
    border-radius: 10px;
    font-weight: 600;
    font-size: 16.8px;
    line-height: 25.2px;
    color: #ffffff;
    margin-top: -10px;
    &.btnDark{
      background-color: #015866;
    }
  }
  @media screen and (max-width: 750px) {
    .btn {
      margin-bottom: 15px;
    }
  }
  @media screen and (max-width: 470px) {
   
       .remember_container {
     .error_message{
     
      margin-left: -20%;
    }
    
    }
  }
  @media screen and (max-width: 340px) {
    .form_container {
      .input_container {
        margin-bottom: 15px;
        .input {
          width: 250px;
        }
        
      }
      .phone_input_container {
        .phone_input{
          width: 250px;
        }
      }
    }
    // .remember_container {
    //  .error_message{
    //   height: 100%;
    //   top:95%;
    //   margin-left:18px;
    // }
    // }
  }
  @media screen and (max-width: 600px) {
    .remember_container {
      max-width: 80%;
    }
    .verify {
      max-width: 80%;
      text-align: center;
      gap: 4px;
    }
  }
}
.verify_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .logoCont{
    justify-content: center;
    align-items: center;
    display: flex;
  .logo {
    margin-top: 20px;
    margin-bottom: 45.5px;
    font-weight: 500;
    font-size: 28px;
    line-height: 42px;
    text-align: center;
    color: color.$primary-color;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
    transform: scale(2.5);
  }
}
  .verification_container {
    width: 85%;
    margin: 0 auto;
    .verification_title {
      font-weight: 500;
      font-size: 18px;
      line-height: 30px;
      color: #353844;
      margin-bottom: 15px;
      &.verification_titleDark{
        color:color.$white-text-color;
      }
    }
    .verification_text {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #8d8d8d;
      margin-bottom: 15px;
      &.verification_textDark{
        color:color.$white-text-color;
      }
      .email {
        display: block;
        color: color.$primary-color;
      }
    }
    .code_container {
      width: 100%;
      height: 56px;
      border: 1px solid color.$border-category-dropdown;
      border-radius: 10px;
      position: relative;
      .code_input {
        background: #f8f8f9;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        padding-left: 20px;
        font-size: 16px;
        &.code_inputDark{
          background: #015866;
          color:rgb(207, 203, 203)
        }
        &:focus {
          outline: none;
        }
      }
      .send_btn {
        position: absolute;
        right: -1px;
        top: -1px;
        height: 56px;
        padding: 0 30px;
        background: #ffffff;
        border: 1px solid color.$border-category-dropdown;
        border-radius: 0px 8px 8px 0px;
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        color: color.$dark-text-color;
        &.send_btnDark{
          background-color: #015866;
          color: color.$white-text-color;
          &:hover{
            background-color: #0298a6;
          }
        }
        &:hover{
          color: white;
          background: color.$primary-color;
        }
      }
      .send_btnRsnd{
        position: absolute;
        right: -1px;
        top: -1px;
        height: 56px;
        padding: 0 30px;
        background: gray;
        border: 1px solid color.$border-category-dropdown;
        border-radius: 0px 8px 8px 0px;
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        color:white;
        cursor: not-allowed;
      }
    }
  }
  .confirm_btn {
    font-weight: 600;
    font-size: 21px;
    line-height: 32px;
    color: #ffffff;
    background: #0298a6;
    border-radius: 10px;
    padding: 12px 51px;
    margin-top: 40px;
    margin-bottom: 30px;
    &.confirm_btnDark{
      background-color: #015866;
    }
  }
  @media screen and (max-width: 590px) {
    .close {
      width: 22px;
      height: 22px;
    }
    .back {
      width: 22px;
      height: 22px;
    }
    .logo {
      font-size: 25.2px;
      line-height: 37.8px;
    }
    .verification_container {
      width: 80%;
      .verification_title {
        font-size: 14px;
        line-height: 21px;
      }
      .verification_text {
        font-size: 12.6px;
        line-height: 18.9px;
      }
      .code_container {
        height: 39.2px;
        .code_input {
          font-size: 12.6px;
          line-height: 21px;
        }
        .send_btn {
          height: 39.2px;
          padding: 0 21px;
          font-size: 12.6px;
          line-height: 21px;
        }
        .send_btnRsnd {
          height: 39.2px;
          padding: 0 21px;
          font-size: 12.6px;
          line-height: 21px;
        }
        
      }
    }
    .confirm_btn {
      font-size: 16.8px;
      line-height: 25.2px;
      padding: 8.4px 35.7px;
      margin-top: 57.4px;
      margin-bottom: 57.4px;
    }
  }
  @media screen and (max-width: 400px) {
    .verification_container {
      width: 90%;
    }
  }
}
