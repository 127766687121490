.container{
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    .title{
        padding-top: 20px;
        &.titleDark{
            color: white;
        }
    }
    .info{
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: 15px;
        color: gray;
        font-weight: 600;
        &.infoDark{
            color: #ffffff;
        }
    }
    .btnCont{
        padding: 20px;
        .btn{
            width: 320px;
            height: 58px;
            background: #e76859;
            border-radius: 10px;
            font-weight: 500;
            font-size: 28px;
            line-height: 150%;
            color: white;
            margin-top: 23px;
            margin-bottom: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            
            &:hover{
              background: #e87567;        
            }
          }
        }
            
}
.confirm_title {
    font-weight: 600;
    font-size: 25.6px;
    line-height: 38.4px;
    margin-bottom: 15px;
    &.confirm_titleDark{
        color:white
    }
  }
  
  .confirm_message {
    font-weight: 500;
    font-size: 19.2px;
    line-height: 28.8px;
    margin-bottom: 15px;
    &.confirm_messageDark{
        color:white
    }
    span{
        font-weight: 600;
        color:#e76859;
    }
  }
.buttons_container {
    display: flex;
    gap: 20px;
    button {
      border-radius: 10px;
      padding: 10.4px 35.2px;
      font-weight: 600;
      font-size: 19.2px;
      line-height: 28.8px;
      color: #ffffff;
    }
  
    .confirm {
      background-color:  #0298a6;
    }
  
    .cancel {
      background-color: #e76859;
    }
}