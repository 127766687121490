@use "../../../../styles/variables.scss" as color;
.container-info {
  background-color: color.$primary-color;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-div {
  background-color: white;
  width: 30%;
  height: 70vh;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .photoContainer {
    // margin-top: 15%;

    .photo {
      width: 100px;
      height: 100px;
      border-radius: 50px;
      object-fit: cover;
    }
    .round {
      width: 60px;
      height: 60px;
      border-radius: 50px;
      border: 1px solid color.$primary-color;
      margin-left: 42%;
      .selectedPhoto {
        width: 60px;
        height: 60px;
        border-radius: 50px;
      }
    }
    .text {
      font-size: 12px;
      color: color.$primary-color;
      label {
        cursor: pointer;
      }
    }
  }
  .fileInput {
    padding-left: 10%;
    padding-top: 2%;
  }
  .inputContainer {
    display: flex;
    flex-direction: column;
    margin-top: 7%;
  }
  .inputN {
    width: 340px;
    height: 40.6px;
    background: color.$light-background-color;
    border: 1px solid color.$border-category-dropdown;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    // margin-left: 17%;
    margin-top: 2%;
    &:focus {
      border: 1px solid color.$primary-color;
      outline: none;
    }
  }
  .submit {
    margin-top: 2%;
    background-color: color.$primary-color;
    width: 30%;
    margin-left: 37%;
    color: white;
    font-weight: 500;
    border-radius: 10px;
    height: 35px;
    &:hover {
      background: #01b1c0;
    }
  }
  .submitLoad {
    margin-top: 2%;
    background-color: gray;
    width: 30%;
    margin-left: 37%;
    color: white;
    font-weight: 500;
    border-radius: 10px;
    height: 35px;
    &:hover {
      background: gray;
    }
  }

  .erroruserInput {
    width: 340px;
    height: 40.6px;
    background: color.$light-background-color;
    border-radius: 10px;
    padding-left: 47.6px;
    // margin-left: 17%;
    margin-top: 2%;
    border: 1px solid red;
  }

  .errorMessage {
    color: red;
    font-size: 14px;
    margin-top: 5px;
  }
}
@media screen and (max-width: 1200px) {
  .center-div {
    width: 50%;
    padding: 0;

    .inputN {
      padding-left: 10px;
    }
    .erroruserInput {
      padding-left: 10px;
    }
  }
}
@media screen and (max-width: 685px) {
  .center-div {
    width: 90%;
    padding: 0;

    .inputN {
      padding-left: 10px;
    }
    .erroruserInput {
      padding-left: 10px;
    }
  }
}

@media screen and (max-width: 400px) {
  .center-div {
    .inputN {
      width: 100%;
    }
    .erroruserInput {
      width: 100%;
    }
    .submit {
      width: 70%;
      align-items: center;
      justify-content: center;
      margin-left: 15%;
    }
  }
}
