@use "../../styles/variables.scss" as color;
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bolder;
  }
  
  .calendar {
      position: relative;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    color:gray ;
    .date{
      padding-left: 6px;
    //   margin-top: -20%;
      color: color.$primary-color;
    }
  
    .datepicker_wrapper {
        position: absolute;
        top: 100%;
        right: 0%;
        margin-top: 5px;
    }
    
    .react-datepicker {
      position: absolute;
      top: 100%;
      left:auto;
      right: 0;
      z-index: 1;
    }
}
.container{
    margin: 1rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;
.notification{
    margin-top: 50px;
    display: flex;
    // height: 80px;
    padding: 20px;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.333);
    .imgnot{
        margin-right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        .imgn{
            width: 30px;
            height: 30px;
            padding: 0px;
            align-items: center;
            justify-content: center;
            &.imgnDark{
              color:white
            }
        }
       }
   .notifyCont{
    display: block;
    width: 300px;
   .notificationTitle{
    font-weight: 600;
    font-size: 15px;
    &.notificationTitleDark{
      color: white;
    }
   }
   .notificationContent{
    font-weight: 400;
    color: #5d5c5c;
    font-size: 13px;
    &.notificationContentDark{
      color: white;
    }
   }
  }
   .time{
    font-weight: 600;
    font-size: 10px;
    width: 100px;
    color: #0298a6;
    padding:15px ;
   }
  
   .time{
    font-weight: 600;
    font-size: 10px;
    width: 80px;
    color: #0298a6;
   }
}
   
}
@media (max-width: 1000px){
    .container{
      width: 100%;
      .notification{
         width:100%;
          display: flex;
          flex-direction: column;
          align-items: start;
          
          .imgnot {
              display: flex;
              width: 60px;
              height: 60px;
              justify-content: flex-start;
              .imgn {
                  display: flex;
                  flex-direction: column;
                  width: 30px;
                  height: 30px;
              }
            }
            
          .time{
            display: flex;
            margin-left: -12px;
        }
      }
          
      }
  }
  .no_payment{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  .img {
    max-width: 400px;
    margin-top: 45px;
  }
  .subtitle {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: color.$dark-text-color;
    margin-top: 23px;
    &.subtitleDark{
      color:white;
    }
  }
  .btn {
    margin-top: 50px;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: white;
    padding: 9px 149px;
    background: color.$primary-color;
    border-radius: 10px;
    &.btnDark{
      background-color: #015866;
    }
    &:hover{
      background-color: #01b1c0;
    }
  }
}
  .text {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: color.$light-gray-color;
    margin-top: 9px;
    text-align: center;
  }
  @media (max-width: 380px){
      .header{
          display: block;
      }
  
      }
    
    
@media screen and (max-width: 620px) {
  .sets{
    float: left;
  }
  .container {
    .title {
      font-size: 28.8px;
      line-height: 43.3px;
    }
    
    .subtitle {
      font-size: 19.2px;
      line-height: 28.8px;
      margin-top: 18.4px;
    }
    .text {
      font-size: 16px;
      line-height: 24px;
      margin-top: 7.2px;
    }
    .btn {
      margin-top: 40px;
      font-size: 19.2px;
      line-height: 28.8px;
      padding: 9px 119.2px;
    }
    .notification{ 
         .notifyCont{
          display: block;
          width: 300px;
         .notificationTitle{
          max-width: 250px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
         }
         .notificationContent{
          max-width: 300px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
         }
        }
        }
  }
}
@media screen and (max-width: 440px) {
  .img {
    width: 300px;
  }
  .container {
    .notification{ 
         .notifyCont{
         .notificationTitle{
          max-width: 200px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
         }
         .notificationContent{
          max-width: 200px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
         }
        }
        }
  }
}
  
  