@use "../../../styles/variables.scss" as color;
.overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.1);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
}
.modal_styles {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2001;
  background-color: white;
  width: 378px;
  height:320px;
  // max-height: 501.9px;
  overflow-y: auto;
  // height: 501.9px;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &.modal_stylesDark {
    background: #015866;
  }
  .close {
    cursor: pointer;
    position: sticky;
    top: 5px;
    margin-left: -50px;
    width: 32px;
    height: 32px;
    &.closeDark {
      background-color: #98dae0;
      border-radius: 50%;
    }
  }
  .title {
    &.titleDark {
      color: white;
    }
  }
}
  .filtercont {
    display: flex;
    align-items: center;
    margin-left: 20px;
    margin-top: 30px;
    margin-bottom: 20px;
    
    .filteric {
      margin-right: 10px;
      cursor: pointer;
      &.filtericDark {
        color: color.$white-text-color;
      }
    }
    .filter {
      font-weight: bold;
      &.filterDark {
        color: color.$white-text-color;
      }
    }
    .filterad {
      cursor: pointer;
      margin-left: auto;
      &.filteradDark {
        color: color.$white-text-color;
      }
    }
  }
  .eleline {
    height: 0.5px;
    width: 190px;
    background-color: rgb(185, 176, 176);
    margin-left: 20px;
    margin-bottom: 15px;
  }
  .elementcont {
    display: flex;
    align-items: center;
    margin-left: 30px;
    flex-direction: column;
  }
  .element {
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: start;
    // width: 100%;
    width: 200px;
    position: relative;
    &.elementDark {
      color: color.$white-text-color;
    }
  }
  .elementad {
    cursor: pointer;
    position: absolute;
    top: 40%;
    right: 0%;
    transform: translate(-50%, -50%);
  }
  .subelements {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .subelement {
      &.subelementDark {
        color: color.$white-text-color;
      }
    }
    .select_input_row {
      display: flex;
      margin-left: -50px;
      margin-bottom: 5px;
    }
    .select_option {
      width: 50px;
      font-size: 15px;
      margin-right: 10px;
      border-radius: 5px;
      &.select_optionDark {
        background-color: #015866;
      }
    }
    .small_input {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
      width: 60px;
      border-radius: 5px;
      font-size: 13px;
      padding-left: 2px;
      margin-right: 3px;
      margin-left: 3px;
      &.small_inputDark {
        background-color: #015866;
      }
    }
    .large_input {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
      width: 150px;
      border-radius: 5px;
      font-size: 13px;
      margin-right: 3px;
      margin-left: -40px;
      &.large_inputDark {
        background-color: #015866;
      }
    }
    .condition {
      display: block;
      margin-bottom: 10px;
      font-size: 15px;
      font-weight: 500;
      margin-left: -30px;
      .radio {
        display: inline-block;
        margin-right: 5px;
      }
    }
  }
  .reset {
    font-size: 15px;
    margin-left: 100px;
    text-decoration: underline;
    font-weight: 500;
  }
  .subeleline {
    height: 0.5px;
    width: 190px;
    background-color: rgb(185, 176, 176);
    margin-left: -50px;
    margin-bottom: 15px;
  }
  .distance {
    margin-left: -30px;
  }
  .distanceText {
    font-size: 13px;
    margin-left: -30px;
  }
  .button_container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    .apply{
      background-color: #0298a6;
      width: 80px;
      height: 40px;
      border-radius: 10px;
      color: white;
      font-weight: 600;
      &:hover{
        background-color: #01b1c0;
      }
    }
  }
  @media screen and (max-width: 445px) {
    .modal_styles{
      width: 90%;
      .close {
        cursor: pointer;
        position: sticky;
        top: 8px;
        margin-left: -60px;
        width: 20px;
        height: 20px;
      }
    }
  }
  @media screen and (max-width: 400px) {
    .modal_styles{
      .close {
        cursor: pointer;
        top: 8px;
        margin-left: -50px;
      }
    }
  }
  @media screen and (max-width: 380px) {
    .modal_styles{
      .close {
        cursor: pointer;
        margin-left: -40px;
      }
    }
  }
  @media screen and (max-width: 355px) {
    .modal_styles{
      .close {
        cursor: pointer;
        top:6px;
        margin-left: -30px;
      }
    }
  }
  @media screen and (max-width: 335px) {
    .modal_styles{
      .close {
        cursor: pointer;
        top:6px;
        margin-left: -20px;
      }
    }
  }