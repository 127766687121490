@use "../../styles/variables.scss" as color;
.help_section {
  padding: 23px 65px;
  &.help_sectionDark{
  background: color.$dark-text-color;
  }
  .title {
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    text-align: center;
    color: color.$dark-text-color;
    &.titleDark{
      color:color.$white-text-color;
    }
  }
  .search_container {
    margin-top: 45px;
    margin-bottom: 45px;
  }
  .help {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: color.$dark-text-color;
    margin-bottom: 45px;
    &.helpDark{
      color:color.$white-text-color;
    }
  }
  .card_container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 85px 100px;
    margin: 0px 140px;
    margin-top: 60px;
    &.card_container:last-child {
      justify-content: flex-start;
  }
  }
  @media screen and (max-width: 1520px) {
    .card_container {
      gap: 18px;
      margin-left: 200px;
    }
  }
  @media screen and (max-width: 1410px) {
    .card_container {
      gap: 18px;
      margin-left: 150px;
    }
  }
  @media screen and (max-width: 1360px) {
    padding: 23px 23px;
    width: 1250px;
    .card_container {
      gap: 16px;
      margin-left: 100px;
    }
  }
  @media screen and (max-width: 1200px) {
   width: auto;
    .card_container {
      margin: 20px 60px;
      
    }
  }
  @media screen and (max-width: 1040px) {
   width: auto;
    .card_container {
      margin: 20px 30px;
      
    }
  }
  @media screen and (max-width: 980px) {
    .card_container {
      margin: 20px 0px;
    }
  }
  @media screen and (max-width: 920px) {
    .card_container {
      margin: 20px 140px;
      width: 650px;
    }
  }
  @media screen and (max-width: 840px) {
    .card_container {
      width: auto;
      margin: 0px 90px;
    }
    padding: 23px 30px;
  }
  @media screen and (max-width: 820px) {
    .card_container {
      width: auto;
      margin: 0px 250px;
    }
    padding: 23px 30px;
  }
  @media screen and (max-width: 750px) {
    .card_container {
      flex-direction: column;
      align-items: center;
      gap: 40px 0;
      margin: 0;
    }
  }
  @media screen and (max-width: 612px) {
    .title {
      font-size: 28.8px;
      line-height: 43.2px;
      margin-top: 10px;
    }
  }
  @media screen and (max-width: 400px) {
    .card_container {
      margin: 0px;
    }
  }
}
