@use "../../../../styles/variables.scss" as color;
.category_section {
  padding: 0 65px;
  &.category_sectionDark{
    background-color: color.$dark-text-color;
    padding-bottom:20px;
  }
  .path {
    margin-top: 22px;
  }
  .search {
    margin-top: 55px;
    margin-bottom: 45px;
    display: flex;
    align-items: center;
  justify-content: center;
  }
  .title_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 46px;
    .title {
      font-weight: 600;
      font-size: 36px;
      line-height: 54px;
      color: color.$dark-text-color;
      &.titleDark{
        color:color.$white-text-color;
      }
    }
   
    .category__type {
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: color.$dark-text-color;
      border: 2px solid color.$primary-color;
      border-radius: 15px;
      &.category__typeDark{
        border: 2px solid #015866;
      }
      button {
        padding: 12px 27px;
        background-color: white;
        cursor: pointer;
        color: color.$dark-text-color;
        border-radius: 16px;
        &.buttonDark{
          background-color: color.$dark-text-color;
          color:color.$white-text-color
        }
        &.active {
          font-weight: 600;
          color: white;
          border: 2px solid color.$primary-color;
          border-radius: 12px;
          background-color: color.$primary-color;
        }
        &.activeDark{
          background-color:#015866 ;
          border: 2px solid #015866;
          border-radius: 12px;
          color: color.$white-text-color;
        }
      }
    }
  }
 
  .result_container {
    padding: 0 19px;
    border-bottom: 2px solid rgba(53, 56, 68, 0.6);
    margin-bottom: 37px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.result_containerDark{
      border-bottom: 2px solid rgba(207,203,203);
    }
    .result_title {
      font-weight: 700;
      font-size: 32px;
      line-height: 48px;
      color: color.$primary-color;
      &.result_titleDark{
        color:#015866
      }
    }
    .result_options {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: color.$dark-text-color;
      .filter_container{
        display: flex;
        font-size: 20px;
        // border: 2px solid #0298a6;
        border-radius: 10px;
        height: 50px;
        width: 100px;
        margin-top: -10px;
        align-items: center;
        justify-content: center;
        float: right;
        cursor: pointer;
        .filter_btn{
          color:#0298a6;
          font-weight: 600;
        }
        .filter_icon{
          color:#0298a6;
          margin-top: 4px;
          margin-right: 5px;
        }
      }
      .label{
        display: inline-block;
        margin-right: 10px;
        &.labelDark{
          color:color.$white-text-color;
        }
      }
      .select{
        display: inline-block;
        padding: 5px 10px 5px 10px;
        font-size: 16px;
        width: auto;
        border: 1px solid color.$primary-color;
        border-radius: 4px;
        background-position: right 10px center;
        &.selectDark{
          background-color: #015866;
          color:color.$white-text-color;
        }
        &:focus {
          outline: none;
          box-shadow: 0 0 3px #999;
        }
      }
      .sort_icon {
        display: inline-block;
        width: 40px;
        height: 40px;
        &.sort_iconDark{
          color: color.$white-text-color;
        }
      }
      .view_icon {
        display: inline-block;
        width: 30px;
        height: 30px;
        color: color.$primary-color;
        margin-left: 5px;
      }
      .span {
        margin-left: 30px;
        cursor: pointer;
        &.spanDark{
          color: color.$white-text-color;
        }
      }
    }
  }
  .card_container {
    display: flex;
    flex-wrap: wrap;
    gap: 18px;
    &.card_containerlist {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      // gap: 100px;
    }
    
  }
  @media screen and (max-width: 1515px) {
    .card_container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
  @media screen and (max-width: 1300px) {
    .title_container {
      .title {
        font-size: 27px;
        line-height: 40.5px;
      }
      .category__type {
        font-size: 15px;
        line-height: 22.5px;
        button {
          padding: 9px 20.25px;
        }
      }
    }
    .result_container {
      .filter_container{
        display: flex;
        font-size: 20px;
        // border: 2px solid #0298a6;
        border-radius: 10px;
        height: 50px;
        width: 100px;
        margin-top: -15px;
        align-items: center;
        justify-content: center;
        float: right;
        cursor: pointer;
        .filter_btn{
          color:#0298a6;
          font-weight: 600;
        }
        .filter_icon{
          color:#0298a6;
          margin-top: 4px;
          margin-right: 5px;
        }
      }
      .result_title {
        font-size: 24px;
        line-height: 36px;
      }
      .result_options {
        font-size: 15px;
        line-height: 22.5px;
        .sort_icon {
          width: 35px;
          height: 35px;
        }
        .view_icon {
          width: 26px;
          height: 26px;
        }
        .span {
          margin-left: 22.5px;
        }
      }
    }
  }
  @media screen and (max-width: 795px) {
    padding: 0 40px;
    .title_container {
      .title {
        font-size: 20.25px;
        line-height: 30.375px;
      }
      .category__type {
        font-size: 11.25px;
        line-height: 16.875px;
        button {
          padding: 6.75px 15.1875px;
        }
      }
    }
    .result_container {
      .result_title {
        font-size: 21px;
        line-height: 27px;
      }
      .result_options {
        font-size: 10px;
        line-height: 16.875px;
        .select{
          display: inline-block;
          padding: 5px 10px 5px 10px;
          font-size: 18px;
          width: 100px;
          font-size: 10px;
          border: 1px solid color.$primary-color;
          border-radius: 4px;
          background-position: right 10px center;
        }
        .sort_icon {
          width: 35px;
          height: 35px;
        }
        .view_icon {
          width: 26px;
          height: 26px;
        }
        .span {
          margin-left: 22.5px;
        }
      }
    }
  }
  @media screen and (max-width: 670px) {
    .result_container {
      .result_title {
        font-size: 16px;
        line-height: 27px;
      }
      .result_options {
        font-size: 10px;
        line-height: 16.875px;
        .filter_container{
          display: flex;
          font-size: 18px;
          // border: 2px solid #0298a6;
          border-radius: 10px;
          height: 50px;
          width: 100px;
          margin-top: -17px;
          align-items: center;
          justify-content: center;
          float: right;
          cursor: pointer;
          .filter_btn{
            color:#0298a6;
            font-weight: 600;
          }
          .filter_icon{
            color:#0298a6;
            margin-top: 4px;
            margin-right: 5px;
          }
        }
        .select{
          display: inline-block;
          padding: 5px 10px 5px 10px;
          font-size: 18px;
          width: 100px;
          font-size: 10px;
          border: 1px solid color.$primary-color;
          border-radius: 4px;
          background-position: right 10px center;
        }
        .sort_icon {
          width: 30px;
          height: 30px;
        }
        .view_icon {
          width: 20px;
          height: 20px;
        }
        .span {
          margin-left: 22.5px;
        }
      }
    }
  }
  @media screen and (max-width: 520px) {
    padding: 0 14px;
   
    .result_container {
      padding: 0;
      .result_title {
        font-size: 13px;
        line-height: 27px;
      }
    }
  }
  @media screen and (max-width: 465px) {
    .title_container{
      .title{
        font-size: 13px;
      }
    }
    .span{
      display: none;
    }
    .card_container {
      flex-direction: column;
      align-items: center;
    }
  }
  @media screen and (max-width: 410px) {
    padding: 0px;
    .title_container{
      .filter_container{
        display: flex;
        font-size: 13px;
        // border: 2px solid #0298a6;
        border-radius: 10px;
        height: 35px;
        width: 100px;
       
      }
    
    }
    .result_container {
      
      .result_title {
        font-size: 12px;
        line-height: 27px;
      }
    
  }
}
  @media screen and (max-width: 380px) {
    .title_container {
      .title {
        font-size: 12px;
        line-height: 30.375px;
      }
      .category__type {
        font-size: 9px;
        line-height: 16.875px;
        button {
          padding: 6.75px 15.1875px;
        }
      }
    }
    .result_container {
      
      .result_title {
        font-size: 12px;
        line-height: 27px;
      }
      .result_options {
        font-size: 8px;
        line-height: 16.875px;
        .sort_icon {
          width: 25px;
          height: 25px;
        }
        .view_icon {
          width: 16px;
          height: 16px;
        }
        .span {
          margin-left: 22.5px;
        }
      }
    }
  }
}
