@use "../../styles/variables.scss" as color;
.subtitle {
  margin-top: 44px;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  color: #353844;
  &.subtitleDark{
    color:color.$white-text-color;
    padding-bottom:50px ;
  }
  @media screen and (max-width: 620px) {
    font-size: 28.8px;
    line-height: 43.2px;
    margin-top: 35.2px;
  }
}
.categories_container {
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  margin-top: 50px;
  flex-wrap:wrap;
  gap: 2.5% ;
 
  &.categories_containerDark{
    background-color: color.$dark-text-color;
    margin-top: -10px;
  }
}
@media screen and (max-width: 1130px) {
  .categories_container {
    gap: 0%;
  }
}
@media screen and (max-width: 1050px) {
  .categories_container {
    gap: 10%;
  }
}
@media screen and (max-width: 770px) {
  .categories_container {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
}
