.chatheader {
    display: flex;
    align-items: center;
    border-top: 2px solid rgba(0, 0, 0, 0.2);
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    
  }
  
  .profile_pic_header {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #ccc;
    margin-right: 10px;
  }
  .content_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
  }
  .detailsheader{
  .nameheader {
    font-size: 16px;
    font-weight: 700;
    padding-top: 15px;
  }
  
  .last_seenheader {
    font-size: 12px;
    color: #888;
    margin-top: 0px;
  }
}
.icons_containerh {
    display: flex;
.iconh {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    color:#0298a6;
    cursor: pointer;
  }
}
.threedots{
  position: relative;
.dotDrop{
  position: absolute;
  top: 100%;
  left: -200px;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 200px;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.dotDrop button {
  width: 100%;
  text-align: left;
  padding: 5px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  .edit{
      font-size: 15px;
      font-weight: 500;
      display: inline-flex;
      align-items: center;
      margin: 0;
      padding: 0;
      .ic{
          margin-right:10px ;
      }
  }
  .delete{
      font-size: 15px;
      font-weight: 500;
      color: red;
      display: inline-flex;
      align-items: center;
      margin: 0;
      padding: 0;
      .icd{
          margin-right:10px ;
          color: red;
      }
    }
  }
}
.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  width: 400px;
}
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
textarea {
  width: 100%;
  resize: vertical;
}
button[type='submit'] {
  margin-top: 10px;
}