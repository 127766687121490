.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // margin-bottom: 50px;
  padding-bottom: 50px;
  .text {
    text-align: center;
    max-width: 413px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #666666;
    margin-top: 45px;
    &.textDark{
      color: white;
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    margin-top: 19px;
    align-items: center;
    .main_setting {
      background: #f0f0f0;
      border-radius: 10px;
      width: 650px;
      height: 63px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 17px 20px;
      .title {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #353844;
        .icon {
          display: inline-block;
          margin-right: 28.8px;
        }
      }
    }
    .setting {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #666666;
      width: 608px;
      margin-top: 12px;
      .setting_title {
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: #353844;
        &.setting_titleDark{
          color: white;
        }
      }
      .setting_text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #666666;
        max-width: 508px;
        margin-bottom: 12px;
        &.setting_textDark{
          color: white;
        }
      }
    }
    
  }
  
  @media screen and (max-width: 710px) {
    .container {
      .main_setting {
        width: 100vw;
        .title {
          font-size: 16px;
          line-height: 24px;
          .icon {
            margin-right: 23.04px;
            width: 25px;
            height: 25px;
          }
        }
      }
      .setting {
        width: 90vw;
        margin-bottom: 9.6px;
        .setting_title {
          font-size: 14.4px;
          line-height: 21.6px;
        }
        .setting_text {
          font-size: 12.8px;
          line-height: 19.2px;
          margin-bottom: 9.6px;
          max-width: 70%;
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    .container {
      .setting {
        .setting_title{
          width: 130px;
        }
        .setting_text{
          width: 300px;
        }
      }
    }
  }
  @media screen and (max-width: 430px) {
    .container {
      .setting {
        .setting_title{
          width: 130px;
        }
        .setting_text{
          width: 200px;
        }
      }
    }
  }
}
