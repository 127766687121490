@use "../../../styles/variables.scss" as color;
.card_section {
  height: 166px;
  // width: 450px;
  background: #ffffff;
  box-shadow: 0px 2.93px 8.32px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  &.card_sectionDark{
    background-color: #015866;
  }
  .image_container {
    position: relative;
    width: 229px;
    height: 166px;
    .img {
      width: 160px;
      height: 166px;
      object-fit: cover;
      border-radius:10px ;
    }
    .stateA {
        position: absolute;
        top: 10%;
        left: 35%;
        transform: translate(-50%, -50%);
        font-size: 18px;
        color: white;
        font-weight: bold;
        text-align: center;
        width: 90px;
        max-width: 160px;
        z-index: 1;
        background-color: color.$primary-color;
      }
      .track {
        position: absolute;
        top: 60%;
        left: 35%;
        transform: translate(-50%, -50%);
        font-size: 13px;
        color: white;
        font-weight: 500;
        text-align: center;
        width: 90px;
        height: 30px;
        max-width: 160px;
        border-radius: 5px;
        line-height: 30px;
        z-index: 1;
        background-color: color.$primary-color;
        cursor: pointer;
        &:hover {
          background-color: #01b1c0;
        }
      }
    .stateR {
        position: absolute;
        top: 50%;
        left: 35%;
        transform: translate(-50%, -50%);
        font-size: 18px;
        color: white;
        font-weight: bold;
        text-align: center;
        width: 90px;
        max-width: 160px;
        z-index: 1;
        background-color: #e76859;
      }
    .stateW {
        position: absolute;
        top: 50%;
        left: 35%;
        transform: translate(-50%, -50%);
        font-size: 18px;
        color: white;
        font-weight: bold;
        text-align: center;
        width: 90px;
        max-width: 160px;
        z-index: 1;
        background-color: color.$primary-color;
      }
    .featured_containermb {
      position: absolute;
      top: 1px;
      right: 0px;
      width: 65px;
      height: 25px;
      background-color: color.$yellow-color;
      padding: 7.91px 9.13px 6.92px 8.91px;
      border-radius: 10px;
      .featured_textmb {
        font-weight: 600;
        font-size: 12px;
        line-height:8px;
        text-align: center;
        color: #000000;
      }
    }
  }
  .details_containermb {
    width: 176px;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 41px;
    
    .price__containermb {
      display: flex;
      align-items: center;
      &.price__containermbdisc{
        display: flex;
      flex-direction: column;
      align-items: normal;
      }
    .pricemb {
      font-weight:700;
      font-size: 14px;
      line-height: 20px;
      color: color.$dark-text-color;
      margin-left: -50px;
      max-width: 250px; 
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &.pricembDark{
        color: #ffffff;
      }
    }
    .oldmbpd{
      display: flex;
      // align-items: center;
      margin-top: -20px;
    .oldmbprice {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 40px;
      margin-left: -40px;
      color: #e76859;
      text-decoration: line-through;
      max-width: 150px; 
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      // &.oldmbpriceDark{
      //   color: color.$red-color;
      // }
    }
    .discountmb{
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 40px;
      margin-left: 10px;
      color: #e76859;
    
    // &.discountmbDark{
    //   color: color.$red-color;
    // }
  }
    }
  }
    .title {
      font-weight: 600;
      font-size: 13px;
      line-height: 107%;
      color: color.$dark-text-color;
      margin-left: -50px;
      &.titleDark{
        color: #ffffff;
      }
      &.titlembdisc{
        margin-top: -8px;
      }
    }
    .location,
    .time {
      font-weight: 600;
      font-size: 10px;
      line-height: 107%;
      color: color.$light-text-color;
      margin-left: -50px;
      margin-top: -5px;
      &.locationDark{
        color: #ffffff;
      }
      &.timeDark{
        color: #ffffff;
      }
    }
    // .location {
    //   margin-top: 20px;
    // }
    // .time {
    //   margin-top: 9px;
    // }
    .icon {
      display: inline-block;
      width: 15px;
      height: 15px;
      color: color.$primary-color;
      margin-right: 5px;
    }
    .heart_icon {
      position: absolute;
      top: 1px;
      right: 13.8px;
      color: color.$primary-color;
      font-size: 18px;
      cursor: pointer;
    }
    .condition_container {
      position: absolute;
      top: -1px;
      right: 35px;
      // z-index: 3;
      .condition_image {
        width: 52px;
        height: 30.4px;
        
      }
      .condition {
        font-weight: 500;
        font-size: 12.8px;
        line-height: 19.2px;
        text-align: center;
        color: #ffffff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  @media screen and (max-width: 475px) {
    &.card_sectionImported{
    height: 132.8px;
    width:230px;
    }
    .image_container {
      &.image_containerImported{
      width: 130px;
      height: 100px;
      }
      .img {
        &.imgImported{
        width: 130px;
        height: 133px;
        object-fit: cover;
        border-radius:10px ;
        }
      }
      .featured_container {
        &.featured_containerImported{
        width: 50px;
        height: 15.104px;
        right: -40%;
        }
        .featured_text {
          &.featured_textImported{
          font-size: 8px;
          line-height: 1px;
          }
        }
      }
    }
    .details_containermb {
      
      &.details_containerImported{
      width: 140.8px;
      padding-top: 32.8px;
      left: 22%;
      }
      .price {
        &.priceImported{
        font-size: 10.57448px;
        line-height: 16px;
      }
      }
      .title {
        &.titleImported{
        font-size: 9.6px;
        line-height: 10%;
        }
      }
      .location,
      .time {
        font-size: 8px;
        line-height: 10%;
      }
  
      .icon {
        width: 12px;
        height: 12px;
        margin-right: 4px;
      }
      .heart_icon {
        cursor: pointer;
        &.heart_iconImported{
        top: 5px;
        left:52px;
        cursor: pointer;
        }
      }
      .condition_container {
        &.condition_containerImported{
          .condition {
            // color: red;
            margin-left: -80%;
          }
        .condition_image {
          &.condition_imageImported{
          width: 40px;
          height: 30.4px;
          margin-left:-85%;
          }
        }
      }
      }
      .condition {
        .conditionImported{
        margin-left: -78%;
        
        .img_condition {
          width: 20px;
          height: 32.05px;
        }
        .text_condition {
          font-size: 8.8px;
          line-height: 12.8px;
        }
      }
    }
    }
  
  }
  @media screen and (max-width: 450px) {
    height: 132.8px;
    width: 324px;
    .image_container {
      width: 130px;
      height: 100px;
      .img {
        width: 130px;
        height: 133px;
        object-fit: cover;
        border-radius:10px ;
        
      }
      .stateA {
        position: absolute;
        top: 50%;
        left: 45%;
      }
      .stateW {
        position: absolute;
        top: 70%;
        left: 45%;
      }
      .stateR {
        position: absolute;
        top: 70%;
        left: 45%;
      }
      .track {
        position: absolute;
        top: 100%;
        left: 45%;
       
      }
      .featured_container {
        width: 50px;
        height: 15.104px;
        right: -40%;
        top:0px;
        .featured_text {
          font-size: 8px;
          line-height: 1px;
        }
      }
    }
    .details_containermb {
      width: 140.8px;
      padding-top: 32.8px;
      left: 20%;
      .price__containermb{
      .pricemb {
        font-size: 10.57448px;
        line-height: 16px;
      }
      .oldmbpd{
        .oldmbprice{
          font-size: 10.57448px;
          // line-height: 16px;
        }
      
      .discountmb{
        font-size: 10.57448px;
      }
    }
    }
      .title {
        font-size: 9.6px;
        line-height: 10%;
      }
      .location,
      .time {
        margin-top: -5px;
        font-size: 8px;
        line-height: 10%;
      }
  
      .icon {
        width: 12px;
        height: 12px;
        margin-right: 4px;
      }
      .heart_icon {
        // top: 8px;
        right: 11.04px;
      }
      // .condition {
      //   right: 40px;
      //   top: -4px;
      //   padding-right: 50%;
      //   .img_condition {
      //     width: 25.18px;
      //     height: 32.05px;
      //   }
      //   .text_condition {
      //     font-size: 8.8px;
      //     line-height: 12.8px;
      //   }
      // }
    }
  }
  @media screen and (max-width: 380px) {
    height: 132.8px;
    width: 280px;
    .image_container {
      width: 130px;
      height: 100px;
      .img {
        width: 130px;
        height: 133px;
        object-fit: cover;
        border-radius:10px ;
      }
      .featured_container {
        width: 50px;
        height: 15.104px;
        right: -40%;
        .featured_text {
          font-size: 8px;
          line-height: 1px;
        }
      }
    }
    .details_containermb {
      width: 140.8px;
      padding-top: 32.8px;
      left: 20%;
      .price {
        font-size: 10.57448px;
        line-height: 16px;
      }
      .title {
        font-size: 9.6px;
        line-height: 10%;
       
      }
      .location,
      .time {
        
        font-size: 8px;
        line-height: 10%;
      }
  
      .icon {
        width: 12px;
        height: 12px;
        margin-right: 4px;
      }
      .heart_icon {
        top: 5px;
        right: 33%;
      }
      .condition_container {
        .condition_image {
          width: 40px;
          height: 30.4px;
          margin-left:-85%;
        }
      }
      .condition {
        margin-left: -87%;
        .img_condition {
          width: 20px;
          height: 32.05px;
        }
        .text_condition {
          font-size: 8.8px;
          line-height: 12.8px;
        }
      }
    }
  }
}
.confirm_title {
  font-weight: 600;
  font-size: 25.6px;
  line-height: 38.4px;
  color: color.$dark-text-color;
  margin-bottom: 15px;
}
.confirm_message {
  font-weight: 500;
  font-size: 19.2px;
  line-height: 28.8px;
  color: color.$dark-text-color;
  margin-bottom: 15px;
}
.buttons_container {
  display: flex;
  gap: 20px;
  button {
    border-radius: 10px;
    padding: 10.4px 35.2px;
    font-weight: 600;
    font-size: 19.2px;
    line-height: 28.8px;
    color: #ffffff;
  }
  .confirm {
    background-color:  color.$primary-color;
  }
  .cancel {
    background-color: color.$red-color;
  }
}
.link_image_container {
  text-decoration: none; /* Remove underlines */
  color: inherit; /* Use the default text color */
  cursor: pointer; /* Show pointer cursor on hover */
  display: inline-block; /* Ensure the link takes up only necessary space */
}